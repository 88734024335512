import tw from "twin.macro";
import IoTDevice from "assets/pages/Dashboard/DeviceControl/IoTDevice.svg";
import NextRightIcon from "assets/common/icon/NextRightIcon.svg";

const Container = tw.div`col-span-2 w-full grid grid-cols-4 gap-4`;

const Card = tw.div`h-36 shadow-[1px 3px 15px rgba(189, 190, 200, 0.15)] rounded-lg bg-white p-4 flex flex-col col-span-1`;

const IconWrap = tw.div`flex items-center justify-between mb-6`;

const IconContainer = tw.div`h-8 w-8 rounded-full bg-[rgba(219, 228, 255, 0.5)] p-2`;

const SubdeviceName = tw.p`text-altoDark text-base mb-2 font-medium`;

const ParameterTypeWrap = tw.div`w-full flex justify-start gap-x-2`;

const ParameterType = tw.div`flex items-center text-xs text-altoDark-medium capitalize leading-normal`;

const ParameterValue = tw.div`h-6 w-[72px] text-center text-xs font-bold text-altoDark-medium flex justify-center items-center bg-altoWhite-white02/50 rounded-lg`;

interface AutomationValueProps {
  [roomName: string]: { [agentId: string]: any };
}

interface TriggerActionCardProps {
  value: AutomationValueProps | undefined;
  type: "parameter" | "action";
}

interface AutomationListProps {
  agentId: string;
  subdeviceName: string;
  compartuor: string;
  value: any;
}
export const TriggerActionCard = ({ value, type }: TriggerActionCardProps) => {
  const convertObjectToArrayByType = (data: AutomationValueProps, type: TriggerActionCardProps["type"]): AutomationListProps[] => {
    let result: any = [];
    Object.keys(data).forEach((roomName: string) => {
      Object.keys(data[roomName]).forEach((subdeviceName: string) => {
        let commandObject;
        if (type === "action") {
          commandObject = data[roomName][subdeviceName]["command"];
          const commandKey = Object.keys(commandObject)[0];
          const commandValue = Object.values(commandObject)[0];
          const agentId = "bac0hvac";
          result.push({
            agentId: agentId,
            subdeviceName: subdeviceName,
            commandKey: commandKey,
            commandValue: commandValue,
          });
        } else if (type === "parameter") {
          commandObject = data[roomName][subdeviceName];
          const commandKey = Object.keys(commandObject)[0];
          const commandValue = Object.values(commandObject[commandKey])[0];
          const comparator = Object.keys(commandObject[commandKey])[0];
          const agentId = "bac0hvac";
          result.push({
            agentId: agentId,
            subdeviceName: subdeviceName,
            comparator: comparator,
            commandKey: commandKey,
            commandValue: commandValue,
          });
        }
      });
    });
    return result;
  };

  return (
    <>
      <Container>
        {value &&
          Object.keys(value).length !== 0 &&
          convertObjectToArrayByType(value, type).map((automationData: any) => (
            <Card>
              <IconWrap>
                <IconContainer>
                  <img src={IoTDevice} />
                </IconContainer>
                <img src={NextRightIcon} />
              </IconWrap>
              <SubdeviceName>{automationData?.subdeviceName}</SubdeviceName>
              <ParameterTypeWrap>
                <ParameterType>{automationData?.commandKey} :</ParameterType>
                <ParameterValue>
                  {automationData?.comparator} {automationData?.commandValue}
                </ParameterValue>
              </ParameterTypeWrap>
            </Card>
          ))}
      </Container>
    </>
  );
};
