import { useState } from "react";
import Indoor from "assets/pages/Dashboard/weather/Indoor.svg";
import GradientIndoorTop from "assets/pages/Dashboard/weather/GradientIndoorTop.svg";
import GradientIndoorBottom from "assets/pages/Dashboard/weather/GradientIndoorBottom.svg";
import CO2 from "assets/pages/Dashboard/weather/CO2.svg";
import AirQuality from "assets/pages/Dashboard/weather/Strom.svg";
import styled from "styled-components";
import { BlockContainer } from "components/Common/components";
import { useBuildingContext } from "layouts/BuildingProvider";
import tw from "twin.macro";

interface OutdoorType {
  data: any;
  siteName: string;
}

const Container = styled(BlockContainer)`
  background-color: tomato;
  border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 10px;
  background: linear-gradient(1.82deg, rgba(226, 160, 49, 0.2) 47.97%, rgba(255, 255, 255, 0.1) 131.89%);
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.21), 0px 16px 32px -4px rgba(225, 230, 240, 0.24);
`;

const LabelCard = styled.div<any>`
  display: flex;
  height: 17px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 3px;
  padding-bottom: 2px;
  margin-top: 3px;
  font-weight: lighter;
  font-size: 10px;
  color: #ffffff;
  background: ${(props) => (props.value < 50 ? "#64BD4C" : props.value < 100 ? "#F5A623" : "#eb2300")};
  border-radius: 4px;
`;

const WeatherInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const ImageDetailContainer = styled.div`
  position: relative;
  top: 5px;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 13px;
`;

const Img = styled.img``;

const RadialImageTop = styled.img`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
  width: 70%;
`;

const RadialImageBottom = styled.img`
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 0;
  width: 40%;
`;

const WeatherDetail = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 30px 10px 30px;
  z-index: 9;
`;

const Detial = styled.div`
  margin-left: 10px;
  display: inline-grid;
`;

const WeatherHeaderContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 30px 0px 20px;
`;

const ImageContainer = styled.div`
  position: relative;
  top: 0px;
  width: 46px;
  height: 46px;
  display: flex;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  background: #ffffff97;
  border-radius: 22.5px;
`;

const OutdoorImage = styled.img`
  width: 28px;
  height: 28px;
`;

const OutdoorText = styled.p`
  font-size: 10px;
  margin: 0px;
`;

const DotsContainer = styled.div(() => [tw`absolute bottom-2 left-[calc(50% - 8px)] flex gap-x-2 h-2`]);

const Dot = styled.div<any>`
  ${tw`w-2 h-2 rounded-full cursor-pointer`};
  ${({ active }) => (active ? `background: #0E7EE4` : `background: #C0E8FF`)}
`;

const FloorList = [
  { label: "Floor1", value: "floor_1" },
  { label: "Floor2", value: "floor_2" },
];

const FloorSelection = ({ selectedFloor, setSelectedFloor }: any) => {
  return (
    <>
      <DotsContainer>
        {FloorList.map((list: { label: string; value: string }) => (
          <Dot key={list.value} active={selectedFloor.value === list.value} onClick={() => setSelectedFloor(list)} />
        ))}
      </DotsContainer>
    </>
  );
};

export const IndoorWeather = ({ data, siteName }: OutdoorType) => {
  const { buildingCode } = useBuildingContext();
  const [selectedFloor, setSelectedFloor] = useState({ label: "Floor1", value: "floor_1" });

  const getValueByBuilding = (indoorValue: any, buildingName: string): { [floorName: string]: any } => {
    if (buildingName === "DAIC") {
      return {
        floor_1: {
          temp: indoorValue ? Number(indoorValue?.floor_1?.temperature?.value).toFixed(1) : 0,
          co2: indoorValue ? Number(indoorValue?.floor_1?.co2?.value).toLocaleString() : 0,
          air_quality_index: indoorValue ? Number(indoorValue?.floor_1?.air_quality_index?.value).toLocaleString() : 0,
          humidity: indoorValue ? Number(indoorValue?.floor_1?.humidity?.value).toLocaleString() : 0,
        },
        floor_2: {
          temp: indoorValue ? Number(indoorValue?.floor_2?.temperature?.value).toFixed(1) : 0,
          co2: indoorValue ? Number(indoorValue?.floor_2?.co2?.value).toLocaleString() : 0,
          air_quality_index: indoorValue ? Number(indoorValue?.floor_2?.air_quality_index?.value).toLocaleString() : 0,
          humidity: indoorValue ? Number(indoorValue?.floor_2?.humidity?.value).toLocaleString() : 0,
        },
      };
    } else if (buildingName === "SHE" || buildingName === "LTLS") {
      return {
        floor_1: {
          temp: indoorValue ? Number(indoorValue?.floor_1?.temperature?.value).toFixed(1) : 0,
          co2: indoorValue ? Number(indoorValue?.floor_1?.co2?.value).toLocaleString() : 0,
          air_quality_index: indoorValue ? Number(indoorValue?.floor_1?.air_quality_index?.value).toLocaleString() : 0,
          humidity: indoorValue ? Number(indoorValue?.floor_1?.humidity?.value).toLocaleString() : 0,
        },
      };
    } else {
      return {
        floor_1: {
          temp: indoorValue ? Number(indoorValue?.temperature?.value).toFixed(1) : 0,
          co2: indoorValue ? Number(indoorValue?.co2?.value).toLocaleString() : 0,
          air_quality_index: indoorValue ? Number(indoorValue?.air_quality_index?.value).toLocaleString() : 0,
          humidity: indoorValue ? Number(indoorValue?.humidity?.value).toLocaleString() : 0,
        },
      };
    }
  };

  return (
    <>
      <Container>
        <RadialImageTop src={GradientIndoorTop} alt="gradient" width="100%" />
        <RadialImageBottom src={GradientIndoorBottom} alt="gradient" width="100%" />
        <WeatherInfo>
          <WeatherHeaderContainer style={{ width: "60%", paddingRight: 0 }}>
            <ImageContainer>
              <OutdoorImage src={Indoor} alt="indoor" />
            </ImageContainer>
            <OutdoorText>
              {siteName}
              <br />
              {selectedFloor.label}
            </OutdoorText>
          </WeatherHeaderContainer>
          <WeatherHeaderContainer style={{ justifyContent: "flex-end", width: "40%" }}>
            <h4 style={{ display: "flex", fontSize: "32px", padding: "0px", margin: "0px" }}>
              {getValueByBuilding(data, buildingCode)[selectedFloor.value]["temp"]}
              <span style={{ fontSize: "12px", paddingTop: "24px" }}>&#8451;</span>
            </h4>
          </WeatherHeaderContainer>
        </WeatherInfo>

        <WeatherDetail>
          <div style={{ width: "100%", display: "flex" }}>
            <ImageDetailContainer>
              <Img src={CO2} alt="co2" />
            </ImageDetailContainer>
            <Detial>
              <small style={{ fontSize: "10px", color: "#788796" }}>Carbon Dioxide</small>
              <strong style={{ fontSize: "14px" }}>
                {getValueByBuilding(data, buildingCode)[selectedFloor.value]["co2"]}
                ppm
              </strong>
            </Detial>
          </div>

          <div style={{ width: "100%" }}>
            <div style={{ width: "100%", display: "flex" }}>
              <ImageDetailContainer>
                <Img src={AirQuality} alt="air" />
              </ImageDetailContainer>
              <Detial>
                <small style={{ fontSize: "10px", color: "#788796" }}>Air Quality Index</small>
                <strong style={{ fontSize: "14px", display: "flex" }}>
                  {getValueByBuilding(data, buildingCode)[selectedFloor.value]["air_quality_index"]}
                  &nbsp;{" "}
                  <LabelCard value={getValueByBuilding(data, buildingCode)[selectedFloor.value]["air_quality_index"]}>
                    {getValueByBuilding(data, buildingCode)[selectedFloor.value]["air_quality_index"] < 50
                      ? "Good"
                      : getValueByBuilding(data, buildingCode)[selectedFloor.value]["air_quality_index"] < 100
                      ? "Moderate"
                      : "Unhealthy"}
                  </LabelCard>
                </strong>
              </Detial>
            </div>
          </div>
        </WeatherDetail>
        <WeatherDetail>
          <div style={{ width: "100%", display: "flex" }}>
            <ImageDetailContainer>
              <Img src={CO2} alt="humi" />
            </ImageDetailContainer>
            <Detial>
              <small style={{ fontSize: "10px", color: "#788796" }}>Humidity</small>
              <strong style={{ fontSize: "14px" }}>{getValueByBuilding(data, buildingCode)[selectedFloor.value]["humidity"]} %</strong>
            </Detial>
          </div>
        </WeatherDetail>
        {buildingCode === "DAIC" && <FloorSelection selectedFloor={selectedFloor} setSelectedFloor={setSelectedFloor} />}
      </Container>
    </>
  );
};
