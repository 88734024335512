import styled from "styled-components";
import { BlockContainer, SubBlockHeader, BlockHeader4, BlockValue } from "components/Common/components";
import { Row, Col } from "antd";

type BgColor = {
  bgColor?: string;
};

interface SummarySavingCompareProps {
  accumulatedValue: string | number;
  header: string;
  imgUrl: string;
  bgColor?: string;
  unit: string;
}

interface EnergySavingCompareProps {
  thisMonthValue?: string | number;
  accumulatedValue: string | number;
  header: string;
  imgUrl: string;
  bgColor?: string;
}

const Container = styled(BlockContainer)<BgColor>`
  background: ${(props) => (props.bgColor ? props.bgColor : "linear-gradient(180deg, rgba(243, 249, 255, 0) 0%, rgba(230, 242, 255, 0.76) 100%)")};
  box-shadow: 0px 2px 8px rgba(145, 158, 171, 0.25);
  flex-direction: row;
`;

const Image = styled.img`
  height: 51px;
  width: 51px;
  margin-right: 13px;
`;

const Wrapper = styled.div`
  width: calc(100% - 64px);
  height: 200px;
`;

export const EnergySavingCompare = ({ thisMonthValue, accumulatedValue, header, imgUrl, bgColor }: EnergySavingCompareProps) => {
  return (
    <>
      <Container bgColor={bgColor}>
        <Image src={imgUrl} />
        <Wrapper>
          <Row gutter={[18, 18]}>
            <Col sm={24}>
              <Row>
                <SubBlockHeader>{header}</SubBlockHeader>
              </Row>
              <Row gutter={[18, 18]}>
                {thisMonthValue && (
                  <Col sm={12}>
                    <Row>
                      <BlockHeader4>This month</BlockHeader4>
                    </Row>
                    <Row>
                      <BlockValue>{thisMonthValue}</BlockValue>
                    </Row>
                  </Col>
                )}
                <Col sm={12}>
                  <Row>
                    <BlockHeader4>Accumulate</BlockHeader4>
                  </Row>
                  <Row>
                    <BlockValue>{accumulatedValue}</BlockValue>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Wrapper>
      </Container>
    </>
  );
};

const SummarySubBlockHeader = styled.h3`
  font-size: 20px;
  font-weight: 700;
  margin: 0px;
`;

const SummarySubBlockDesc = styled.h4`
  font-size: 14px;
  color: #212529;
  margin: 0px;
  margin-top: -4px;
  font-weight: 300;
`;

const SummaryContainer = styled(BlockContainer)<BgColor>`
  box-shadow: 0.695977px 2.08793px 9.0477px rgba(196, 196, 196, 0.15);
  flex-direction: row;
`;

const SummaryBlockValue = styled.p`
  font-size: 32px;
  font-weight: 500;
  margin: 0px;
  margin-top: 10px;
`;

export const SummarySavingCompare = ({ accumulatedValue, header, unit, imgUrl, bgColor }: SummarySavingCompareProps) => {
  return (
    <>
      <SummaryContainer bgColor={bgColor}>
        <Image src={imgUrl} />
        <Wrapper>
          <Row gutter={[18, 18]}>
            <Col sm={24}>
              <Row>
                <Col sm={24}>
                  <SummarySubBlockHeader>{header}</SummarySubBlockHeader>
                </Col>
                <Col sm={24}>
                  <SummarySubBlockDesc>Accumulated</SummarySubBlockDesc>
                </Col>
              </Row>
              <Row gutter={[18, 18]}>
                <Col sm={12}>
                  <Row>
                    <SummaryBlockValue>
                      {accumulatedValue} <small>{unit}</small>
                    </SummaryBlockValue>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Wrapper>
      </SummaryContainer>
    </>
  );
};
