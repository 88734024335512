import { useEffect } from "react"
import styled from 'styled-components'
import { useTable, useSortBy, usePagination } from 'react-table'

type TdProps = {
  [col: string]:
  {
    [att: string]: string
  }
}

const Td = styled.td<any>`
  margin: 0;
  padding: 0.5rem;
  width: 16.3%;
  border-right: 0px solid black;
  background-color: ${props => props.bgColor ? props.bgColor : 'transparent'};
  color: ${props => props.color ? props.color : 'black'};
  border-radius: ${props => props.first ? '10px 10px 0px 0px' : props.last ? '0px 0px 10px 10px' : '0px'};
  :last-child {
    border-right: 0;
  }
`

const Styles = styled.div<any>`
  padding: 1rem;

  table {
    border-spacing: 0;
    width: 100%;
    border-radius: 10px;
    border-collapse: collapse;
    border-radius: 10px;
    border-style: hidden; /* hide standard table (collapsed) border */
    font-size: 16px;

    tr {
      text-align: center;
      height: 55px;
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-right: 0px solid black;
      :last-child {
        border-right: 0;
      }
    }

    thead {
      /* background: #e3eeff; */
      opacity: 1;
      /* box-shadow: 1px 3px 20px rgba(196, 196, 196, 0.3); */
      /* backdrop-filter: blur(20px); */
      border-radius: 13px;
      
      th {
        :first-child{
          border-top-left-radius: 12px;
        }
        :last-child{
          border-top-right-radius: 12px;
        }
        color: #397CDD;
      }
    }
  }
`

const CellAttMap: TdProps = {
  bgrimm: {
    bgColor: '#FAD29159',
    color: '#EA663F'
  },
  zeb: {
    bgColor: '#DBE4FF4D',
    color: 'linear-gradient(77.25deg, #0E7EE4 9.22%, #14B8B4 90.78%)'
  }
}

export const PlainTable = ({ columns, data }: any) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  }: any = useTable<any>(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: false,
    },
    useSortBy,
    usePagination
  )
  const firstPageRows = rows.slice(0, 20)

  return (
    <Styles>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {firstPageRows.map(
            (row: any, i: number) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    let cellValue = CellAttMap[cell.column.id]
                    return (
                      <Td
                        {...cell.getCellProps()}
                        bgColor={cellValue && cellValue['bgColor']}
                        color={cellValue && cellValue['color']}
                        first={i === 0}
                        last={i === 4}
                      >
                        {['bec', 'heps', 'econ', 'zeb'].includes(cell.column.id) && cell.value !== "" && <span>&#8804; </span>}
                        {cell.render('Cell')}
                      </Td>
                    )
                  })}
                </tr>
              )
            }
          )}
        </tbody>
      </table>
      <br />
    </Styles>
  )
}
