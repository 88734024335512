import { useMemo } from "react";
import { Row, Col } from "antd";
import { LoadingPage } from "components/Common/LoadingPage/LoadingPage";
import { TableWithSelect } from "components/Common/Table/TableWithSelect";

interface DeviceTableSectionProps {
  data: any[];
  onSelectRow: any;
  limitOneRow?: boolean;
}

const DeviceTableSection = ({ data, onSelectRow, limitOneRow }: DeviceTableSectionProps) => {
  const columns = useMemo(
    () => [
      {
        Header: "Device ID",
        accessor: "device_id",
      },
      {
        Header: "Device Name",
        accessor: "device_name",
      },
      {
        Header: "Agent ID",
        accessor: "agent_id",
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
      },
    ],
    []
  );

  return (
    <>
      <Row gutter={[18, 18]} style={{ width: "100%" }}>
        <Col xs={24} xl={24}>
          {data === undefined && <LoadingPage height={"114px"} />}
          {data === null && <p style={{ width: "100%", textAlign: "center", marginTop: "50px", color: "#0E7EE4" }}>Coming soon</p>}
          {data && (
            <TableWithSelect
              columns={columns}
              data={data}
              pagination
              onSelectRow={onSelectRow}
              defaultPageSize={5}
              defaultPageSizeSelect={[5, 10, 15, 20]}
              limitOneRow={limitOneRow}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default DeviceTableSection;
