import styled from 'styled-components'
import { Col, Row } from 'antd'
import { MainBlockContainer } from "components/Common/components"
import Tab from 'components/Tab'

type EnergyTypeSectionProps = {
  tabNameList: any[]
  selectedTabName: string
  handleSelectTabName: (tabName: string) => void
  bgColor: string
}

const Container = styled(MainBlockContainer)`
  background: transparent;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 25px;
  margin-left: 0px;
  color: #586B7C;
`

const EnergyTypeSection = ({ tabNameList, selectedTabName, handleSelectTabName, bgColor }: EnergyTypeSectionProps) => {
  return (
    <Row gutter={[18, 18]}>
      <Col xs={24} xl={24}>
        <Container>
          <Tab
            dataList={tabNameList}
            selectedValue={selectedTabName}
            handleSelect={handleSelectTabName}
            bgColor={bgColor}
          />
        </Container>
      </Col>
    </Row>
  )
}

export default EnergyTypeSection