import { BlockBody, Container } from "./Components";
import { CameraDropdownDaikin } from "./CameraDropdownDaikin2";
import { FloorModel } from "./FloorModel/FloorModel";
import { CameraDropdownGardenWing } from "./CameraDropdownGardenWing";
import { SelfDrivingTab } from "components/Common/SelfDrivingTab/SelfDrivingTab";

interface BuildingProps {
  deviceList: any[];
  selectedTab: string;
  handleOpenModal: (e: any) => void;
  visibleDeviceObject: { [agent_id: string]: boolean };
  buildingName: string;
  allIotStatusFb: any;
  buildingCode: string;
}

export const Building = ({
  deviceList,
  selectedTab,
  handleOpenModal,
  visibleDeviceObject,
  buildingName,
  allIotStatusFb,
  buildingCode,
}: BuildingProps) => {
  const hotel_code = localStorage.getItem("hotel_code");
  return (
    <>
      <Container>
        <BlockBody>
          <FloorModel
            deviceList={deviceList}
            selectedTab={selectedTab}
            handleOpenModal={handleOpenModal}
            visibleDeviceObject={visibleDeviceObject}
            hotel_code={hotel_code}
            allIotStatusFb={allIotStatusFb}
          />
          <SelfDrivingTab />
          {buildingName === "Garden Wing" && <CameraDropdownGardenWing />}
          {buildingName === "Daikin CCC" && <CameraDropdownDaikin />}
        </BlockBody>
      </Container>
    </>
  );
};
