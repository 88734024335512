import styled from 'styled-components'
import { BlockContainer } from "../../../../components/Common/components";

export const Container = styled(BlockContainer)`
background-color: white;
display: flex;
flex-direction: column;
padding-top: 15px;
filter: drop-shadow(0px 2px 10px rgba(171, 183, 196, 0.2));
height: calc(100%);
padding: 30px 40px;
`

export const BuildingName = styled.h2`
font-size: 24px;
font-weight: 600;
color: #EA663F;
margin: 0px;
`

export const CompanyName = styled.p`
font-size: 16px;
margin: 0px;
color: #EA663F;
`

export const LocationRow = styled.div`
width: 100%;
height: fit-content;
display: flex;
align-items: center;
margin-top: 2px;
`

export const LocationName = styled.p`
font-size: 10px;
font-weight: 400;
margin: 0px 5px;
color: #586A7C;
`

export const LocationIcon = styled.img`
width: 20px;
height: 20px;
`

export const Underline = styled.div`
width: 100%;
height: 1px;
background-color: #EAEDEF;
margin: 8px 0px;
`

export const InfoBlockHeader = styled.h3`
font-size: 16px;
margin:0px;
font-weight: 500;
color: #EA663F;
margin-bottom: 10px;
`

export const Title = styled.h4`
  font-size: 14px;
  color: #586A7C;
  margin: 0px;
  font-weight: 300;
`

export const Desc = styled.p`
  font-size: 14px;
  color: #212529;
  margin: 0px;
  font-weight: 600;
`

export const DeviceImage = styled.img`
  width: 60%;
  height: fit-content;
  object-position: center;
`