import styled from "styled-components";
import Tab from "components/Tab";
import { MainBlockContainer } from "components/Common/components";

type TabProps = "deviceManagement" | "userManagement";

interface settingTypeProps {
  selectedType: string;
  handleSelectType: (type: TabProps) => void;
  buildingCode: string;
}

const Container = styled(MainBlockContainer)`
  background: transparent;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  margin-left: 0px;
  color: #586b7c;
`;

const SettingTypeList = [
  { value: "iso50006", title: "Saving Summary" },
  { value: "ac-power", title: "AC Power" },
  { value: "people-power", title: "People vs Power" },
  { value: "power-temp", title: "Power and Temperature" },
  { value: "all-data", title: "All Data" },
  { value: "ac-env-zone", title: "AC Env Zone" },

];

const ShellSettingTypeSection = ({ selectedType, handleSelectType, buildingCode }: settingTypeProps) => {
  return (
    <>
      <Container>
        <Tab dataList={SettingTypeList} selectedValue={selectedType} handleSelect={handleSelectType} />
      </Container>
    </>
  );
};

export default ShellSettingTypeSection;
