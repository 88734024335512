import ReactDOM from "react-dom";
import { Provider } from "jotai";
import { GlobalStyle } from "./GlobalStyle/GlobalStyle";
import { FirebaseProvider } from "./firebase";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/AdminLayout";
import LoginPage from "pages/LoginPage";
import "./main.css";
import { Toaster } from "react-hot-toast";
//@ts-ignore
import MessengerCustomerChat from "react-messenger-customer-chat";
import { ContactSupport } from "components/Common/ContactSupport/ContactSupport";
import Modal from "react-modal";

Modal.setAppElement("#root");

ReactDOM.render(
  <Provider>
    <FirebaseProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/login" component={LoginPage} />
          <Redirect from="*" to="/admin/overview" />
        </Switch>
      </BrowserRouter>
      <GlobalStyle />
      <Toaster />
      <ContactSupport />
      <MessengerCustomerChat pageId={"685983694926359"} appId={"971858410399697"} />
    </FirebaseProvider>
  </Provider>,
  document.getElementById("root")
);
