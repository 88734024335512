import styled from "styled-components";
import { SearchOutlined } from "@ant-design/icons";

const Button = styled.button<any>`
  width: ${(props) => (props.width ? props.width : "20px")};
  height: ${(props) => (props.height ? props.height : "20px")};
  padding: 16px;
  margin-left: 10px;
  border-radius: 10px;
  border: 1px solid #cecece;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: #d1d1d1;
  }
  :active {
    background-color: #b8b8b8;
  }
`;

export const SearchButton = ({ width, height, onClick }: any) => {
  return (
    <>
      <Button onClick={onClick} width={width} height={height}>
        <SearchOutlined />
      </Button>
    </>
  );
};
