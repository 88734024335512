import styled from "styled-components";
import { Row, Col, Slider } from "antd";
import { BlockContainer, BlockHeader } from "components/Common/components";
import { SingleDatePicker } from "components/DateRangePicker/DateRangePicker";
import { TabNameProps } from "pages/EnergyPage";
import { DeviceHistory } from "./DeviceHistory";
// import { SelectedTimeline } from "./SelectedTimeline";
// import { TimelineSlider } from "./TimelineSlider";
// import { SelfDrivingTimelineImage } from "./SelfDrivingTimelineImage";
// import { selfDrivingData, TimeLocationMap } from "MockData/SelfDrivingAction";
import { SelfDrivingSchema } from "MockData/SelfDrivingAction";
import AcIcon from "assets/pages/Energy/AcIcon.svg";
import BlindIcon from "assets/pages/Energy/BlindIcon.svg";
import FreshAirIcon from "assets/pages/Energy/FreshAirIcon.svg";
import type { SliderMarks } from "antd/es/slider";

type SelfDrivingTimelineProps = {
  startDate: Date;
  stringStartDate: string;
  setStartDate: (date: any) => void;
  endDate: Date;
  setEndDate: (date: any) => void;
  selectedTabName: string;
  handleSelectTabName: (tabName: string) => void;
  selectedValue: number;
  selectTime: (time: number) => void;
  xPosition: number;
  deviceList: any;
  historyDeviceData: any;
  visibleDeviceObject: any;
};

const Container = styled(BlockContainer)`
  padding: 30px 40px !important;
`;

const CardContainer = styled.div<any>`
  position: absolute;
  width: 107px;
  height: 176px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 13px;
  filter: drop-shadow(0px 3px 20px rgba(161, 183, 205, 0.5));
  backdrop-filter: blur(20px);
  right: ${(props) => `${props.right}%`};
  bottom: ${(props) => `${props.bottom}%`};
  padding: 9px;
`;

const DeviceIcon = styled.img`
  width: 24px;
  height: 24px;
  user-select: none;
`;

const DeviceName = styled.p<any>`
  color: ${(props) => (props.color ? props.color : "black")};
  font-size: 10px;
  height: 12px;
  margin: 3px 0px 3px 0px;
  user-select: none;
`;

const DeviceIconMap: { [agent_id: string]: any } = {
  bac0hvac: AcIcon,
  tuyacloudcurtain: BlindIcon,
  tasmota: FreshAirIcon,
};

const SchemaName = styled.p`
  font-size: 10px;
  margin: 0px;
  line-height: 12.5px;
  user-select: none;
`;

const TimeMap: { [number: string]: string } = {
  0: "00:00",
  1: "00:30",
  2: "01:00",
  3: "01:30",
  4: "02:00",
  5: "02:30",
  6: "03:00",
  7: "03:30",
  8: "04:00",
  9: "04:30",
  10: "05:00",
  11: "05:30",
  12: "06:00",
  13: "06:30",
  14: "07:00",
  15: "07:30",
  16: "08:00",
  17: "08:30",
  18: "09:00",
  19: "09:30",
  20: "10:00",
  21: "10:30",
  22: "11:00",
  23: "11:30",
  24: "12:00",
  25: "12:30",
  26: "13:00",
  27: "13:30",
  28: "14:00",
  29: "14:30",
  30: "15:00",
  31: "15:30",
  32: "16:00",
  33: "16:30",
  34: "17:00",
  35: "17:30",
  36: "18:00",
  37: "18:30",
  38: "19:00",
  39: "19:30",
  40: "20:00",
  41: "20:30",
  42: "21:00",
  43: "21:30",
  44: "22:00",
  45: "22:30",
  46: "23:00",
  47: "23:30",
};

const formatter: any = (value: number) => `${TimeMap[value]}`;

const marks: SliderMarks = {
  0: "00:00",
  2: "01:00",
  4: "02:00",
  6: "03:00",
  8: "04:00",
  10: "05:00",
  12: "06:00",
  14: "07:00",
  16: "08:00",
  18: "09:00",
  20: "10:00",
  22: "11:00",
  24: "12:00",
  26: "13:00",
  28: "14:00",
  30: "15:00",
  32: "16:00",
  34: "17:00",
  36: "18:00",
  38: "19:00",
  40: "20:00",
  42: "21:00",
  44: "22:00",
  46: "23:00",
};

const SelfDrivingCard = ({ data, right, bottom }: any) => {
  return (
    <CardContainer right={right} bottom={bottom}>
      {data.data.map((deviceData: any) => {
        return (
          <>
            <Row>
              <Col xs={8} style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start" }}>
                <DeviceIcon src={DeviceIconMap[deviceData.agent_id]} />
              </Col>
              <Col xs={16}>
                <DeviceName color={SelfDrivingSchema[deviceData.agent_id]["color"]}>{deviceData.device_name}</DeviceName>
                {SelfDrivingSchema[deviceData.agent_id]["schema"].map((schema: string) => (
                  <Row>
                    <Col xs={12}>
                      <SchemaName>{schema}</SchemaName>
                    </Col>
                    <Col xs={12}>
                      {" "}
                      <SchemaName style={{ fontWeight: "bold" }}>{deviceData.status[schema]}</SchemaName>
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          </>
        );
      })}
    </CardContainer>
  );
};

export const SelfDrivingTimeline = ({
  startDate,
  stringStartDate,
  setStartDate,
  selectedValue,
  selectTime,
  deviceList,
  historyDeviceData,
  visibleDeviceObject,
}: SelfDrivingTimelineProps) => {
  return (
    <Container>
      <Row>
        <Col sm={8} lg={8}>
          <BlockHeader>Self-driving Timeline</BlockHeader>
        </Col>
        <Col sm={16} lg={16} style={{ display: "flex", justifyContent: "flex-end" }}>
          {/* <Tab
            dataList={TabTimelineList}
            selectedValue={selectedTabName}
            handleSelect={handleSelectTabName}
            bgColor={
              'linear-gradient(294.71deg, #FFFFFF -17.26%, rgba(216, 232, 255, 0.38) 149.95%)'
            }
            size={'small'}
            selectedTextColor={'#397CDD'}
          /> */}
          <SingleDatePicker
            startDate={startDate}
            setStartDate={setStartDate}
            // endDate={endDate}
            // setEndDate={setEndDate}
          />
        </Col>
      </Row>

      <Row style={{ padding: "0px 60px" }}>
        <div className="w-full">
          <Slider
            marks={marks}
            included={false}
            defaultValue={0}
            tipFormatter={formatter}
            min={0}
            max={47}
            value={selectedValue}
            onChange={selectTime}
          />
        </div>
      </Row>
      <Row>
        <Col xs={24} xl={24}>
          <DeviceHistory
            deviceList={deviceList}
            historyDeviceData={historyDeviceData && historyDeviceData[stringStartDate + " " + marks[selectedValue]]}
            visibleDeviceObject={visibleDeviceObject}
          />
        </Col>
      </Row>
      {/* <Row>
        <SelfDrivingTimelineContainer>
          <SelfDrivingTimelineImage />
          {selfDrivingData.map((item: any) => (
            <SelfDrivingCard
              data={item}
              right={TimeLocationMap[item.time]['x']}
              bottom={TimeLocationMap[item.time]['y']}
            ></SelfDrivingCard>
          ))}
        </SelfDrivingTimelineContainer>
      </Row>
      <Row>
        <SelectedTimeline />
      </Row> */}
    </Container>
  );
};
