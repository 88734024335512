import { MoreIcon } from "icons/MoreIcon";
import { RemoveIcon } from "icons/RemoveIcon";
import { ViewIcon } from "icons/ViewIcon";
import { Fragment, useState, PropsWithChildren } from "react";
import styled from "styled-components";
import tw from "twin.macro";

const DropdownContainer = styled.div(() => [tw`flex justify-center`]);

const DropdownMenu = styled.div(() => [tw`relative w-[fit-content] h-auto`]);

const DropdownMenuTrigger = styled.div(() => [
  tw`w-8 h-8 bg-white border border-gray-300 shadow cursor-pointer rounded-full text-cupink-dark
  flex items-center justify-center transition-shadow 
  hover:shadow-[0px 3px 20px rgba(161, 183, 205, 0.2)]
  [&[aria-expanded=true]]:shadow-[0px 3px 20px rgba(161, 183, 205, 0.2)]`,
]);

const DropdownMenuContent = styled.div<any>`
  ${tw`w-[fit-content] h-[fit-content] top-[40px] right-0 bg-white rounded-xl flex-col z-10 gap-y-1 p-0.5 shadow-lg`};
  ${({ isOpen }) => (isOpen ? "position:absolute; display:flex" : "display:none")};
`;

const DropdownMenuItem = styled.div(() => [
  tw`h-10 flex justify-start items-center min-w-[140px] origin-top-right 
  focus:outline-none p-0.5 bg-white rounded-[10px] cursor-pointer 
  hover:shadow-[0px 3px 20px rgba(161, 183, 205, 0.2)] hover:border-primary hover:border hover:border-solid hover:bg-primary-extraLight
`,
]);

const WrapIcon = styled.div(() => [tw`h-6 w-8 flex justify-center items-center`]);

interface DeviceActionsProps {
  userInfo?: any;
  openViewUser: any;
  openDeleteUser: any;
}

export function RowActions({ children }: PropsWithChildren<{}>) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <DropdownContainer>
      <DropdownMenu>
        <DropdownMenuTrigger onClick={() => setIsOpen(!isOpen)}>
          <MoreIcon />
        </DropdownMenuTrigger>
        <DropdownMenuContent isOpen={isOpen}>{children}</DropdownMenuContent>
      </DropdownMenu>
    </DropdownContainer>
  );
}

export function UserActions({ userInfo, openViewUser, openDeleteUser }: DeviceActionsProps) {

  return (
    <Fragment>
      <RowActions>
        <DropdownMenuItem onClick={() => openViewUser(userInfo)}>
          <WrapIcon>
            <ViewIcon />
          </WrapIcon>
          View
        </DropdownMenuItem>
        <DropdownMenuItem tw="text-red-500" onClick={() => openDeleteUser(userInfo)}>
          <WrapIcon>
            <RemoveIcon />
          </WrapIcon>
          Remove
        </DropdownMenuItem>
      </RowActions>
    </Fragment>
  );
}
