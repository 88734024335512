import tw from "twin.macro";
import styled from "styled-components";
import DeviceConfig from "configs/device_config.json";

const Container = styled.div(() => [tw`h-10 flex`]);

const Tab = styled.div<any>`
  ${tw`h-full flex justify-center items-center mr-5 cursor-pointer`}
  ${({ selected }) => (selected ? `border-bottom: 4px solid #0e7de4` : `border-bottom: 4px solid #00000000`)}
`;

const TabText = styled.span`
  ${tw`text-altoDark font-medium`}
`;

const Icon = styled.img`
  ${tw`w-4 h-4 mr-1`}
`;

export const UnderlineTab = ({ listOfTabs, selectedTab, onChange }: any) => {
  const AgentToName: any = DeviceConfig;

  if (!listOfTabs) {
    return <></>;
  }

  if (listOfTabs[0].value) {
    return (
      <>
        <Container>
          {listOfTabs.map((tabName: { value: string; label: string; icon: string }) => (
            <Tab key={tabName.value} selected={selectedTab.value === tabName.value} onClick={() => onChange(tabName)}>
              {tabName.icon && <Icon src={tabName.icon} />}
              <TabText>{tabName.label}</TabText>
            </Tab>
          ))}
        </Container>
      </>
    );
  } else {
    return (
      <>
        <Container>
          {listOfTabs.map((tabName: string) => (
            <Tab key={tabName} selected={selectedTab === tabName} onClick={() => onChange(tabName)}>
              {selectedTab.icon && selectedTab.icon}
              {AgentToName[tabName]["title"]}
            </Tab>
          ))}
        </Container>
      </>
    );
  }
};
