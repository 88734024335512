import { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { getAllDevices } from "services/api.service";
import { responseErrorHandler } from "services/prepareAxios";

export interface DevicesContextValue {
  devices: any[] | null | undefined;
  agents: any | null;
}

const DevicesContext = createContext<DevicesContextValue>(null as unknown as DevicesContextValue);

export function useDevicesContext() {
  return useContext(DevicesContext);
}

export function DevicesProvider({ children }: PropsWithChildren<{}>) {
  const [devices, setDevices] = useState<any[] | null>();
  const [agents, setAgents] = useState<any | null>([]);

  const getData = async () => {
    try {
      const result = await getAllDevices();
      const devices = result.data.devices;
      const agentsSet = new Set();
      //Prep list of devices
      setDevices(devices);

      //Prep a set of agents
      devices.forEach((item: any) => {
        const agentId = item.agent_id;
        if (agentId) {
          agentsSet.add(agentId);
        }
      });
      setAgents(agentsSet);
    } catch (err) {
      responseErrorHandler(err);
      setDevices(null);
      setAgents(null);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return <DevicesContext.Provider value={{ devices: devices, agents: agents }}>{children}</DevicesContext.Provider>;
}
