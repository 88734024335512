import { useState } from "react";
import styled from "styled-components";
import { useTable, useSortBy, usePagination, useGlobalFilter, useAsyncDebounce } from "react-table";
import UpIcon from "assets/pages/Dashboard/Up.svg";
import DownIcon from "assets/pages/Dashboard/Down.svg";
import { Row, Col } from "antd";

const Container = styled.div`
  background: #ffffff;
  opacity: 0.7;
  box-shadow: 1.47651px 4.42953px 19.1946px rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(29.5302px);
  border-radius: 16px;
  padding: 12px 12px 24px 12px;
  width: 100%;
`;

const ReportPanel = styled.div`
  background: linear-gradient(101.24deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.4) 100%);
  box-shadow: 1px 3px 15px rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(20px);
  border-radius: 16px;
  padding: 12px 18px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 0px;
  margin-bottom: 16px;
`;

const ReportTypeBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ReportInputBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;

const SizeMap: any = {
  th: {
    height: {
      small: "40px",
      medium: "56px",
    },
    fontSize: {
      small: "14px",
      medium: "18px",
    },
  },
};

const Styles = styled.div<any>`
  table {
    border-spacing: 0;
    border-top: 0px;
    width: 100%;
    border-radius: 10px;
    border-collapse: collapse;
    border-radius: 10px;
    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px #ffffff;
    color: black;

    tr {
      :first-child {
        td {
          border-top: 0px;
        }
      }
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid rgba(183, 204, 251, 0.3);
      border-right: 0px solid rgba(183, 204, 251, 0.3);
      height: ${(props) => (props.size ? SizeMap.th.height[props.size] : "56px")};
      font-size: ${(props) => (props.size ? SizeMap.th.fontSize[props.size] : "16px")};
      font-weight: 400;
      text-align: center;

      :last-child {
        border-right: 0;
      }
    }

    thead {
      background: rgba(219, 228, 255, 0.4);
      opacity: 1;
      backdrop-filter: blur(20px);
      border-radius: 13px;

      th {
        border-bottom: 0px;
        :first-child {
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
        }
        :last-child {
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
        }
        color: #0075e2;
      }
    }
  }
  input {
    border-radius: 6px;
    background: rgba(219, 228, 255, 0.3);
    border: 1px solid rgba(14, 126, 228, 0.6);
    padding: 6px;
    margin-right: 6px;
  }

  select {
    border-radius: 6px;
    background: rgba(219, 228, 255, 0.3);
    border: 1px solid rgba(14, 126, 228, 0.6);
    padding: 6px;
    margin-right: 6px;
  }

  button {
    width: 34px;
    height: 34px;
    border: 1px solid rgba(14, 126, 228, 0.6);
    border-radius: 50px;
    cursor: pointer;
  }
`;

function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }: any) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: "1.1rem",
          border: "0",
        }}
      />
    </span>
  );
}

export const FilterBox = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter, openAddDevice }: any) => {

  return (
    <ReportPanel>
      <Row style={{ width: "100%" }}>
        <Col xs={18}>
          <ReportTypeBox>
            <ReportInputBox>
              <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
            </ReportInputBox>
          </ReportTypeBox>
        </Col>
      </Row>
    </ReportPanel>
  );
};

export const Table = ({ columns, data, pagination, size, openAddDevice }: any) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  }: any = useTable<any>(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      // TODO Uncomment this when you change to use fetching with pagination
      // manualPagination: false, // Tell the usePagination
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  // TODO Uncomment this when you change to use fetching with pagination
  // const firstPageRows = rows.slice(0, 20);
  // Listen for changes in pagination and use the state to fetch our new data
  // useEffect(() => {
  //   fetchData({ pageIndex, pageSize })
  // }, [data, pageIndex, pageSize])

  return (
    <>
      <Styles size={size}>
        <FilterBox
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          openAddDevice={openAddDevice}
        />

        <Container>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render("Header")}
                      <span>{column.isSorted ? column.isSortedDesc ? <img src={UpIcon} /> : <img src={DownIcon} /> : ""}</span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {/* // TODO Uncomment this when you change to use fetching with pagination */}
              {/* {firstPageRows.map((row: any, i: number) => { */}
              {page.length !== 0 ? (
                page.map((row: any, i: number) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell: any) => {
                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                      })}
                    </tr>
                  );
                })
              ) : (
                <p style={{ width: "100%", textAlign: "center", marginTop: "8px" }}>No matching records found</p>
              )}
            </tbody>
          </table>
          {pagination && (
            <>
              <div className="pagination" style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                <div>
                  <select
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                  <span>
                    Page{" "}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>{" "}
                  </span>
                  <span>
                    | Go to page:{" "}
                    <input
                      type="number"
                      defaultValue={pageIndex + 1}
                      onChange={(e) => {
                        const page = e.target.value ? Number(e.target.value) - 1 : 0;
                        gotoPage(page);
                      }}
                      style={{ width: "100px" }}
                    />
                  </span>
                </div>
                <div>
                  <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {"<<"}
                  </button>{" "}
                  <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {"<"}
                  </button>{" "}
                  <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {">"}
                  </button>{" "}
                  <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {">>"}
                  </button>{" "}
                </div>
              </div>
            </>
          )}
        </Container>
      </Styles>
    </>
  );
};
