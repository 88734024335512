import styled from "styled-components";
import Tab from "components/Tab";
import { MainBlockContainer } from "components/Common/components";

type TabProps = "schedule" | "parameter";

interface settingTypeProps {
  selectedType: string;
  handleSelectType: (type: TabProps) => void;
  selectedIoTDevice: string;
  handleSelectIoTDevice: (device: string) => void;
}

const Container = styled(MainBlockContainer)`
  background: transparent;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  margin-left: 0px;
  color: #586b7c;
`;

const SettingTypeList = [
  { value: "schedule", title: "Schedule" },
  // { value: "parameter", title: "Parameter" },
];

const SettingTypeSection = ({ selectedType, handleSelectType, selectedIoTDevice, handleSelectIoTDevice }: settingTypeProps) => {
  return (
    <>
      <Container>
        <Tab dataList={SettingTypeList} selectedValue={selectedType} handleSelect={handleSelectType} />
      </Container>
    </>
  );
};

export default SettingTypeSection;
