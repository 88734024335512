import { ResponsiveLine } from '@nivo/line';
import moment from 'moment';

export type DatumValue = string | number | Date

export interface Datum {
  x?: DatumValue | null
  y?: DatumValue | null
  [key: string]: any
}

export interface Serie {
  id: string | number
  data: Datum[]
  [key: string]: any
}

type LineChartProps = {
  data: Serie[]
  unit: string
}

export const LineChartDate = (prop: LineChartProps) => (
  <ResponsiveLine
    data={prop.data}
    margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
    xScale={{
      type: 'time',
      format: '%Y-%m-%d %H:%M:%S',
      precision: 'second',
      useUTC: false,
      stacked: true
    }}
    yScale={{
      type: 'linear',
      min: 0,
      max: 'auto',
      stacked: false,
      reverse: false
    }}
    yFormat=' >-.2f'
    axisTop={null}
    axisRight={null}
    xFormat='time:%Y-%m-%d %H:%M:%S'
    axisBottom={{
      format: '%m-%d',
      // tickValues: 'every 2 hour',
      legend: 'Date',
      legendOffset: 34,
      orient: 'bottom',
      tickSize: 2,
      tickPadding: 5,
      legendPosition: 'middle',
      tickRotation: -25
    }}
    enableSlices='x'
    sliceTooltip={({ slice }) => {
      const date = slice.points[0].data.xFormatted;
      return (
        <div
          style={{
            backgroundColor: 'white',
            fontSize: '10px',
            border: '1px solid #dfdfdf',
            padding: '2px',
            borderRadius: '8px'
          }}
        >
          <strong style={{ color: 'black' }}>
            {`${moment(date).format('MMMM Do YYYY, H:mm')}`}
          </strong>
          {slice.points.map(point => (
            <div key={point.id}>
              <strong style={{ color: 'red' }}>
                {`${point.serieId} ${point.data.yFormatted} ${prop.unit}`}
              </strong>
            </div>
          ))}
        </div>
      );
    }}
    axisLeft={{
      tickValues: 3,
      orient: 'left',
      tickSize: 2,
      tickPadding: 3,
      tickRotation: 0,
      legend: `${prop.unit}`,
      legendOffset: -40,
      legendPosition: 'middle'
    }}
    enablePoints={false}
    colors={{ scheme: 'category10' }}
    pointSize={10}
    pointColor={{ theme: 'background' }}
    pointBorderWidth={2}
    pointBorderColor={{ theme: 'background' }}
    pointLabelYOffset={-12}
    useMesh={true}
    enableGridX={false}
    enableGridY={false}
    // legends={[]}
    curve='catmullRom'
    legends={[
      {
          anchor: 'top',
          direction: 'row',
          justify: false,
          translateX: 20,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
              {
                  on: 'hover',
                  style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1
                  }
              }
          ]
      }
  ]}
  />
);
