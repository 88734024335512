import styled from "styled-components";
import { Row, Col } from "antd";
import { BlockContainer, BlockHeader } from "components/Common/components";

type ThisMonthElectricityBillProps = {
  data: any;
};

const Container = styled(BlockContainer)`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  filter: drop-shadow(0px 2px 10px rgba(171, 183, 196, 0.2));
  height: 100%;
`;

const TotalEbillContainer = styled.div`
  width: 100%;
  height: 117px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(275.58deg, rgba(192, 232, 255, 0.6) 1.81%, rgba(192, 232, 255, 0.24) 100%);
  box-shadow: 1px 3px 13px rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(20px);
  border-radius: 16px;
  margin-bottom: 24px;
`;

const TotalEbill = styled.p`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 10px;
`;

const DatetimeBox = styled.div`
  position: absolute;
  height: 36px;
  width: 80%;
  left: 10%;
  bottom: -17px;
  background: #0e7ee4;
  box-shadow: 1px 3px 20px rgba(57, 124, 221, 0.3);
  backdrop-filter: blur(20px);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: 2px solid #c3e2ff;
`;

const WrapText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 44px;
  border-bottom: 1px solid rgba(183, 204, 251, 0.3);
  padding: 8px 20px;
  &:last-child {
    border-bottom: 0px;
  }
`;

const MainTitle = styled.p`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  color: #212529;
  margin-bottom: 0px;
  height: 24px;
`;

const SubTitle = styled.p`
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  font-size: 14px;
  color: #212529;
  margin-bottom: 0px;
  height: 24px;
`;

const TotalMonthlyEbill = () => {
  return (
    <TotalEbillContainer>
      <TotalEbill>
        133008.09 <small style={{ fontSize: "14px" }}>THB</small>
      </TotalEbill>
      <DatetimeBox>Aug 01, 2021 - Aug 15, 2021</DatetimeBox>
    </TotalEbillContainer>
  );
};

const EbillInDetail = ({ data }: any) => {
  if (Array.isArray(data.value)) {
    return (
      <>
        <WrapText>
          <MainTitle>
            <span>{data.thTitle}</span>
            <span>THB</span>
          </MainTitle>
          {data.value.map((item: any) => (
            <SubTitle>
              <span>{item.thTitle}</span>
              <span>{item.value} THB</span>
            </SubTitle>
          ))}
        </WrapText>
      </>
    );
  } else {
    return (
      <>
        <WrapText>
          <MainTitle>
            <span>{data.thTitle}</span>
            <span>THB</span>
          </MainTitle>
        </WrapText>
      </>
    );
  }
};

export const ThisMonthElectricityBill = ({ data }: ThisMonthElectricityBillProps) => {
  return (
    <Container>
      <Row>
        <Col sm={24} lg={24}>
          <BlockHeader style={{ color: "#0E7EE4" }}>This Month Electricity Bill</BlockHeader>
        </Col>
        <Col sm={24} xl={24}>
          <TotalMonthlyEbill />
        </Col>
        {data.map((item: any) => (
          <Col xs={24}>
            <EbillInDetail data={item} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};
