import styled from 'styled-components'
import { Row, Col } from 'antd';

const BuildingDigitalTwin = ({ data }: any) => {
  return (
    <>
      <Row>
        <Col>
        </Col>
      </Row>
    </>
  )
}

export default BuildingDigitalTwin