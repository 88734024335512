import styled from 'styled-components';
import SunPath from 'assets/pages/Dashboard/DeviceControl/SunPath.svg';
import Sun from 'assets/pages/Dashboard/DeviceControl/Sun.png';
import SolarOutdoorWeather from 'assets/pages/Dashboard/DeviceControl/SolarOutdoorWeather.png';
import RightArrow from 'assets/pages/Dashboard/DeviceControl/RightArrow.svg';
import { Col, Row } from 'antd';

const Container = styled.div`
  top: -10px;
  right: 0px;
  display: flex;
  justify-content: space-around;
`;

const SunContainer = styled.div`
  position: relative;
  margin: 5px;
  padding: 24px;
  width: 270px;
  height: 102px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 16px;
  box-shadow: 1px 3px 15px rgba(196, 196, 196, 0.15);
`;

const OutdoorContainer = styled.div`
  margin: 5px;
  padding: 12px;
  width: 102px;
  height: 102px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 16px;
  background: radial-gradient(
    322.12% 375.43% at 294.55% -121.45%,
    #0e7ee4 0%,
    rgba(200, 239, 255, 0.9) 100%
  );
  box-shadow: 1px 3px 15px rgba(196, 196, 196, 0.15);
`;

const SunriseTime = styled.div`
  position: absolute;
  left: 25px;
  bottom: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #a1b7cd;
  text-align: center;
`;

const SunriseSet = styled.div`
  position: absolute;
  right: 25px;
  bottom: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #a1b7cd;
  text-align: center;
`;

const SunPathImage = styled.img<any>`
  position: absolute;
  width: 187px;
  height: 32px;
`;

const SunImage = styled.img<any>`
  position: absolute;
  width: 30px;
  height: 30px;
  left: ${(props) => `${props.left}%`};
  top: ${(props) => `${props.top}%`};
`;

const SolarOutdoorWeatherImage = styled.img`
  width: 100%;
`;

const Temp = styled.p`
  margin: 0px;
  font-size: 32px;
  font-family: Be Vietnam, sans-serif;
`;

export const SolarWidget = () => {
  const getPosition = (start: any, stop: any) => {
    const now = new Date().getHours() * 60 + new Date().getMinutes();
    const startTime = new Date(start).getHours() * 60 + new Date().getMinutes();
    const stopTime = new Date(stop).getHours() * 60 + new Date().getMinutes();
    const percent = Math.round(((now - startTime) / (stopTime - startTime)) * 12);
    const leftList: number[] = [
      13, 18.25, 23.5, 28.75, 34, 39.25, 44.5, 49.75, 55, 60.25, 65.5, 71, 76,
    ];
    const topList: number[] = [25, 21, 17, 12, 9, 9, 9, 9, 12, 17, 21, 25];
    if (now < startTime) {
      return null;
    } else if (now > stopTime) {
      return null;
    } else {
  
      return {
        left: leftList[percent - 1],
        top: topList[percent - 1],
      };
    }
  };
  return (
    <>
      <Container>
        <SunContainer>
          <SunPathImage src={SunPath} />
          <SunImage
            src={Sun}
            left={
              getPosition(
                'Thursday, October 28, 2021 7:18:56',
                'Thursday, October 28, 2021 19:18:56'
              )?.left
            }
            top={
              getPosition(
                'Thursday, October 28, 2021 7:18:56',
                'Thursday, October 28, 2021 19:18:56'
              )?.top
            }
          />
          <SunriseTime>
            7.34
            <br />
            Sunrise
          </SunriseTime>
          <SunriseSet>
            18.49
            <br />
            Sunset
          </SunriseSet>
        </SunContainer>
        <OutdoorContainer>
          <Row style={{ width: '100%' }}>
            <Col xs={10}>
              <SolarOutdoorWeatherImage src={SolarOutdoorWeather} />
            </Col>
            <Col xs={14}>
              <p style={{ fontSize: '10px', color: '#788796', margin: '0px 0px 0px 8px' }}>
                Outdoor, Provice
              </p>
            </Col>
          </Row>
          <Row style={{ width: '100%' }}>
            <Col xs={18}>
              <Temp>
                34<small style={{ fontSize: '12px' }}>°C</small>
              </Temp>
            </Col>
            <Col
              xs={6}
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center',
                paddingBottom: '10px',
              }}
            >
              <img src={RightArrow} style={{ cursor: 'pointer' }} alt="arrow"/>
            </Col>
          </Row>
        </OutdoorContainer>
      </Container>
    </>
  );
};
