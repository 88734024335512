import styled from "styled-components";
import { BlockContainer, BlockHeader, GraphContainer } from "components/Common/components";
import { Row, Col } from "antd";
import { ResponsiveLine } from "@nivo/line";
import { LoadingPage } from "components/Common/LoadingPage/LoadingPage";
import { ResponsiveBar } from "@nivo/bar";

const Container = styled(BlockContainer)`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 10px;
  filter: drop-shadow(0px 2px 10px rgba(171, 183, 196, 0.2));
  height: 235px;
  justify-content: space-between;
`;

const MyResponsiveLine = ({ data /* see data tab */ }: any) => (
  <ResponsiveLine
    data={data}
    margin={{ top: 15, right: 10, bottom: 45, left: 45 }}
    xScale={{ format: "%Y-%m-%d %H:%M", type: "time", useUTC: false }}
    xFormat="time:%Y-%m-%d %H:%M"
    yScale={{ type: "linear", min: 0, max: "auto", stacked: false, reverse: false }}
    yFormat=" >-.2f"
    // curve="cardinal"
    axisTop={null}
    axisRight={null}
    axisBottom={{
      orient: "bottom",
      format: "%H:%M",
      tickSize: 5,
      tickPadding: 5,
      tickRotation: -41,
      legend: "Time",
      legendOffset: 36,
      legendPosition: "middle",
    }}
    axisLeft={{
      orient: "left",
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "kW",
      legendOffset: -40,
      legendPosition: "middle",
      // tickValues: [0, 10, 20, 30, 40, 50, 60]
    }}
    lineWidth={1}
    enableGridX={false}
    enableGridY={false}
    colors={{ scheme: "paired" }}
    enablePoints={false}
    pointSize={10}
    pointColor={{ theme: "background" }}
    pointBorderWidth={2}
    pointBorderColor={{ from: "serieColor" }}
    pointLabelYOffset={-12}
    enableArea={true}
    areaOpacity={0.15}
    useMesh={true}
    legends={[
      {
        anchor: "top",
        direction: "row",
        justify: false,
        translateX: 40,
        translateY: -18,
        itemsSpacing: 0,
        itemDirection: "left-to-right",
        itemWidth: 60,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: "circle",
        symbolBorderColor: "rgba(0, 0, 0, .5)",
        effects: [
          {
            on: "hover",
            style: {
              itemBackground: "rgba(0, 0, 0, .03)",
              itemOpacity: 1,
            },
          },
        ],
      },
    ]}
  />
);

export const MyResponsiveBar = ({ data, keys, indexBy }: any) => (
  <>
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy={indexBy}
      margin={{ top: 0, right: 0, bottom: 40, left: 0 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "fries",
          },
          id: "dots",
        },
        {
          match: {
            id: "sandwich",
          },
          id: "lines",
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "device_type",
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={null}
      enableLabel={true}
      enableGridY={false}
      labelSkipWidth={9}
      labelSkipHeight={7}
      colors={"rgb(139, 198, 255)"}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1]],
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={function (e) {
        return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;
      }}
      tooltip={(props: any) => {
        return (
          <div
            style={{
              padding: 0,
              color: "#FE6F9A",
            }}
          >
            <strong>{props.indexValue}</strong>
            <br />
            value: {Number(props.value).toFixed(2)}
          </div>
        );
      }}
    />
  </>
);

export const EnergyBaseline = ({ data, barData, title, graphType }: any) => {
  return (
    <>
      <Container>
        <Row>
          <Col sm={24} lg={24}>
            <BlockHeader style={{ marginBottom: "25px" }} sm>
              {title}
            </BlockHeader>
          </Col>
        </Row>
        <Row>
          <GraphContainer>
            {data && graphType === "line" && <MyResponsiveLine data={data} />}
            {data && graphType === "bar" && <MyResponsiveBar data={barData} keys={["energy"]} indexBy={["type"]} />}
            {data === undefined && <LoadingPage height={"100%"} />}
            {data === null && <p style={{ width: "100%", textAlign: "center", marginTop: "50px", color: "#0E7EE4" }}>No matching data found</p>}
          </GraphContainer>
        </Row>
      </Container>
    </>
  );
};
