import { useState } from "react";
import { PageContainer } from "components/Common/components";
import EnergyTypeSection from "./EnergyTypeSection";
import EnergyContentSection from "./EnergyContentSection";
import { getVisibleTab } from "utils/checkVisible";

const bgColor = `linear-gradient(274.48deg, #0E7EE4 0%, #55A6F2 100%)`;

export type TabNameProps = {
  value: string;
  title: string;
}[];

const TabNameList: TabNameProps = getVisibleTab();

const EnergyPage = () => {
  const [selectedTab, setSelectedTab] = useState("information");
  const handleSelectTab = (tabName: string) => {
    setSelectedTab(tabName);
  };

  return (
    <>
      <PageContainer>
        <EnergyTypeSection tabNameList={TabNameList} selectedTabName={selectedTab} handleSelectTabName={handleSelectTab} bgColor={bgColor} />
        <EnergyContentSection tabName={selectedTab} data={[]} />
      </PageContainer>
    </>
  );
};

export default EnergyPage;
