import Modal from "react-modal";
import "./AddParameterModal.css";
import DeviceTableSection from "./DeviceTableSection";
import { UnderlineTab } from "components/Common/UnderlineTab/UnderlineTab";
import {
  Button,
  Container,
  customStyles,
  Input,
  InputContainer,
  Label,
  SubdeviceTitle,
  WrapButton,
  WrapInput,
  WrapTable,
} from "./AddParameterModalComponents";
import "antd/dist/antd.css";
import Select from "react-select";

const selectCustomStyles = {
  container: (provided: any) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided: any) => ({
    ...provided,
    borderRadius: "8px",
    height: "100%",
    border: "1px solid rgba(14, 126, 228, 0.6)",
  }),
  menu: (provided: any) => ({
    ...provided,
    position: "absolute",
    overflow: "scroll",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    backgroundColor: "white",
  }),
};

const comparatorOptions = [
  { label: ">", value: ">" },
  { label: "<", value: "<" },
  { label: "=", value: "==" },
];


export const AddDeviceStatusModal = ({
  value,
  onChange,
  isOpen,
  onClose,
  deviceData,
  onSelectRow,
  currentAgent,
  onChangeAgentTab,
  agentsList,
  allCommand,
  onSubmit,
}: any) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles} contentLabel="Edit modal">
      <Container style={{ rowGap: "16px" }}>
        <SubdeviceTitle style={{ marginBottom: "8px" }}>Devices</SubdeviceTitle>
        <WrapTable>
          <DeviceTableSection data={deviceData} onSelectRow={onSelectRow} limitOneRow />
        </WrapTable>

        <SubdeviceTitle style={{ marginTop: "16px", marginBottom: "8px" }}>Function</SubdeviceTitle>
        {currentAgent && (
          <>
            <UnderlineTab listOfTabs={agentsList} selectedTab={currentAgent} onChange={onChangeAgentTab} />
            <div className="grid gap-y-4 grid-cols-2 mt-6">
              <InputContainer>
                <Label>Parameter type</Label>
                <WrapInput>
                  <Select
                    value={value && value[currentAgent] && value[currentAgent]["parameter_type"]}
                    onChange={(val: any) => onChange("parameter_type", val, currentAgent)}
                    styles={selectCustomStyles}
                    options={currentAgent && allCommand[currentAgent]["parameter_options"]}
                  />
                </WrapInput>
              </InputContainer>
              <div />
              <InputContainer>
                <Label>Comparator</Label>
                <WrapInput>
                  <Select
                    value={value && value[currentAgent] && value[currentAgent]["comparator"]}
                    onChange={(val: any) => onChange("comparator", val, currentAgent)}
                    styles={selectCustomStyles}
                    options={comparatorOptions}
                  />
                </WrapInput>
              </InputContainer>
              <div />
              <InputContainer>
                <Label>Value</Label>
                <WrapInput>
                  <Input
                    value={value && value[currentAgent] && value[currentAgent]["value"]}
                    onChange={(val: any) => onChange("value", val.target.value, currentAgent)}
                    placeholder="Enter Value"
                    disabled={false}
                  />
                </WrapInput>
              </InputContainer>
            </div>
          </>
        )}
        <br />
        <WrapButton>
          <Button onClick={onSubmit}>Add</Button>
          <Button onClick={onClose} data-outlined>
            Cancel
          </Button>
        </WrapButton>
      </Container>
    </Modal>
  );
};
