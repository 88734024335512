import { useState } from "react";
import styled, { keyframes } from "styled-components";
import { Row, Col } from "antd";
import FanOn from "assets/pages/Dashboard/DeviceControl/fan_mode_on.png";
import FanOff from "assets/pages/Dashboard/DeviceControl/fan_mode_off.png";
import { SelfDrivingMode } from "../SelfDrivingMode";
import moment from "moment";

type rlOutputStatus = {
  is_data_updated: boolean;
  output_enable: boolean;
  timestamp: string;
  unix_timestamp: number;
  output_temperature: number;
};

const deviceAnimation = keyframes`
  0% {  transform: rotate(0deg) }
  100% { transform: rotate(359deg) }
`;

const Container = styled.div`
  width: 750px;
  height: auto;
`;

const Status = styled.p`
  font-weight: 600;
  font-size: 22px;
  color: #212529;
  margin-top: 10px;
`;

const DeviceStatusBox = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const DeviceIcon = styled.img<any>`
  width: 120px;
  height: 120px;
  cursor: pointer;
  animation: rotation 1.5s infinite linear;
  animation-name: ${(props) => (props.active ? deviceAnimation : "none")};
  margin-top: 16px;
`;

export const OauControl = ({ deviceData, command, deviceInfo, handleChangeCommand }: any) => {
  const [selfDrivingStatus, setSelfDrivingStatus] = useState<rlOutputStatus | undefined>();
  return (
    <Container>
      <Row>
        <Col xs={24}>
          <Row>
            <DeviceStatusBox>
              <DeviceIcon
                src={command?.mode === "on" ? FanOn : FanOff}
                active={command?.mode === "on"}
                onClick={() => {
                  handleChangeCommand("mode", command?.mode === "on" ? "off" : "on", deviceInfo.agent_id);
                }}
              />
              <Status>{command?.mode === "on" ? "STATUS : ON" : "STATUS : OFF"}</Status>
            </DeviceStatusBox>
          </Row>
        </Col>
        <Col xs={12}>
          <Row>
            <Col xs={24}>
              <SelfDrivingMode deviceData={deviceData} selfDrivingStatus={selfDrivingStatus} setSelfDrivingStatus={setSelfDrivingStatus} />
            </Col>
          </Row>
        </Col>
      </Row>
      <p style={{ position: "absolute", bottom: "0px", right: "12px", marginBottom: "0px", fontSize: "12px", color: "#8c8c8c" }}>
        Updated at: {command?.timestamp && moment(command?.timestamp).format("YYYY-MM-DD HH:mm:ss")}
      </p>
    </Container>
  );
};
