export function RemoveIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 14 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.3 1.4C3.41046 1.4 3.5 1.31046 3.5 1.2V0.2C3.5 0.0895429 3.58954 0 3.7 0H10.3C10.4105 0 10.5 0.0895431 10.5 0.2V1.2C10.5 1.31046 10.5895 1.4 10.7 1.4H13.8C13.9105 1.4 14 1.48954 14 1.6V2.6C14 2.71046 13.9105 2.8 13.8 2.8H12.8C12.6895 2.8 12.6 2.88954 12.6 3V13.3C12.6 13.4857 12.5262 13.6637 12.395 13.795C12.2637 13.9263 12.0857 14 11.9 14H2.1C1.91435 14 1.7363 13.9263 1.60503 13.795C1.47375 13.6637 1.4 13.4857 1.4 13.3V3C1.4 2.88954 1.31046 2.8 1.2 2.8H0.2C0.0895429 2.8 0 2.71046 0 2.6V1.6C0 1.48954 0.0895431 1.4 0.2 1.4H3.3ZM3 2.8C2.88954 2.8 2.8 2.88954 2.8 3V12.4C2.8 12.5105 2.88954 12.6 3 12.6H11C11.1105 12.6 11.2 12.5105 11.2 12.4V3C11.2 2.88954 11.1105 2.8 11 2.8H3ZM4.9 5.6C4.9 5.2134 5.2134 4.9 5.6 4.9C5.9866 4.9 6.3 5.2134 6.3 5.6V9.8C6.3 10.1866 5.9866 10.5 5.6 10.5C5.2134 10.5 4.9 10.1866 4.9 9.8V5.6ZM7.7 5.6C7.7 5.2134 8.0134 4.9 8.4 4.9C8.7866 4.9 9.1 5.2134 9.1 5.6V9.8C9.1 10.1866 8.7866 10.5 8.4 10.5C8.0134 10.5 7.7 10.1866 7.7 9.8V5.6Z" />
    </svg>
  )
}
