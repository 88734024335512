import { Row, Col } from "antd";
import { RealtimePowerGeneration } from "./RealtimePowerGeneration";
import { EnergyBaseline } from "./EnergyBaseline";
import { EnergyConsumption } from "./EnergyConsumption";
import FloorConfig from "configs/building_config.json";

const FloorConfigData: any = FloorConfig;

const SummarySection = ({
  realtimePowerData,
  energyConsumptionData,
  energyConsumptionVsBaselineData,
  buildingCode,
  energyConsumptionByDeviceType,
  energySavingVsPacback,
}: any) => {
  return (
    <>
      <Row gutter={[18, 18]}>
        <Col xs={8} style={{ zIndex: 9999 }}>
          <RealtimePowerGeneration
            data={realtimePowerData}
            title={FloorConfigData[buildingCode]["summary_box"]["first"]["title"]}
            graphType={FloorConfigData[buildingCode]["summary_box"]["first"]["graphType"]}
          />
        </Col>
        <Col xs={8} style={{ zIndex: 9998 }}>
          <EnergyBaseline
            data={energyConsumptionVsBaselineData}
            barData={energyConsumptionByDeviceType}
            title={FloorConfigData[buildingCode]["summary_box"]["second"]["title"]}
            graphType={FloorConfigData[buildingCode]["summary_box"]["second"]["graphType"]}
          />
        </Col>
        <Col xs={8}>
          <EnergyConsumption
            data={energyConsumptionData}
            title={FloorConfigData[buildingCode]["summary_box"]["third"]["title"]}
            energySavingVsPacback={energySavingVsPacback}
            graphType={FloorConfigData[buildingCode]["summary_box"]["third"]["graphType"]}
          />
        </Col>
      </Row>
    </>
  );
};

export default SummarySection;
