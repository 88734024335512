import { Row, Col } from "antd";
import { EnergyActualAndForecast } from "./EnergyActualAndForecast";
import { AnalyticDetail } from "./AnalyticDetail";
import { AnalyticSummary } from "./AnalyticSummary";
// import { SummarySection } from "./SummarySection";
// import { PeakPower } from "./PeakPower";
// import { BuildingEnergyUsagePerSquareMeter } from "./BuildingEnergyUsagePerSquareMeter";
// import { AgentSummary } from "./AgentSummary";
// import { DeviceControlActionSummary } from "./DeviceControlActionSummary";
// import AnnualCarUseIcon from "assets/common/icon/AnnualCarUseIcon.svg";
import Lightning from "assets/pages/Energy/Lightning.svg";
import MonthForecast from "assets/pages/Energy/MonthForecast.svg";
import YearForecast from "assets/pages/Energy/YearForecast.svg";
import { ThisMonthElectricityBill } from "./ThisMonthElectricityBill";

const ThisMonthEbillMockData = [
  { title: "thisMonthEBill", thTitle: "ค่าไฟรายเดือน", value: 133008.09 },
  {
    title: "baseEbill",
    thTitle: "ค่าไฟฟ้าฐาน",
    value: [
      { title: "baseDemand", thTitle: "ค่าความต้องการพลังไฟฟ้า", value: 20 },
      { title: "baseEnergy", thTitle: "ค่าพลังงานไฟฟ้า", value: 30 },
      { title: "basePowerFactor", thTitle: "ค่าเพาเวอร์แฟคเตอร์", value: 20 },
      { title: "baseService", thTitle: "ค่าบริการ", value: 20 },
    ],
  },
  { title: "variedEbill", thTitle: "ค่าไฟฟ้าผันแปร (Ft)", value: 300 },
  { title: "vat", thTitle: "ค่าภาษีมูลค่าเพิ่ม 7%", value: 20 },
];

const EnergyAnalytic = ({ data }: any) => {
  // const [co2Emission, setCo2Emission] = useState({ thisMonth: 1, accumulate: 2 });
  // const [annualCarUse, setAnnualCarUse] = useState({ thisMonth: 1, accumulate: 2 });
  // const [treeToAbsorbCo2, setTreeToAbsorbCo2] = useState({ thisMonth: 1, accumulate: 2 });
  // const [agentSummaryData, setAgentSummaryData] = useState();

  return (
    <>
      <Row gutter={[18, 18]}>
        <Col xs={16} xl={16}>
          <Row gutter={[18, 18]}>
            <Col xs={24}>
              <EnergyActualAndForecast />
            </Col>
            <Col xs={12}>
              <AnalyticDetail cardTitle={"Building Carbon Footprint Per Square Meter"} cardUnit={"kgCO2e"} />
            </Col>
            <Col xs={12}>
              <AnalyticDetail cardTitle={"Building Energy Usage Per Square Meter"} cardUnit={"kgCO2e"} />
            </Col>
            <Col xs={12}>
              <AnalyticDetail cardTitle={"Peak power"} cardUnit={"kgCO2e"} />
            </Col>
          </Row>
        </Col>
        <Col xs={8} xl={8}>
          <Row style={{ position: "relative" }} gutter={[18, 18]}>
            <Col span={24}>
              <AnalyticSummary
                header={"This Month E-Consumption"}
                value={23008.09}
                updatedAt={"07:07"}
                bgColor={"white"}
                color={"#FF7A00"}
                imgUrl={Lightning}
                imageBgColor={"linear-gradient(135.92deg, #f8af37 0%, #f2c94c 99.6%, #f2c94c 99.6%)"}
                unit={"kWh"}
              />
            </Col>
            <Col span={24}>
              <ThisMonthElectricityBill data={ThisMonthEbillMockData} />
            </Col>
            <Col span={24}>
              <AnalyticSummary
                header={"This Month E-Bill Forcast"}
                value={23008.09}
                bgColor={"white"}
                color={"#14B8B4"}
                imageBgColor={"linear-gradient(274.48deg, #14B8B4 0%, #59D1CE 100%)"}
                imgUrl={MonthForecast}
                unit={"THB"}
              />
            </Col>
            <Col span={24}>
              <AnalyticSummary
                header={"This Year E-Bill Forcast"}
                value={23008.09}
                bgColor={"white"}
                color={"#9640EC"}
                imageBgColor={"linear-gradient(135deg, #C8A7E4 0%, #AB5EED 100%)"}
                imgUrl={YearForecast}
                unit={"THB"}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EnergyAnalytic;
