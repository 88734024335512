import styled from "styled-components";
import { Row, Col } from "antd";
import { BlockContainer, BlockHeader, ContentContainer, HeaderContainer } from "components/Common/components";
import { IconContainer, IconImage } from "components/Pages/EnergyPage/SelfDriving/SelfDriving";
import TotalEnergySaving from "assets/pages/Energy/totalEnergySaving.svg";
import UpdatedAt from "components/Common/UpdatedAt";
import { convertDatetimeToStrDatetime } from "utils/convertDatetime";

type ContentProps = {
  title: string;
  value: number;
  unit: string;
};

const StyledBlockContainer = styled(BlockContainer)`
  background: linear-gradient(271.8deg, rgba(139, 198, 255, 0.6) 0%, rgba(85, 166, 242, 0.8) 100%);
  box-shadow: 1px 3px 15px rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(20px);
`;

const TotalSavingHeader = styled(BlockHeader)`
  white-space: nowrap;
`;

const CalendarTitle = styled.h3`
  margin: 0px;
  font-size: 14px;
  font-weight: 500;
`;

const ValueBox = styled.div`
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(28px);
  border-radius: 16px;
  font-size: 36px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0px 10px;
  margin: 5px 10px 0px 0px;
`;

const UnitBox = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 6px;
`;

const Line = styled.div`
  width: 1px;
  height: 100%;
  background-color: #e9e9e980;
`;

const Content = ({ title, value, unit }: ContentProps) => {
  return (
    <>
      <Row>
        <CalendarTitle>{title}</CalendarTitle>
      </Row>
      <Row>
        <Col sm={20} lg={20}>
          <ValueBox>{Number(value).toLocaleString(undefined, { minimumFractionDigits: 1 })}</ValueBox>
        </Col>
        <Col sm={4} lg={4}>
          <UnitBox>{unit}</UnitBox>
        </Col>
      </Row>
    </>
  );
};

export const TotalEnergyConsumption = () => {
  return (
    <StyledBlockContainer>
      <Row>
        <Col sm={2} lg={2}>
          <IconContainer>
            <IconImage src={TotalEnergySaving} />
          </IconContainer>
        </Col>
        <Col sm={22} lg={22}>
          <Row>
            <Col sm={24}>
              <HeaderContainer>
                <TotalSavingHeader>Total Energy Consumption</TotalSavingHeader>
                <UpdatedAt time={`Updated at ${convertDatetimeToStrDatetime(new Date())}`} />
              </HeaderContainer>
            </Col>
          </Row>
          <Row>
            <Col sm={24}>
              <Row>
                <Col sm={11} lg={11}>
                  <Content title={"Today"} value={1656.1} unit={"THB"} />
                </Col>
                <Col sm={2} lg={2}>
                  <Line />
                </Col>
                <Col sm={11} lg={11}>
                  <Content title={"Month"} value={24891.3} unit={"THB"} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledBlockContainer>
  );
};
