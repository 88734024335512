import Information from './Information'
import SelfDriving from './SelfDriving'
import EnergyAnalytic from './EnergyAnalytic'
import EnergyPerformance from './EnergyPerformance'
import BuildingDigitalTwin from './BuildingDigitalTwin'
import EnergyAudit from './EnergyAudit'

type EnergyContentSectionProps = {
  tabName: string
  data: any
}

const getEnergyContent = (data: any): any => ({
  information: <><Information data={data} /></>,
  selfDriving: <><SelfDriving data={data} /></>,
  energyAnalytic: <><EnergyAnalytic data={data} /></>,
  energyPerformance: <><EnergyPerformance data={data} /></>,
  buildingDigitalTwin: <><BuildingDigitalTwin data={data} /></>,
  energyAudit: <><EnergyAudit data={data} /></>,
})

const EnergyContentSection = ({ tabName, data }: EnergyContentSectionProps) => {
  return (
    <>
      {getEnergyContent(data)[tabName]}
    </>
  )
}

export default EnergyContentSection