import { Floor1 } from "./Floor1";
import { Floor2 } from "./Floor2";
import { Floor1CCC } from "./Floor1CCC";
import { Floor2CCC } from "./Floor2CCC";
import Floor1MintTowerBuildingMain from "assets/pages/Dashboard/FloorImage/Floor1MintTowerBuildingMain.svg";
import FloorMMintTowerBuildingMain from "assets/pages/Dashboard/FloorImage/FloorMMintTowerBuildingMain.svg";
import Floor2MintTowerBuildingMain from "assets/pages/Dashboard/FloorImage/Floor2MintTowerBuildingMain.svg";
import Floor3MintTowerBuildingMain from "assets/pages/Dashboard/FloorImage/Floor3MintTowerBuildingMain.svg";
import Floor4MintTowerBuildingMain from "assets/pages/Dashboard/FloorImage/Floor4MintTowerBuildingMain.svg";
import Floor510MintTowerBuildingMain from "assets/pages/Dashboard/FloorImage/Floor510MintTowerBuildingMain.svg";
import LotusGofreshFloorPlan from "assets/pages/Dashboard/FloorImage/LotusGofreshFloorPlan.svg";
import { FloorModelContainer, ImageContainer, ScaleImage, Image } from "./Components";
import { DeviceIcon } from "./DeviceIcon";
import { DeviceProps } from "MockData/DeviceData";
import HeatScale from "assets/pages/Dashboard/HeatScale.svg";
import ShellFloorPlan from "assets/pages/FloorUsage/FloorPlan/ShellFloorPlan.svg";

const FloorMap: { [hotel_code: string]: { [floorName: string]: any } } = {
  BGM: {
    F1: <Floor1 />,
    F2: <Floor2 />,
  },
  DAIC: {
    F1: <Floor1CCC />,
    F2: <Floor2CCC />,
  },
  MTW: {
    F1: <Image src={Floor1MintTowerBuildingMain} />,
    M: <Image src={FloorMMintTowerBuildingMain} />,
    F2: <Image src={Floor2MintTowerBuildingMain} />,
    F3: <Image src={Floor3MintTowerBuildingMain} />,
    F4: <Image src={Floor4MintTowerBuildingMain} />,
    F5: <Image src={Floor510MintTowerBuildingMain} />,
    F6: <Image src={Floor510MintTowerBuildingMain} />,
    F7: <Image src={Floor510MintTowerBuildingMain} />,
    F8: <Image src={Floor510MintTowerBuildingMain} />,
    F9: <Image src={Floor510MintTowerBuildingMain} />,
    F10: <Image src={Floor510MintTowerBuildingMain} />,
  },
  SHE: {
    F1: <Image src={ShellFloorPlan} />,
  },
  LTLS: {
    F1: <Image src={LotusGofreshFloorPlan} />,
  },
};

export const FloorModel = ({ deviceList, selectedTab, handleOpenModal, visibleDeviceObject, hotel_code, allIotStatusFb }: any) => {
  return (
    <FloorModelContainer>
      <ImageContainer>
        {["DAIC", "BGM", "MTW", "SHE", "LTLS"].includes(hotel_code) && selectedTab !== "B" && (
          <>
            {hotel_code && FloorMap[hotel_code][selectedTab]}
            {deviceList &&
              deviceList.map((item: DeviceProps) => (
                <>
                  <DeviceIcon
                    key={item.device_id}
                    deviceId={item.device_id}
                    agentId={item.agent_id}
                    onClick={() => handleOpenModal(item)}
                    x={item.ui_location[0]}
                    y={item.ui_location[1]}
                    showTempZone={true}
                    visible={visibleDeviceObject && visibleDeviceObject[item.agent_id]}
                    firebaseDeviceData={allIotStatusFb && allIotStatusFb[item.device_id]}
                  />
                </>
              ))}
            {hotel_code !== "SHE" && <ScaleImage src={HeatScale} />}
          </>
        )}
      </ImageContainer>
    </FloorModelContainer>
  );
};
