import { Suspense, useCallback, useState } from "react";
import { AddDeviceModal } from "./AddDeviceModal";
import TableSection from "./TableSection";
import { LoadingPage } from "components/Common/LoadingPage/LoadingPage";
import { ButtonSection } from "./ButtonSection";
import toast from "react-hot-toast";
import { DeviceExtensionCamera, DeviceExtensionEdge } from "components/Common/Table/DeviceActions";
import { useDisclosure } from "utils/hooks";
import { deleteDevice, postDevice } from "services/api.service";
import axios from "axios";
import { DeleteModal } from "components/Common/DeleteModal/DeleteModal";

export type Device = {
  id: string;
  name: string;
  description: string;
  installed_at: string;
  updated_at: string;
  status: boolean;
  site_id: number;
  floor_id: number;
} & (DeviceExtensionCamera | DeviceExtensionEdge);

export const DeviceManagementSection = ({ data, hotelCode }: any) => {
  const addDialog = useDisclosure();
  const deleteDialog = useDisclosure();
  // const editDialog = useDisclosure();
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDeviceData, setSelectedDeviceData] = useState();

  const onClose = () => {
    setIsView(false);
    setIsEdit(false);
    setSelectedDeviceData(() => undefined);
    addDialog.onClose();
  };

  const openViewDevice = (deviceData: any) => {
    setIsView(true);
    setSelectedDeviceData(deviceData);
    addDialog.onOpen();
  };

  const openEditDevice = (deviceData: any) => {
    setIsEdit(true);
    setSelectedDeviceData(deviceData);
    addDialog.onOpen();
  };

  const openDeleteDevice = (deviceData: any) => {
    setSelectedDeviceData(deviceData.device_id);
    deleteDialog.onOpen();
  };

  const onAddDevice = useCallback(async (payload: any) => {
    try {
      setLoading(true);
      // await toast.promise(postDevice(payload), {
      //   loading: "Sending...",
      //   success: (res: any) => {
      //     setLoading(false);
      //     return <b>Successful</b>;
      //   },
      //   error: (error: any) => {
      //     setLoading(false);
      //     if (axios.isAxiosError(error) && error.response?.status === 400) {
      //       return <b>Failed</b>;
      //     }
      //     return <b>Failed</b>;
      //   },
      // });
    } catch (e: unknown) {
      setLoading(false);
      if (e instanceof Error) {
        toast.error(<b>Failed to add device: {e.message}</b>);
      }
      throw e;
    }
  }, []);

  const onCloseDeleteModal = () => {
    deleteDialog.onClose();
  };

  // const onEditDevice = useCallback(async (device: Device, values: DeviceFormData) => {
  //   try {
  //     toast.success(<b>Device updated successfully</b>);
  //   } catch (e: unknown) {
  //     if (e instanceof Error) {
  //       toast.error(<b>Failed to edit device: {e.message}</b>);
  //     }
  //     throw e;
  //   }
  // }, []);

  const onDeleteDevice = useCallback(async (device_id: string) => {
    try {
      setLoading(true);
      // await toast.promise(deleteDevice(device_id), {
      //   loading: "Sending...",
      //   success: (res: any) => {
      //     setLoading(false);
      //     return <b>Successful</b>;
      //   },
      //   error: (error: any) => {
      //     setLoading(false);
      //     if (axios.isAxiosError(error) && error.response?.status === 400) {
      //       return <b>Failed</b>;
      //     }
      //     return <b>Failed</b>;
      //   },
      // });
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(<b>Failed to delete device: {e.message}</b>);
      }
      throw e;
    }
  }, []);

  return (
    <>
      <ButtonSection onOpen={addDialog.onOpen} />
      {data && (
        <TableSection
          data={data}
          openAddDevice={addDialog.onOpen}
          openViewDevice={openViewDevice}
          openEditDevice={openEditDevice}
          openDeleteDevice={openDeleteDevice}
        />
      )}
      {data === null && <p>No matching data found</p>}
      {data === undefined && <LoadingPage />}
      <Suspense fallback={null}>
        {addDialog.isOpen && (
          <AddDeviceModal
            isOpen={addDialog.isOpen}
            onClose={onClose}
            onAddDevice={onAddDevice}
            hotelCode={hotelCode}
            loading={loading}
            isView={isView}
            isEdit={isEdit}
            selectedDeviceData={selectedDeviceData}
          />
        )}
        <DeleteModal
          isOpen={deleteDialog.isOpen}
          onClose={onCloseDeleteModal}
          topic={"Device id"}
          onDelete={onDeleteDevice}
          selectedDeviceData={selectedDeviceData}
          disabled={true}
        />
      </Suspense>
    </>
  );
};
