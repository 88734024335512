export function EditIcon() {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      style={{marginRight:'4px'}}
    >
      <path d="M10.4768 9.43153L11.3101 8.55666C11.4404 8.41996 11.6669 8.51565 11.6669 8.71249V12.6877C11.6669 13.4122 11.107 14 10.4169 14H1.25003C0.559908 14 0 13.4122 0 12.6877V3.0641C0 2.3396 0.559908 1.75179 1.25003 1.75179H8.37258C8.55748 1.75179 8.65123 1.98692 8.52102 2.12635L7.68767 3.00122C7.6486 3.04223 7.59652 3.0641 7.53923 3.0641H1.25003V12.6877H10.4169V9.58463C10.4169 9.52722 10.4377 9.47254 10.4768 9.43153ZM14.555 3.91437L7.96577 10.8319C7.80457 11.0011 7.5892 11.1085 7.35705 11.1355L5.36209 11.3672C4.67979 11.4465 4.09905 10.8423 4.17457 10.1205L4.39933 7.98746C4.42245 7.76799 4.51753 7.56233 4.66974 7.40253L11.2737 0.46956C11.87 -0.15652 12.8336 -0.15652 13.4274 0.46956L14.5524 1.65064C15.1488 2.27672 15.1488 3.29102 14.555 3.91437ZM11.982 4.75917L10.469 3.17073L5.67716 8.20399C5.64679 8.23589 5.6278 8.27693 5.62314 8.32072L5.46674 9.78947C5.45315 9.91705 5.5615 10.0242 5.68893 10.0093L6.96159 9.85992C7.07739 9.84633 7.18481 9.79269 7.26525 9.70828L11.982 4.75917ZM13.6696 2.58019L12.5445 1.39911C12.4378 1.28702 12.2633 1.28702 12.1591 1.39911L11.3544 2.24391L12.8675 3.83235L13.6722 2.98755C13.7763 2.87272 13.7763 2.69228 13.6696 2.58019Z" />
    </svg>
  )
}
