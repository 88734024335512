import { useState } from "react";
import { DeviceListData, DeviceListProps } from "MockData/DeviceData";
import { BlockHeader, HeaderContainer, TitleWrap } from "./Components";
import { ChangeViewButton } from "components/Common/ChangeViewButton/ChangeViewButton";
import { DeviceFilter } from "./DeviceFilter";

const RoomSectionHeader = ({ setVisibleDeviceObject, selectedTab, buildingName, selectedView, setSelectedView }: any) => {
  const [selectedDeviceList, setSelectedDeviceList] = useState<DeviceListProps>(DeviceListData);

  const handleSelectDevice = (agentId: string) => {
    setSelectedDeviceList((prev: any) => {
      let tempPrev = [...prev];
      for (let i = 0; i < tempPrev.length; i++) {
        if (tempPrev[i].agentId === agentId) {
          tempPrev[i]["isOpened"] = !tempPrev[i]["isOpened"];
        }
      }
      return tempPrev;
    });
    setVisibleDeviceObject((prev: any) => {
      let tempPrev = { ...prev };
      tempPrev[agentId] = !tempPrev[agentId];
      return tempPrev;
    });
  };

  return (
    <>
      <TitleWrap>
        <HeaderContainer>
          <BlockHeader>{buildingName + " : " + selectedTab}</BlockHeader>
          {setSelectedView === "fp" && <DeviceFilter selectedDeviceList={selectedDeviceList} handleSelectDevice={handleSelectDevice} />}
        </HeaderContainer>
        <ChangeViewButton selectedView={selectedView} setSelectedView={setSelectedView} />
      </TitleWrap>
    </>
  );
};

export default RoomSectionHeader;
