import building_config from 'configs/building_config.json'

export const getPath = () => {
  const buildingConfig: { [buildingCode: string]: { floors: any[], buildings: string[], company_name: string } } = building_config
  const hotel_code: any = localStorage?.getItem("hotel_code")
  try {
    return `buildings/${buildingConfig[hotel_code]["company_name"]}/${buildingConfig[hotel_code]["buildings"][0]}`
  } catch (err) {
    return ''
  }
}

export const pages_path = `${getPath()}/pages/dashboard`;
export const outdoor_path = `${getPath()}/pages/dashboard/outdoor_weather`;
export const indoor_path = `${getPath()}/pages/dashboard/indoor_weather`;
export const co2_path = `${getPath()}/pages/dashboard/co2_emission`;
export const energy_saving_path = `${getPath()}/pages/dashboard/energy_saving`;
export const benchmark_rl_path = `${getPath()}/pages/dashboard/benchmark_rl`;
export const tooltips_path = `${getPath()}/pages/dashboard/tooltips`;
export const total_energy_consumption_path = `${getPath()}/pages/dashboard/total_energy_consumption`;
export const iot_devices = `${getPath()}/pages/dashboard/iot_devices`;
export const energy_info = `${getPath()}/pages/energy/information`;
export const notification_status_path = `${getPath()}/pages/dashboard/notification`;
export const global_rl_output = `${getPath()}/iot_devices/global_rl_output/rl_global_output_state/subdev_0`;
export const daikin_camera: { [cameraName: string]: string } = {
  camera1: `${getPath()}/iot_devices/44:ef:bf:a0:14:4c/camera_inout/subdev_0`,
  camera2: `${getPath()}/iot_devices/44:ef:bf:9f:dd:71/camera_inout/subdev_0`,
  camera3: `${getPath()}/iot_devices/44:ef:bf:a0:09:c2/camera_inout/subdev_0`,
  camera4: `${getPath()}/iot_devices/44:ef:bf:a0:0b:af/camera_inout/subdev_0`,
}
export const iot_devices_path = `${getPath()}/iot_devices`;
export const EmptyDeviceData = {
  ac: {
    title: '',
    online: '',
    total: ''
  },
  blind: {
    title: '',
    online: '',
    total: ''
  },
  environment_sensor: {
    title: '',
    online: '',
    total: ''
  }
}
export const api_monitor = `${getPath()}/api_monitor`;