import styled from "styled-components";
import Modal from "react-modal";
import { Col, Row } from "antd";
import SubTab from "components/SubTab";
import LineTab from "components/LineTab";

const tabNameList = [
  { title: "Control", value: "control" },
  { title: "Information", value: "information" },
  { title: "Automation", value: "automation" },
  { title: "History", value: "history" },
];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    border: "0.5px solid #f3f3f3",
    padding: "32px",
    minWidth: "750px",
    background: "white",
    boxShadow: "1px 3px 18px rgba(196, 196, 196, 0.15)",
    backdropFilter: "blur(20px)",
    minHeight: "300px",
  },
  overlay: {
    backgroundColor: "#00000066",
    zIndex: 99999,
  },
};

const DeviceType = styled.h4`
  font-weight: bold;
  font-size: 18px;
  margin: 0px 0px 4px 0px;
`;

const DeviceId = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #0e7ee4;
  border-top: 1px solid #eaeaea;
  padding-top: 4px;
  width: fit-content;
`;

const ErrorCode = styled.a`
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: red;
  width: fit-content;
  margin: 0;
`;

const Title = ({ agentId, deviceId, deviceName, firebaseDeviceData }: any) => {
  const errorCode = firebaseDeviceData && firebaseDeviceData[deviceId] ? firebaseDeviceData[deviceId]?.device?.subdev_0?.error_code : undefined;
  const statusCode = firebaseDeviceData && firebaseDeviceData[deviceId] ? firebaseDeviceData[deviceId]?.device?.subdev_0?.status_code : undefined;

  const deviceMap: any = {
    bac0hvac: "Air-condition",
    netatmo: "Air Quality (Netatmo)",
    tuya_air_quality: "Air Quality (Tuya)",
    tuya_env_relay: "Environment Sensor (Tuya)",
    son_env: "Environment Sensor (Sonoff)",
    tuya_temp_humid: "Environment Sensor (Tuya)",
    airveda: "Air Quality (Airveda)",
    cctv: "CCTV",
    tasmota: "Fresh Air",
    tuyacloudcurtain: "Curtain",
    tuyablinds: "Curtain",
    itm_oau: "Fresh Air",
    itm_fcu: "Air-condition",
    camera: "CCTV",
    tuya_plug: "Smart Plug",
  };

  return (
    <>
      <Row>
        <Col xs={24}>
          <DeviceType>{deviceMap[agentId] ? deviceMap[agentId] : "Air-condition"}</DeviceType>
        </Col>
        <Col xs={24}>
          <DeviceId>
            {deviceName} / {deviceId}
          </DeviceId>
        </Col>
        <Col xs={24}>
          {statusCode === "error" && (
            <ErrorCode href="https://www.daikin.co.th/service-error-code" target="_blank">
              Error code:{errorCode}
            </ErrorCode>
          )}
          {statusCode === "unconnected" && (
            <ErrorCode href="https://www.daikin.co.th/service-error-code" target="_blank">
              Unconnected
            </ErrorCode>
          )}
        </Col>
      </Row>
    </>
  );
};

export const RoomModal = ({
  modalIsOpen,
  handleCloseModal,
  selectedTabName,
  handleSelectTabName,
  roomInfoData,
  command,
  handleChangeCommand,
  allIotStatusFb,
}: any) => {
  return (
    <>
      <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} style={customStyles} contentLabel="Edit modal">
        <Row style={{ marginBottom: "20px" }}>
          <Col xs={11}>
            <Title
              // agentId={roomInfoData && roomInfoData.room_name}
              deviceId={roomInfoData && roomInfoData.room_type}
              deviceName={roomInfoData && roomInfoData.room_name}
              firebaseDeviceData={allIotStatusFb}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={13}>
            <LineTab
              dataList={tabNameList}
              selectedValue={selectedTabName}
              handleSelect={handleSelectTabName}
              size={"small"}
              selectedTextColor={"#0E7EE4"}
            />
          </Col>
        </Row>
        {selectedTabName === "control" && (
          <Row>
            <Col xs={24}></Col>
          </Row>
        )}
        {selectedTabName === "information" && (
          <Row>
            <Col xs={24}></Col>
          </Row>
        )}
        {selectedTabName === "automation" && (
          <Row>
            <Col xs={24}></Col>
          </Row>
        )}
        {selectedTabName === "history" && (
          <Row>
            <Col xs={24}></Col>
          </Row>
        )}
      </Modal>
    </>
  );
};
