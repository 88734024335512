import styled from "styled-components";
import tw from "twin.macro";

const SVG = styled.svg(()=>[tw`w-5 h-5`])

export function DownloadIcon() {
  return (
    <SVG xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
    </SVG>
  );
}
