import styled from 'styled-components';
import {
  BlockContainer,
} from 'components/Common/components';
import { Row, Col } from 'antd';

const Container = styled(BlockContainer)`
  box-shadow: 0px 2px 8px rgba(145, 158, 171, 0.25);
  flex-direction: row;
  height: 100%;
`;

const AttributeKey = styled.p`
  font-weight: 500;
  font-size: 16px;
  margin: 0px;
`;

const AttributeValue = styled.p`
  font-weight: 500;
  font-size: 16px;
  margin: 0px;
  text-align: end;
  line-height: 18px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const AttributeList = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const SolarAttributes = ({ energySavingThisMonth, energySavingAccumulate }: any) => {
  return (
    <>
      <Container>
        <Wrapper>
          <Row>
            <Col sm={24}>
              <AttributeList>
                <AttributeKey>PV Yield</AttributeKey>
                <AttributeValue style={{ textAlign: 'end' }}>
                  145.89 <br />
                  <small style={{fontWeight: 300}}>MWh</small>
                </AttributeValue>
              </AttributeList>
              <AttributeList>
                <AttributeKey>PV Yield</AttributeKey>
                <AttributeValue style={{ textAlign: 'end' }}>
                  145.89 <br />
                  <small style={{fontWeight: 300}}>MWh</small>
                </AttributeValue>
              </AttributeList>
              <AttributeList>
                <AttributeKey>PV Yield</AttributeKey>
                <AttributeValue style={{ textAlign: 'end' }}>
                  145.89 <br />
                  <small style={{fontWeight: 300}}>MWh</small>
                </AttributeValue>
              </AttributeList>
            </Col>
          </Row>
        </Wrapper>
      </Container>
    </>
  );
};
