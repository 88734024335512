import styled from "styled-components";
import { Row, Col, Spin } from "antd";
import { Slider } from "antd";
import { useEffect, useState } from "react";
import { LoadingPage } from "components/Common/LoadingPage/LoadingPage";
import BlindStateOn from "assets/pages/Dashboard/DeviceControl/BlindStateOn.svg";
import BlindStateOff from "assets/pages/Dashboard/DeviceControl/BlindStateOff.svg";
import moment from "moment";

const Container = styled.div`
  width: 750px;
  height: 350px;
`;

const WrapCurtain = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 130px;
  padding: 0px 80px;
`;

const WrapSlider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const SwitchButton = styled.button<any>`
  width: 150px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(1px 3px 20px rgba(57, 124, 221, 0.3));
  backdrop-filter: blur(20px);
  margin: 0px 40px 0px 40px;
  background: linear-gradient(154.82deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.3) 100%);
  box-shadow: 0px 3px 20px rgba(161, 183, 205, 0.2);
  border-radius: 8px;
  &:disabled {
    cursor: not-allowed;
  }
  &:hover {
    box-shadow: 0px 3px 20px rgba(161, 183, 205, 0.4);
    &:disabled {
      box-shadow: 0px 3px 20px rgba(161, 183, 205, 0.2);
    }
  }
`;

const Image = styled.img`
  width: 56px;
  height: 56px;
`;

const marks = {
  0: "0%",
  25: "25%",
  50: "50%",
  75: "75%",
  100: {
    style: {
      color: "#788796",
    },
    label: <strong>100%</strong>,
  },
};

export const BlindControl = ({ command, handleChangeCommand, deviceData, loading, isOnline }: any) => {
  const [value, setValue] = useState<number | undefined | null>();
  const [initialValue, setInitialValue] = useState();

  const onChangeBlind = (percent: number) => {
    setValue(percent);
    handleChangeCommand("percent_position", percent, deviceData.agent_id, 0);
    // if (percent === 0) {
    //   handleChangeCommand("control_state", "open", deviceData.agent_id, 0);
    // } else if (percent === 100) {
    //   handleChangeCommand("control_state", "close", deviceData.agent_id, 0);
    // } else {
    //   handleChangeCommand("percent_position", percent, deviceData.agent_id, 0);
    // }
  };

  const onOpenClose = (percent: number) => {
    setValue(percent);
    handleChangeCommand("percent_position", percent, deviceData.agent_id, 0);
    // if (percent === 0) {
    //   handleChangeCommand("control_state", "open", deviceData.agent_id, 0);
    // } else if (percent === 100) {
    //   handleChangeCommand("control_state", "close", deviceData.agent_id, 0);
    // }
  };

  const onChange = (sliderPercent: number) => {
    setValue(sliderPercent);
  };

  useEffect(() => {
    const percent = command?.percent_position;
    if (!percent) {
      setInitialValue(() => percent);
    } else {
      if (percent !== initialValue) {
        setInitialValue(() => percent);
      }
    }
    setValue(() => Number(percent));
    return () => {
      setValue(undefined);
    };
  }, []);

  return (
    <Container>
      <Row>
        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <>
                <WrapCurtain>
                  <SwitchButton onClick={() => onOpenClose(0)} disabled={!isOnline}>
                    <Image src={BlindStateOn} />
                  </SwitchButton>
                  <WrapSlider>
                    <Slider
                      marks={marks}
                      value={value ? value : 0}
                      tooltipVisible={true}
                      step={null}
                      onChange={onChange}
                      onAfterChange={(percent: number) => onChangeBlind(percent)}
                      disabled={!isOnline}
                    />
                  </WrapSlider>
                  <SwitchButton onClick={() => onOpenClose(100)} disabled={!isOnline}>
                    <Image src={BlindStateOff} />
                  </SwitchButton>
                </WrapCurtain>

                <p style={{ textAlign: "center", fontWeight: "bold" }}>
                  {loading && <Spin style={{ marginRight: "8px" }} />}
                  {value} %
                </p>
              </>
              {value === undefined && <p>No matching data found</p>}
              {value === null && <LoadingPage />}
            </Col>
          </Row>
        </Col>
      </Row>
      <p style={{ position: "absolute", bottom: "0px", right: "12px", marginBottom: "0px", fontSize: "12px", color: "#8c8c8c" }}>
        <span>Updated at: {command?.timestamp && moment(command?.timestamp).format("YYYY-MM-DD HH:mm:ss")}</span>
      </p>
    </Container>
  );
};
