import axios from 'axios';
import { toast } from 'react-hot-toast';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_BASE_URL;
let env = "production"

const requestHandler = (request: any) => {
    request.headers['Authorization'] = authHeader();
    return request;
}

const responseSuccessHandler = (response: any) => {
    return response.data;
};

const forceLogout = () => {
    localStorage.clear()
    sessionStorage.clear()
    window.location.href = '/login'
}

const getRole = () => {
    const role = localStorage.getItem('role')
    return role
}

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    env = 'development'
}

export const responseErrorHandler = (error: any) => {
    var errors = ["Something went wrong, please try again"];
    const isDev = env === "development"
    console.log(error)
    if (error.response) {
        if (error.response.data.errors) {
            errors = error.response.data.errors;
        }
        else if (error.response.data.error) {
            errors = [error.response.data.error];
        }
        else {
            errors = [error]
        }

        if (error?.response?.status === 401) {
            if (getRole() === "md") {
                forceLogout();
            }
        }
        if (isDev) {
            console.log(errors)
            // toast.error(errors.toString())
        }
    } else if (error.request) {
        if (isDev) {
            console.log(error.request);
            // toast.error(error.request.toString())
        }
    } else {
        if (isDev) {
            console.log('Error', error.message);
            // toast.error(error.message)
        }
    }
    return Promise.reject({
        status: error?.response?.status,
        errors: errors
    });
}

export const prepareAxios = () => {
    axios.defaults.baseURL = API_URL;
    axios.interceptors.request.use(request => requestHandler(request));
    axios.interceptors.response.use(
        response => responseSuccessHandler(response),
        error => responseErrorHandler(error)
    );
}
