import styled from "styled-components";
import { BlockContainer, SubBlockHeader, BlockHeader4, BlockValue } from "components/Common/components";
import Co2EmissionIcon from "assets/pages/Dashboard/Co2EmissionIcon.svg";
import { Row, Col } from "antd";
import { LoadingPage } from "components/Common/LoadingPage/LoadingPage";

const Container = styled(BlockContainer)`
  background: linear-gradient(180deg, rgba(243, 249, 255, 0) 0%, rgba(230, 242, 255, 0.76) 100%);
  box-shadow: 0px 2px 8px rgba(145, 158, 171, 0.25);
  flex-direction: row;
`;

const Image = styled.img`
  height: 64px;
  width: 60px;
  margin-right: 13px;
`;

const Wrapper = styled.div`
  width: calc(100% - 64px);
  height: 100%;
`;

export const Co2Emission = ({ co2ThisMonth, co2Accumulate }: any) => {
  return (
    <>
      <Container>
        <Image src={Co2EmissionIcon} />
        <Wrapper>
          <Row>
            <Col sm={24}>
              <Row>
                <SubBlockHeader>CO2 Reduction (kg)</SubBlockHeader>
              </Row>
              <Row>
                <Col sm={12}>
                  <Row>
                    <BlockHeader4>This month</BlockHeader4>
                  </Row>
                  <Row>
                    <BlockValue>{co2ThisMonth}</BlockValue>
                  </Row>
                </Col>
                <Col sm={12}>
                  <Row>
                    <BlockHeader4>Accumulate</BlockHeader4>
                  </Row>
                  <Row>
                    <BlockValue>{co2Accumulate}</BlockValue>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Wrapper>
      </Container>
    </>
  );
};

export const Co2EmissionMd = ({ data, floor }: any) => {
  return (
    <>
      <Container style={{ flexDirection: "column" }}>
        <div style={{ display: "flex" }}>
          <Image src={Co2EmissionIcon} />
          <Wrapper>
            <Row>
              <Col sm={24}>
                <Row>
                  <Col sm={24}>
                    <SubBlockHeader style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "0px" }}>CO2 Reduction (kg)</SubBlockHeader>
                  </Col>
                  <Col sm={24}>
                    <p style={{ marginBottom: "0px" }}>{floor}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Wrapper>
        </div>
        {data === undefined && <LoadingPage height={"90px"} />}
        {data === null && (
          <p style={{ width: "100%", textAlign: "center", marginTop: "35px", marginBottom: "40px", color: "#0E7EE4" }}>No matching data found</p>
        )}
        {data && (
          <Row style={{ marginTop: "20px" }}>
            <Col sm={12} style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              <Row>
                <BlockHeader4 style={{ fontSize: "14px" }}>Today</BlockHeader4>
              </Row>
              <Row>
                <BlockValue style={{ fontSize: "32px" }}>{data.daily.toFixed(2)}</BlockValue>
              </Row>
            </Col>
            <Col sm={12} style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              <Row>
                <BlockHeader4 style={{ fontSize: "14px" }}>This month</BlockHeader4>
              </Row>
              <Row>
                <BlockValue style={{ fontSize: "32px" }}>{data.monthly.toFixed(2)}</BlockValue>
              </Row>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};
