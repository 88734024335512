import { useEffect, useState } from "react";
import { PageContainer } from "components/Common/components";
import styled from "styled-components";
import tw from "twin.macro";
import { StoreList } from "MockData/StoreList";

const Wrapper = styled.div(() => [tw`grid grid-cols-12 gap-6`]);

const MediumBox = styled.div(() => [tw`col-span-6 py-8 rounded-xl bg-white shadow-table pl-8 pr-4 grid grid-cols-12`]);

const MediumImage = styled.img(() => [tw`w-36 h-36 col-span-3 rounded-full`]);

const WrapMediumDesc = styled.div(() => [tw`col-span-9`]);

const SmallBox = styled.div(() => [
  tw`col-span-3 py-4 grid grid-cols-12 rounded-xl bg-white px-4 border-b-2 border-gray-300 items-center overflow-hidden`,
]);

const SmallImage = styled.img(() => [tw`w-12 h-12 rounded-lg col-span-3`]);

const WrapSmallDesc = styled.div(() => [tw`col-span-9 flex flex-col justify-start`]);

const MediumName = styled.p(() => [tw`text-[32px] font-bold mb-3 leading-5`]);

const Name = styled.p(() => [tw`mb-0 leading-5`]);

const InstalledStatus = styled.div<any>`
  ${tw`rounded-md px-1 py-0.5 bg-gray-200 w-[fit-content] text-[10px] items-center mt-1`}
  ${({ installed }) => (installed ? `background: #b3edb3` : `background`)}
`;

const StorePage = () => {
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [url, setUrl] = useState<string | undefined>();

  useEffect(() => {
    let today = new Date();
    const yesterday = new Date(today.setDate(today.getDate() - 1));
    setStartDate(yesterday);
  }, []);

  useEffect(() => {
    let code = localStorage.getItem("hotel_code");
    let hotel_code = "Bgrimm";
    const siteMap: any = {
      DAIC: "DaikinCCC",
      BGM: "Bgrimm",
    };
    if (code) {
      hotel_code = siteMap[code];
    }
    if (startDate) {
      let stringDatetime =
        startDate.getFullYear().toString() +
        (startDate.getMonth() < 10 ? "0" + (startDate.getMonth() + 1) : startDate.getMonth() + 1).toString() +
        (startDate.getDate() < 10 ? "0" + startDate.getDate() : startDate.getDate()).toString();
      setUrl(`https://altoautomate.blob.core.windows.net/automation/${hotel_code}_report_` + stringDatetime + ".html");
    }
  }, [startDate]);

  return (
    <>
      <PageContainer>
        <Wrapper>
          {StoreList.hightlight.map((items) => (
            <MediumBox>
              <WrapMediumDesc>
                <MediumName>{items.name}</MediumName>
                <InstalledStatus installed={items.status === "INSTALLED"}>{items.status}</InstalledStatus>
              </WrapMediumDesc>
              <MediumImage src={items.url} />
            </MediumBox>
          ))}
          {StoreList.available.map((items) => (
            <SmallBox>
              <SmallImage src={items.url} />
              <WrapSmallDesc>
                <Name>{items.name}</Name>
                <InstalledStatus installed={items.status === "INSTALLED"}>{items.status}</InstalledStatus>
              </WrapSmallDesc>
            </SmallBox>
          ))}
        </Wrapper>
      </PageContainer>
    </>
  );
};

export default StorePage;
