import { Row, Col } from "antd";
import { RealtimePowerGeneration } from "./RealtimePowerGeneration";
import { SummaryBox } from "./SummaryBox";
import { EnergyBaseline } from "./EnergyBaseline";
import { EnergyConsumption } from "./EnergyConsumption";
import { EnergySaving } from "./EnergySaving";
import { Co2EmissionMd, Co2Emission } from "./Co2Emission";
import { AggregatedEnergyConsumption } from "./AggregatedEnergyConsumption";
import { CurrentPowerGeneration } from "./CurrentPowerGeneration";
import { SolarAttributes } from "./SolarAttributes";

const SummarySection = ({
  realtimePowerData,
  energyConsumptionData,
  realtimePowerConsumption,
  selectedTab,
  co2Emission,
  energySaving,
  energyConsumptionVsBaselineData,
  buildingCode,
  benchmarkRl,
  floorMap,
}: any) => {
  return (
    <>
      {selectedTab === "B" && (
        <Row gutter={[18, 18]}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ zIndex: 9999 }}>
            <RealtimePowerGeneration data={realtimePowerData} buildingCode={buildingCode} />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ zIndex: 9998 }}>
            <EnergyBaseline data={energyConsumptionVsBaselineData} />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <EnergyConsumption data={energyConsumptionData} buildingCode={buildingCode} />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Row gutter={[18, 18]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <EnergySaving
                  energySavingThisMonth={energySaving?.this_month}
                  energySavingAccumulate={energySaving?.accumulated}
                  benchmarkRl={benchmarkRl}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Co2Emission co2ThisMonth={co2Emission?.this_month} co2Accumulate={co2Emission?.accumulated} />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {["F1", "F2", "F3", "F4", "F5", "F6", "F7", "F8", "F9"].includes(selectedTab) && (
        <Row gutter={[18, 18]}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ zIndex: 9999 }}>
            <SummaryBox
              title={"Real-time Power Consumption"}
              subtitle={floorMap[selectedTab]["summary_box_label"]}
              graphType={"line"}
              data={
                realtimePowerConsumption && realtimePowerConsumption[selectedTab] && realtimePowerConsumption[selectedTab]["realtimePowerConsumption"]
              }
              unit={"kW"}
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <SummaryBox
              title={"Daily Energy Consumption"}
              subtitle={floorMap[selectedTab]["summary_box_label"]}
              graphType={"bar"}
              keys={["y"]}
              data={
                realtimePowerConsumption && realtimePowerConsumption[selectedTab] && realtimePowerConsumption[selectedTab]["dailyEnergyConsumption"]
              }
              unit={"kWh"}
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <SummaryBox
              title={"Monthly Energy Consumption"}
              subtitle={floorMap[selectedTab]["summary_box_label"]}
              graphType={"bar"}
              keys={["y"]}
              data={
                realtimePowerConsumption && realtimePowerConsumption[selectedTab] && realtimePowerConsumption[selectedTab]["monthlyEnergyConsumption"]
              }
              unit={"kWh"}
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Row gutter={[18, 18]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Co2EmissionMd
                  data={realtimePowerConsumption && realtimePowerConsumption[selectedTab] && realtimePowerConsumption[selectedTab]["co2"]}
                  floor={floorMap[selectedTab]["summary_box_label"]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {["S"].includes(selectedTab) && (
        <Row gutter={[18, 18]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <RealtimePowerGeneration data={realtimePowerData} />
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <AggregatedEnergyConsumption energySavingThisMonth={energySaving.this_month} energySavingAccumulate={energySaving.accumulated} />
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <CurrentPowerGeneration energySavingThisMonth={energySaving.this_month} energySavingAccumulate={energySaving.accumulated} />
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <SolarAttributes energySavingThisMonth={energySaving.this_month} energySavingAccumulate={energySaving.accumulated} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default SummarySection;
