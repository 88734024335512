import { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import CCTVDropdown from "assets/pages/Dashboard/CCTVDropdown.svg";
import CCTVDropdownWhite from "assets/pages/Dashboard/CCTVDropdown_white.svg";
import Zoom from "assets/pages/Dashboard/Zoom.svg";
import Modal from "react-modal";

const Container = styled.div<any>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  right: 10px;
  top: 10px;
  width: 200px;
  height: auto;
  max-height: 100%;
  overflow-y: scroll;
  z-index: 100;
`;

const IconContainer = styled.img<any>`
  width: 40px;
  height: 40px;
  background: ${(props) => (props.isOpen ? "linear-gradient(274.48deg, #0e7ee4 0%, #55a6f2 100%)" : "rgba(219, 228, 255, 0.3)")};
  backdrop-filter: blur(20px);
  border-radius: 6px;
  padding: 6px;
  border: ${(props) => (props.isOpen ? "1px solid #d6d6d6" : "1px solid #0e7ee4")};
  cursor: pointer;
  margin-bottom: 12px;
`;

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding: 6px;
  background: rgba(6, 91, 169, 0.2);
  box-shadow: 2.54475px 5.0895px 25.4475px rgba(161, 183, 205, 0.2);
  backdrop-filter: blur(50.895px);
  border-radius: 10px;
  margin-bottom: 12px;
`;

const CCTVName = styled.p`
  font-size: 12px;
  color: #0e7ee4;
  margin-bottom: 4px;
  margin-top: 8px;
`;

const VideoBox = styled.img`
  width: 100%;
`;

const ZoomBox = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.837);
  backdrop-filter: blur(10px);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ZoomIcon = styled.img`
  width: 11px;
  height: 11px;
`;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    border: "0.5px solid #f3f3f3",
    height: "95vh",
  },
  overlay: {
    backgroundColor: "#00000066",
    zIndex: 99999,
  },
};

const CameraModule = ({ isOpen }: any) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  let ImageRef1 = useRef<any>();
  let ImageRef2 = useRef<any>();
  const [sockets, setSockets] = useState<any[]>([]);

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    const WS_URL1 = `wss://stream.altotech.net/socket/1`;
    const WS_URL2 = `wss://stream.altotech.net/socket/2`;

    if (isOpen) {
      setSockets([new WebSocket(WS_URL1), new WebSocket(WS_URL2)]);
    }

    setSockets((prev: any) => {
      prev[0].onopen = function () {
        prev[0].onmessage = function (event: any) {
          const reader = new FileReader();
          reader.onload = () => {
            if (ImageRef1.current) {
              ImageRef1.current.src = reader.result;
            }
          };
          reader.readAsDataURL(event.data);
        };
      };
      prev[1].onopen = function () {
        prev[1].onmessage = function (event: any) {
          const reader = new FileReader();
          reader.onload = () => {
            if (ImageRef1.current) {
              ImageRef2.current.src = reader.result;
            }
          };
          reader.readAsDataURL(event.data);
        };
      };
      return prev;
    });

    return () => {
      setSockets((prev: any) => {
        prev[0].close();
        prev[1].close();
        return prev;
      });
    };
  }, []);

  return (
    <>
      <VideoContainer>
        <ZoomBox onClick={() => setModalIsOpen(!modalIsOpen)}>
          <ZoomIcon src={Zoom} />
        </ZoomBox>
        <CCTVName>CCTV 1</CCTVName>
        <VideoBox ref={ImageRef1} style={{ width: "100%", height: "100%" }} />
      </VideoContainer>
      <VideoContainer>
        <ZoomBox onClick={() => setModalIsOpen(!modalIsOpen)}>
          <ZoomIcon src={Zoom} />
        </ZoomBox>
        <CCTVName>CCTV 2</CCTVName>
        <VideoBox ref={ImageRef2} style={{ width: "100%", height: "100%" }} />
      </VideoContainer>

      <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} style={customStyles} contentLabel="Example Modal">
        {isOpen && (
          <>
            <CCTVName>CCTV 1</CCTVName>
            <VideoBox ref={ImageRef1} style={{ width: "100%", height: "auto" }} />
            <CCTVName>CCTV 2</CCTVName>
            <VideoBox ref={ImageRef2} style={{ width: "100%", height: "auto" }} />
          </>
        )}
      </Modal>
    </>
  );
};

export const CameraDropdownGardenWing = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Container>
        <IconContainer isOpen={isOpen} src={isOpen ? CCTVDropdownWhite : CCTVDropdown} onClick={() => handleOpen()} />
        {isOpen && <CameraModule isOpen={isOpen} />}
      </Container>
    </>
  );
};
