import { useCallback, useState, Suspense, useEffect } from "react";
import { PageContainer } from "components/Common/components";
import TableSection from "./TableSection";
import { DeviceFormData } from "components/Common/Table/EditDeviceDialog";
import { DeviceExtensionCamera, DeviceExtensionEdge } from "components/Common/Table/DeviceActions";
import { toast } from "react-hot-toast";
import { deleteBlob, getAllBlobs, getBlobPathByName } from "services/api.service";
import { useDisclosure } from "utils/hooks";
import { AddFileModal } from "./AddFileModal/AddFileModal";
import { EditDeviceModal } from "./EditDeviceModal/EditDeviceModal";
import { responseErrorHandler } from "services/prepareAxios";
import { ButtonSection } from "./ButtonSection";
import axios from "axios";
import { DeleteModal } from "components/Common/DeleteModal/DeleteModal";

export type Device = {
  id: string;
  name: string;
  description: string;
  installed_at: string;
  updated_at: string;
  status: boolean;
  site_id: number;
  floor_id: number;
} & (DeviceExtensionCamera | DeviceExtensionEdge);

const DocumentPage = () => {
  const addDialog = useDisclosure();
  const editDialog = useDisclosure();
  const viewDialog = useDisclosure();
  const deleteDialog = useDisclosure();
  const [hotelCode, setHotelCode] = useState<string | undefined>();
  const [data, setData] = useState<any[] | null | undefined>();
  const [loading, setLoading] = useState(true);
  const [selectedDocName, setSelectedDocName] = useState<string | undefined>();

  const downloadFile = async (fileName: any) => {
    try {
      const result = await getBlobPathByName(fileName);
      const url = result?.data?.url;
      window.location.assign(url);
    } catch (err) {
      responseErrorHandler(err);
    }
  };

  const openDeleteFile = (docData: any) => {
    setSelectedDocName(docData?.document_name);
    deleteDialog.onOpen();
  };

  const onCloseDeleteModal = () => {
    deleteDialog.onClose();
  };

  const onAddDevice = useCallback(async (values: DeviceFormData) => {
    try {
      toast.success(<b>Device added successfully</b>);
      addDialog.onClose();
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(<b>Failed to add device: {e.message}</b>);
      }
      addDialog.onClose();
      throw e;
    }
  }, []);

  const onEditDevice = useCallback(async (device: Device, values: DeviceFormData) => {
    try {
      toast.success(<b>Device updated successfully</b>);
      editDialog.onClose();
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(<b>Failed to edit device: {e.message}</b>);
      }
      editDialog.onClose();
      throw e;
    }
    //@ts-ignore
  }, []);

  const onDeleteBlob = useCallback(async (blobName: string) => {
    if (blobName) {
      try {
        setLoading(true);
        await toast.promise(deleteBlob(blobName), {
          loading: "Sending...",
          success: (res: any) => {
            deleteDialog.onClose();
            getData();
            setLoading(false);
            return <b>Successful</b>;
          },
          error: (error: any) => {
            deleteDialog.onClose();
            setLoading(false);
            if (axios.isAxiosError(error) && error.response?.status === 400) {
              return <b>Failed</b>;
            }
            return <b>Failed</b>;
          },
        });
      } catch (e: unknown) {
        deleteDialog.onClose();
        setLoading(false);
        if (e instanceof Error) {
          toast.error(<b>Failed to delete device: {e.message}</b>);
        }
        throw e;
      }
    } else {
      deleteDialog.onClose();
      alert("Cannot delet this file");
      return;
    }

    //@ts-ignore
  }, []);

  const getData = async () => {
    try {
      const result = await getAllBlobs();
      setData(result.data.files.map((blobItems: any) => ({ ...blobItems, document_type: blobItems?.document_name?.split(".")[1] })));
      setLoading(false);
    } catch (err) {
      responseErrorHandler(err);
      setData(null);
    }
  };

  useEffect(() => {
    const hotel_code = localStorage.getItem("hotel_code");
    if (hotel_code) {
      setHotelCode(hotel_code);
    }
    if (loading) {
      getData();
    }
  }, []);

  return (
    <PageContainer>
      <ButtonSection onOpen={addDialog.onOpen} />
      <TableSection data={data} openViewFile={viewDialog.onOpen} downloadFile={downloadFile} openDeleteFile={openDeleteFile} />
      <Suspense fallback={null}>
        <AddFileModal isOpen={addDialog.isOpen} onClose={addDialog.onClose} onAddDevice={onAddDevice} hotelCode={hotelCode} getData={getData} />
        <EditDeviceModal isOpen={editDialog.isOpen} onClose={editDialog.onClose} onEditDevice={onEditDevice} hotelCode={hotelCode} />
        <DeleteModal
          isOpen={deleteDialog.isOpen}
          onClose={onCloseDeleteModal}
          topic={"Document name"}
          onDelete={onDeleteBlob}
          selectedDeviceData={selectedDocName}
          loading={loading}
        />
      </Suspense>
    </PageContainer>
  );
};

export default DocumentPage;
