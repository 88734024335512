import Button from "components/Common/Button";
import styled from "styled-components";
import { BiPlus } from "react-icons/bi";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 0px;
  margin-bottom: 12px;
`;

export const ButtonSection = ({ onOpen }: any) => {
  return (
    <Wrapper>
      <Button
        active
        title={"Add"}
        onClick={onOpen}
        size={"medium"}
        bgColor={"#0E7EE4"}
        width={160}
        icon={<BiPlus style={{ height: "24px", width: "24px" }} color={"white"} />}
      />
    </Wrapper>
  );
};
