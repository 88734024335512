import styled from "styled-components";
import { BlockContainer, BlockHeader4, SubBlockHeader } from "components/Common/components";
import EnergySavingIcon from "assets/pages/Dashboard/EnergySavingIcon.svg";
import { Row, Col } from "antd";

const Container = styled(BlockContainer)`
  background: linear-gradient(1.47deg, rgba(232, 255, 250, 0.72) 1.25%, rgba(255, 255, 255, 0) 98.75%);
  box-shadow: 0px 2px 8px rgba(145, 158, 171, 0.25);
  flex-direction: row;
`;

const Image = styled.img`
  height: 64px;
  width: 60px;
  margin-right: 13px;
`;

const Wrapper = styled.div`
  width: calc(100% - 64px);
  height: 100%;
`;

const BlockValue = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin: 0px;
  margin-top: 0px;
`;

export const EnergySaving = ({ energySavingThisMonth, energySavingAccumulate, benchmarkRl }: any) => {
  return (
    <>
      <Container>
        <Image src={EnergySavingIcon} />
        <Wrapper>
          <Row>
            <Col sm={24}>
              <Row>
                <SubBlockHeader>Energy Saving (THB) ({benchmarkRl && benchmarkRl?.percent} %)</SubBlockHeader>
              </Row>
              <Row>
                {benchmarkRl && (
                  <Col sm={8}>
                    <Row>
                      <BlockHeader4>Daily</BlockHeader4>
                    </Row>
                    <Row>
                      <BlockValue>
                        {benchmarkRl?.avg_energy &&
                          (benchmarkRl?.avg_energy * 4.2).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </BlockValue>
                    </Row>
                  </Col>
                )}
                <Col sm={8}>
                  <Row>
                    <BlockHeader4>This month</BlockHeader4>
                  </Row>
                  <Row>
                    <BlockValue>
                      {energySavingThisMonth &&
                        (energySavingThisMonth * 4.2).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                    </BlockValue>
                  </Row>
                </Col>
                <Col sm={8}>
                  <Row>
                    <BlockHeader4>Accumulate</BlockHeader4>
                  </Row>
                  <Row>
                    <BlockValue>
                      {energySavingAccumulate &&
                        (energySavingAccumulate * 4.2).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                    </BlockValue>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Wrapper>
      </Container>
    </>
  );
};
