import styled from "styled-components";
import Trophy from "assets/pages/Energy/Trophy.svg";
import { useMemo, useState } from "react";
import { SummaryBarChart } from "components/Common/Graph/BarChart";
import { AiOutlinePlus } from "react-icons/ai";

type AgentSummaryProps = {
  agentSummaryData: any;
};

const Container = styled.div`
  background: white;
  box-shadow: 1.4px 4.2px 18.2px rgba(196, 196, 196, 0.15);
  border-radius: 16px;
  min-height: 716px;
  overflow: scroll;
  padding: 18px 24px;
`;

const InstallDeviceContainer = styled.div`
  background: rgba(189, 190, 200, 0.1);
  opacity: 0.7;
  border: 1px dashed #bdbec8;
  box-shadow: 1.4px 4.2px 18.2px rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(28px);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 75px;
  transition: all;
  padding: 12px;
  margin-top: 12px;
  cursor: pointer;
  &:hover {
    background: rgba(189, 190, 200, 0.2);
  }
`;

const DeviceTitle = styled.h4`
  font-size: 20px;
  font-weight: bold;
`;

const WrapProgress = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.img`
  width: 72px;
  margin: 20px;
`;

const WrapTotalStatus = styled.div`
  width: 100%;
  height: 42px;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid rgba(183, 204, 251, 0.3);
`;

const WrapStatus = styled.div`
  width: 100%;
  padding-top: 8px;
  display: flex;
  justify-content: space-around;
`;

const WrapStatusTitle = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
`;

const WrapStatusValue = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const TotalStatus = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin: 0px;
`;

const Status = styled.p`
  font-size: 16px;
  margin: 0px;
`;

const Dot = styled.div<any>`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background: ${(props) => (props.bgColor ? props.bgColor : "#0E7EE4")};
  margin-right: 12px;
`;

const WrapChart = styled.div`
  margin-top: 20px;
`;

const WrapBarChart = styled.div`
  display: flex;
  height: 110px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AgentSummary = ({ agentSummaryData }: AgentSummaryProps) => {
  const [stat, setStat] = useState({
    total: 0,
    win: 0,
    lost: 0,
  });
  const [graphData, setGraphData] = useState<any[] | undefined>();

  useMemo(() => {
    if (agentSummaryData) {
      let total = 0;
      let win = 0;
      let lost = 0;
      let emptyGraphData: any[] = [];
      agentSummaryData.forEach((item: any) => {
        let anEmptyGraphData: any[] = [];
        total = total + item.total;
        win = win + item.on;
        lost = lost + item.off;
        anEmptyGraphData.push(
          {
            label: "win",
            times: item.on,
          },
          {
            label: "lost",
            times: item.off,
          }
        );
        emptyGraphData.push(anEmptyGraphData);
      });
      setStat({ total, win, lost });
      setGraphData(emptyGraphData);
    }
  }, []);
  return (
    <Container>
      <DeviceTitle>Agent Summary</DeviceTitle>
      <WrapProgress>
        <Icon src={Trophy} />
      </WrapProgress>
      <WrapTotalStatus>
        <WrapStatusTitle>
          <TotalStatus>Total</TotalStatus>
        </WrapStatusTitle>
        <WrapStatusValue>
          <TotalStatus>{stat.total} times</TotalStatus>
        </WrapStatusValue>
      </WrapTotalStatus>
      <WrapStatus>
        <WrapStatusTitle>
          <Dot bgColor={"linear-gradient(77.25deg, #0E7EE4 9.22%, #14B8B4 90.78%)"} />
          <Status>Win</Status>
        </WrapStatusTitle>
        <WrapStatusValue>
          <Status>{stat.win} times</Status>
        </WrapStatusValue>
      </WrapStatus>
      <WrapStatus>
        <WrapStatusTitle>
          <Dot bgColor={"#788796"} />
          <Status>Off</Status>
        </WrapStatusTitle>
        <WrapStatusValue>
          <Status>{stat.lost} times</Status>
        </WrapStatusValue>
      </WrapStatus>
      <WrapChart>
        {graphData &&
          graphData.map((item: any) => (
            <WrapBarChart>
              <SummaryBarChart data={item} unit={"times"} horizontal legend={"Times"} keys={["times"]} />
            </WrapBarChart>
          ))}
      </WrapChart>
      <InstallDeviceContainer>
        <AiOutlinePlus size={50} style={{ margin: "0px 12px 0px 0px" }} />
        Please install more device to see data.
      </InstallDeviceContainer>
    </Container>
  );
};
