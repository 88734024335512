import { Spin } from 'antd';

export const LoadingPage = ({ height }: string | undefined | any) => {
  return (
    <div
      style={{
        width: '100%',
        height: `${height}`,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        fontWeight: 200,
      }}
    >
      <Spin />
    </div>
  );
};
