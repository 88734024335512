import { useState } from "react";
import { OutdoorWeather } from "./OutdoorWeather";
import { IndoorWeather } from "./IndoorWeather";
import { IoTDevices } from "./IoTDevices";
import { Calendar } from "./Calendar";
import { Row, Col } from "antd";
import { EnergySaving } from "./EnergySaving";

type RightSectionProps = {
  iotDevices: any;
  outdoorData: any;
  indoorData: any;
  siteName: any;
  energySaving: any;
  benchmarkRl: any;
  co2Emission: any;
};

export const RightSection = ({ iotDevices, outdoorData, indoorData, siteName, energySaving, benchmarkRl, co2Emission }: RightSectionProps) => {
  return (
    <>
      <Row gutter={[18, 18]}>
        <Col xs={24}>
          <EnergySaving
            energySavingThisMonth={energySaving?.this_month}
            energySavingAccumulate={energySaving?.accumulated}
            benchmarkRl={benchmarkRl}
            co2ThisMonth={co2Emission?.this_month}
            co2Accumulate={co2Emission?.accumulated}
          />
        </Col>
        {/* <Col xs={24}>
          <Calendar selectedDate={selectedDate} />
        </Col> */}
        <Col xs={24}>
          <OutdoorWeather data={outdoorData} />
        </Col>
        <Col xs={24}>
          <IndoorWeather data={indoorData} siteName={siteName} />
        </Col>
        <Col xs={24}>{iotDevices && <IoTDevices iotDevices={iotDevices} />}</Col>
      </Row>
    </>
  );
};
