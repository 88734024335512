import { useMemo } from "react";
import { Row, Col } from "antd";
import { LoadingPage } from "components/Common/LoadingPage/LoadingPage";
import { Table } from "components/Common/Table/Table";

interface TableSectionProps {
  data: any[];
}

const TableSection = ({ data }: TableSectionProps) => {
  const columns = useMemo(
    () => [
      {
        Header: "Electricity bill from AC",
        accessor: "ac_baht",
      },
      {
        Header: "CO2 emission from AC consumption",
        accessor: "ac_co2_kg",
      },
      {
        Header: "Energy consumption from AC",
        accessor: "ac_kWh",
      },
      {
        Header: "Electricity bill from building",
        accessor: "building_baht",
      },
      {
        Header: "CO2 emission from building consumption",
        accessor: "building_co2_kg",
      },
      {
        Header: "Building energy consumption (kWh)",
        accessor: "building_kWh",
      },
      {
        Header: "Date",
        accessor: "date",
      },
    ],
    []
  );

  return (
    <>
      <Row gutter={[18, 18]}>
        <Col xs={24} xl={24}>
          {data === undefined && <LoadingPage height={"114px"} />}
          {data === null && (
            <div className="w-full h-full flex p-10" style={{ padding: "24px 40px" }}>
              <p className="text-md text-left w-[fit-content] text-[#0E7EE4]">
                <span className="font-bold text-lg">Please follow steps below to download the required report;</span>
                <br />
                1. Select "Report Type."
                <br />
                2. Select "Date."
                <br />
                3. Click "Search" button.
                <br />
              </p>
            </div>
          )}
          {data && (
            <Table
              columns={columns}
              data={data}
              pagination
              sortBy={[
                {
                  id: "timestamp",
                  desc: true,
                },
              ]}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default TableSection;
