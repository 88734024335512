import { useEffect, useState } from "react";
import Modal from "react-modal";
import { useForm, Controller } from "react-hook-form";
import "./AddAutomationModal.css";
import DeviceTableSection from "./DeviceTableSection";
import { UnderlineTab } from "components/Common/UnderlineTab/UnderlineTab";
import { sort } from "utils/sort";
import {
  ActionWrap,
  Button,
  CheckBox,
  CheckBoxText,
  Container,
  customStyles,
  FieldErrorMessage,
  Form,
  Input,
  InputContainer,
  Label,
  Line,
  SubdeviceTitle,
  Title,
  WrapButton,
  WrapCheckbox,
  WrapInput,
  WrapTable,
  Small,
} from "./AddAutomationModalComponents";
import { AcControl } from "components/Common/DeviceModal/AcControl";
import { SwitchControl } from "components/Common/DeviceModal/SwitchControl";
import { OauControl } from "components/Common/DeviceModal/OauControl";
import { BlindControl } from "components/Common/DeviceModal/BlindControl";
import DeviceConfig from "configs/device_config.json";
import RoomNameMapConfig from "configs/room_name_map.json";
import { TimePicker } from "antd";
import "antd/dist/antd.css";
import { ScheduleDay } from "./ScheduleDay";
import Select from "react-select";
import { SingleDatePicker } from "components/DateRangePicker/DateRangePicker";

const selectCustomStyles = {
  container: (provided: any) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided: any) => ({
    ...provided,
    borderRadius: "8px",
    height: "100%",
    border: "1px solid rgba(14, 126, 228, 0.6)",
  }),
  menu: (provided: any) => ({
    ...provided,
    height: "120px",
    width: "calc( 100% - 96px)",
    position: "absolute",
    overflow: "scroll",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    backgroundColor: "white",
  }),
};

const selectCustomStylesNotiImportant = {
  container: (provided: any) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided: any) => ({
    ...provided,
    borderRadius: "8px",
    height: "100%",
    border: "1px solid rgba(14, 126, 228, 0.6)",
  }),
  menu: (provided: any) => ({
    ...provided,
    height: "200px",
    width: "calc( 100% - 96px)",
    position: "absolute",
    overflow: "scroll",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    backgroundColor: "white",
  }),
};

const repeatSchema = [
  { label: "Every Day", value: "day" },
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
];

const notiImportantOptions = [
  { label: "Critical", value: "critical" },
  { label: "Error", value: "error" },
  { label: "Warning", value: "warning" },
  { label: "Debug", value: "debug" },
  { label: "Info", value: "info" },
];

const getScheduleDayMonthlyValue = (datetime: Date) => {
  const date = datetime.getDate();
  return [
    {
      label: "Monthly on day " + date,
      value: date,
    },
  ];
};

export const AddAutomationModal = ({ isOpen, onClose, deviceData, loading, onAddAutomation, buildingCode }: any) => {
  const AgentMap = DeviceConfig;
  const RoomNameMap: any = RoomNameMapConfig;
  const [startDate, setStartDate] = useState(new Date());
  const [selectedDevices, setSelectedDevices] = useState<any>([]);
  const [agentsList, setAgentsList] = useState<any[]>([]);
  const [currentAgent, setCurrentAgent] = useState<string | undefined>();
  const [allCommand, setAllCommand] = useState<any>(AgentMap);
  const [scheduleDayMonthlyValue, setScheduleDayMonthlyValue] = useState<any>(getScheduleDayMonthlyValue(new Date()));
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  }: any = useForm({
    defaultValues: {
      automation_name: undefined,
      schedule_name: undefined,
      schedule_time: undefined,
      repeat_every_value: 1,
      schedule_day: undefined,
      schedule_day_monthly: getScheduleDayMonthlyValue(new Date()),
      repeat_every_type: { label: "Week", value: "week" },
      allow_notification: true,
      line: true,
      web: true,
      email: true,
      source: "automation",
      noti_important: { label: "Critical", value: "critical" },
    },
  });

  const prepCron = (data: any) => {
    let cronResult: any[] = ["*", "*", "*", "*", "*", "*"];
    // Prep 1st and 2nd index of cron
    const hour = data.schedule_time.hour();
    const minute = data.schedule_time.minute();
    cronResult[0] = minute.toString();
    cronResult[1] = hour.toString();
    // Prep 3rd, 4th and 5th index of cron
    if (data.repeat_every_type.value === "day") {
      // TODO: Uncomment below line to enable every x day
      // cronResult[2] = "*/" + data.repeat_every_value;
      cronResult[2] = "*";
    } else if (data.repeat_every_type.value === "week") {
      let dayList: undefined | string;
      data.schedule_day.forEach((dayValue: number, index: number) => {
        const tempDayValue = dayValue !== 0 ? dayValue : 7;
        if (dayList) {
          dayList = dayList + "," + tempDayValue;
        } else {
          dayList = tempDayValue.toString();
        }
      });
      cronResult[4] = dayList;
    } else if (data.repeat_every_type.value === "month") {
      cronResult[2] = "*/" + data.schedule_day_monthly.value;
    }
    return cronResult;
  };

  const prepAction = (buildingCode: string) => {
    let action: { [room_name: string]: any } = {};
    selectedDevices.forEach((devicesData: any) => {
      const agentId = devicesData.agent_id;
      const sub0 = devicesData["subdevices"]["0"];
      const sub1 = devicesData["subdevices"]["1"];
      const room_name = devicesData["room_name"];
      let newCommand = allCommand[agentId]["command"];
      let nickname;

      // 1) Check if ac_command's mode === "off" => Change command to {mode:"off"} and check building_code === "BGM"
      if (newCommand?.mode) {
        if (newCommand?.mode === "off") {
          // mode === "off"
          newCommand = { mode: "off" };
        } else {
          // mode === "cool" | "fan" | "dry"
          if (buildingCode === "BGM" && newCommand?.louver) {
            delete newCommand["louver"];
          }
        }
      }
      // 2) Assign subdevice's nickname to nickname var
      if (sub0) {
        nickname = sub0.nickname;
      } else if (sub1) {
        nickname = sub1.nickname;
      }
      // 3) Assign new command into action
      if (action[room_name]) {
        action[room_name][nickname] = { command: newCommand };
      } else {
        action[room_name] = { [nickname]: { command: newCommand } };
      }
    });

    return action;
  };

  const setNotifyTo = (web: boolean, email: boolean, line: boolean) => {
    let result = [];
    if (web) result.push("web");
    if (email) result.push("email");
    if (line) result.push("line");
    return result;
  };

  const onSubmit = async (data: any) => {
    const notify_to = setNotifyTo(data.web, data.email, data.line);
    const payload = {
      automation_image: "",
      automation_name: data.automation_name,
      trigger: {
        trigger_type: "schedule",
        trigger_time: {
          cron: prepCron(data),
        },
      },
      condition: {
        condition_event: "",
        condition_value: "",
      },
      action: prepAction(buildingCode),
      allow_notification: data.allow_notification,
      notification: {
        notify_to: notify_to,
        noti_image: "",
        noti_message: data.noti_message,
        noti_icon: "",
        noti_type: "device",
        noti_instruction: {
          contact: {
            line: "@altosupport",
            email: "support@altotech.net",
          },
          guide: "",
        },
        noti_important: data.noti_important.value,
      },
      source: "trivial",
    };

    onAddAutomation(payload);
  };

  const onSelectRow = (data: any) => {
    setSelectedDevices(
      data.map((item: any) => ({ ...item.original, ["room_name"]: RoomNameMap[item.original.room] ? RoomNameMap[item.original.room] : "" }))
    );
    const allAgents = data.map((item: any) => item?.original?.agent_id);
    let selectedAgents: any[] = [];
    if (data.length === 0) {
      setCurrentAgent(undefined);
      return;
    }
    allAgents.forEach((agentId: string) => {
      if (!selectedAgents.includes(agentId)) {
        selectedAgents.push(agentId);
      }
    });
    selectedAgents = selectedAgents.sort((a, b) => sort(a, b));

    setAgentsList(selectedAgents);
    if (selectedAgents[0]) {
      if (!currentAgent) {
        setCurrentAgent(selectedAgents[0]);
      } else {
        if (!selectedAgents.includes(currentAgent)) {
          setCurrentAgent(selectedAgents[0]);
        }
      }
    }
  };

  const onChangeAgentTab = (tabName: string) => {
    setCurrentAgent(tabName);
  };

  const handleChangeCommand = (commandTopic: string, commandValue: string | number, agentId: string, subdevNumber: number) => {
    setAllCommand((prev: any) => {
      const tempPrev = { ...prev };
      tempPrev[agentId]["command"][commandTopic] = commandValue;
      return tempPrev;
    });
  };

  useEffect(() => {
    if (watch(["repeat_every_type"])[0].value === "week") {
      setValue("repeat_every_value", 1);
    }
  }, [watch(["repeat_every_type"])[0]]);

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles} contentLabel="Edit modal">
        <Container>
          <Title>Add New Schedule</Title>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <SubdeviceTitle>Schedule</SubdeviceTitle>
            <InputContainer>
              <Label>Schedule ID</Label>
              <WrapInput>
                <Input
                  // {...register("automation_name", { required: "Schedule Name is required" })}
                  {...register("automation_id")}
                  placeholder="-"
                  disabled={true}
                  // data-view-mode={isView}
                />
                <FieldErrorMessage error={errors.automation_id} />
              </WrapInput>
            </InputContainer>

            <InputContainer>
              <Label>Schedule Name *</Label>
              <WrapInput>
                <Input
                  {...register("automation_name", { required: "Schedule Name is required" })}
                  placeholder="Enter Schedule Name"
                  disabled={loading}
                  // data-view-mode={isView}
                />
                <FieldErrorMessage error={errors.automation_name} />
              </WrapInput>
            </InputContainer>

            <InputContainer>
              <Label>Schedule Time *</Label>
              <WrapInput>
                <Controller
                  name="schedule_time"
                  control={control}
                  rules={{ required: "Schedule Time is required" }}
                  render={({ field }: any) => (
                    <TimePicker
                      {...field}
                      placeholder="HH:mm"
                      format={"HH:mm"}
                      getPopupContainer={(trigger) => trigger.parentElement}
                      onSelect={(e) => {
                        field.onChange(e);
                        console.log("onChange");
                      }}
                    />
                  )}
                />
                <FieldErrorMessage error={errors.schedule_time} />
              </WrapInput>
            </InputContainer>

            <InputContainer>
              <Label>Repeat Every *</Label>
              <WrapInput style={{ flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: "0px 16px" }}>
                {watch(["repeat_every_type"])[0].value === "week" && (
                  <Controller
                    name="repeat_every_value"
                    control={control}
                    rules={{ required: "Value is required" }}
                    render={({ field }: any) => (
                      <Input {...field} placeholder="" disabled={watch(["repeat_every_type"])[0].value === "week"} style={{ width: "80px" }} />
                    )}
                  />
                )}
                <WrapInput style={{ width: "200px" }}>
                  <Controller
                    name="repeat_every_type"
                    control={control}
                    rules={{ required: "Repeat Every is required" }}
                    render={({ field }: any) => <Select {...field} styles={selectCustomStyles} options={repeatSchema} />}
                  />
                  <FieldErrorMessage error={errors.repeat_every_type} />
                </WrapInput>
              </WrapInput>
            </InputContainer>

            <InputContainer>
              <Label>Ends *</Label>
              <WrapInput style={{ justifyContent: "flex-start", height: "fit-content" }}>
                <WrapCheckbox>
                  <CheckBox type="checkbox" checked={true} />
                  <CheckBoxText>Never</CheckBoxText>
                </WrapCheckbox>
                {/* TODO Ask IoT team about cron format to make this work before cancel ****disabled**** */}
                {/* <WrapCheckbox>
                  <CheckBox type="checkbox" checked={false} disabled />
                  <CheckBoxText>On</CheckBoxText>
                  <SingleDatePicker startDate={startDate} setStartDate={setStartDate} marginBottom={"0"} disabled />
                </WrapCheckbox> */}
                {/* TODO Ask IoT team about cron format to make this work before cancel ****disabled**** */}
                {/* <WrapCheckbox>
                  <CheckBox type="checkbox" checked={false} disabled />
                  <CheckBoxText>After</CheckBoxText>
                  <Input placeholder="" disabled={true} style={{ width: "80px" }} />
                  <CheckBoxText>occurence(s)</CheckBoxText>
                </WrapCheckbox> */}
              </WrapInput>
            </InputContainer>
            {watch && watch(["repeat_every_type"])[0].value === "week" && (
              <InputContainer>
                <Label>Schedule Day *</Label>
                <WrapInput>
                  <Controller
                    name="schedule_day"
                    control={control}
                    rules={{ required: "Schedule Day is required" }}
                    render={({ field }: any) => <ScheduleDay {...field} />}
                  />
                  <FieldErrorMessage error={errors.schedule_day} />
                </WrapInput>
              </InputContainer>
            )}
            {watch && watch(["repeat_every_type"])[0].value === "month" && (
              <InputContainer>
                <Label>Schedule Day *</Label>
                <WrapInput>
                  <Select
                    styles={selectCustomStyles}
                    options={scheduleDayMonthlyValue}
                    value={scheduleDayMonthlyValue}
                    onChange={(value: any) => setScheduleDayMonthlyValue(value)}
                  />
                </WrapInput>
              </InputContainer>
            )}
            <Line />
            <SubdeviceTitle>Device</SubdeviceTitle>
            <WrapTable>
              <DeviceTableSection data={deviceData} onSelectRow={onSelectRow} />
            </WrapTable>
            <Line />
            <SubdeviceTitle>
              Action
              {!currentAgent && (
                <>
                  <br />
                  <Small>Please select device</Small>
                </>
              )}
            </SubdeviceTitle>
            {currentAgent && <UnderlineTab listOfTabs={agentsList} selectedTab={currentAgent} onChange={onChangeAgentTab} />}
            <ActionWrap>
              {deviceData &&
                (currentAgent === "bac0hvac" ||
                  currentAgent === "itm_fcu" ||
                  currentAgent === "ac_wifi_adapter" ||
                  currentAgent === "ac_wifi_adaptor") && (
                  <AcControl
                    command={allCommand[currentAgent]["command"]}
                    handleChangeCommand={handleChangeCommand}
                    deviceData={deviceData}
                    agentId={currentAgent}
                  />
                )}
              {deviceData && currentAgent === "tasmota" && (
                <SwitchControl
                  command={allCommand[currentAgent]["command"]}
                  deviceInfo={deviceData}
                  handleChangeCommand={handleChangeCommand}
                  agentId={currentAgent}
                />
              )}
              {deviceData && currentAgent === "itm_oau" && (
                <OauControl
                  command={allCommand[currentAgent]["command"]}
                  deviceInfo={deviceData}
                  handleChangeCommand={handleChangeCommand}
                  agentId={currentAgent}
                />
              )}
              {deviceData && (currentAgent === "tuyacloudcurtain" || currentAgent === "tuyablinds") && (
                <BlindControl
                  command={allCommand[currentAgent]["command"]}
                  handleChangeCommand={handleChangeCommand}
                  deviceData={deviceData}
                  agentId={currentAgent}
                />
              )}
            </ActionWrap>
            <Line />
            <SubdeviceTitle>Notification</SubdeviceTitle>
            <InputContainer>
              <Label>Notification Message *</Label>
              <WrapInput>
                <Input
                  {...register("noti_message", { required: "Notification Message is required" })}
                  placeholder="Enter Notification Message"
                  disabled={loading}
                  // data-view-mode={isView}
                />
                <FieldErrorMessage error={errors.noti_message} />
              </WrapInput>
            </InputContainer>
            <InputContainer>
              <WrapInput>
                <WrapCheckbox>
                  <CheckBox type="checkbox" {...register("allow_notification")} />
                  <CheckBoxText>Allow Notification</CheckBoxText>
                </WrapCheckbox>
              </WrapInput>
              {watch && watch(["allow_notification"])[0] && (
                <WrapInput style={{ justifyContent: "flex-start", height: "fit-content" }}>
                  <WrapCheckbox>
                    <CheckBox type="checkbox" {...register("line")} />
                    <CheckBoxText>Line</CheckBoxText>
                  </WrapCheckbox>
                  <WrapCheckbox>
                    <CheckBox type="checkbox" {...register("web")} />
                    <CheckBoxText>Web</CheckBoxText>
                  </WrapCheckbox>
                  <WrapCheckbox>
                    <CheckBox type="checkbox" {...register("email")} />
                    <CheckBoxText>Email</CheckBoxText>
                  </WrapCheckbox>
                </WrapInput>
              )}
            </InputContainer>
            <InputContainer>
              <Label>Importance Level *</Label>
              <WrapInput>
                <Controller
                  name="noti_important"
                  control={control}
                  render={({ field }: any) => <Select {...field} styles={selectCustomStylesNotiImportant} options={notiImportantOptions} />}
                />
              </WrapInput>
            </InputContainer>
            <WrapButton>
              <Button onClick={onClose} data-outlined>
                Cancel
              </Button>
              <Button type="submit" disabled={loading}>
                Add
              </Button>
            </WrapButton>
          </Form>
        </Container>
      </Modal>{" "}
    </>
  );
};
