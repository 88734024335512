import { Row, Col } from "antd";
import { BuildingPerformance, CircleEnergyPerformance } from "./BuildingPerformance";
import { BlockBody, BlockDesc, BlockHeader, Container, FloorTab, FloorTabContainer } from "./Components";
import { DeviceListProps } from "MockData/DeviceData";
import { DeviceFilter } from "./DeviceFilter";
import { SelfDrivingTab } from "./SelfDrivingTab";
import { SolarWidget } from "./SolarWidget";
import { CameraDropdownDaikin } from "./CameraDropdownDaikin2";
import { FloorModel } from "./FloorModel/FloorModel";
import FloorConfig from "configs/building_config.json";
import { CameraDropdownGardenWing } from "./CameraDropdownGardenWing";

interface BuildingProps {
  deviceList: any[];
  selectedTab: string;
  setSelectedTab: (tabName: string) => void;
  buildingTitle: string;
  buildingDesc: string;
  selectedDeviceList: DeviceListProps;
  handleSelectDevice: (agentId: string) => void;
  selectedDevice: any;
  selfDriveIsOpened: boolean;
  handleClickSelfDriving: (e: any) => void;
  mode: string;
  setMode: (mode: string) => void;
  dashboardData2?: any;
  totalEnergyConsumption: any;
  handleOpenModal: (e: any) => void;
  handleCloseModal: () => void;
  modalIsOpen: boolean;
  selectedTabName: string;
  tabNameList: { title: string; value: string }[];
  handleSelectTabName: (tab: string) => void;
  command: any;
  handleChangeCommand: any;
  visibleDeviceObject: { [agent_id: string]: boolean };
  handleViewGraph: any;
  siteName: string;
  allIotStatusFb: any;
}

interface FloorSelectionProps {
  selectedTab: string;
  setSelectedTab: (tabName: string) => void;
  hotel_code: any;
}

const FloorSelection = ({ selectedTab, setSelectedTab, hotel_code }: FloorSelectionProps) => {
  const FloorConfigData: any = FloorConfig;
  if (!FloorConfig) {
    return null;
  }

  return (
    <FloorTabContainer>
      {FloorConfigData &&
        FloorConfigData[hotel_code] &&
        FloorConfigData[hotel_code]["floors"].map((item: { value: string; label: string }) => (
          <FloorTab key={item.value} active={selectedTab === item.label} onClick={() => setSelectedTab(item.label)}>
            {item.label}
          </FloorTab>
        ))}
    </FloorTabContainer>
  );
};

export const Building = ({
  deviceList,
  buildingTitle,
  buildingDesc,
  selectedTab,
  setSelectedTab,
  selectedDeviceList,
  handleSelectDevice,
  selectedDevice,
  selfDriveIsOpened,
  handleClickSelfDriving,
  mode,
  setMode,
  dashboardData2,
  totalEnergyConsumption,
  handleOpenModal,
  handleCloseModal,
  modalIsOpen,
  selectedTabName,
  tabNameList,
  handleSelectTabName,
  command,
  handleChangeCommand,
  visibleDeviceObject,
  handleViewGraph,
  siteName,
  allIotStatusFb,
}: BuildingProps) => {
  const hotel_code = localStorage.getItem("hotel_code");
  return (
    <>
      <Container>
        <Row gutter={[18, 18]}>
          <Col sm={24} md={12} xl={12}>
            <Row>
              <BlockHeader>{buildingTitle}</BlockHeader>
            </Row>
            <Row>
              <BlockDesc>{buildingDesc}</BlockDesc>
            </Row>
          </Col>
          {selectedTab !== "S" ? (
            <>
              <Col sm={20} md={9} xl={9}>
                <BuildingPerformance totalEnergyConsumption={totalEnergyConsumption} />
              </Col>
              <Col sm={4} md={3} xl={3} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                <CircleEnergyPerformance totalEnergyConsumption={totalEnergyConsumption} />
              </Col>
            </>
          ) : (
            <Col sm={24} md={12} xl={12} style={{ display: "flex", justifyContent: "flex-end" }}>
              <SolarWidget />
            </Col>
          )}
        </Row>
        <Row gutter={[18, 18]}>
          <Col sm={24} md={24} xl={24}>
            <BlockBody>
              <FloorModel
                deviceList={deviceList}
                selectedTab={selectedTab}
                dashboardData2={dashboardData2}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
                modalIsOpen={modalIsOpen}
                selectedTabName={selectedTabName}
                tabNameList={tabNameList}
                handleSelectTabName={handleSelectTabName}
                selectedDevice={selectedDevice}
                command={command}
                handleChangeCommand={handleChangeCommand}
                visibleDeviceObject={visibleDeviceObject}
                handleViewGraph={handleViewGraph}
                hotel_code={hotel_code}
                allIotStatusFb={allIotStatusFb}
              />
              <SelfDrivingTab isOpened={selfDriveIsOpened} handleClickSelfDriving={handleClickSelfDriving} mode={mode} setMode={setMode} />
              <FloorSelection selectedTab={selectedTab} setSelectedTab={setSelectedTab} hotel_code={hotel_code} />
              {selectedTab !== "S" && selectedTab !== "B" && (
                <DeviceFilter selectedDeviceList={selectedDeviceList} handleSelectDevice={handleSelectDevice} />
              )}
              {siteName === "Bgrimm Garden Wing" && <CameraDropdownGardenWing />}
              {siteName === "Daikin CCC" && <CameraDropdownDaikin />}
            </BlockBody>
          </Col>
        </Row>
      </Container>
    </>
  );
};
