import { EditIcon } from "icons/EditIcon";
import { MoreIcon } from "icons/MoreIcon";
import { RemoveIcon } from "icons/RemoveIcon";
import { ViewIcon } from "icons/ViewIcon";
import { Fragment, useState, PropsWithChildren } from "react";
import { useDisclosure } from "utils/hooks";
import styled from "styled-components";
import tw from "twin.macro";
import { BiCaretDownCircle, BiRightArrow } from "react-icons/bi";
import { FaClosedCaptioning } from "react-icons/fa";
import { AiOutlineCheck, AiOutlineClose, AiOutlineRight } from "react-icons/ai";
import { GrCheckmark } from "react-icons/gr";
import { BsCheck } from "react-icons/bs";

const DropdownContainer = styled.div(() => [tw`flex justify-center`]);

const DropdownMenu = styled.div(() => [tw`relative w-[fit-content] h-auto`]);

const DropdownMenuTrigger = styled.div(() => [
  tw`w-8 h-8 bg-white border border-gray-300 shadow cursor-pointer rounded-full text-cupink-dark
  flex items-center justify-center transition-shadow 
  hover:shadow-[0px 3px 20px rgba(161, 183, 205, 0.2)]
  [&[aria-expanded=true]]:shadow-[0px 3px 20px rgba(161, 183, 205, 0.2)]`,
]);

const DropdownMenuContent = styled.div<any>`
  ${tw`w-[fit-content] h-[fit-content] top-[40px] right-0 bg-white rounded-xl flex-col z-10 gap-y-1 p-0.5 shadow-lg`};
  ${({ isOpen }) => (isOpen ? "position:absolute; display:flex" : "display:none")};
`;

const DropdownMenuItem = styled.div(() => [
  tw`h-10 flex justify-start items-center min-w-[140px] origin-top-right px-1
  focus:outline-none p-0.5 bg-white rounded-[10px] cursor-pointer 
  hover:shadow-[0px 3px 20px rgba(161, 183, 205, 0.2)] hover:border-primary hover:border hover:border-solid hover:bg-primary-extraLight
`,
]);

const WrapIcon = styled.div(() => [tw`h-6 w-8 flex justify-center items-center`]);

const Text = tw.p`text-base text-altoDark mb-0 truncate`;

export interface DeviceExtensionCamera {
  type: "camera";
  rel_x: number;
  rel_y: number;
}

export interface DeviceExtensionEdge {
  type: "edge";
  ai_model_id: number;
}

export type DeviceFormData = {
  id: string;
  name: string;
  type: "camera" | "edge";
  ai_model_id: number;
  description: string;
  installed_at: Date;
  building_id: number;
  floor_id: number;
  rel_x: number;
  rel_y: number;
};

export type Device = {
  id: string;
  name: string;
  description: string;
  installed_at: string;
  updated_at: string;
  status: boolean;
  site_id: number;
  floor_id: number;
} & (DeviceExtensionCamera | DeviceExtensionEdge);

interface DeviceActionsProps {
  device: Device;
  openViewDevice: any;
  openEditDevice: any;
  openDeleteDevice: any;
}

interface AutomationActionsProps {
  automation: Device;
  openViewAutomation: any;
  openDeleteAutomation: any;
}

export function RowActions({ children, style }: PropsWithChildren<{}> | any) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <DropdownContainer style={style}>
      <DropdownMenu>
        <DropdownMenuTrigger onClick={() => setIsOpen(!isOpen)}>
          <MoreIcon />
        </DropdownMenuTrigger>
        <DropdownMenuContent isOpen={isOpen}>{children}</DropdownMenuContent>
      </DropdownMenu>
    </DropdownContainer>
  );
}

export function DeviceActions({ device, openViewDevice, openEditDevice, openDeleteDevice }: DeviceActionsProps) {
  return (
    <Fragment>
      <RowActions>
        <DropdownMenuItem onClick={() => openViewDevice(device)}>
          <WrapIcon>
            <GrCheckmark />
          </WrapIcon>
          Acknowledge
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => openEditDevice(device)}>
          <WrapIcon>
            <AiOutlineClose />
          </WrapIcon>
          Close
        </DropdownMenuItem>
        {/* <DropdownMenuItem tw="text-red-500" onClick={() => openDeleteDevice(device)}>
          <WrapIcon>
            <RemoveIcon />
          </WrapIcon>
          Remove
        </DropdownMenuItem> */}
      </RowActions>
    </Fragment>
  );
}

export function AutomationActions({ automation, openViewAutomation, openDeleteAutomation }: AutomationActionsProps) {
  return (
    <Fragment>
      <RowActions style={{ position: "absolute", right: "16px" }}>
        <DropdownMenuItem onClick={() => openViewAutomation(automation)}>
          <WrapIcon>
            <ViewIcon />
          </WrapIcon>
          View
        </DropdownMenuItem>
        <DropdownMenuItem tw="text-red-500" onClick={() => openDeleteAutomation(automation)}>
          <WrapIcon>
            <RemoveIcon />
          </WrapIcon>
          Remove
        </DropdownMenuItem>
      </RowActions>
    </Fragment>
  );
}

const getDeviceUrl = (data: any): any => {
  // Convert unix to YYYY-MM-DD
  console.log(data);
  const date = new Date(data.unix_timestamp * 1000);
  const year = date.getFullYear();
  const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
  const dt = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
  // Get tomorrow date from unix
  const tomorrow = new Date(data.unix_timestamp * 1000 + 86400000);
  const tomorrowYear = tomorrow.getFullYear();
  const tomorrowMonth = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
  const tomorrowDt = date.getDate() + 1 > 9 ? date.getDate() + 1 : `0${date.getDate() + 1}`;
  // return response format ?start_time=YYYY-MM-DD&end_time=YYYY-MM-DD&device_id=id
  let param = "https://shelldata.altotech.net/apps/all-data?";
  if (data.unix_timestamp) {
    const start_date = `${year}-${month}-${dt}`;
    const end_date = `${tomorrowYear}-${tomorrowMonth}-${tomorrowDt}`;
    param += `start_date=${start_date}&end_date=${end_date}&`;
  }
  if (data.device_id) {
    param += `device_id=${data.device_id}`;
  }
  return param;
};

export function TaskActions() {
  return (
    <div className="flex justify-center w-full">
      <div className="relative w-6 h-6 cursor-pointer">
        <div className="group relative h-2 z-50">
          <div
            style={{ bottom: "16px" }}
            className="hidden group-hover:block absolute z-10 top-[-px] left-0 bg-gray-dark text-black rounded px-2 py-1 text-sm whitespace-nowrap bg-white shadow-md"
          >
            <span>Acknowledge</span>
          </div>
          <AiOutlineCheck color="#397CDD" fill="#397CDD" />
        </div>
      </div>
      <div className="relative w-6 h-6 cursor-pointer">
        <div className="group relative h-2 z-50">
          <div
            style={{ bottom: "16px" }}
            className="hidden group-hover:block absolute z-10 top-[-px] left-0 bg-gray-dark text-black rounded px-2 py-1 text-sm whitespace-nowrap bg-white shadow-md"
          >
            <span>Close</span>
          </div>
          <AiOutlineClose color="gray" fill="gray" />
        </div>
      </div>
    </div>
  );
}
