import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #0e7ee4;
`;

export const DeviceAutomation = () => {
  return <Container>Coming Soon</Container>;
};
