import EbillSaving from 'assets/pages/Energy/EbillSaving.svg';
import Co2Emission from 'assets/pages/Energy/Co2Emission.svg';
import EnergySaving from 'assets/pages/Energy/EnergySaving.svg';
import PaybackPeriod from 'assets/pages/Energy/PaybackPeriod.svg';
import EBaseline from 'assets/pages/Energy/EBaseline.svg';

export const convertFbData = (page: string, type: string, fbData: any) => {
  if (page === "energy") {
    if (type === "summary_data") {
      return [
        {
          title: 'E-Bill Saving',
          value: fbData[type]['ebill_saving']['acc_saving']['value'],
          unit: fbData[type]['ebill_saving']['acc_saving']['unit'],
          targetValue: fbData[type]['ebill_saving']['target_saving']['value'],
          bgColor: '#0E7EE4',
          iconUrl: EbillSaving,
        },
        {
          title: 'Energy Saving',
          value: fbData[type]['energy_saving']['acc_saving']['value'],
          unit: fbData[type]['energy_saving']['acc_saving']['unit'],
          targetValue: fbData[type]['energy_saving']['target_saving']['value'],
          bgColor: '#14B0BC',
          iconUrl: EnergySaving,
        },
        {
          title: 'CO2 Reduction',
          value: fbData[type]['co2_emission']['acc_co2_reduction']['value'],
          unit: fbData[type]['co2_emission']['acc_co2_reduction']['unit'],
          targetValue: fbData[type]['co2_emission']['target_co2_reduction']['value'],
          bgColor: '#788796',
          iconUrl: Co2Emission,
        },
        {
          title: 'Payback Period',
          value: fbData[type]['payback_period']['estimated_payback']['value'],
          unit: fbData[type]['payback_period']['estimated_payback']['unit'],
          targetValue: fbData[type]['payback_period']['target_payback']['value'],
          bgColor: '#AB63E8',
          iconUrl: PaybackPeriod,
        },
        {
          title: 'E-baseline',
          value: fbData[type]['energy_baseline']['current_eui']['value'],
          unit: fbData[type]['energy_baseline']['current_eui']['unit'],
          targetValue: fbData[type]['energy_baseline']['target_eui']['value'],
          bgColor: '#FF7A00',
          iconUrl: EBaseline,
        },
      ]
    } else if (type === "building_info") {
      return (
        [
          { title: 'Condition area', desc: fbData[type]?.usable_area?.value + fbData[type]?.usable_area?.unit },
          { title: 'Height', desc: fbData[type]?.height?.value + fbData[type]?.height?.unit },
          { title: 'Type of Building', desc: fbData[type]?.type?.value + fbData[type]?.type?.unit },
          { title: 'Window to Wall Ratio (WWR)', desc: fbData[type]?.wwr?.value + fbData[type]?.wwr?.unit },
          { title: 'Calculation Type', desc: fbData[type]?.elec_bill_type?.value + fbData[type]?.elec_bill_type?.unit },
        ]
      )
    } else if (type === "building_name") {
      return (
        fbData?.building_info
      )
    }
  }
}