import { useMemo } from "react";
import { Row, Col } from "antd";
import { LoadingPage } from "components/Common/LoadingPage/LoadingPage";
import { Detail, DeviceActions, TaskActions } from "components/Common/Table/MaintenanceAction";
import { Table } from "components/Common/Table/Table";
import { convertTimestampToDatetime } from "utils/convertDatetime";

interface TableSectionProps {
  data: any[];
  acknowledge: any;
  close: any;
}

const TableSection = ({ data, acknowledge, close }: TableSectionProps) => {
  const columns = useMemo(
    () => [
      {
        Header: "Device ID",
        accessor: "device_id",
      },
      {
        Header: "Asset Name",
        accessor: "asset_name",
      },
      {
        Header: "Alert Type",
        accessor: "alert_type",
      },
      {
        Header: "Status",
        accessor: "alert_status",
      },
      {
        Header: "Description",
        accessor: "alert_description",
      },
      {
        Header: "Time",
        accessor: "unix_timestamp",
        Cell: ({ row }: any) => {
          return <p>{convertTimestampToDatetime(row.original.unix_timestamp)["datetime"]}</p>;
        },
      },
      {
        id: "action",
        Header: <div tw="flex-1 flex justify-center">Action</div>,
        accessor: "action",
        disableSortBy: true,
        Cell: ({ row }: any) => <TaskActions data={row.original} acknowledge={acknowledge} close={close} />,
      },
      {
        id: "detail",
        Header: <div tw="flex-1 flex justify-center">Detail</div>,
        accessor: "detail",
        disableSortBy: true,
        Cell: ({ row }: any) => <Detail data={row.original} />,
      },
    ],
    []
  );

  return (
    <>
      <Row gutter={[18, 18]}>
        <Col xs={24} xl={24}>
          {data === undefined && <LoadingPage height={"114px"} />}
          {data === null && <p style={{ width: "100%", textAlign: "center", marginTop: "50px", color: "#0E7EE4" }}>Coming soon</p>}
          {data && (
            <Table
              columns={columns}
              data={data}
              pagination
              sortBy={[
                {
                  id: "device_id",
                  desc: true,
                },
              ]}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default TableSection;
