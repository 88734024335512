import tw from "twin.macro";
import styled from "styled-components";
import SViewIcon from "assets/common/icon/SViewIconColor.svg";
import SViewIconWhite from "assets/common/icon/SViewIconWhite.svg";
import FPViewIcon from "assets/common/icon/FPViewIconColor.svg";
import FPViewIconWhite from "assets/common/icon/FPViewIconWhite.svg";

const Container = tw.div`h-8 flex justify-center border border-primary rounded-[6px] overflow-hidden`;

const Tab = styled.div<any>`
  ${tw`px-4 py-2 flex justify-center items-center cursor-pointer`}
  ${({ active }) => active && "background: #0E7EE4"}
`;

const Icon = tw.img`w-4 h-4 user-select[none]`;

export const ChangeViewButton = ({ selectedView, setSelectedView }: any) => {
  return (
    <>
      <Container>
        <Tab active={selectedView === "s"} onClick={() => setSelectedView("s")}>
          <Icon src={selectedView === "s" ? SViewIconWhite : SViewIcon} />
        </Tab>
        {/* <Tab active={selectedView === "m"} onClick={() => setSelectedView("m")}>
          <Icon src={selectedView === "m" ? MViewIconWhite : MViewIcon} />
        </Tab> */}
        <Tab active={selectedView === "fp"} onClick={() => setSelectedView("fp")}>
          <Icon src={selectedView === "fp" ? FPViewIconWhite : FPViewIcon} />
        </Tab>
      </Container>
    </>
  );
};
