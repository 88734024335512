import axios from 'axios';
import { API_CONSTANTS } from './api.constants';

class AuthService {
  login(username: string, password: string) {
    return axios.post(API_CONSTANTS.LOGIN_URL , {
      username,
      password,
    });
  }
}

export default new AuthService();
