import Button from "components/Common/Button";
import { BiPlus } from "react-icons/bi";
import styled from "styled-components";
import tw from "twin.macro";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 0px;
  margin-bottom: 12px;
`;

export const ButtonSection = ({ onOpen }: any) => {
  return (
    <Wrapper>
      {/* <Button
        active
        title={"Download"}
        onClick={() => console.log("Print")}
        size={"medium"}
        bgColor={"rgba(219, 228, 255, 0.3)"}
        textColor={"#0E7EE4"}
        width={132}
      />
      <Button
        active
        title={"Remove"}
        onClick={() => console.log("Print")}
        size={"medium"}
        bgColor={"#788796"}
        width={132}
        disabled
        icon={<FiTrash size={15} color={"white"} />}
      /> */}
      <Button
        active
        title={"Add Document"}
        onClick={onOpen}
        size={"medium"}
        bgColor={"#0E7EE4"}
        width={160}
        icon={<BiPlus size={15} color={"white"} />}
      />
    </Wrapper>
  );
};
