import { useMemo } from "react";
import { Row, Col } from "antd";
import { LoadingPage } from "components/Common/LoadingPage/LoadingPage";
import { Table } from "./Table";
import { DocumentAction } from "components/Common/Table/DocumentAction";

interface TableSectionProps {
  data: any[] | null | undefined;
  openViewFile: any;
  downloadFile: any;
  openDeleteFile: any;
}

const TableSection = ({ data, openViewFile, downloadFile, openDeleteFile }: TableSectionProps) => {
  const columns = useMemo(
    () => [
      {
        Header: "Document Name",
        accessor: "document_name",
      },
      {
        Header: "Document Type",
        accessor: "document_type",
      },
      {
        Header: "Size",
        accessor: "size",
      },
      {
        Header: "Creation Time",
        accessor: "creation_time",
      },
      {
        id: "action",
        Header: <div tw="flex-1 flex justify-center">Action</div>,
        accessor: "id",
        disableSortBy: true,
        Cell: ({ row }: any) => (
          <DocumentAction fileData={row.original} openViewFile={openViewFile} downloadFile={downloadFile} openDeleteFile={openDeleteFile} />
        ),
      },
    ],
    []
  );

  return (
    <>
      <Row gutter={[18, 18]}>
        <Col xs={24} xl={24}>
          {data === undefined && <LoadingPage height={"114px"} />}
          {data === null && <p style={{ width: "100%", textAlign: "center", marginTop: "50px", color: "#0E7EE4" }}>Coming soon</p>}
          {data && <Table columns={columns} data={data} pagination openViewFile={openViewFile} />}
        </Col>
      </Row>
    </>
  );
};

export default TableSection;
