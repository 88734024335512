import styled from "styled-components";
import { Row, Col } from "antd";
import { BlockContainer, BlockHeader } from "components/Common/components";
import { BarChart } from "components/Common/Graph/BarChart";
import { Serie } from "@nivo/line";

type SelfDrivingActiveSummaryProps = {
  selfDrivingActiveSummaryData: Serie[] | undefined;
};

const Container = styled(BlockContainer)`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  filter: drop-shadow(0px 2px 10px rgba(171, 183, 196, 0.2));
`;

const AccumulateGraphContainer = styled.div`
  position: absolute;
  bottom: 0px;
  width: calc(100%);
  height: 150px;
  left: -18px;
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 8px;
`;

const Value = styled.p`
  color: ${(props) => (props.color ? props.color : "black")};
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const WrapChart = styled.div`
  height: 210px;
`;

const MockData: any = [
  {
    label: "Sun 5",
    hrs: 18,
  },
  {
    label: "Mon 6",
    hrs: 16,
  },
  {
    label: "Tue 7",
    hrs: 13,
  },
  {
    label: "Wed 8",
    hrs: 11,
  },
  {
    label: "Thu 9",
    hrs: 13,
  },
  {
    label: "Fri 10",
    hrs: 8,
  },
];

export const SelfDrivingActiveSummary = ({ selfDrivingActiveSummaryData }: SelfDrivingActiveSummaryProps) => {
  return (
    <Container>
      <Row>
        <Col xs={24} lg={24} style={{ marginBottom: "12px" }}>
          <BlockHeader>Self-driving Active Summary</BlockHeader>
        </Col>

        <Col xs={8} xl={8}>
          <Row>
            <Col xs={24}>
              <Title>Accumulated</Title>
            </Col>
            <Col xs={24}>
              <Value color="#14B8B4">3456 hrs</Value>
            </Col>
            <Col xs={24}>
              <Title>Month</Title>
            </Col>
            <Col xs={24}>
              <Value>3456 hrs</Value>
            </Col>
          </Row>
        </Col>
        <Col xs={16} xl={16}>
          <WrapChart>
            <BarChart data={MockData} unit={"hrs"} keys={["hrs"]} />
          </WrapChart>
        </Col>
      </Row>
    </Container>
  );
};
