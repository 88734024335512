import { useEffect, useState, useContext } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { UnderlineTab } from "components/Common/UnderlineTab/UnderlineTab";
import NotificationIcon from "assets/common/icon/notificationIcon.svg";
import SaveIcon from "assets/common/icon/SaveIcon.svg";
import DoneIcon from "assets/common/icon/DoneIcon.svg";
import { GrClose } from "react-icons/gr";
import { LoadingPage } from "components/Common/LoadingPage/LoadingPage";
import { getNotification } from "services/api.service";
import { convertDatetimeToStrDatetimeNew } from "utils/convertDatetime";
import DeviceNoti from "assets/common/icon/DeviceNoti.svg";
import { FirebaseContext } from "../../firebase";
import { notification_status_path } from "MockData/FirebasePath";
import moment from "moment";

const Container = styled.div`
  position: relative;
`;

const DropdownContainer = styled.div(() => [
  tw`fixed h-[calc(100vh - 67px)] w-[448px] flex flex-col justify-start items-start right-[-448px] bottom-0 px-6 py-6 bg-white rounded-tl-2xl rounded-bl-2xl shadow-[1px 3px 20px rgba(57, 124, 221, 0.3)]`,
]);

const DropdownRow = styled.div(() => [tw`w-full h-10 flex px-0 py-1 hover:opacity-80`]);

const TransparentBg = styled.div(() => [tw`w-screen h-screen fixed left-0 top-0`]);

const AnimatedDropdownContainer = animated(DropdownContainer);

const NotiHeader = styled.h4(() => [tw`text-2xl text-altoDark mb-0 font-bold`]);

const NotiListWrap = styled.div(() => [tw`w-full h-[calc(100% - 100px)] mt-6 flex flex-col overflow-scroll`]);

type NotificationDropdownProps = {
  notificationData: any[] | undefined | null;
  open: boolean;
  listOfTabs: any;
  selectedTab: any;
  onChange: any;
  onClose: any;
};

const listOfTabs: any[] = [
  {
    value: "notification",
    label: "Notification",
    icon: NotificationIcon,
  },
  {
    value: "save",
    label: "Save",
    icon: SaveIcon,
  },
  {
    value: "done",
    label: "Done",
    icon: DoneIcon,
  },
];

const NotificationDropdown = ({ notificationData, open, listOfTabs, selectedTab, onChange, onClose }: NotificationDropdownProps) => {
  const dropdownProps = useSpring({
    transform: open ? "translate3d(-448px,0,0)" : "translate3d(0px,0,0)",
  });

  return (
    <>
      <AnimatedDropdownContainer style={dropdownProps}>
        <GrClose style={{ position: "absolute", top: "32px", right: "32px", cursor: "pointer" }} onClick={onClose} />
        <NotiHeader>Notifications</NotiHeader>
        <UnderlineTab listOfTabs={listOfTabs} selectedTab={selectedTab} onChange={onChange} />
        <NotiListWrap>
          {notificationData === undefined && <LoadingPage />}
          {notificationData === null && <DropdownRow>No new notification</DropdownRow>}
          {notificationData && notificationData.length === 0 && <DropdownRow>No new notifications</DropdownRow>}

          {notificationData &&
            notificationData.length !== 0 &&
            selectedTab.value === "notification" &&
            notificationData.map((noti: any) => (
              <div className="h-16 w-full flex py-4 gap-x-4" key={noti?.unixtimestamp}>
                <img src={DeviceNoti} className="w-8 h-8" />
                <div className="flex flex-col">
                  <span className="font-bold text-altoDark text-sm" style={{ marginTop: "-4px" }}>
                    {noti?.noti_message}
                  </span>
                  <span className="font-bold text-[10px]" style={{ color: "#788796" }}>
                    {noti?.timestamp}
                  </span>
                </div>
              </div>
            ))}

          {notificationData && selectedTab.value === "save" && <DropdownRow>No saved notifications</DropdownRow>}
          {notificationData && selectedTab.value === "done" && <DropdownRow>No done notifications</DropdownRow>}
        </NotiListWrap>
      </AnimatedDropdownContainer>
    </>
  );
};

const Notification = () => {
  const firebase = useContext<any>(FirebaseContext);
  const [open, setOpen] = useState(false);
  const [starttime, setStarttime] = useState(new Date());
  const [notificationReadStatus, setNotificationReadStatus] = useState<"read" | "not_read">();
  const [selectedTab, setSelectedTab] = useState({
    value: "notification",
    label: "Notification",
    icon: NotificationIcon,
  });
  const [notificationData, setNotificationData] = useState<any>();

  const onChange = (tabData: any) => {
    setSelectedTab(tabData);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(!open);
    setNotificationReadStatus("read");
    sessionStorage.setItem("notification_read_status", "read");
  };

  const getAllNoti = async () => {
    try {
      const res: any = await getNotification({
        starttime: convertDatetimeToStrDatetimeNew(starttime, -7),
        endtime: convertDatetimeToStrDatetimeNew(starttime),
      });
      if (res?.data?.results?.length === 0) {
        setNotificationData(null);
      } else {
        setNotificationData(res?.data?.results);
      }
    } catch (err) {
      setNotificationData(null);
    }
  };

  const checkNotiReadStatusFromStorage = () => {
    const status: any = sessionStorage.getItem("notification_read_status");
    if (status && ["read", "not_read"].includes(status)) {
      setNotificationReadStatus(status);
    }
  };

  const checkNotiReadStatusFromFb = (status: boolean, lastUpdateFromFb: string) => {
    if (status === true) {
      const lastUpdateFromStorage = sessionStorage.getItem("notification_last_update");
      getAllNoti();
      if (lastUpdateFromStorage) {
        if (moment(lastUpdateFromFb) > moment(lastUpdateFromStorage)) {
          setNotificationReadStatus("not_read");
          sessionStorage.setItem("notification_last_update", lastUpdateFromFb);
          sessionStorage.setItem("notification_read_status", "not_read");
        }
      } else {
        setNotificationReadStatus("not_read");
        sessionStorage.setItem("notification_last_update", lastUpdateFromFb);
      }
    }
  };

  const subscribeDevice = (didMount: boolean) => {
    if (didMount) {
      firebase.db.ref(notification_status_path).off("value");
    } else {
      firebase.db.ref(notification_status_path).on("value", function (snap: { val: () => any }) {
        if (snap) {
          const val = snap.val();
          checkNotiReadStatusFromFb(val?.status, val?.updated_at);
        }
      });
    }
  };

  useEffect(() => {
    getAllNoti();
    checkNotiReadStatusFromStorage();
    subscribeDevice(false);
    return () => {
      subscribeDevice(true);
    };
  }, []);

  return (
    <>
      {open && <TransparentBg onClick={() => setOpen(!open)} />}
      <Container>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer" }} onClick={onOpen}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.2849 8.44216C17.2357 7.77554 16.6792 7.25 16 7.25C15.3208 7.25 14.7643 7.77554 14.7151 8.44216C14.407 8.5135 14.1014 8.60479 13.8047 8.7078C10.9408 9.70232 9.65 12.6867 9.65 15.4575V20.6694H9C8.58579 20.6694 8.25 21.0051 8.25 21.4194C8.25 21.8336 8.58579 22.1694 9 22.1694H23C23.4142 22.1694 23.75 21.8336 23.75 21.4194C23.75 21.0051 23.4142 20.6694 23 20.6694H22.35V15.4575C22.35 12.6867 21.0592 9.70232 18.1953 8.7078C17.8986 8.60479 17.593 8.5135 17.2849 8.44216ZM20.85 20.6694V15.4575C20.85 13.045 19.7357 10.8306 17.7032 10.1248C17.2645 9.97245 16.8378 9.86214 16.4439 9.8121C16.288 9.79229 16.1398 9.78226 16 9.78226C15.8602 9.78226 15.712 9.79229 15.5561 9.8121C15.1622 9.86214 14.7355 9.97245 14.2968 10.1248C12.2643 10.8306 11.15 13.045 11.15 15.4575V20.6694H20.85Z"
            fill="#065BA9"
          />
          <path
            d="M17.9776 23.2989C17.8322 24.2585 16.9985 25 16.0001 25C15.0473 25 14.1725 24.2812 14.0226 23.299C13.9976 23.1352 14.1344 23 14.3001 23H17.7001C17.8657 23 18.0024 23.1351 17.9776 23.2989Z"
            fill="#065BA9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.0001 23C16.031 23.2044 16.0289 23.3953 16.0001 23.5718C16.0001 23.5719 16.0001 23.5716 16.0001 23.5718C15.9714 23.3954 15.9693 23.2041 16.0001 23C16.0001 23 16.0001 23 16.0001 23L14.3001 23C14.1344 23 13.9976 23.1352 14.0226 23.299C14.0641 23.5709 14.1612 23.8227 14.3001 24.0446C14.4985 24.3616 14.7823 24.6177 15.1116 24.7846C15.3842 24.9229 15.6879 25 16.0001 25C16.3209 25 16.6247 24.9234 16.8941 24.7877C17.2256 24.6207 17.505 24.3641 17.7001 24.0503C17.8399 23.8253 17.9364 23.5709 17.9776 23.2989C18.0024 23.1351 17.8657 23 17.7001 23L16.0001 23Z"
            fill="#065BA9"
          />
          {notificationReadStatus !== "read" && notificationData && (
            <g filter="url(#filter0_b_4104_44729)">
              <circle cx="24" cy="8" r="8" fill="url(#paint0_linear_4104_44729)" />
              <circle cx="24" cy="8" r="7.5" stroke="white" strokeOpacity="0.5" />
              <text x={notificationData.length < 10 ? "21" : "17.8"} y="12" fill="white" fontSize="10px">
                {notificationData.length < 10 ? notificationData.length : "9+"}
              </text>
            </g>
          )}
          <defs>
            <filter id="filter0_b_4104_44729" x="12" y="-4" width="24" height="24" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImage" stdDeviation="2" />
              <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4104_44729" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4104_44729" result="shape" />
            </filter>
            <linearGradient id="paint0_linear_4104_44729" x1="19.1111" y1="2.22222" x2="27.5556" y2="13.7778" gradientUnits="userSpaceOnUse">
              <stop stopColor="#EF4337" />
              <stop offset="1" stopColor="#EF4337" stopOpacity="0.73" />
            </linearGradient>
          </defs>
        </svg>

        <NotificationDropdown
          notificationData={notificationData}
          open={open}
          listOfTabs={listOfTabs}
          selectedTab={selectedTab}
          onChange={onChange}
          onClose={onClose}
        />
      </Container>
    </>
  );
};

export default Notification;
