import { useState } from "react";
import Modal from "react-modal";
import { useForm, Controller } from "react-hook-form";
import "./AddParameterModal.css";
import { sort } from "utils/sort";
import {
  AddButton,
  Button,
  CheckBox,
  CheckBoxText,
  Container,
  customStyles,
  FieldErrorMessage,
  Form,
  Input,
  InputContainer,
  Label,
  Line,
  SubdeviceTitle,
  Title,
  WrapButton,
  WrapCheckbox,
  WrapInput,
} from "./AddParameterModalComponents";
import DeviceConfig from "configs/device_config.json";
import RoomNameMapConfig from "configs/room_name_map.json";
import "antd/dist/antd.css";
import Select from "react-select";
import { useDisclosure } from "utils/hooks";
import { AddDeviceActionModal } from "./AddDeviceActionModal";
import { AddDeviceStatusModal } from "./AddDeviceStatusModal";
import { TriggerActionCard } from "./TriggerActionCard";

interface DeviceActionCommandProps {
  [roomName: string]: {
    [subdeviceName: string]: {
      command: {
        [command: string]: any;
      };
    };
  };
}

interface DeviceParameterCommandProps {
  [roomName: string]: {
    [subdeviceName: string]: {
      [schema: string]: {
        [comparator: string]: any;
      };
    };
  };
}

interface ParameterCommandProps {
  parameter_type: { label: string; value: string };
  comparator: { label: string; value: string };
  value: number | string;
}

export interface AgentMapProps {
  [agent_id: string]: {
    title: string;
    sub_tabs: string[];
    type: string;
    columns: { Header: string; accessor: string }[];
    command?: any;
    command_list?: { label: string; value: string }[];
    parameter_command?: ParameterCommandProps;
  };
}

const selectCustomStylesNotiImportant = {
  container: (provided: any) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided: any) => ({
    ...provided,
    borderRadius: "8px",
    height: "100%",
    border: "1px solid rgba(14, 126, 228, 0.6)",
  }),
  menu: (provided: any) => ({
    ...provided,
    height: "200px",
    width: "calc( 100% - 96px)",
    position: "absolute",
    overflow: "scroll",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    backgroundColor: "white",
  }),
};

const notiImportantOptions = [
  { label: "Critical", value: "critical" },
  { label: "Error", value: "error" },
  { label: "Warning", value: "warning" },
  { label: "Debug", value: "debug" },
  { label: "Info", value: "info" },
];

const addDefaultCommand = (data: any) => {
  const agentIdList = Object.keys(data);
  let returnData = { ...data };
  agentIdList.forEach((agentId: string) => {
    returnData[agentId]["parameter_command"] = [
      {
        parameter_type: null,
        comparator: null,
        value: null,
      },
    ];
  });
  return returnData;
};

export const AddParameterModal = ({ isOpen, onClose, deviceData, controllableDeviceData, loading, onAddAutomation }: any) => {
  let AgentMap: AgentMapProps = DeviceConfig;
  const RoomNameMap: any = RoomNameMapConfig;
  const addParameterDialog = useDisclosure();
  const addDeviceActionDialog = useDisclosure();

  // Device trigger modal data
  const [selectedDevicesParameter, setSelectedDevicesParameter] = useState<any>([]);
  const [agentsList, setAgentsList] = useState<any[]>([]);
  const [currentAgent, setCurrentAgent] = useState<string | undefined>();
  const [allCommand, setAllCommand] = useState<AgentMapProps>(addDefaultCommand(AgentMap));
  const [parameterType, setParameterType] = useState<{ [agentId: string]: ParameterCommandProps }>();
  const [deviceParameter, setDeviceParameter] = useState<DeviceParameterCommandProps>();
  // Device action modal data
  const [selectedDevicesAction, setSelectedDevicesAction] = useState<any>([]);
  const [deviceActionAgentsList, setDeviceActionAgentsList] = useState<any[]>([]);
  const [deviceActionCommand, setDeviceActionCommand] = useState<AgentMapProps>(AgentMap);
  const [deviceAction, setDeviceAction] = useState<DeviceActionCommandProps>();
  const [currentDeviceActionAgent, setCurrentDeviceActionAgent] = useState<string | undefined>();
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  }: any = useForm({
    defaultValues: {
      automation_id: "",
      automation_name: undefined,
      allow_notification: true,
      noti_message: "",
      line: true,
      web: true,
      email: true,
      noti_important: { label: "Critical", value: "critical" },
    },
  });

  const setNotifyTo = (web: boolean, email: boolean, line: boolean) => {
    let result = [];
    if (web) result.push("web");
    if (email) result.push("email");
    if (line) result.push("line");
    return result;
  };

  const onSelectDeviceActionRow = (data: any) => {
    setSelectedDevicesAction(
      data.map((item: any) => ({ ...item.original, ["room_name"]: RoomNameMap[item.original.room] ? RoomNameMap[item.original.room] : "" }))
    );
    const allAgents = data.map((item: any) => item?.original?.agent_id);
    let selectedAgents: any[] = [];
    if (data.length === 0) {
      setCurrentDeviceActionAgent(undefined);
      return;
    }
    allAgents.forEach((agentId: string) => {
      if (!selectedAgents.includes(agentId)) {
        selectedAgents.push(agentId);
      }
    });
    selectedAgents = selectedAgents.sort((a, b) => sort(a, b));

    setDeviceActionAgentsList(selectedAgents);
    if (selectedAgents[0]) {
      if (!currentDeviceActionAgent) {
        setCurrentDeviceActionAgent(selectedAgents[0]);
      } else {
        if (!selectedAgents.includes(currentAgent)) {
          setCurrentDeviceActionAgent(selectedAgents[0]);
        }
      }
    }
  };

  const onSelectRow = (data: any) => {
    setSelectedDevicesParameter(
      data.map((item: any) => ({ ...item.original, ["room_name"]: RoomNameMap[item.original.room] ? RoomNameMap[item.original.room] : "" }))
    );
    const allAgents = data.map((item: any) => item?.original?.agent_id);
    let selectedAgents: any[] = [];
    if (data.length === 0) {
      setCurrentAgent(undefined);
      return;
    }
    allAgents.forEach((agentId: string) => {
      if (!selectedAgents.includes(agentId)) {
        selectedAgents.push(agentId);
      }
    });
    selectedAgents = selectedAgents.sort((a, b) => sort(a, b));
    setAgentsList(selectedAgents);
    if (selectedAgents[0]) {
      if (!currentAgent) {
        setCurrentAgent(selectedAgents[0]);
      } else {
        if (!selectedAgents.includes(currentAgent)) {
          setCurrentAgent(selectedAgents[0]);
        }
      }
    }
  };

  const onChangeAgentTab = (tabName: string) => {
    setCurrentAgent(tabName);
  };

  const handleChangeCommand = (commandTopic: string, commandValue: any, agentId: string) => {
    setParameterType((prev: any) => {
      let tempPrev = { ...prev };
      if (tempPrev[agentId]) {
        tempPrev[agentId][commandTopic] = commandValue;
      } else {
        tempPrev[agentId] = { [commandTopic]: commandValue };
      }
      return tempPrev;
    });
  };

  const handleChangeDeviceActionCommand = (commandTopic: string, commandValue: string | number, agentId: string, subdevNumber: number) => {
    setDeviceActionCommand((prev: any) => {
      const tempPrev = { ...prev };
      tempPrev[agentId]["command"][commandTopic] = commandValue;
      return tempPrev;
    });
  };

  const getDeviceActionCommand = (selectedDevices: any[], command: AgentMapProps): DeviceActionCommandProps => {
    let action: DeviceActionCommandProps = {};
    selectedDevices.forEach((deviceData: any) => {
      const agentId = deviceData.agent_id;
      const sub0 = deviceData["subdevices"]["0"];
      const sub1 = deviceData["subdevices"]["1"];
      const room_name: string = deviceData["room_name"];
      let nickname;
      if (sub0) {
        nickname = sub0.nickname;
      } else if (sub1) {
        nickname = sub1.nickname;
      }

      if (action[room_name]) {
        action[room_name][nickname] = { command: command[agentId]["command"] };
      } else {
        action[room_name] = { [nickname]: { command: command[agentId]["command"] } };
      }
    });
    return action;
  };

  const getParameterCommand = (selectedDevices: any[], command: { [agentId: string]: ParameterCommandProps }): DeviceParameterCommandProps => {
    let action: DeviceParameterCommandProps = {};
    selectedDevices.forEach((deviceData: any) => {
      const sub0 = deviceData["subdevices"]["0"];
      const sub1 = deviceData["subdevices"]["1"];
      const room_name = deviceData["room_name"];
      const agentId = deviceData["agent_id"];
      let nickname;
      if (sub0) {
        nickname = sub0.nickname;
      } else if (sub1) {
        nickname = sub1.nickname;
      }
      action[room_name] = {
        [nickname]: {
          [command[agentId].parameter_type.value]: {
            [command[agentId].comparator.value]: command[agentId].value,
          },
        },
      };
    });
    return action;
  };

  const onSubmitParameter = () => {
    if (parameterType) {
      setDeviceParameter(getParameterCommand(selectedDevicesParameter, parameterType));
      addParameterDialog.onClose();
    } else {
      alert("Please complete the form");
    }
  };

  const onSubmitDeviceAction = () => {
    setDeviceAction(getDeviceActionCommand(selectedDevicesAction, deviceActionCommand));
    addDeviceActionDialog.onClose();
  };

  const onSubmit = async (data: any) => {
    const notify_to = setNotifyTo(data.web, data.email, data.line);
    const payload = {
      automation_image: "",
      automation_name: data.automation_name,
      trigger: {
        trigger_type: "device",
        trigger_device: deviceParameter,
      },
      condition: {
        condition_event: "",
        condition_value: "",
      },
      action: deviceAction,
      allow_notification: data.allow_notification,
      notification: {
        notify_to: notify_to,
        noti_image: "",
        noti_message: data.noti_message,
        noti_icon: "",
        noti_type: "device",
        noti_instruction: {
          contact: {
            line: "@altosupport",
            email: "support@altotech.net",
          },
          guide: "",
        },
        noti_important: data.noti_important.value,
      },
    };
    onAddAutomation(payload)
  };

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles} contentLabel="Edit modal">
        <Container>
          <Title>Add New Parameter</Title>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <SubdeviceTitle>Parameter</SubdeviceTitle>
            <InputContainer>
              <Label>Parameter ID</Label>
              <WrapInput>
                <Input {...register("automation_id")} placeholder="-" disabled={true} />
                <FieldErrorMessage error={errors.automation_id} />
              </WrapInput>
            </InputContainer>

            <InputContainer>
              <Label>Parameter Name *</Label>
              <WrapInput>
                <Input
                  {...register("automation_name", { required: "Parameter Name is required" })}
                  placeholder="Enter Parameter Name"
                  disabled={loading}
                />
                <FieldErrorMessage error={errors.automation_name} />
              </WrapInput>
            </InputContainer>
            <Line />
            <div className="relative w-ful col-span-2">
              <SubdeviceTitle>
                IF
                <br />
                <small className="font-normal">When any condition is met.</small>
              </SubdeviceTitle>
              <AddButton onClick={() => addParameterDialog.onOpen()}>+ Add</AddButton>
            </div>
            <TriggerActionCard value={deviceParameter} type="parameter" />
            <Line />
            <div className="relative w-ful col-span-2">
              <SubdeviceTitle>THEN</SubdeviceTitle>
              <AddButton onClick={() => addDeviceActionDialog.onOpen()}>+ Add</AddButton>
            </div>
            <TriggerActionCard value={deviceAction} type="action" />
            <Line />

            <SubdeviceTitle>Notification</SubdeviceTitle>
            <InputContainer>
              <Label>Notification Message *</Label>
              <WrapInput>
                <Input
                  {...register("noti_message", { required: "Notification Message is required" })}
                  placeholder="Enter Notification Message"
                  disabled={loading}
                />
                <FieldErrorMessage error={errors.noti_message} />
              </WrapInput>
            </InputContainer>
            <InputContainer>
              <Label>Importance Level *</Label>
              <WrapInput>
                <Controller
                  name="noti_important"
                  control={control}
                  render={({ field }: any) => <Select {...field} styles={selectCustomStylesNotiImportant} options={notiImportantOptions} />}
                />
              </WrapInput>
            </InputContainer>
            <InputContainer>
              <WrapInput>
                <WrapCheckbox>
                  <CheckBox type="checkbox" {...register("allow_notification")} />
                  <CheckBoxText>Allow Notification</CheckBoxText>
                </WrapCheckbox>
              </WrapInput>
              {watch && watch(["allow_notification"])[0] && (
                <WrapInput style={{ justifyContent: "flex-start", height: "fit-content" }}>
                  <WrapCheckbox>
                    <CheckBox type="checkbox" {...register("line")} />
                    <CheckBoxText>Line</CheckBoxText>
                  </WrapCheckbox>
                  <WrapCheckbox>
                    <CheckBox type="checkbox" {...register("web")} />
                    <CheckBoxText>Web</CheckBoxText>
                  </WrapCheckbox>
                  <WrapCheckbox>
                    <CheckBox type="checkbox" {...register("email")} />
                    <CheckBoxText>Email</CheckBoxText>
                  </WrapCheckbox>
                </WrapInput>
              )}
            </InputContainer>

            <WrapButton>
              <Button onClick={onClose} data-outlined>
                Cancel
              </Button>
              <Button type="submit" disabled={loading}>
                Add
              </Button>
            </WrapButton>
          </Form>
        </Container>
      </Modal>
      <AddDeviceStatusModal
        value={parameterType}
        onChange={handleChangeCommand}
        isOpen={addParameterDialog.isOpen}
        onClose={addParameterDialog.onClose}
        onSubmit={onSubmitParameter}
        deviceData={deviceData}
        onSelectRow={onSelectRow}
        currentAgent={currentAgent}
        agentsList={agentsList}
        allCommand={allCommand}
        onChangeAgentTab={onChangeAgentTab}
      />
      <AddDeviceActionModal
        value={deviceActionCommand}
        onChange={handleChangeDeviceActionCommand}
        isOpen={addDeviceActionDialog.isOpen}
        onClose={addDeviceActionDialog.onClose}
        onSubmit={onSubmitDeviceAction}
        deviceData={controllableDeviceData}
        onSelectRow={onSelectDeviceActionRow}
        currentAgent={currentDeviceActionAgent}
        agentsList={deviceActionAgentsList}
        setCurrentAgent={setCurrentDeviceActionAgent}
      />
    </>
  );
};
