import styled from "styled-components";
import { BlockContainer, BlockHeader, GraphContainer2 } from "components/Common/components";
import { Row, Col } from "antd";
import { ResponsiveLine, Serie } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
import { LoadingPage } from "components/Common/LoadingPage/LoadingPage";
// import { RealtimeData } from 'components/Common/MockData/MockData'

const Container = styled(BlockContainer)`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 10px;
  filter: drop-shadow(0px 2px 10px rgba(171, 183, 196, 0.2));
  padding-right: 0px;
`;

const SummaryValue = styled.p`
  font-weight: bold;
  font-size: 32px;
  line-height: 47px;
  margin: 0px 0px 0px 0px;
`;

type LineProps = {
  data: Serie[];
};

const MyResponsiveLine = ({ data }: LineProps) => (
  <ResponsiveLine
    data={data}
    margin={{ top: 15, right: 5, bottom: 5, left: 5 }}
    xScale={{
      format: "%Y-%m-%d %H:%M",
      type: "time",
    }}
    xFormat="time:%Y-%m-%d %H:%M"
    yScale={{ type: "linear", min: 0, max: "auto", stacked: false, reverse: false }}
    yFormat=" >-.2f"
    axisTop={null}
    axisRight={null}
    axisBottom={null}
    axisLeft={null}
    lineWidth={2}
    enableGridX={false}
    enableGridY={false}
    colors={["#8BC6FF"]}
    enablePoints={false}
    pointSize={10}
    pointColor={{ theme: "background" }}
    pointBorderWidth={2}
    pointBorderColor={{ from: "serieColor" }}
    pointLabelYOffset={-12}
    enableArea={true}
    areaOpacity={0.15}
    useMesh={true}
  />
);

const MyResponsiveBar = ({ data, keys }: any) => (
  <>
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy="x"
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "fries",
          },
          id: "dots",
        },
        {
          match: {
            id: "sandwich",
          },
          id: "lines",
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={null}
      enableLabel={true}
      enableGridY={false}
      labelSkipWidth={9}
      labelSkipHeight={7}
      colors={"rgb(139, 198, 255)"}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1]],
      }}
      legends={[]}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={function (e) {
        return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;
      }}
      tooltip={(props: any) => {
        return (
          <div
            style={{
              padding: 0,
              color: "#FE6F9A",
            }}
          >
            <strong>{props.indexValue}</strong>
            <br />
            value: {Number(props.value).toFixed(2)}
          </div>
        );
      }}
    />
  </>
);

export const SummaryBox = ({ title, subtitle, data, unit, graphType, keys }: any) => {
  const length = data && data[0] && data[0]["data"] && data[0]["data"].length;
  let currentValue;
  try {
    currentValue = Number(data[0]?.data[length - 1]?.y);
    if (isNaN(currentValue)) {
      currentValue = (0).toFixed(2);
    } else {
      currentValue = currentValue.toFixed(2);
    }
  } catch (err) {
    currentValue = (0).toFixed(2);
  }
  return (
    <>
      <Container>
        <Row>
          <Col sm={24} lg={24}>
            <BlockHeader sm style={{ fontSize: "18px" }}>
              {title}
            </BlockHeader>
            <p style={{ marginBottom: "0px" }}>{subtitle}</p>
          </Col>
        </Row>
        {data === undefined && <LoadingPage height={"114px"} />}
        {data === null && (
          <p style={{ width: "100%", textAlign: "center", marginTop: "50px", marginBottom: "50px", color: "#0E7EE4" }}>No matching data found</p>
        )}
        {data && data.length === 0 && (
          <p style={{ width: "100%", textAlign: "center", marginTop: "50px", marginBottom: "50px", color: "#0E7EE4" }}>No matching data found</p>
        )}
        {data && data.length > 0 && (
          <>
            <Row>
              <SummaryValue>
                {currentValue} {unit}
              </SummaryValue>
            </Row>
            <Row>
              <GraphContainer2>
                {data && graphType === "line" && <MyResponsiveLine data={data} />}
                {data && graphType === "bar" && <MyResponsiveBar data={data[0] && data[0]["data"]} keys={keys} />}
              </GraphContainer2>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};
