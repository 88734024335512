import { Row, Col, Spin } from 'antd';
import LocationImage from 'assets/common/icon/location.svg'
import { Container, BuildingName, CompanyName, LocationIcon, LocationName, LocationRow, Underline, InfoBlockHeader, Title, Desc } from './StyledComponents';

type BuildingNameAndLocProps = {
  buildingName: string
  companyName: string
  locationName: string
}

type BuilidngInfoProps = {
  buildingNameData: any
  infoData: any
}

export type InfoDetailProps = {
  infoData: { title: string, desc: string }[]
}

const BuildingNameAndLoc = ({ buildingName, companyName, locationName }: BuildingNameAndLocProps) => {
  return (
    <>
      <Row gutter={[8, 8]}>
        <Col sm={24} lg={24}>
          <BuildingName>
            {buildingName}
          </BuildingName>
          <CompanyName>
            {companyName}
          </CompanyName>
          <LocationRow>
            <LocationIcon src={LocationImage} />
            <LocationName>
              {locationName}
            </LocationName>
          </LocationRow>
          <Underline />
        </Col>
      </Row>
      <Row>
        <Col sm={24} lg={24}>

        </Col>
      </Row>
      <Row>
        <Col sm={24} lg={24}>

        </Col>
      </Row>
    </>
  )
}

const InfoDetail = ({ infoData }: InfoDetailProps) => {
  return (
    <>
      {infoData ? infoData.map((data) => (
        <Row style={{ marginBottom: '25px' }}>
          <Col sm={12} xl={12}>
            <Title>
              {data.title}
            </Title>
          </Col>
          <Col sm={12} xl={12}>
            <Desc>
              {data.desc}
            </Desc>
          </Col>
        </Row>
      )) :
        <Spin />
      }
    </>
  )
}

export const BuildingInfo = ({ buildingNameData, infoData }: BuilidngInfoProps) => {
  return (
    <>
      <Container>
        <Row>
          <Col sm={24} lg={24}>
            <BuildingNameAndLoc
              buildingName={buildingNameData?.building_name?.value}
              companyName={buildingNameData?.company_name?.value}
              locationName={buildingNameData?.location?.value}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={24} lg={24}>
            <InfoBlockHeader >
              ข้อมูลทั่วไป
            </InfoBlockHeader>
            <InfoDetail infoData={infoData} />
          </Col>
        </Row>
      </Container>
    </>
  )
}