import { useState } from "react";
import { Row, Col } from "antd";
import { DateRangePicker } from "components/DateRangePicker/DateRangePicker";
import { DateRangeContainer, DateRangeTitle } from "./StyledComponents";
import { AgentSummary } from "./AgentSummary";
import { DeviceControlActionSummary } from "./DeviceControlActionSummary";
import { SelfDrivingActiveSummary } from "./SelfDrivingActiveSummary";
import { SummarySavingCompare } from "components/Common/EnergySavingCompare/EnergySavingCompare";
import Co2EmissionIcon from "assets/common/icon/Co2EmissionIcon.svg";
import SummaryAnnualCarUseIcon from "assets/common/icon/SummaryAnnualCarUseIcon.svg";
import SummaryTreeToAbsorbCo2Icon from "assets/common/icon/SummaryTreeToAbsorbCo2Icon.svg";
import { DeviceSummaryCard, InstallMoreDeviceCard } from "./DeviceSummaryCard";
import ACSummaryIcon from "assets/pages/Energy/ACSummaryIcon.svg";
import BlindSummaryIcon from "assets/pages/Energy/BlindSummaryIcon.svg";
import FreshAirSummaryIcon from "assets/pages/Energy/FreshAirSummaryIcon.svg";
// import { Serie } from "@nivo/line";

const AgentData = [
  {
    title: "Air-conditions",
    total: 890,
    on: 500,
    off: 390,
    icon: ACSummaryIcon,
    color: "#0E7EE4",
  },
  {
    title: "Blinds",
    total: 890,
    on: 500,
    off: 390,
    icon: BlindSummaryIcon,
    color: "#9640EC",
  },
  {
    title: "Fresh Air",
    total: 890,
    on: 500,
    off: 390,
    icon: FreshAirSummaryIcon,
    color: "#FF7A00",
  },
];

export const SummarySection = () => {
  let today = new Date();
  let yesterday = new Date(today.setDate(today.getDate() - 1));
  const [startDate, setStartDate] = useState<Date>(yesterday);
  const [endDate, setEndDate] = useState<Date>(new Date());
  // const [accumulateSavingData, setAccumulateSavingData] = useState<Serie[]>();
  const [agentSummaryData] = useState();
  const [selfDrivingActiveSummaryData] = useState();
  const [co2Emission] = useState({ thisMonth: 1, accumulate: 2 });
  const [annualCarUse] = useState({ thisMonth: 1, accumulate: 2 });
  const [treeToAbsorbCo2] = useState({ thisMonth: 1, accumulate: 2 });

  return (
    <>
      <Row gutter={[18, 18]} style={{ position: "relative" }}>
        <DateRangeContainer>
          <DateRangeTitle>Date:</DateRangeTitle>
          <DateRangePicker startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
        </DateRangeContainer>
        <Col xs={8} xl={8}>
          <SummarySavingCompare header={"CO2 Emission"} accumulatedValue={co2Emission.accumulate} unit={"kg"} imgUrl={Co2EmissionIcon} />
        </Col>
        <Col xs={8} xl={8}>
          <SummarySavingCompare header={"Annual Car Use"} accumulatedValue={annualCarUse.accumulate} unit={"km"} imgUrl={SummaryAnnualCarUseIcon} />
        </Col>
        <Col xs={8} xl={8}>
          <SummarySavingCompare
            header={"Tree to absorb CO2"}
            accumulatedValue={treeToAbsorbCo2.accumulate}
            unit={"Trees"}
            imgUrl={SummaryTreeToAbsorbCo2Icon}
          />
        </Col>
        <Col span={18}>
          <Row gutter={[18, 18]}>
            {AgentData.map((item: any) => (
              <Col xs={8}>
                <DeviceSummaryCard title={item.title} on={item.on} off={item.off} icon={item.icon} color={item.color} />
              </Col>
            ))}
            <Col xs={8}>
              <InstallMoreDeviceCard />
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <AgentSummary agentSummaryData={AgentData} />
        </Col>
        <Col span={12}>
          <SelfDrivingActiveSummary selfDrivingActiveSummaryData={selfDrivingActiveSummaryData} />
        </Col>
        <Col span={12}>
          <DeviceControlActionSummary deviceControlActionSummaryData={agentSummaryData} />
        </Col>
      </Row>
    </>
  );
};
