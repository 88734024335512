import styled from "styled-components";
import { MainBlockContainer } from "components/Common/components";

type dataProps = { title: string; value: string };

interface TabProps {
  dataList: dataProps[];
  selectedValue: string;
  handleSelect: (type: string) => void;
  bgColor?: string;
  size?: string;
  selectedTextColor?: string;
  agentId: string;
}

interface SizeMapProps {
  [size: string]: {
    height: string;
    fontSize: string;
  };
}

const SizeMap: SizeMapProps = {
  small: {
    height: "30px",
    fontSize: "12px",
  },
  medium: {
    height: "42px",
    fontSize: "16px",
  },
};

const Container = styled.div`
  width: fit-content;
  background: transparent;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-left: 11px;
  padding-bottom: 0px;
  padding-right: 11px;
  color: #586b7c;
`;

const TabContainer = styled.div<any>`
  width: fit-content;
  height: ${(props) => SizeMap[props.size]["height"]};
  border-radius: 14px !important;
  background: ${(props) => (props.bgColor ? props.bgColor : "white")};
  backdrop-filter: blur(4px);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1.81395px 12.093px rgba(161, 183, 205, 0.2);
  padding: 2px 2px;
  @media only screen and (max-width: 1300px) {
  }
  @media only screen and (max-width: 850px) {
  }
`;

const TabButton = styled.div<any>`
  width: fit-content;
  padding: 0px 16px;
  border-radius: ${(props) => (props.isActive ? "13px" : "0px")};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${(props) => (props.isActive ? "bold" : "normal")};
  height: 100%;
  cursor: pointer;
  background: ${(props) =>
    props.isActive ? (props.bgColor ? props.bgColor : "linear-gradient(274.48deg, #0E7EE4 0%, #55A6F2 100%);") : "transparent"};
  opacity: ${(props) => (props.isActive ? "1" : "0.8")};
  /* backdrop-filter: blur(20px); */
  border: ${(props) => (props.isActive ? "1px solid #ffffff90" : "0px")};
`;

const TabTitle = styled.p<any>`
  margin: 0px;
  white-space: nowrap;
  font-size: ${(props) => SizeMap[props.size]["fontSize"]};
  font-weight: ${(props) => (props.isActive ? "bold" : "normal")};
  color: ${(props) => (props.isActive ? (props.color ? props.color : "#ffffff") : "#586A7C")};
`;

const generateControlName = (agentId: string) => {
  if (agentId === "netatmo" || agentId === "son_env" || agentId === "modbus") {
    return "Realtime";
  } else if (agentId === "cctv") {
    return "Action";
  } else {
    return "Control";
  }
};

const SubTab = ({ dataList, selectedValue, handleSelect, bgColor, size, selectedTextColor, agentId }: TabProps) => {
  return (
    <>
      <Container>
        <TabContainer size={size ? size : "medium"}>
          {dataList.map((tabData: dataProps) => (
            <TabButton key={tabData.title} isActive={tabData.value === selectedValue} onClick={() => handleSelect(tabData.value)} bgColor={bgColor}>
              <TabTitle
                isActive={selectedValue === tabData.value}
                size={size ? size : "medium"}
                color={selectedTextColor ? selectedTextColor : "white"}
              >
                {tabData.title === "Control" ? generateControlName(agentId) : tabData.title}
              </TabTitle>
            </TabButton>
          ))}
        </TabContainer>
      </Container>
    </>
  );
};

export default SubTab;
