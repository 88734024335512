import { useState, useEffect } from "react";
import { Row, Col } from "antd";
import Down from "assets/pages/Dashboard/Down.svg";
import Up from "assets/pages/Dashboard/Up.svg";
import { IconType } from "react-icons/lib";
import { FiArrowUpCircle } from "react-icons/fi";
import { BuildingMain } from "./BuildingMain";
import { Floor1 } from "./Floor1";
import { Floor2 } from "./Floor2";
import { Floor1CCC } from "./Floor1CCC";
import { Floor2CCC } from "./Floor2CCC";
import { CCC } from "./CCC";
// import { Roof } from "./Roof";
import Floor1MintTowerBuildingMain from "assets/pages/Dashboard/FloorImage/Floor1MintTowerBuildingMain.svg";
import FloorMMintTowerBuildingMain from "assets/pages/Dashboard/FloorImage/FloorMMintTowerBuildingMain.svg";
import Floor2MintTowerBuildingMain from "assets/pages/Dashboard/FloorImage/Floor2MintTowerBuildingMain.svg";
import Floor3MintTowerBuildingMain from "assets/pages/Dashboard/FloorImage/Floor3MintTowerBuildingMain.svg";
import Floor4MintTowerBuildingMain from "assets/pages/Dashboard/FloorImage/Floor4MintTowerBuildingMain.svg";
import Floor510MintTowerBuildingMain from "assets/pages/Dashboard/FloorImage/Floor510MintTowerBuildingMain.svg";
import {
  ContentBody,
  FloorModelContainer,
  ImageContainer,
  Line,
  Subtitle,
  UpdatedAt,
  Value,
  ViewGraphButton,
  ScaleImage,
  UpDownImage,
  Image,
} from "./Components";
import { DeviceIcon } from "./DeviceIcon";
import { Tooltip } from "components/Common/Tooltip/Tooltip";
import { DeviceProps } from "MockData/DeviceData";
import HeatScale from "assets/pages/Dashboard/HeatScale.svg";
import { DeviceModal } from "components/DeviceModal/DeviceModal";
import { MintTower } from "./MintTower";
import { sort } from "utils/sort";

type ArrowMapperType = {
  [locationName: string]: {
    icon: IconType;
    top: string;
    left: string;
    startAngle: number;
    fill: string;
    stroke: string;
  };
};

const ArrowMapper: ArrowMapperType = {
  "Load Fl.1": {
    icon: FiArrowUpCircle,
    top: "69.7%",
    left: "73%",
    startAngle: 52,
    fill: "#20A696",
    stroke: "#F3F3F3'",
  },
  "Load Fl.2": {
    icon: FiArrowUpCircle,
    top: "58.4%",
    left: "73%",
    startAngle: 52,
    fill: "#20A696",
    stroke: "#F3F3F3'",
  },
  "Buy-in Power": {
    icon: FiArrowUpCircle,
    top: "71.5%",
    left: "16%",
    startAngle: 48,
    fill: "#788796",
    stroke: "#F3F3F3'",
  },
  "Output Power": {
    icon: FiArrowUpCircle,
    top: "51%",
    left: "13.5%",
    startAngle: 228,
    fill: "#FF7A00",
    stroke: "#F3F3F3'",
  },
};

const FloorMap: { [hotel_code: string]: { [floorName: string]: any } } = {
  BGM: {
    F1: <Floor1 />,
    F2: <Floor2 />,
  },
  DAIC: {
    F1: <Floor1CCC />,
    F2: <Floor2CCC />,
  },
  MTW: {
    F1: <Image src={Floor1MintTowerBuildingMain} />,
    M: <Image src={FloorMMintTowerBuildingMain} />,
    F2: <Image src={Floor2MintTowerBuildingMain} />,
    F3: <Image src={Floor3MintTowerBuildingMain} />,
    F4: <Image src={Floor4MintTowerBuildingMain} />,
    F5: <Image src={Floor510MintTowerBuildingMain} />,
    F6: <Image src={Floor510MintTowerBuildingMain} />,
    F7: <Image src={Floor510MintTowerBuildingMain} />,
    F8: <Image src={Floor510MintTowerBuildingMain} />,
    F9: <Image src={Floor510MintTowerBuildingMain} />,
    F10: <Image src={Floor510MintTowerBuildingMain} />,
  },
};

const TooltipContent = ({ title, items, updatedAt, value, unit, type, handleViewGraph, modalType }: any) => {
  const [hidden, setHidden] = useState(true);
  return (
    <>
      <ContentBody onClick={() => setHidden(!hidden)}>
        <Row>
          <Col xs={24}>
            <Subtitle>{title}</Subtitle>
            <Value>
              {type === "camera" ? value?.toFixed(0) : value?.toFixed(2)}
              <span style={{ marginLeft: "3px" }}>{unit}</span>
            </Value>
          </Col>
          <UpDownImage src={hidden ? Down : Up} />
        </Row>
        {!hidden && <Line />}
        {!hidden &&
          items &&
          Object.values(items)
            .sort((a: any, b: any) => sort(a.title, b.title))
            .map((itemsValue: any) => (
              <Row key={itemsValue.title}>
                <Col xs={24}>
                  <Subtitle>{itemsValue.title}</Subtitle>
                  <Value>
                    {itemsValue.value}
                    <span style={{ marginLeft: "3px" }}>{itemsValue.unit}</span>
                  </Value>
                </Col>
              </Row>
            ))}
        {!hidden && (
          <Row style={{ justifyContent: "center" }}>
            <ViewGraphButton onClick={() => handleViewGraph(modalType)}>View Graph</ViewGraphButton>
          </Row>
        )}
        <Row>
          <UpdatedAt>{updatedAt}</UpdatedAt>
        </Row>
      </ContentBody>
    </>
  );
};

const GenerateArrow = ({ top, left, startAngle, reverse, color }: any) => {
  const [angle, setAngle] = useState<any>();

  useEffect(() => {
    if (reverse) {
      setAngle(startAngle - 180);
    } else {
      setAngle(startAngle);
    }
  }, [reverse]);

  return (
    <>
      {angle && (
        <FiArrowUpCircle
          style={{
            width: "25px",
            height: "25px",
            position: "absolute",
            top: `${top}%`,
            left: `${left}%`,
            transform: `rotate(${startAngle}deg)`,
            fill: color,
            stroke: "#F3F3F3",
          }}
        />
      )}
    </>
  );
};

const TooltipItem = ({ handleViewGraph, dashboardData2 }: any) => {
  const [consumption, setConsumption] = useState(0);
  const [generation, setGeneration] = useState(0);

  const getSum = (data: any, fixedNumber?: number) => {
    return Object.entries(data).forEach(([tooltipKey, tooltipValue]: any) => {
      data[tooltipKey]["value"] = Object.values(tooltipValue["items"]).reduce((a: number, b: any) => a + b.value, 0);
    });
  };

  const validateValue = (deviceType: string, currentValue: number, consumptionNumber: number, generationNumber: number) => {
    const validateGrid = (currentValue: number, consumptionNumber: number, generationNumber: number) => {
      if (generationNumber > consumptionNumber) {
        return 0;
      } else {
        return consumptionNumber;
      }
    };

    const validateGeneration = (currentValue: number, consumptionNumber: number, generationNumber: number) => {
      if (generationNumber > consumptionNumber) {
        return consumptionNumber;
      } else {
        return currentValue;
      }
    };

    return deviceType === "grid"
      ? validateGrid(currentValue, consumptionNumber, generationNumber)
      : deviceType === "generation"
      ? validateGeneration(currentValue, consumptionNumber, generationNumber)
      : currentValue;
  };

  const balancePower = (data: any) => {
    if (data) {
      getSum(data);
      Object.values(data).forEach((tooltipData: any) => {
        if (tooltipData.type === "consumption") {
          setConsumption((prev) => prev + tooltipData.value);
        } else if (tooltipData.type === "generation") {
          setGeneration((prev) => prev + tooltipData.value);
        }
      });
    }
  };

  useEffect(() => {
    if (dashboardData2) {
      setConsumption(0);
      balancePower(dashboardData2);
    }
    //@ts-ignore
  }, [dashboardData2]);

  return (
    <>
      {dashboardData2 &&
        Object.entries(dashboardData2).map(([tooltipKey, tooltipValue]: any) => (
          <div key={tooltipKey}>
            <Tooltip
              visible
              position={tooltipValue.position}
              left={tooltipValue.location.left}
              top={tooltipValue.location.top}
              styles={tooltipValue.styles}
              children={
                <>
                  <TooltipContent
                    title={tooltipValue.title}
                    items={tooltipValue.items}
                    value={validateValue(tooltipValue.type, tooltipValue.value, consumption, generation)}
                    unit={tooltipValue.unit}
                    type={tooltipValue.type}
                    modalType={tooltipValue.key}
                    updatedAt={tooltipValue.updated_at}
                    handleViewGraph={handleViewGraph}
                  />
                </>
              }
            />
            {tooltipValue.arrow && ArrowMapper[tooltipValue.title] && (
              <GenerateArrow
                top={tooltipValue.arrow["top"]}
                left={tooltipValue.arrow["left"]}
                startAngle={ArrowMapper[tooltipValue.title]["startAngle"]}
                reverse={true}
                color={ArrowMapper[tooltipValue.title]["fill"]}
              />
            )}
          </div>
        ))}
    </>
  );
};

export const FloorModel = ({
  deviceList,
  selectedTab,
  data,
  dashboardData2,
  handleOpenModal,
  handleCloseModal,
  modalIsOpen,
  selectedTabName,
  tabNameList,
  handleSelectTabName,
  selectedDevice,
  visibleDeviceObject,
  command,
  handleChangeCommand,
  handleViewGraph,
  hotel_code,
  allIotStatusFb,
}: any) => {
  return (
    <FloorModelContainer>
      <ImageContainer>
        {["DAIC", "BGM", "MTW"].includes(hotel_code) && selectedTab === "B" && (
          <>
            {hotel_code === "MTW" && <MintTower handleOpenModal={handleOpenModal} />}
            {hotel_code === "DAIC" && <CCC handleOpenModal={handleOpenModal} />}
            {hotel_code === "BGM" && <BuildingMain handleOpenModal={handleOpenModal} />}
            <TooltipItem data={data} handleViewGraph={handleViewGraph} dashboardData2={dashboardData2} />
          </>
        )}
        {["DAIC", "BGM", "MTW"].includes(hotel_code) && selectedTab !== "B" && (
          <>
            {hotel_code && FloorMap[hotel_code][selectedTab]}
            {deviceList &&
              deviceList.map((item: DeviceProps) => (
                <>
                  <DeviceIcon
                    key={item.device_id}
                    deviceId={item.device_id}
                    agentId={item.agent_id}
                    onClick={() => handleOpenModal(item)}
                    x={item.ui_location[0]}
                    y={item.ui_location[1]}
                    showTempZone={true}
                    visible={visibleDeviceObject && visibleDeviceObject[item.agent_id]}
                    firebaseDeviceData={allIotStatusFb && allIotStatusFb[item.device_id]}
                  />
                </>
              ))}
            <ScaleImage src={HeatScale} />
          </>
        )}
        {/* {["DAIC", "BGM"].includes(hotel_code) && selectedTab === "S" && <Roof data={data} />} */}
      </ImageContainer>
      <DeviceModal
        modalIsOpen={modalIsOpen}
        handleCloseModal={handleCloseModal}
        selectedTabName={selectedTabName}
        tabNameList={tabNameList}
        handleSelectTabName={handleSelectTabName}
        deviceData={selectedDevice}
        command={command}
        handleChangeCommand={handleChangeCommand}
        allIotStatusFb={allIotStatusFb}
      />
    </FloorModelContainer>
  );
};
