import { CSSProperties, useEffect, useRef } from 'react'
import { getInstanceByDom, init } from 'echarts';
import type { EChartsOption, ECharts } from "echarts";

export interface ReactEChartsProps {
  option: EChartsOption;
  style?: CSSProperties;
}

export const Echarts = ({ option, style }: ReactEChartsProps): JSX.Element => {
  let chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Initialize chart
    let chart: ECharts | undefined;
    if (chartRef.current !== null) {
      chart = init(chartRef.current);
    }

    // Add chart resize listener
    // ResizeObserver is leading to a bit janky UX
    function resizeChart() {
      chart?.resize();
    }
    window.addEventListener("resize", resizeChart);

    // Return cleanup function
    return () => {
      chart?.dispose();
      window.removeEventListener("resize", resizeChart);
    };
  }, []);

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      chart.setOption(option);
    }
  });


  return (
    <>
      <div ref={chartRef} style={{ width: "100%", height: "400px", ...style }} />
    </>
  )
}
