// import tw from "twin.macro";
// import styled from "styled-components";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    border: "0.5px solid #f3f3f3",
    maxHeight: "95vh",
    padding: "24px",
    width: "60vw",
    height: "424px",
  },
  overlay: {
    backgroundColor: "#00000066",
    zIndex: 99999,
  },
};

// const Container = styled.div(() => [tw`w-full flex flex-col items-center`]);

// const CloseIcon = styled.img(() => [tw`w-[72px] h-[72px] mb-6`]);

// const Title = styled.p(() => [tw`text-altoDark font-bold text-2xl mb-2`]);

// const Row = styled.div(() => [tw`flex gap-x-4 items-center mb-14`]);

// const Topic = styled.p(() => [tw`text-sm text-altoDark mb-0`]);

// const Value = styled.div(() => [tw`h-9 px-4 flex items-center rounded-[18px] font-bold bg-primary-extraLight text-primary`]);

// const Note = styled.p(() => [tw`text-altoDark-medium text-sm text-center w-full mb-6`]);

// const Button = styled.button`
//   ${tw`
//   h-10 w-[134px] rounded-lg font-bold transition
//   flex items-center justify-center gap-2
//   border border-primary border-solid
//   cursor-pointer
//   [&:not([data-outlined=true])]:(
//     bg-primary text-white
//     hover:(shadow) active:(shadow-none)
//   )
//   [&[data-outlined=true]]:(
//     bg-transparent text-primary
//     border-primary
//     hover:(bg-primary/10)
//     active:(bg-primary/20)
//   )
//   `}
// `;

export const InfoModal = ({ children, isOpen, onClose }: any) => {
  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles} contentLabel="Edit modal">
        {children}
      </Modal>
    </>
  );
};
