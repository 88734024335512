import styled from "styled-components";
import { Row, Col } from "antd";
import RealtimeAqhi from "assets/pages/Dashboard/DeviceControl/envsensor_realtime_aqhi.svg";
import Humidy from "assets/pages/Dashboard/DeviceControl/envsensor_realtime_humidity.svg";
import Light from "assets/pages/Dashboard/DeviceControl/envsensor_realtime_light.svg";
import Noise from "assets/pages/Dashboard/DeviceControl/envsensor_realtime_noise.svg";
import Temperature from "assets/pages/Dashboard/DeviceControl/envsensor_realtime_temperature.svg";
import UpdatedAt from "assets/pages/Dashboard/DeviceControl/envsensor_realtime_updated_at.svg";
import moment from "moment";

type envSensorSchemaProps = {
  title: string;
  key: string;
  unit: string;
  icon: any;
};

const Container = styled.div`
  /* width: 750px; */
  height: fit-content;
`;

const SensorDataContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 6px 9px;
`;

const Wrapper = styled.div<any>`
  width: 100%;
  height: 100%;
  background: ${(props) =>
    props.visibleBg
      ? `linear-gradient(
    139.46deg,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.4) 100%
  )`
      : "transparent"};
  box-shadow: ${(props) => (props.visibleBg ? `1px 3px 15px rgba(196, 196, 196, 0.15)` : "none")};
  backdrop-filter: ${(props) => (props.visibleBg ? `blur(20px)` : "none")};
  border-radius: 16px;
  padding: 12px;
`;

const Title = styled.h4`
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #212529;
`;

const envSenesorSchema: envSensorSchemaProps[] = [
  {
    title: "Temperature",
    key: "temperature",
    unit: "°C",
    icon: Temperature,
  },
  {
    title: "CO2",
    key: "co2",
    unit: "",
    icon: RealtimeAqhi,
  },
  {
    title: "Humidity",
    key: "humidity",
    unit: "%",
    icon: Humidy,
  },
  {
    title: "Noise",
    key: "noise",
    unit: "dB",
    icon: Noise,
  },
  {
    title: "Light",
    key: "light",
    unit: "",
    icon: Light,
  },
  {
    title: "Updated At",
    key: "timestamp",
    unit: "",
    icon: UpdatedAt,
  },
];

const StatusValue = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  color: #212529;
  margin: 13px 0px 26px 0px;
`;

const AQHIValue = styled.div`
  width: 90px;
  height: 30px;
  background: linear-gradient(276.05deg, #0e7ee4 3.01%, #8bc6ff 100%);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

const UpdatedAtValue = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #212529;
  width: 120px;
  white-space: nowrap;
  margin: 0px;
`;

const IndexMapper: any = {
  1: "Excellent",
};

const getValueFromType = (type: string, value: string, unit: string) => {
  if (type === "temperature") {
    return (
      <StatusValue>
        {value ? value : <small>No data</small>}
        <small>{unit}</small>
      </StatusValue>
    );
  } else if (type === "co2") {
    return (
      <StatusValue>
        {value ? value : <small>No data</small>}
        <small>{unit} ppm</small>
      </StatusValue>
    );
  } else if (type === "health_idx") {
    return <AQHIValue>{IndexMapper[value] ? IndexMapper[value] : "No data"}</AQHIValue>;
  } else if (type === "humidity") {
    return (
      <StatusValue>
        {value ? value : <small>No data</small>}
        <small>{unit}</small>
      </StatusValue>
    );
  } else if (type === "noise") {
    return (
      <StatusValue>
        {value ? value : <small style={{ fontSize: "14px", fontWeight: "normal" }}>No data</small>}
        <small>{value && unit}</small>
      </StatusValue>
    );
  } else if (type === "timestamp") {
    return <UpdatedAtValue>{value && moment(value).format("YYYY-MM-DD HH:mm")}</UpdatedAtValue>;
  } else {
    return "No data";
  }
};

export const EnvSensorControl = ({ command, allIotStatusFb }: any) => {
  return (
    <Container>
      <Row>
        {envSenesorSchema.map((boxData: envSensorSchemaProps, index: number) => (
          <Col xs={8}>
            <SensorDataContainer>
              <Wrapper visibleBg={index !== envSenesorSchema.length - 1}>
                <Row>
                  <Col xs={6}>
                    <img src={boxData.icon} style={{ width: "34px", height: "34px" }} alt="box" />
                  </Col>
                  <Col xs={18} style={{ display: "flex", alignItems: "center" }}>
                    <Title>{boxData.title}</Title>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <div
                      style={{
                        width: "100%",
                        height: "80px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {command && getValueFromType(boxData.key, command[boxData.key], boxData.unit)}
                    </div>
                  </Col>
                </Row>
              </Wrapper>
            </SensorDataContainer>
          </Col>
        ))}
      </Row>
    </Container>
  );
};
