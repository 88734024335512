import { keyframes } from "styled-components";
import MintTowerBuilding from "assets/pages/Dashboard/BuildingImage/MintTowerBuilding.svg";

// const LoadPath = styled.path`
//   -webkit-animation: ${dash(true)} 30s linear infinite;
//   strokedasharray: 8;
//   stroke-width: 2;
// `;

// const SolarPath = styled.path`
//   -webkit-animation: ${dash()} 30s linear infinite;
//   strokedasharray: 8;
//   stroke-width: 2;
// `;

// const MeterPath = styled.path`
//   -webkit-animation: ${dash(true)} 30s linear infinite;
//   strokedasharray: 8;
//   stroke-width: 2;
// `;

// const MainsPath = styled.path`
//   -webkit-animation: ${dash(true)} 30s linear infinite;
//   strokedasharray: 8;
//   stroke-width: 2;
// `;

export const MintTower = ({ handleOpenModal }: any) => {
  return <img src={MintTowerBuilding} />;
};
