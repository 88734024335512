function formatAMPM(date: Date) {
  var hours = date.getHours();
  var minutes: Number | String = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export const getDatetime = (date: Date) => {
  const shortMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  return shortMonths[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear() + ' | ' + formatAMPM(date)
};
