import { useState } from "react";
import Modal from "react-modal";
import tw from "twin.macro";
import styled from "styled-components";
import { useForm, FieldError, Controller } from "react-hook-form";
import DeviceConfig from "configs/device_config.json";
import Select from "react-select";
import "./AddDeviceModal.css";

const subdevSchema = [
  { label: "environment", value: "environment" },
  { label: "hvac", value: "hvac" },
  { label: "electric", value: "electric" },
  { label: "switch", value: "switch" },
  { label: "brightness", value: "brightness" },
  { label: "device", value: "device" },
  { label: "hsv_colour", value: "hsv_colour" },
  { label: "light_temperature", value: "light_temperature" },
  { label: "remotec", value: "remotec" },
  { label: "location", value: "location" },
  { label: "sensor", value: "sensor" },
  { label: "battery", value: "battery" },
  { label: "charging_station", value: "charging_station" },
  { label: "e_vehicle", value: "e_vehicle" },
  { label: "occupancy", value: "occupancy" },
  { label: "charger", value: "charger" },
  { label: "online", value: "online" },
  { label: "cctv", value: "cctv" },
  { label: "curtain", value: "curtain" },
];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    border: "0.5px solid #f3f3f3",
    maxHeight: "95vh",
    padding: "34px 42px",
    width: "652px",
  },
  overlay: {
    backgroundColor: "#00000066",
    zIndex: 99999,
  },
};

const selectCustomStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: "8px",
    border: "1px solid rgba(14, 126, 228, 0.6)",
  }),
  menu: (provided: any) => ({
    ...provided,
    height: "150px",
    position: "absolute",
    overflow: "scroll",
  }),
};

export const Container = styled.div`
  width: 100%;
  padding: 0px 2px;
  min-height: 50vh;
  overflow: scroll;
`;

export const Title = tw.h3`sticky top-0 pb-4 text-2xl font-bold mb-0 z-10 bg-white`;

const SubdeviceTitle = tw.h4`pt-1 pb-0 text-xl font-bold mb-0`;

interface FieldErrorMessageProps {
  error: FieldError | undefined;
}

function FieldErrorMessage({ error }: FieldErrorMessageProps) {
  if (!error) return null;
  return <span tw="text-red-700">{error.message}</span>;
}

const InputContainer = tw.div`
  flex flex-col gap-1
`;

const Label = tw.label`
  text-base text-black1
`;

const WrapButton = tw.div`col-span-2 flex items-center justify-end gap-4`;

export const Input = styled.input`
  ${tw`
  w-full h-10 px-4 rounded-lg 
  border border-[rgba(14, 126, 228, 0.6)]
  outline-none
  transition-all
  border-solid
  focus:(ring-primary-light ring)
  placeholder:(text-primary)
  disabled:(bg-[#F3F4FA] border-none text-black2 placeholder:text-black2)
  focus:shadow-[0 0 0 2px rgb(24 144 255 / 20%)]
  [&[data-view-mode=true]]:(text-primary-dark bg-transparent)
  `}
`;

const Button = styled.button`
  ${tw`
  h-10 px-4 rounded-lg font-medium transition
  flex items-center justify-center gap-2
  border border-primary border-solid
  cursor-pointer
  [&:not([data-outlined=true])]:(
    bg-primary text-white
    hover:(shadow) active:(shadow-none)
  )
  [&[data-outlined=true]]:(
    bg-transparent text-primary
    border-primary
    hover:(bg-primary/10)
    active:(bg-primary/20)
  )
  `}
`;

const Form = styled.form(() => [tw`flex-1 grid grid-cols-2 gap-x-10 gap-y-4 justify-end`]);

const fillInitialFormData = (isEdit: boolean, isView: boolean, selectedDeviceData: any) => {
  if ((isEdit || isView) && selectedDeviceData) {
    return {
      device_id: selectedDeviceData.device_id,
      device_name: selectedDeviceData.device_name,
      room_name: selectedDeviceData.room_name,
      agent_id: { value: selectedDeviceData.agent_id, label: selectedDeviceData.agent_id },
      public_space_name: selectedDeviceData.public_space_name,
      subdevice_number: 0,
      subdevice_name: selectedDeviceData.subdevices["0"] && selectedDeviceData.subdevices["0"]["nickname"],
      schema: selectedDeviceData.subdevices["0"] &&
        selectedDeviceData.subdevices["0"] && {
          value: selectedDeviceData.subdevices["0"]["schema"],
          label: selectedDeviceData.subdevices["0"]["schema"],
        },
    };
  } else {
    return {
      device_id: undefined,
      device_name: undefined,
      room_name: undefined,
      agent_id: undefined,
      public_space_name: "",
      subdevice_number: 0,
      subdevice_name: undefined,
      schema: undefined,
    };
  }
};

export const AddDeviceModal = ({ isOpen, onClose, onAddDevice, loading, isView, isEdit, selectedDeviceData }: any) => {
  const [agentOptions] = useState(
    Object.keys(DeviceConfig).map((item: any) => ({
      label: item,
      value: item,
    }))
  );
  const [isLocked] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  }: any = useForm({
    defaultValues: fillInitialFormData(isView, isEdit, selectedDeviceData),
  });

  const onSubmit = async (data: any) => {
    const payload = {
      device_id: data.device_id,
      device_name: data.device_name,
      room_name: data.room_name,
      agent_id: data.agent_id.value,
      public_space_name: "",
      subdevices: {
        [data.subdevice_number]: {
          nickname: isLocked ? data.device_name : data.subdevice_name,
          schema: [data.schema.value],
        },
      },
      // gateway_name: "daikincccdev",
    };
    onAddDevice(payload);
  };

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles} contentLabel="Edit modal">
        <Container>
          <Title>
            {isEdit && "Edit Device"}
            {isView && "View Device"}
            {!isEdit && !isView && "Add Device"}
          </Title>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <InputContainer>
              <Label>Device ID</Label>
              <Input
                {...register("device_id", { required: "Device id is required" })}
                placeholder="Enter Device ID"
                disabled={loading || isView}
                // data-view-mode={isView}
              />
              <FieldErrorMessage error={errors.device_id} />
            </InputContainer>
            <InputContainer>
              <Label>Device Name</Label>
              <Input
                {...register("device_name", { required: "Device name is required" })}
                placeholder="Enter Device Name"
                disabled={loading || isView}
                // data-view-mode={isView}
              />
              <FieldErrorMessage error={errors.device_name} />
            </InputContainer>
            <InputContainer>
              <Label>
                Agent ID <small>(ex. modbus)</small>
              </Label>
              {/* <Input
                {...register("agent_id", { required: "Agent ID is required" })}
                placeholder="Enter Agent ID"
                disabled={loading || isView}
                // data-view-mode={isView}
              /> */}
              <Controller
                name="agent_id"
                control={control}
                // rules={{ required: "Agent ID is required" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    isDisabled={loading || isView || isLocked}
                    styles={selectCustomStyles}
                    options={agentOptions}
                    placeholder={"Select Agent ID"}
                  />
                )}
              />
              <FieldErrorMessage error={errors.agent_id} />
            </InputContainer>
            <InputContainer>
              <Label>Room Name</Label>
              <Input
                {...register("room_name", { required: "Room Name is required" })}
                placeholder="Enter Room Name"
                disabled={loading || isView}
                // data-view-mode={isView}
              />
              <FieldErrorMessage error={errors.room_name} />
            </InputContainer>
            <SubdeviceTitle>
              Subdevices
              {/* {!isView && <AdvancedSettings onClick={() => setIsLocked(!isLocked)}>advanced settings</AdvancedSettings>} */}
            </SubdeviceTitle>
            <br />

            <InputContainer>
              <Label>Subdevice Number</Label>
              <Input
                {...register(`subdevice_number`, { required: "Subdevice number is required" })}
                placeholder="Enter Subdevice Number"
                disabled={loading || isView || isLocked}
                // data-view-mode={isView}
              />
              <FieldErrorMessage error={errors.device_id} />
            </InputContainer>
            {!isLocked && (
              <InputContainer>
                <Label>Subdevice Name</Label>
                <Input
                  {...register(`subdevice_name`, { required: "Subdevice name is required" })}
                  placeholder="Enter Subdevice Name"
                  disabled={loading || isView || isLocked}
                  // data-view-mode={isView}
                />
                <FieldErrorMessage error={errors.subdevice_name} />
              </InputContainer>
            )}

            <InputContainer>
              <Label>Subdevice Schema</Label>
              <Controller
                name="schema"
                control={control}
                // rules={{ required: "Schema is required" }}
                render={({ field }) => (
                  <Select
                    isDisabled={loading || isView || isLocked}
                    styles={selectCustomStyles}
                    {...field}
                    options={subdevSchema}
                    placeholder={"Select Subdevice Schema"}
                  />
                )}
              />
              <FieldErrorMessage error={errors.schema} />
            </InputContainer>

            {/* --------------- TODO Enable this to add more sub_device --------------- */}
            {/* <WrapAddButton>
            <PlusDevice onClick={()=>addSubdevice()}>
              <FiPlus size={20} color={'white'}/>
            </PlusDevice>
          </WrapAddButton> */}
            {/* ------------------------------------------------------------------- */}
            <WrapButton>
              <Button type="submit" style={{ cursor: "not-allowed" }} disabled>
                Add
              </Button>
              <Button onClick={onClose} data-outlined>
                Cancel
              </Button>
            </WrapButton>
          </Form>
        </Container>
      </Modal>
    </>
  );
};
