import styled from "styled-components";
import { BlockContainer, BlockHeader4, SubBlockHeader } from "components/Common/components";
import { Row, Col } from "antd";
import EnergySavingIcon from "assets/pages/Overview/saving/EnergySaving.svg";
import Co2EmissionIcon from "assets/pages/Overview/saving/Co2Reduction.svg";
import TreeEquivalentIcon from "assets/pages/Overview/saving/TreeEquivalent.svg";

const Container = styled(BlockContainer)`
  background: linear-gradient(1.47deg, rgba(232, 255, 250, 0.72) 1.25%, rgba(255, 255, 255, 0) 98.75%);
  box-shadow: 0px 2px 8px rgba(145, 158, 171, 0.25);
  flex-direction: row;
`;

const Image = styled.img`
  height: 64px;
  width: 60px;
  margin-right: 13px;
  margin-top: 2px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const BlockValue = styled.p`
  font-size: 22px;
  font-weight: 500;
  margin: 0px;
  margin-top: 0px;
`;

const Unit = styled.span`
  font-size: 14px;
  font-weight: 300;
  margin-left: 4px;
`;

export const EnergySaving = ({ energySavingThisMonth, energySavingAccumulate, benchmarkRl, co2ThisMonth, co2Accumulate }: any) => {
  return (
    <>
      <Container>
        <Wrapper>
          <Row gutter={16}>
            <Col sm={6}>
              <Image src={EnergySavingIcon} />
            </Col>
            <Col sm={18} style={{ marginBottom: "8px" }}>
              <Row>
                <SubBlockHeader>Energy Saving ({benchmarkRl && benchmarkRl?.percent} %)</SubBlockHeader>
              </Row>
              <Row>
                {/* {benchmarkRl && (
                  <Col sm={12}>
                    <Row>
                      <BlockHeader4>Daily</BlockHeader4>
                    </Row>
                    <Row>
                      <BlockValue>
                        {benchmarkRl?.avg_energy &&
                          (benchmarkRl?.avg_energy * 4.2).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </BlockValue>
                    </Row>
                  </Col>
                )} */}
                <Col sm={12}>
                  <Row>
                    <BlockHeader4>This month</BlockHeader4>
                  </Row>
                  <Row>
                    <BlockValue>
                      {energySavingThisMonth &&
                        (energySavingThisMonth * 4.2).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      <Unit>THB</Unit>
                    </BlockValue>
                  </Row>
                </Col>
                <Col sm={12}>
                  <Row>
                    <BlockHeader4>Accumulate</BlockHeader4>
                  </Row>
                  <Row>
                    <BlockValue>
                      {energySavingAccumulate &&
                        (energySavingAccumulate * 4.2).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      <Unit>THB</Unit>
                    </BlockValue>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <Image src={Co2EmissionIcon} />
            </Col>
            <Col sm={18} style={{ marginBottom: "8px" }}>
              <Row>
                <SubBlockHeader>CO2-eq</SubBlockHeader>
              </Row>
              <Row>
                <Col sm={12}>
                  <Row>
                    <BlockHeader4>This month</BlockHeader4>
                  </Row>
                  <Row>
                    <BlockValue>
                      {co2ThisMonth}
                      <Unit>kg</Unit>
                    </BlockValue>
                  </Row>
                </Col>
                <Col sm={12}>
                  <Row>
                    <BlockHeader4>Accumulate</BlockHeader4>
                  </Row>
                  <Row>
                    <BlockValue>
                      {co2Accumulate}
                      <Unit>kg</Unit>
                    </BlockValue>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <Image src={TreeEquivalentIcon} />
            </Col>
            <Col sm={18}>
              <Row>
                <SubBlockHeader>Tree-eq</SubBlockHeader>
              </Row>
              <Row>
                <Col sm={12}>
                  <Row>
                    <BlockHeader4>This month</BlockHeader4>
                  </Row>
                  <Row>
                    <BlockValue>
                      {Math.round(co2ThisMonth / 25)}
                      <Unit>trees</Unit>
                    </BlockValue>
                  </Row>
                </Col>
                <Col sm={12}>
                  <Row>
                    <BlockHeader4>Accumulate</BlockHeader4>
                  </Row>
                  <Row>
                    <BlockValue>
                      {Math.round(co2Accumulate / 25)}
                      <Unit>trees</Unit>
                    </BlockValue>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Wrapper>
      </Container>
    </>
  );
};
