import styled from 'styled-components';
import { Row, Col } from 'antd';
import {
  BlockContainer,
  DeviceBlockHeader,
} from '../../../../components/Common/components';
import { MockRealtimePowerCon } from 'MockData/DeviceData';
import { ResponsiveLine } from '@nivo/line';

const Container = styled(BlockContainer)`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  filter: drop-shadow(0px 2px 10px rgba(171, 183, 196, 0.2));
  height: calc(100% - 24px);
  /* padding: 23px 42px; */
`;

type LineProps = {
  data: any;
};

const MyResponsiveLine = ({ data }: LineProps) => (
  <ResponsiveLine
    data={data}
    margin={{ top: 15, right: 5, bottom: 25, left: 5 }}
    xScale={{
      format: '%Y-%m-%d %H:%M',
      type: 'time',
    }}
    xFormat="time:%Y-%m-%d %H:%M"
    yScale={{ type: 'linear', min: 0, max: 'auto', stacked: false, reverse: false }}
    yFormat=" >-.2f"
    axisTop={null}
    axisRight={null}
    axisBottom={null}
    axisLeft={null}
    lineWidth={2}
    enableGridX={false}
    enableGridY={false}
    colors={['#8BC6FF']}
    enablePoints={false}
    pointSize={10}
    pointColor={{ theme: 'background' }}
    pointBorderWidth={2}
    pointBorderColor={{ from: 'serieColor' }}
    pointLabelYOffset={-12}
    enableArea={true}
    areaOpacity={0.15}
    useMesh={true}
  />
);

export const AutomaticMeterReading = () => {
  return (
    <>
      <Container>
        <Row style={{ padding: '23px 24px 0px 24px' }}>
          <Col sm={24} lg={24}>
            <DeviceBlockHeader>Automatic Meter Reading (AMR)</DeviceBlockHeader>
          </Col>
        </Row>
        <Row style={{ padding: '12px 24px 12px 24px' }}>
          <Col sm={24}>
            <p style={{ marginTop: '16px' }}>Type of meter: Single Phrase</p>
          </Col>
        </Row>
        <Row>
          <Col sm={24}>
            <Container style={{ margin: '0px', width: '100%' }}>
              <p>Rank of This Month</p>
              <div style={{ height: '180px' }}>
                <MyResponsiveLine data={MockRealtimePowerCon['ranking']} />
              </div>
            </Container>
          </Col>
        </Row>
        <Row>
          <Col sm={24}>
            <Container style={{ margin: '0px', width: '100%' }}>
              <p>Rank average</p>
              <div style={{ height: '180px' }}>
                <MyResponsiveLine data={MockRealtimePowerCon['ranking']} />
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};
