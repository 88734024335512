import styled from "styled-components";

const ArrowUp = styled.div<any>`
  position: absolute;
  top: -10px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgba(255, 255, 255, 0.5);
  filter: drop-shadow(0px 2.04px 13.6px rgba(161, 183, 205, 0.2));
  backdrop-filter: blur(13.6px);
  background: rgba(255, 255, 255, 0.5);
`;

const ArrowDown = styled.div`
  position: absolute;
  bottom: -10px;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid rgba(255, 255, 255, 0.5);
  filter: drop-shadow(0px 2.04px 13.6px rgba(161, 183, 205, 0.2));
  backdrop-filter: blur(13.6px);
  background: rgba(255, 255, 255, 0.5);
`;

const ArrowRight = styled.div`
  position: absolute;
  right: -10px;
  width: 0;
  height: 0;
  border-top: 60px solid transparent;
  border-bottom: 60px solid transparent;
  border-left: 60px solid rgba(255, 255, 255, 0.5);
  filter: drop-shadow(0px 2.04px 13.6px rgba(161, 183, 205, 0.2));
  backdrop-filter: blur(13.6px);
  background: rgba(255, 255, 255, 0.5);
`;

const AarrowLeft = styled.div`
  position: absolute;
  left: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid rgba(255, 255, 255, 0.5);
  filter: drop-shadow(0px 2.04px 13.6px rgba(161, 183, 205, 0.2));
  backdrop-filter: blur(13.6px);
`;

interface ContainerProps {
  visible: boolean;
  width?: string;
  height?: string;
  top?: number;
  left?: number;
}

interface TooltipProps extends ContainerProps {
  children: any;
  position: string;
  styles: any;
}

const Container = styled.div<ContainerProps>`
  position: absolute;
  display: ${(props) => (props.visible ? "block" : "none")};
  width: ${(props) => (props.width ? props.width : "fit-content")};
  height: ${(props) => (props.height ? props.height : "fit-content")};
  filter: drop-shadow(0px 2.04px 13.6px rgba(161, 183, 205, 0.2));
  backdrop-filter: blur(13.6px);
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  top: ${(props) => props.top}%;
  left: ${(props) => props.left}%;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const arrow = (style: any): any => ({
  right: (
    <>
      <AarrowLeft style={style} />
    </>
  ),
  left: (
    <>
      <ArrowRight style={style} />
    </>
  ),
  top: (
    <>
      <ArrowDown style={style} />
    </>
  ),
  down: (
    <>
      <ArrowUp style={style} />
    </>
  ),
});

export const Tooltip = ({ visible, top, left, width, height, children, position, styles }: TooltipProps) => {
  return (
    <>
      <Container visible={visible} top={top} left={left} width={width} style={styles}>
        <Wrapper>
          {arrow(styles)[position]}
          {children}
        </Wrapper>
      </Container>
    </>
  );
};
