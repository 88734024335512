import styled from 'styled-components'

export const IconContainer = styled.div`
  width: 50px;
  display: flex;
  justify-content: center;
  padding: 0px 0px;
`

export const IconImage = styled.img`
  width: 100%;
  object-fit: contain;
`