import styled from "styled-components";
import { BlockContainer, BlockHeader, GraphContainer } from "components/Common/components";
import { Row, Col } from "antd";
import { PieChart } from "components/Common/Graph/PieChart";
import { LoadingPage } from "components/Common/LoadingPage/LoadingPage";

const Container = styled(BlockContainer)`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 10px;
  filter: drop-shadow(0px 2px 10px rgba(171, 183, 196, 0.2));
  height: 235px;
  justify-content: space-between;
`;

export const EnergyConsumption = ({ data, buildingCode }: any) => {
  return (
    <>
      <Container>
        <Row>
          <Col sm={12} lg={24}>
            <BlockHeader style={{ marginBottom: "25px" }} sm>
              {buildingCode !== "DAIC" ? "Daily Energy Consumption by Floor (kWh)" : "Daily Energy Consumption (kWh)"}
            </BlockHeader>
          </Col>
        </Row>
        <Row>
          <GraphContainer style={{ height: " 135px" }}>
            {data && <PieChart data={data} unit={"kWh"} />}
            {data === undefined && <LoadingPage height={"100%"} />}
            {data === null && <p style={{ width: "100%", textAlign: "center", marginTop: "50px", color: "#0E7EE4" }}>No matching data found</p>}
          </GraphContainer>
        </Row>
      </Container>
    </>
  );
};
