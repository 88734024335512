const getHotelCode = () => {
  const hotel_code = localStorage.getItem('hotel_code')
  if (hotel_code) {
    return hotel_code
  }
}

export const checkVisible = (page: string, tab: string, type: string) => {
  const hotelCode = getHotelCode()

  const websitePath: any = {
    "dashboard": {
      "b": true
    },
    "energy": {
      "information": {
        "SummaryBlock": ["BGM", "DAIC"],
        "BuildingInfo": ["BGM", "DAIC"],
        "BuildingEvaluation": ["BGM"],
        "DailySaving": ["BGM"],
        "AccumulateSaving": ["BGM"],
        "SummaryOfEnergySavingMeasures": ["BGM"]
      }
    }
  }

  if (hotelCode) {
    try {
      return websitePath[page][tab][type].includes(hotelCode)
    } catch (err) {
      return false
    }
  } else {
    return false
  }
}

export const getVisibleTab = () => {
  const hotelCode = getHotelCode()
  if (hotelCode === "BGM") {
    return (
      [
        { value: 'information', title: "Information" },
        { value: 'selfDriving', title: "Self-driving" },
        { value: 'energyAnalytic', title: "Energy Analytic" },
        { value: 'energyAudit', title: "Energy Audit" }
      ]
    )
  } else if (hotelCode === "DAIC") {
    return (
      [
        { value: 'information', title: "Information" },
      ]
    )
  } else {
    return [
      { value: 'information', title: "Information" },
      { value: 'selfDriving', title: "Self-driving" },
      { value: 'energyAnalytic', title: "Energy Analytic" },
      // { value: 'energyPerformance', title: "Energy Performance" },
      // { value: 'buildingDigitalTwin', title: "Building Digital Twin" },
      { value: 'energyAudit', title: "Energy Audit" }
    ]
  }
}