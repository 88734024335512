import { useState } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { DateRangePicker } from 'components/DateRangePicker/DateRangePicker';

import {
  BlockContainer,
  BlockHeader,
  SelfDrivingGraphContainer,
} from 'components/Common/components';
import { Echarts } from 'components/Common/Graph/Echarts';

const Container = styled(BlockContainer)`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  filter: drop-shadow(0px 2px 10px rgba(171, 183, 196, 0.2));
  padding: 24px 24px;
`;

const option: any = {
  toolbar: {
    show: true,
    tools: {
      download: false, // <== line to add
    },
  },
  title: {
    text: '',
    subtext: '',
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
    },
  },
  toolbox: {
    show: true,
    feature: {
      saveAsImage: {},
    },
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: [
      '00:00',
      '01:15',
      '02:30',
      '03:45',
      '05:00',
      '06:15',
      '07:30',
      '08:45',
      '10:00',
      '11:15',
      '12:30',
      '13:45',
      '15:00',
      '16:15',
      '17:30',
      '18:45',
      '20:00',
      '21:15',
      '22:30',
      '23:45',
    ],
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: '{value} W',
    },
    axisPointer: {
      snap: true,
    },
  },
  visualMap: {
    show: false,
    dimension: 0,
    pieces: [
      {
        lte: 6,
        color: '#0E7EE4',
      },
      {
        gt: 6,
        lte: 8,
        color: 'red',
      },
      {
        gt: 8,
        lte: 14,
        color: '#0E7EE4',
      },
      {
        gt: 14,
        lte: 17,
        color: 'red',
      },
      {
        gt: 17,
        color: '#0E7EE4',
      },
    ],
  },
  series: [
    {
      name: 'Actual',
      type: 'line',
      smooth: false,
      data: [
        300, 280, 250, 260, 270, 300, 550, 500, 400, 390, 380, 390, 400, 500, 600, 750, 800, 700,
        600, 400,
      ],
      markArea: {
        itemStyle: {
          color: 'rgba(255, 173, 177, 0.4)',
        },
        data: [
          [
            {
              name: '07:30-10:00',
              xAxis: '07:30',
            },
            {
              xAxis: '10:00',
            },
          ],
          [
            {
              name: '17:30-21.15',
              xAxis: '17:30',
            },
            {
              xAxis: '21:15',
            },
          ],
        ],
      },
    },
    {
      name: 'Baseline',
      type: 'line',
      smooth: false,
      data: [
        290, 260, 245, 252, 215, 291, 515, 474, 320, 380, 330, 380, 390, 470, 590, 730, 770, 710,
        640, 370,
      ],
      markArea: {
        itemStyle: {
          color: 'rgba(255, 173, 177, 0.4)',
        },
        data: [
          [
            {
              name: '',
              xAxis: '07:30',
            },
            {
              xAxis: '10:00',
            },
          ],
          [
            {
              name: '',
              xAxis: '17:30',
            },
            {
              xAxis: '21:15',
            },
          ],
        ],
      },
    },
  ],
};

const ForecastGraph = () => {
  return (
    <SelfDrivingGraphContainer>
      <Echarts option={option} />
    </SelfDrivingGraphContainer>
  );
};

export const EnergyActualAndForecast = () => {
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());
  // const [selectedTabName, setSelectedTabName] = useState<string>('today');
  // let today = new Date();
  // let yesterday = new Date(today.setDate(today.getDate() - 1));

  // const handleSelectTabName = (e: string) => {
  //   setSelectedTabName(e);
  // };

  return (
    <>
      <Container>
        <Row>
          <Col sm={12} lg={12}>
            <BlockHeader>Energy Actual and Forecast Today</BlockHeader>
          </Col>
          <Col sm={12} lg={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <DateRangePicker />
          </Col>
        </Row>
        <Row>
          <Col sm={24} xl={24} style={{ overflow: 'hidden' }}>
            <ForecastGraph />
          </Col>
        </Row>
      </Container>
    </>
  );
};
