import { Suspense } from "react";
import { AddAutomationModal } from "./AddAutomationModal";
import TableSection from "./TableSection";
import { LoadingPage } from "components/Common/LoadingPage/LoadingPage";
import { ButtonSection } from "./ButtonSection";
import { DeleteModal } from "components/Common/DeleteModal/DeleteModal";

export const ScheduleSection = ({
  data,
  addDialog,
  deleteDialog,
  deviceData,
  openDeleteAutomation,
  onCloseDeleteModal,
  onDeleteAutomation,
  selectedAutomation,
  loading,
  onAddAutomation,
  buildingCode,
}: any) => {
  return (
    <>
      <ButtonSection onOpen={addDialog.onOpen} />
      {data && <TableSection data={data} openAddDevice={addDialog.onOpen} openDeleteAutomation={openDeleteAutomation} />}
      {data === null && <p>No matching data found</p>}
      {data === undefined && <LoadingPage />}
      <Suspense fallback={null}>
        <AddAutomationModal
          isOpen={addDialog.isOpen}
          onClose={addDialog.onClose}
          deviceData={deviceData}
          loading={loading}
          onAddAutomation={onAddAutomation}
          buildingCode={buildingCode}
        />
        <DeleteModal
          isOpen={deleteDialog.isOpen}
          onClose={onCloseDeleteModal}
          topic={"Automation id"}
          onDelete={onDeleteAutomation}
          selectedDeviceData={selectedAutomation}
          disabled={loading}
        />
      </Suspense>
    </>
  );
};
