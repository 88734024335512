const keyMap: any = {
  power_load_est: "Load",
  power_solar_est: "Solar",
  power_grid_est: "Grid",
  baseline: "Baseline",
  energy_consumption: "Energy Consumption",
  power_load1: "FL.1 LIGHT",
  power_load2: "FL.1 AC",
  power_load3: "FL.2 LIGHT",
  power_load4: "FL.2 AC",
  count_in: "PEOPLE IN",
  count_out: "PEOPLE OUT",
  power_load_fl1: "TOTAL LOAD",
  power_load_fl2: "AC LOAD",
  sum_count_in: "PEOPLE IN",
  sum_count_out: "PEOPLE OUT",
};

const DaikinMeterMap: any = {
  floor_1: 'Total Load',
  floor_2: 'AC Load',
}

export const prepLineGraph = (data: any, keyList: string[], xName: string, lastText?: string) => {
  if (Object.keys(data).length !== 0) {
    let result: any[] = keyList.map((key: string) => {
      let tempObject = {};
      tempObject = {
        id: keyMap[key] ? keyMap[key] : key,
        data: data.map((powerData: any) => ({
          x: lastText ? powerData[xName] + lastText : powerData[xName]?.replace("T", " ").slice(0, -3),
          y: powerData[key] ? powerData[key] : 0,
        })),
      };
      return tempObject;
    });
    return result;
  } else {
    return [];
  }
};

export const prepLineGraphModal = (data: any, modalObject: any) => {
  let result: any = {};
  if (!modalObject) return result;
  Object.entries(modalObject).forEach(([key, val]: any) => {
    result[key] = prepLineGraph(data[val.key], val.y, val.x);
  });
  return result;
};

export const prepBarGraph = (data: any, keyList: string[], xName: string, lastText?: string) => {
  if (!data) return []
  if (Object.keys(data).length === 0 || keyList === null) return []
  let result: any[] = keyList.map((key: string) => {
    let tempObject = {};
    tempObject = {
      id: keyMap[key] ? keyMap[key] : key,
      data: data.map((powerData: any) => ({
        x: lastText ? powerData[xName] + lastText : powerData[xName]?.replace("T", " ").slice(0, 10),
        y: powerData[key] && Number(powerData[key]).toFixed(1),
        yColor: 'rgb(139, 198, 255)'
      })),
    };
    return tempObject;
  });
  return result;
};

export const prepLineGraphDiff = (data: any, firstKey: string, lastKey: string, xName: string) => {
  let tempObject = {};
  tempObject = {
    id: "energy",
    data: data.map((powerData: any) => ({
      x: powerData[xName]?.replace("T", " ").slice(0, -3),
      y: powerData[lastKey] - data[0][firstKey],
    })),
  };
  return [tempObject];
};

export const prepPieGraph = (data: any, buildingCode: string) => {
  if (buildingCode === "BGM") {
    let result: any[] = ["Load floor 1", "Load floor 2"].map((key: string) => {
      let tempObject = {};
      tempObject = {
        id: key,
        value: data["floor_energy_kWh"][key]["kWh"].toFixed(2),
        label: key,
      };
      return tempObject;
    });
    return result;
  } else if (buildingCode === "DAIC") {
    let result: any[] = ["floor_1", "floor_2"].map((key: string) => {
      let tempObject = {};
      tempObject = {
        id: DaikinMeterMap[key],
        value: data["energy_kWh"]["floor_energy"][key]["daily"]["energy"].toFixed(2),
        label: DaikinMeterMap[key],
      };
      return tempObject;
    });
    return result;
  }
};

// export const prepBarGraph = (data:any, resample:string)=>{

// }