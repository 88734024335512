import styled from 'styled-components'
import { Row, Col, Spin } from 'antd';
import { BlockContainer, DeviceBlockHeader } from "../../../../components/Common/components";
import { Title, Desc, DeviceImage } from './StyledComponents';
import { InfoDetailProps } from './BuildingInfo';

const Container = styled(BlockContainer)`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  filter: drop-shadow(0px 2px 10px rgba(171, 183, 196, 0.2));
  height: 100%;
  padding: 23px 42px;
`

const InfoDetail = ({ infoData }: InfoDetailProps) => {
  if (!infoData) {
    return (
      <Row>
        <Spin />
      </Row>
    )
  }
  return (
    <>
      <Row>
        <Col sm={8} lg={8} style={{display: 'flex', justifyContent: 'center'}}>
          <DeviceImage src={infoData[0].desc} />
        </Col>
        <Col sm={16} lg={16}>
          {infoData.map((data: any, index: number) => (
            index !== 0 &&
            <>
              <Row style={{ marginBottom: '25px' }}>
                <Col sm={12} xl={12}>
                  <Title>
                    {data.title}
                  </Title>
                </Col>
                <Col sm={12} xl={12}>
                  <Desc>
                    {data.desc}
                  </Desc>
                </Col>
              </Row>
            </>)
          )
          }
        </Col>
      </Row>
    </>
  )
}

export const DeviceInfo2 = ({ deviceName, infoData }: any) => {
  return (
    <>
      <Container>
        <Row>
          <Col sm={24} lg={24}>
            <DeviceBlockHeader style={{marginBottom: '20px'}}>
              {deviceName}
            </DeviceBlockHeader>
          </Col>
        </Row>
        <Row>
          <Col sm={24} lg={24}>
            <InfoDetail infoData={infoData} />
          </Col>
        </Row>
      </Container>
    </>
  )
}