export const mapArrayToKey = (item: any[], key: string) => {
  let newObject: { [key: string]: any } = {}
  // if (key) {
  //   item.forEach((item) => {
  //     newObject[item[key]] = item
  //   })
  // } else {
  //   item.forEach((item) => {
  //     newObject[item.label] = item.value
  //   })
  // }
  item.forEach((item) => {
    newObject[item[key]] = item
  })
  return newObject
}