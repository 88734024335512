import { useMemo, useState } from "react";
import { Row, Col } from "antd";
// import { LoadingPage } from "components/Common/LoadingPage/LoadingPage";
// import { Table } from "components/Common/Table/Table";
import { AutomationActions } from "components/Common/Table/DeviceActions";
import tw from "twin.macro";
import TimeIcon from "assets/pages/Automation/icons/TimeIcon.svg";
import CalendarIcon from "assets/pages/Automation/icons/CalendarIcon.svg";
import IoTIcon from "assets/pages/Automation/icons/IoTIcon.svg";
import Modal from "react-modal";
import { useDisclosure } from "utils/hooks";
import { InfoModal } from "components/Common/InfoModal/InfoModal";
import styled from "styled-components";

interface TableSectionProps {
  data: any[];
  setSelectedDevices?: any;
  setSelectedAgents?: any;
  openAddDevice?: any;
  openEditDevice?: any;
  openDeleteAutomation?: any;
}

const Container = tw.div`grid grid-cols-12 gap-4`;

const CardContainer = tw.div`relative sm:col-span-6 md:col-span-4 xl:col-span-3 col-span-12 w-full h-[256px] flex flex-col p-4 bg-white rounded-2xl pb-0`;

const Title = tw.p`w-full text-primary font-bold text-lg pb-4 border-b-[1px] border-altoWhite-white03 truncate mb-4`;

const Section = tw.div`flex gap-y-4 mb-3`;

const SectionTitle = tw.p`w-10 mr-4 text-base text-primary font-normal`;

const SectionValue = tw.div`w-[calc(100% - 40px)] flex items-center flex-col gap-y-4`;

const Icon = tw.img`w-4 h-4 mr-4 mt-[6px]`;

const Text = tw.p`text-base text-altoDark mb-0 truncate`;

const StyledRow = tw.div`w-full flex items-start justify-start`;

const DeviceListBox = styled.div<any>`
  ${tw`flex flex-col gap-y-1 relative overflow-scroll pb-4`}
  ${({ height }) => height && `height:${height}`}
`;

const SubdeviceName = tw.p`text-base text-altoDark mb-0 truncate`;

const Small = tw.p`text-[10px] mt-[-4px] text-altoDark`;

const DayMap: any = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday",
};

const joinDay = (dayList: string[]) => {
  try {
    let tempResponse = "";
    dayList.forEach((dayNumber: string, index: number) => {
      if (index !== 0) {
        tempResponse = tempResponse + "," + DayMap[dayNumber];
      } else {
        tempResponse = tempResponse + DayMap[dayNumber];
      }
    });
    return tempResponse;
  } catch (err) {
    console.log(err);
    return "";
  }
};

const joinAction = (val: any) => {
  if (val && val.command) {
    let res: any[] = [];
    Object.entries(val.command).forEach(([key, value]: any) => {
      res.push({ key: key.toString(), val: value.toString() });
    });
    return res;
  } else {
    return [];
  }
};

const getDeviceName = (deviceObject: any): any[] => {
  let deviceList: any[] = [];
  Object.keys(deviceObject).forEach((roomName) => {
    Object.keys(deviceObject[roomName]).forEach((subdeviceName: string) => {
      deviceList.push({
        key: roomName + " - " + subdeviceName,
        subdeviceName,
        roomName,
        action: deviceObject[roomName][subdeviceName],
      });
    });
  });
  return deviceList;
};

const convertNumbertTo2Digit = (number: number | string) => {
  if (number < 10) {
    return "0" + number;
  } else {
    return number;
  }
};

const AutomationCard = ({
  automationData,
  automationName,
  cron,
  action,
  openDeleteAutomation,
  openViewAutomation,
  actionVisible,
  actionHeight,
}: any) => {
  return (
    <>
      <Title>{automationName}</Title>
      <Section>
        <SectionTitle>IF</SectionTitle>
        <SectionValue>
          <StyledRow>
            <Icon src={TimeIcon} />
            <Text>
              {convertNumbertTo2Digit(cron[1])}:{convertNumbertTo2Digit(cron[0])}
            </Text>
          </StyledRow>
        </SectionValue>
      </Section>
      <Section style={{ marginBottom: 0 }}>
        <SectionTitle>THEN</SectionTitle>
        <SectionValue>
          <StyledRow>
            <Icon src={CalendarIcon} />
            <Text>Every {cron[4] === "*" ? "day" : joinDay(cron[4].split(","))}</Text>
          </StyledRow>
          <StyledRow>
            <Icon src={IoTIcon} />
            <DeviceListBox height={actionHeight}>
              {getDeviceName(action).map((value: any) => (
                <div className="relative gap-y-0 group over">
                  <SubdeviceName>{value.subdeviceName}</SubdeviceName>
                  <Small>{value.roomName}</Small>
                  {/* <div className="absolute flex-col hidden group-hover:flex top-10 left-0">
                    {joinAction(value.action).map((item: any) => (
                      <Small>
                        {item.key} : {item.val}
                      </Small>
                    ))}
                  </div> */}
                  {joinAction(value.action).map((item: any) => (
                    <Small>
                      {item.key} : {item.val}
                    </Small>
                  ))}
                </div>
              ))}
            </DeviceListBox>
          </StyledRow>
        </SectionValue>
      </Section>
      {actionVisible && (
        <AutomationActions
          automation={automationData["automation_id"]}
          openViewAutomation={() => openViewAutomation(automationData)}
          openDeleteAutomation={() => openDeleteAutomation(automationData)}
        />
      )}
    </>
  );
};

const TableSection = ({ data, openDeleteAutomation }: TableSectionProps) => {
  const viewDialog = useDisclosure();
  const [selectedAutomation, setSelectedAutomation] = useState<any>();
  const openViewAutomation = (data: any) => {
    setSelectedAutomation(data);
    viewDialog.onOpen();
  };

  return (
    <>
      <Row gutter={[18, 18]}>
        <Col xs={24} xl={24}>
          {data && (
            <Container>
              {data.map((item: any) => (
                <CardContainer>
                  <AutomationCard
                    automationData={item}
                    automationName={item["automation_name"]}
                    cron={item?.trigger?.trigger_time?.cron}
                    action={item?.action}
                    openDeleteAutomation={openDeleteAutomation}
                    openViewAutomation={openViewAutomation}
                    actionVisible={true}
                    actionHeight={"104px"}
                  />
                </CardContainer>
              ))}
            </Container>
          )}
        </Col>
      </Row>
      {selectedAutomation && (
        <InfoModal isOpen={viewDialog.isOpen} onClose={() => viewDialog.onClose()}>
          <AutomationCard
            automationData={selectedAutomation}
            automationName={selectedAutomation["automation_name"]}
            cron={selectedAutomation?.trigger?.trigger_time?.cron}
            action={selectedAutomation?.action}
            actionVisible={false}
            actionHeight={"fit-content"}
          />
        </InfoModal>
      )}
    </>
  );
};

export default TableSection;
