import { useState, useEffect } from "react";
import { PageContainer } from "components/Common/components";
import SettingTypeSection from "./SettingTypeSection";
import { getAllUsers } from "services/api.service";
import { responseErrorHandler } from "services/prepareAxios";
import { DeviceManagementSection } from "./DeviceManagementSection";
import { Col, Row } from "antd";
import { UserManagementSection } from "./UserManagementSection";
import { useBuildingContext } from "layouts/BuildingProvider";
import { useDevicesContext } from "layouts/DevicesProvider";

interface DataProps {
  deviceManagement: undefined | null | any[];
  userManagement: undefined | null | any[];
}

type TabProps = "deviceManagement" | "userManagement";

const SettingPage = () => {
  const { buildingCode } = useBuildingContext();
  const { devices } = useDevicesContext();
  const [data, setData] = useState<DataProps>({
    deviceManagement: undefined,
    userManagement: undefined,
  });
  const [selectedType, setSelectedType] = useState<TabProps>("deviceManagement");
  const [selectedIoTDevice, setSelectedIoTDevice] = useState("all");

  const handleSelectType = (type: TabProps) => {
    setSelectedType(type);
  };

  const handleSelectIoTDevice = (device: string) => {
    setSelectedIoTDevice(device);
  };

  const getData = async (tabName: TabProps) => {
    setData((prev) => {
      let tempPrev = { ...prev };
      tempPrev[tabName] = undefined;
      return tempPrev;
    });
    try {
      if (tabName === "userManagement") {
        const result = await getAllUsers();
        setData((prev) => ({ ...prev, [tabName]: result.data.users }));
      }
    } catch (err) {
      responseErrorHandler(err);
      setData((prev) => {
        let tempPrev = { ...prev };
        tempPrev[tabName] = null;
        return tempPrev;
      });
    }
  };

  useEffect(() => {
    getData(selectedType);
  }, [selectedType]);

  return (
    <PageContainer>
      <Row gutter={[18, 18]}>
        <Col xs={24}>
          <SettingTypeSection
            selectedType={selectedType}
            handleSelectType={handleSelectType}
            selectedIoTDevice={selectedIoTDevice}
            handleSelectIoTDevice={handleSelectIoTDevice}
          />
        </Col>
        <Col xs={24} xl={24}>
          {selectedType === "deviceManagement" && (
            <DeviceManagementSection
              data={devices}
              hotelCode={buildingCode}
              selectedIoTDevice={selectedIoTDevice}
              handleSelectIoTDevice={handleSelectIoTDevice}
            />
          )}
          {selectedType === "userManagement" && (
            <UserManagementSection data={data["userManagement"]} reload={() => getData(selectedType)} hotelCode={buildingCode} />
          )}
        </Col>
      </Row>
    </PageContainer>
  );
};

export default SettingPage;
