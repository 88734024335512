import { useEffect, useState } from "react";
import styled from "styled-components";
import { BlockContainer, SubBlockHeader } from "components/Common/components";
import { Row, Col } from "antd";

const Container = styled(BlockContainer)`
  background: linear-gradient(99.81deg, rgba(255, 255, 255, 0.9) -3.83%, rgba(255, 255, 255, 0.53) 134.3%);
  box-shadow: 0px 0px 5px rgba(145, 158, 171, 0.21);
  padding-top: 13px;
  overflow: hidden;
  height: 130px;
  flex-direction: column;
`;

const DeviceContainer = styled.div`
  width: 28%;
  height: 74px;
  display: flex;
  flex-direction: column;
  align-items: space-around;
  padding: 5px;
  background: linear-gradient(99.81deg, rgba(255, 255, 255, 0.9) -3.83%, rgba(255, 255, 255, 0.53) 134.3%);
  box-shadow: 1px 1px 2px rgba(145, 158, 171, 0.15);
  border-radius: 16px;
  border: 1px solid #e9e9e9;
  margin: 5px 5px 0px 0px;
  justify-content: space-around;
  align-items: center;
  :last-child {
    margin-right: 0px;
  }
`;

const DeviceTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 20px;
  font-size: 10px;
  color: #788796;
  text-align: center;
`;

const DeviceOnline = styled.p`
  color: #2ca8ff;
  font-size: 16px;
  margin: 0px;
  width: 100%;
  text-align: center;
`;

const DeviceTotal = styled.span`
  font-size: 10px;
  color: #788796;
`;

const ArrowIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #a1b7cd;
  color: #a1b7cd;
  border-radius: 12px;
  position: absolute;
  top: 33px;
  cursor: pointer;
`;

const DeviceComponent = ({ deviceData }: any) => {
  return (
    <DeviceContainer>
      <DeviceTitle>{deviceData?.title}</DeviceTitle>
      <DeviceOnline>
        {deviceData?.online}
        <DeviceTotal>/{deviceData?.total}</DeviceTotal>
      </DeviceOnline>
    </DeviceContainer>
  );
};

// type IoTDevicesProps = {
//   [name:string]: {title:string, online:number, total:number}
// }

export const IoTDevices = ({ iotDevices }: any) => {
  const [showedDevices, setShowedDevices] = useState<any>();
  const [range, setRange] = useState<number[]>([0, 1, 2]);

  const handleRange = (type: string) => {
    if (type === "prev") {
      setRange((prev: number[]) => {
        let length = showedDevices.length;
        let newArray = [];
        for (let index of prev) {
          if (index === length - 1) {
            newArray.push(0);
          } else {
            newArray.push(index + 1);
          }
        }
        return newArray;
      });
    } else {
      setRange((prev: number[]) => {
        let length = showedDevices.length;
        let newArray = [];
        for (let index of prev) {
          if (index === 0) {
            newArray.push(length - 1);
          } else {
            newArray.push(index - 1);
          }
        }
        return newArray;
      });
    }
  };

  useEffect(() => {
    if (iotDevices) {
      setShowedDevices(Object.keys(iotDevices));
    }
  }, [iotDevices]);

  return (
    <>
      <Container>
        <Row>
          <Col>
            <SubBlockHeader>IoT Devices</SubBlockHeader>
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <ArrowIcon style={{ left: "-15px" }} onClick={() => handleRange("prev")}>
            {"<"}
          </ArrowIcon>
          <ArrowIcon style={{ right: "-15px" }} onClick={() => handleRange("next")}>
            {">"}
          </ArrowIcon>
          {iotDevices && showedDevices && range.map((index) => <DeviceComponent key={index} deviceData={iotDevices[showedDevices[index]]} />)}
        </Row>
      </Container>
    </>
  );
};
