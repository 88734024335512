import { useMemo } from "react";
import { Row, Col } from "antd";
import { LoadingPage } from "components/Common/LoadingPage/LoadingPage";
import { TaskActions } from "components/Common/Table/CompletedMaintenanceAction";
import { Table } from "components/Common/Table/Table";

interface PmTableProps {
  data: any[];
  acknowledge?: any;
  close?: any;
  searchable?: boolean;
}

const PmTable = ({ data, acknowledge, close, searchable }: PmTableProps) => {
  const columns = useMemo(
    () => [
      {
        Header: "No.",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Due",
        accessor: "due",
      },
      {
        Header: "Completed at",
        accessor: "completed_at",
      },
      {
        Header: "Assigned to",
        accessor: "assigned_to",
      },
      {
        id: "action",
        Header: <div tw="flex-1 flex justify-center">Action</div>,
        accessor: "action",
        disableSortBy: true,
        Cell: ({ row }: any) => <TaskActions />,
      },
    ],
    []
  );

  return (
    <>
      <Row gutter={[18, 18]}>
        <Col xs={24} xl={24}>
          {data === null ||
            (data === undefined && (
              <p style={{ width: "100%", textAlign: "center", marginTop: "50px", color: "#0E7EE4", marginBottom: "24px" }}>No record</p>
            ))}
          {data && (
            <Table
              size="small"
              columns={columns}
              data={data}
              pagination
              searchable={searchable}
              sortBy={[
                {
                  id: "id",
                  desc: true,
                },
              ]}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default PmTable;
