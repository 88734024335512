import { Suspense } from "react";
import { AddParameterModal } from "./AddParameterModal";
import TableSection from "./TableSection";
import { LoadingPage } from "components/Common/LoadingPage/LoadingPage";
import { ButtonSection } from "./ButtonSection";
import { AddDeviceActionModal } from "./AddDeviceActionModal";

export const ParameterSection = ({ data, addParameterDialog, controllableDeviceData, deviceData, loading, onAddAutomation }: any) => {
  return (
    <>
      <ButtonSection onOpen={addParameterDialog.onOpen} />
      {data && <TableSection data={data} openAddDevice={addParameterDialog.onOpen} />}
      {data === null && <p>No matching data found</p>}
      {data === undefined && <LoadingPage />}
      <Suspense fallback={null}>
        <AddParameterModal
          isOpen={addParameterDialog.isOpen}
          onClose={addParameterDialog.onClose}
          deviceData={deviceData}
          controllableDeviceData={controllableDeviceData}
          loading={loading}
          onAddAutomation={onAddAutomation}
        />
      </Suspense>
    </>
  );
};
