import styled from 'styled-components'

export const PageContainer = styled.div`
  width: 100%;
  padding: 16px 24px 20px 24px;
  overflow: scroll;
  height: calc(100vh - 67px);
`

export const BlockContainer = styled.div`
  width: 100%;
  padding: 16px 16px 16px 16px;
  display: flex;
  /* margin: 0px 11px 22px 11px; */
  height: fit-content;
  border-radius: 16px;
  background-color: white;
  flex-direction: column;
  filter: drop-shadow(0px 2px 10px rgba(171, 183, 196, 0.2));
`

export const MainBlockContainer = styled.div`
  width: 100%;
  padding: 22px 22px;
  /* margin: 0px 11px 22px 11px; */
  height: fit-content;
  border-radius: 16px;
`

export const BlockHeader = styled.h3<any>`
  font-size: ${props => props.sm ? '16px' : '20px'};
  font-weight: 600;
  margin: 0px;
`

export const DeviceBlockHeader = styled.h3`
  font-size: 16px;
  margin:0px;
  font-weight: 500;
  color: #397CDD;
`

export const BlockDesc = styled.h3`
  font-size: 14px;
  margin:0px;
  font-weight: 400;
`

export const ContentContainer = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
`

export const SelfDrivingGraphContainer = styled.div`
  width: calc(100% + 80px);
  margin-left: -40px;
  height: 400px;
`

export const SelfDrivingTimelineContainer = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  display: flex;
  padding-top: 50px;
  justify-content: center;
  margin-bottom: 200px;
`

export const GraphContainer = styled.div`
  width: 100%;
  height: 160px;
`

export const GraphContainer2 = styled.div`
  width: 100%;
  height: 65px;
`

export const SubBlockHeader = styled.h3`
  font-weight:600;
  font-size: 14px;
  margin:0px;
  margin-bottom: 2px;
  line-height: 1.2;
`

export const BlockHeader4 = styled.h4`
  font-size: 10px;
  color: #788796;
  margin: 0px;
`

export const BlockValue = styled.p`
  font-size: 22px;
  font-weight: 500;
  margin: 0px;
  margin-top: -5px;
`

export const CalendarHeader = styled.h3`
  font-size: 20px;
  margin:0px;
  font-weight: 500;
  margin-bottom: 2px;
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Subtitle = styled.h4`
  font-size: 16px;
  margin: 0px;
  font-weight: 500;
`

export const Graph1 = styled.p`
  font-size: 10px;
  margin: 0px;
`

export const H3 = styled.h3<any>`
  font-size: ${props => props.fontSize ? `${props.fontSize}px` : '20px'};
  margin: 0px;
  color: ${props => props.color ? props.color : '#212529'};
  font-weight: 600;
  line-height: 25px;
  margin-top: ${props => props.mt ? `${props.mt}px` : '6px'};
`

export const BlockTitle = styled.h3<any>`
  font-size: 20px;
  margin:0px;
  font-weight: 600;
`