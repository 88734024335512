import { Suspense, useState } from "react";
import TableSection from "./TableSection";
import { LoadingPage } from "components/Common/LoadingPage/LoadingPage";
import { DeviceExtensionCamera, DeviceExtensionEdge } from "components/Common/Table/DeviceActions";
import { AcknowledgeModal } from "components/Common/DeleteModal/AcknowledgeModal";
import { CloseModal } from "components/Common/DeleteModal/CloseModal";
import OpenedTaskTableSection from "./OpenedTaskTableSection";
import { DoneModal } from "components/Common/DeleteModal/DoneModal";

export type Device = {
  id: string;
  name: string;
  description: string;
  installed_at: string;
  updated_at: string;
  status: boolean;
  site_id: number;
  floor_id: number;
} & (DeviceExtensionCamera | DeviceExtensionEdge);

export const OpenedTaskManagementSection = ({ data, onSubmitDone, onSubmitClose, closeDialog, doneDialog }: any) => {
  const [taskId, setTaskId] = useState();

  const onCloseAcknowledgeModal = () => {
    closeDialog.onClose();
    doneDialog.onClose();
    setTaskId(undefined);
  };

  const handleDone = (data: any) => {
    doneDialog.onOpen();
    setTaskId(data.id);
  };

  const handleClose = (data: any) => {
    closeDialog.onOpen();
    setTaskId(data.id);
  };

  return (
    <>
      {data && <OpenedTaskTableSection data={data} done={handleDone} close={handleClose} />}
      {data === null && <p>No matching data found</p>}
      {data === undefined && <LoadingPage />}
      <Suspense fallback={null}>
        <DoneModal
          isOpen={doneDialog.isOpen}
          onClose={onCloseAcknowledgeModal}
          topic={"Task id"}
          onSubmitDone={onSubmitDone}
          taskId={taskId}
        />
        <CloseModal isOpen={closeDialog.isOpen} onClose={onCloseAcknowledgeModal} topic={"Task id"} onCloseTicket={onSubmitClose} taskId={taskId} />
      </Suspense>
    </>
  );
};
