import styled from "styled-components";
import { Row, Col } from "antd";
import UpdatedAt from "assets/pages/Dashboard/DeviceControl/envsensor_realtime_updated_at.svg";
import moment from "moment";

type envSensorSchemaProps = {
  title: string;
  key: string;
  unit: string;
  icon: any;
};

const Container = styled.div`
  /* width: 750px; */
  height: fit-content;
`;

const SensorDataContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 6px 9px;
`;

const Wrapper = styled.div<any>`
  width: 100%;
  height: 100%;
  background: ${(props) =>
    props.visibleBg
      ? `linear-gradient(
    139.46deg,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.4) 100%
  )`
      : "transparent"};
  box-shadow: ${(props) => (props.visibleBg ? `1px 3px 15px rgba(196, 196, 196, 0.15)` : "none")};
  backdrop-filter: ${(props) => (props.visibleBg ? `blur(20px)` : "none")};
  border-radius: 16px;
  padding: 12px;
`;

const Title = styled.h4`
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #212529;
`;

const envSenesorSchema: envSensorSchemaProps[] = [
  {
    title: "Energy",
    key: "energy",
    unit: "kWh",
    icon: "",
  },
  {
    title: "Power Reactive",
    key: "power_reactive",
    unit: "VAR",
    icon: "",
  },
  {
    title: "Power",
    key: "power",
    unit: "kW",
    icon: "",
  },
  {
    title: "Power Apparent",
    key: "power_apparent",
    unit: "VA",
    icon: "",
  },
  {
    title: "Energy Reactive",
    key: "energy_reactive",
    unit: "varh",
    icon: "",
  },
  {
    title: "Updated At",
    key: "timestamp",
    unit: "",
    icon: UpdatedAt,
  },
];

const StatusValue = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  color: #212529;
  margin: 13px 0px 26px 0px;
`;

const UpdatedAtValue = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #212529;
  width: 120px;
  white-space: nowrap;
  margin: 0px;
`;

const getValueFromType = (type: string, value: number, unit: string) => {
  if (type === "timestamp") {
    return <UpdatedAtValue>{value && moment(value).format("YYYY-MM-DD HH:mm")}</UpdatedAtValue>;
  } else {
    const strValue = value ? value.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + ` ${unit}` : "No data";
    return <StatusValue>{strValue}</StatusValue>;
  }
};

export const MeterRealtime = ({ command, deviceData }: any) => {
  return (
    <Container>
      <Row>
        {envSenesorSchema.map((boxData: envSensorSchemaProps, index: number) => (
          <Col xs={8}>
            <SensorDataContainer>
              <Wrapper visibleBg={index !== envSenesorSchema.length - 1}>
                <Row>
                  {boxData.icon && (
                    <Col xs={6}>
                      <img src={boxData.icon} style={{ width: "34px", height: "34px" }} />
                    </Col>
                  )}
                  <Col xs={18} style={{ display: "flex", alignItems: "center" }}>
                    <Title>{boxData.title}</Title>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <div
                      style={{
                        width: "100%",
                        height: "80px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {command && getValueFromType(boxData.key, command?.[boxData.key], boxData.unit)}
                    </div>
                  </Col>
                </Row>
              </Wrapper>
            </SensorDataContainer>
          </Col>
        ))}
      </Row>
    </Container>
  );
};
