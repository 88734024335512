import styled from "styled-components";

type dataProps = { title: string; value: string };

interface TabProps {
  dataList: dataProps[];
  selectedValue: string;
  handleSelect: (type: string) => void;
  bgColor?: string;
  size?: string;
  selectedTextColor?: string;
}

interface SizeMapProps {
  [size: string]: {
    height: string;
    fontSize: string;
  };
}

const SizeMap: SizeMapProps = {
  small: {
    height: "36px",
    fontSize: "14px",
  },
  medium: {
    height: "42px",
    fontSize: "16px",
  },
};

const Container = styled.div`
  width: fit-content;
  background: transparent;
  padding-top: 0px;
  color: #586b7c;
`;

const TabContainer = styled.div<any>`
  width: fit-content;
  height: ${(props) => SizeMap[props.size]["height"]};
  display: flex;
  column-gap: 32px;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1300px) {
  }
  @media only screen and (max-width: 850px) {
  }
`;

const TabButton = styled.div<any>`
  width: fit-content;
  padding: 0px;
  pading-right: 20px;
  border-radius: ${(props) => (props.isActive ? "10px" : "0px")};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${(props) => (props.isActive ? "bold" : "normal")};
  height: 100%;
  cursor: pointer;
  /* border: ${(props) => (props.isActive ? "1px solid #ffffff90" : "0px")}; */
`;

const TabTitle = styled.p<any>`
  margin: 0px;
  white-space: nowrap;
  font-size: ${(props) => SizeMap[props.size]["fontSize"]};
  font-weight: ${(props) => (props.isActive ? "bold" : "normal")};
  color: ${(props) => (props.isActive ? (props.color ? props.color : "#ffffff") : "#586A7C")};
  border-bottom: ${(props) => (props.isActive ? `2px solid ${props.color}` : "none")};
`;

const LineTab = ({ dataList, selectedValue, handleSelect, bgColor, size, selectedTextColor }: TabProps) => {
  return (
    <>
      <Container>
        <TabContainer size={size ? size : "medium"}>
          {dataList.map((tabData: dataProps) => (
            <TabButton isActive={tabData.value === selectedValue} onClick={() => handleSelect(tabData.value)} bgColor={bgColor} key={tabData.value}>
              <TabTitle
                isActive={selectedValue === tabData.value}
                size={size ? size : "medium"}
                color={selectedTextColor ? selectedTextColor : "white"}
              >
                {tabData.title}
              </TabTitle>
            </TabButton>
          ))}
        </TabContainer>
      </Container>
    </>
  );
};

export default LineTab;
