import { useMemo } from "react";
import { Row, Col } from "antd";
import { LoadingPage } from "components/Common/LoadingPage/LoadingPage";
import { DeviceActions } from "components/Common/Table/DeviceActions";
import { Table } from "components/Common/Table/Table";

interface TableSectionProps {
  data: any[];
  openAddDevice: any;
  openViewDevice: any;
  openEditDevice: any;
  openDeleteDevice: any;
}

const TableSection = ({ data, openAddDevice, openViewDevice, openEditDevice, openDeleteDevice }: TableSectionProps) => {
  const columns = useMemo(
    () => [
      {
        Header: "Device ID",
        accessor: "device_id",
      },
      {
        Header: "Device Name",
        accessor: "device_name",
      },
      {
        Header: "Location on map (x,y)",
        accessor: "ui_location",
      },
      // {
      //   Header: "Room Name",
      //   accessor: "room_name",
      // },
      {
        Header: "Updated At",
        accessor: "updated_at",
      },
      {
        id: "action",
        Header: <div tw="flex-1 flex justify-center">Action</div>,
        accessor: "id",
        disableSortBy: true,
        Cell: ({ row }: any) => (
          <DeviceActions device={row.original} openViewDevice={openViewDevice} openEditDevice={openEditDevice} openDeleteDevice={openDeleteDevice} />
        ),
      },
    ],
    []
  );

  return (
    <>
      <Row gutter={[18, 18]}>
        <Col xs={24} xl={24}>
          {data === undefined && <LoadingPage height={"114px"} />}
          {data === null && <p style={{ width: "100%", textAlign: "center", marginTop: "50px", color: "#0E7EE4" }}>Coming soon</p>}
          {data && (
            <Table
              columns={columns}
              data={data}
              pagination
              openAddDevice={openAddDevice}
              sortBy={[
                {
                  id: "device_id",
                  desc: true,
                },
              ]}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default TableSection;
