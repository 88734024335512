import tw from "twin.macro";

import Button from "components/Common/Button";

import Chart from "assets/common/icon/graph.svg";
import { FloorEnergySummary, FloorEnergySummaryProps } from "./FloorEnergySummary";
// import { RoomFilter } from "./RoomFilter";
// import { ServiceRequestLabel } from "./ServiceRequestLabel";

type FloorEnergySummaryTab = {
  energySummaryData: FloorEnergySummaryProps["energySummaryData"];
  onOpenViewGraph: () => void;
};

const Container = tw.div`relative flex flex-col justify-center items-start border-b-[1px] pb-3 border-gray-100 gap-y-4`;

const GraphImage = tw.img`w-5 h-5`;

const Row = tw.div`w-full flex justify-between pl-4`;

export const FloorEnergySummaryTab = ({ energySummaryData, onOpenViewGraph }: FloorEnergySummaryTab) => {
  return (
    <Container>
      <FloorEnergySummary energySummaryData={energySummaryData} />
      <Row>
        {/* <RangeTabs /> */}
        <div />
        <Button active bgColor={"#0E7EE4"} icon={<GraphImage src={Chart} />} onClick={() => onOpenViewGraph()}>
          View Grap
        </Button>
      </Row>
      {/* <ServiceRequestLabel /> */}
      {/* <RoomFilter handleSelectDevice={handleSelectDevice} /> */}
    </Container>
  );
};
