import styled from "styled-components";

type dataProps = { title: string; value: string };

interface TabProps {
  active?: boolean;
  dataList?: dataProps[];
  title?: string;
  selectedValue?: string;
  onClick?: (value: any) => void;
  bgColor?: string;
  size?: string;
  textColor?: string;
  width?: number;
  icon?: any;
  disabled?: any;
  children?: any;
}

interface SizeMapProps {
  [size: string]: {
    height: string;
    fontSize: string;
  };
}

const SizeMap: SizeMapProps = {
  small: {
    height: "36px",
    fontSize: "14px",
  },
  medium: {
    height: "42px",
    fontSize: "16px",
  },
};

const TabButton = styled.button<any>`
  width: fit-content;
  width: ${(props) => (props.width ? `${props.width}px` : "fit-content")} !important;
  padding: 0px 8px;
  border-radius: 10px;
  height: ${(props) => SizeMap[props.size]["height"]};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${(props) => (props.isActive ? "bold" : "normal")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background: ${(props) =>
    props.isActive ? (props.bgColor ? props.bgColor : "linear-gradient(274.48deg, #0E7EE4 0%, #55A6F2 100%)") : "transparent"};
  opacity: ${(props) => (props.isActive ? "1" : "0.8")};
  border: 1px solid #ffffff90;
  margin-left: 6px;
  padding-bottom: 2px;
  color: ${(props) => (props.isActive ? (props.color ? props.color : "#ffffff") : "#0E7EE4")};
`;

const Button = ({ active, title, onClick, bgColor, size, textColor, width, icon, disabled, children }: TabProps) => {
  return (
    <>
      <TabButton
        isActive={active}
        onClick={!disabled && onClick}
        bgColor={bgColor}
        size={size ? size : "medium"}
        width={width}
        color={textColor ? textColor : "white"}
        disabled={disabled}
      >
        {icon && icon}{" "}
        <span style={{ marginLeft: "4px" }}>
          {title}
          {children}
        </span>
      </TabButton>
    </>
  );
};

export default Button;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 0px 18px;
`;
