import IconHumidity from "assets/pages/Dashboard/Maintenance/IconHumidity.svg"
import IconTemperature from "assets/pages/Dashboard/Maintenance/IconTemperature.svg"
import IconTime from "assets/pages/Dashboard/Maintenance/IconTime.svg"
import IconPeriod from "assets/pages/Dashboard/Maintenance/IconPeriod.svg"
import IconCO2 from "assets/pages/Dashboard/Maintenance/IconCO2.svg"
import IconLineNotification from "assets/pages/Dashboard/Maintenance/IconLineNotification.svg"
import IconDiffTemp from "assets/pages/Dashboard/Maintenance/IconDiffTemp.svg"

export type TriggerValueProps = {
  parameter: string
  value: number | string | boolean
  operator: ">" | "<" | "=" | ">=" | "<=" | ""
}

export type TriggerProps = {
  name: string
  datapoints: TriggerValueProps[]
}

export type ActionProps = {
  name: string
  datapoints: TriggerValueProps[]
}

export type ConditionValueProps = {
  name: string
  trigger: TriggerProps[]
  action: ActionProps[]
}

export type ConditionBasedProps = {
  [deviceId: string]: ConditionValueProps[]
}

const AcTrigger: TriggerProps[] = [
  {
    name: 'tempcondition',
    datapoints: [
      {
        parameter: "diff_temperature",
        value: 8,
        operator: ">",
      },
      {
        parameter: "time",
        value: 120,
        operator: ">",
      },
    ],
  },
  {
    name: 'tempcondition',
    datapoints: [
      {
        parameter: "diff_temperature",
        value: 9,
        operator: ">",
      },
      {
        parameter: "time",
        value: 60,
        operator: ">",
      },
    ],
  },
]

const FreezerTrigger: TriggerProps[] = [
  {
    name: 'tempcondition',
    datapoints: [
      {
        parameter: "temperature",
        value: -10,
        operator: ">",
      },
      {
        parameter: "time",
        value: 60,
        operator: ">",
      },
    ],
  },
]

const ChillerTrigger: TriggerProps[] = [
  {
    name: 'tempcondition',
    datapoints: [
      {
        parameter: "temperature",
        value: 10,
        operator: ">",
      },
      {
        parameter: "time",
        value: 60,
        operator: ">",
      },
    ],
  }, {
    name: 'tempcondition_high',
    datapoints: [
      {
        parameter: "temperature",
        value: 20,
        operator: ">",
      },
      {
        parameter: "time",
        value: 3,
        operator: ">",
      },
    ],
  }]

const TempAndHumidityTrigger: TriggerProps[] = [
  {
    name: 'tempandhumidtempcondition',
    datapoints: [
      {
        parameter: "temperature",
        value: 30,
        operator: ">",
      },
      {
        parameter: "time",
        value: 60,
        operator: ">",
      },
      {
        parameter: "period",
        value: "5:00 - 23:00",
        operator: "",
      }
    ],
  },
  {
    name: 'tempandhumidhumidcondition',
    datapoints: [
      {
        parameter: "humidity",
        value: 70,
        operator: ">",
      },
      {
        parameter: "time",
        value: 60,
        operator: ">",
      },
      {
        parameter: "period",
        value: "5:00 - 23:00",
        operator: "",
      }
    ]
  }
]

const AqiTrigger: TriggerProps[] = [
  {
    name: 'aqitempcondition',
    datapoints: [
      {
        parameter: "temperature",
        value: 30,
        operator: ">",
      },
      {
        parameter: "time",
        value: 60,
        operator: ">",
      },
      {
        parameter: "period",
        value: "5:00 - 23:00",
        operator: "",
      }
    ],
  },
  {
    name: 'aqihumidcondition',
    datapoints: [
      {
        parameter: "humidity",
        value: 70,
        operator: ">",
      },
      {
        parameter: "time",
        value: 60,
        operator: ">",
      },
      {
        parameter: "period",
        value: "5:00 - 23:00",
        operator: "",
      }
    ]
  }
]

const NotificationAction: ActionProps[] = [
  {
    name: 'notification',
    datapoints: [
      {
        parameter: "line_notify",
        value: true,
        operator: "",
      },
    ],
  }
]

export const ConditionData: ConditionBasedProps = {
  shell_rama2_ac_1: [{
    name: 'AC',
    trigger: AcTrigger,
    action: NotificationAction,
  }],
  shell_rama2_ac_2: [{
    name: 'AC',
    trigger: AcTrigger,
    action: NotificationAction
  }],
  shell_rama2_ac_3: [{
    name: 'AC',
    trigger: AcTrigger,
    action: NotificationAction
  }],
  shell_rama2_ac_4: [{
    name: 'AC',
    trigger: AcTrigger,
    action: NotificationAction
  }],
  ebc2dd2a42e4e29f46a94q: [{
    name: 'Freezer',
    trigger: FreezerTrigger,
    action: NotificationAction
  }],
  ebbd6a1f74fd8e71b5zrs0: [{
    name: 'Chiller',
    trigger: ChillerTrigger,
    action: NotificationAction
  }],
  eb6c6e422496f076f0jtwe: [{
    name: 'Chiller',
    trigger: ChillerTrigger,
    action: NotificationAction
  }],
  ebe35092a77d685835ieas: [{
    name: 'T&H_Sensor',
    trigger: TempAndHumidityTrigger,
    action: NotificationAction
  }],
  ebcfccc42dbf6aad48hnds: [{
    name: 'T&H_Sensor',
    trigger: TempAndHumidityTrigger,
    action: NotificationAction
  }],
  ebf82931461113cbaf1bni: [{
    name: 'T&H_Sensor',
    trigger: TempAndHumidityTrigger,
    action: NotificationAction
  }],
  ebd066be011c9f1319o1r5: [{
    name: 'T&H_Sensor',
    trigger: TempAndHumidityTrigger,
    action: NotificationAction
  }],
  eb5308767ad026fd70tcrj: [{
    name: 'T&H_Sensor',
    trigger: TempAndHumidityTrigger,
    action: NotificationAction
  }],
  eb4df51be68b3d58b8b2z5: [{
    name: 'T&H_Sensor',
    trigger: TempAndHumidityTrigger,
    action: NotificationAction
  }],
  eb522bb0f910a7b6156u5g: [{
    name: 'T&H_Sensor',
    trigger: TempAndHumidityTrigger,
    action: NotificationAction
  }],
  ebdfc87e263725e132etvj: [{
    name: 'AQI_Sensor',
    trigger: AqiTrigger,
    action: NotificationAction
  }]
};

type ConditionDataMapProps = {
  [key: string]: {
    icon: string;
    unit: string;
    text: string;
  };
}

export const ConditionDataMap: ConditionDataMapProps = {
  temperature: {
    icon: IconTemperature,
    unit: '°C',
    text: 'Temp.'
  },
  humidity: {
    icon: IconHumidity,
    unit: '%',
    text: 'Humidity'
  },
  time: {
    icon: IconTime,
    unit: 'mins',
    text: 'for'
  },
  period: {
    icon: IconPeriod,
    unit: '',
    text: '',
  },
  co2: {
    icon: IconCO2,
    unit: 'ppm',
    text: 'CO2'
  },
  line_notify: {
    icon: IconLineNotification,
    unit: '',
    text: 'Line Notification'
  },
  diff_temperature: {
    icon: IconDiffTemp,
    unit: '°C',
    text: 'Diff. Temp.'
  }
}

