import Modal from "react-modal";
import tw from "twin.macro";
import styled from "styled-components";
import { useForm, FieldError, Controller } from "react-hook-form";
import Select from "react-select";
import "./AddUserModal.css";
import { useBuildingContext } from "layouts/BuildingProvider";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    border: "0.5px solid #f3f3f3",
    maxHeight: "95vh",
    padding: "34px 42px",
    width: "652px",
  },
  overlay: {
    backgroundColor: "#00000066",
    zIndex: 99999,
  },
};

const selectCustomStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: "8px",
  }),
  menu: (provided: any) => ({
    ...provided,
    height: "90px",
    position: "absolute",
    overflow: "scroll",
  }),
};

const Title = tw.h3`sticky top-0 pb-4 text-2xl font-bold mb-0`;

const ErrorSpan = tw.span`text-red-700`;

interface FieldErrorMessageProps {
  error: FieldError | undefined;
}

function FieldErrorMessage({ error }: FieldErrorMessageProps) {
  if (!error) return null;
  return <ErrorSpan>{error.message}</ErrorSpan>;
}

const InputContainer = tw.div`
  flex flex-col gap-1
`;

const Label = tw.label`
  text-base text-black1
`;

const WrapButton = tw.div`col-span-2 flex items-center justify-end gap-4`;

const Input = styled.input`
  ${tw`
  h-10 px-4 rounded-lg 
  border border-primary hover:border-primary-dark
  outline-none
  transition
  border-solid
  focus:(ring-primary-light ring)
  placeholder:(text-primary)
  disabled:(bg-[#F3F4FA] border-none text-black2 placeholder:text-black2)
  [&[data-view-mode=true]]:(text-primary-dark bg-transparent)
  `}
`;

const Button = styled.button`
  ${tw`
  h-10 px-4 rounded-lg font-medium transition
  flex items-center justify-center gap-2
  border border-primary border-solid
  cursor-pointer
  [&:not([data-outlined=true])]:(
    bg-primary text-white
    hover:(shadow) active:(shadow-none)
  )
  [&[data-outlined=true]]:(
    bg-transparent text-primary
    border-primary
    hover:(bg-primary/10)
    active:(bg-primary/20)
  )
  `}
`;

const Form = styled.form(() => [tw`flex-1 grid grid-cols-2 gap-x-10 gap-y-4 justify-end`]);

const HotelMap: any = {
  DAIC: "Daikin",
  BGM: "Bgrimm",
};

const roleOptions: any = [
  {
    label: "ADMIN",
    value: "md",
  },
  {
    label: "VIEWER",
    value: "front",
  },
];

const fillInitialFormData = (isView: boolean, selectedUser: any): any => {
  if (isView && selectedUser) {
    return {
      firstname: selectedUser.first_name,
      lastname: selectedUser.last_name,
      email: selectedUser.email,
      role: { value: selectedUser.role, label: selectedUser.role },
      username: undefined,
      password: undefined,
    };
  } else {
    return {
      firstname: undefined,
      lastname: undefined,
      email: undefined,
      role: undefined,
      username: undefined,
      password: undefined,
    };
  }
};

export const AddUserModal = ({ isOpen, onClose, onAddUser, loading, isView, selectedUser }: any) => {
  const { buildingCode } = useBuildingContext();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: fillInitialFormData(isView, selectedUser) });
  const onSubmit = (data: any) => {
    const payload = {
      firstname: data.firstname,
      lastname: data.lastname,
      location: "13.753374003489137, 100.5364055144555",
      username: data.username,
      password: data.password,
      role: data.role.value,
      email: data.email,
      confirmation_password: data.password,
      claimcode: buildingCode,
      hotel: HotelMap[buildingCode],
    };
    onAddUser(payload);
  };

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles} contentLabel="Edit modal">
        <Title>Add User</Title>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputContainer>
            <Label>First Name</Label>
            <Input
              {...register("firstname", { required: "First Name is required" })}
              placeholder="Enter First Name"
              disabled={loading || isView}
              // data-view-mode={isView}
            />
            <FieldErrorMessage error={errors.firstname} />
          </InputContainer>
          <InputContainer>
            <Label>Last Name</Label>
            <Input
              {...register("lastname", { required: "Last Name is required" })}
              placeholder="Enter Last Name"
              disabled={loading || isView}
              // data-view-mode={isView}
            />
            <FieldErrorMessage error={errors.lastname} />
          </InputContainer>
          <InputContainer>
            <Label>Email</Label>
            <Input
              {...register("email", { required: "Email is required" })}
              placeholder="Enter Email"
              disabled={loading || isView}
              // data-view-mode={isView}
            />
            <FieldErrorMessage error={errors.email} />
          </InputContainer>
          <InputContainer>
            <Label>Role</Label>
            <Controller
              name="role"
              control={control}
              rules={{ required: "Role is required" }}
              render={({ field }) => <Select styles={selectCustomStyles} {...field} options={roleOptions} isDisabled={loading || isView} />}
            />
            <FieldErrorMessage error={errors.role} />
          </InputContainer>
          {!isView && (
            <>
              <InputContainer>
                <Label>Username</Label>
                <Input
                  {...register("username", { required: "Username is required" })}
                  placeholder="Enter Username"
                  // disabled={loading || isView}
                  // data-view-mode={isView}
                />
                <FieldErrorMessage error={errors.username} />
              </InputContainer>
              <InputContainer>
                <Label>Password</Label>
                <Input
                  {...register("password", { required: "Password is required" })}
                  placeholder="Enter Password"
                  // disabled={loading || isView}
                  type="password"
                  // data-view-mode={isView}
                />
                <FieldErrorMessage error={errors.password} />
              </InputContainer>
            </>
          )}
          <WrapButton>
            {!isView && <Button type="submit">Add</Button>}
            <Button onClick={onClose} data-outlined>
              Cancel
            </Button>
          </WrapButton>
        </Form>
      </Modal>{" "}
    </>
  );
};
