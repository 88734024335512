import { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import tw from "twin.macro";
import styled from "styled-components";
import DeviceConfig from "configs/device_config.json";
import "./AddFileModal.css";
import { useDropzone } from "react-dropzone";
import { postBlob } from "services/api.service";
import toast from "react-hot-toast";
import axios from "axios";
import { responseErrorHandler } from "services/prepareAxios";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    border: "0.5px solid #f3f3f3",
    maxHeight: "95vh",
    padding: "34px 42px",
    width: "652px",
  },
  overlay: {
    backgroundColor: "#00000066",
    zIndex: 99999,
  },
};

const Title = tw.h3`sticky top-0 pb-4 text-2xl font-bold mb-0`;

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div<any>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  height: 160px;
`;

const CancelButton = styled.button`
  ${tw`
  h-10 px-4 rounded-lg font-medium transition
  flex items-center justify-center gap-2
  border border-primary border-solid
  cursor-pointer
  [&:not([data-outlined=true])]:(
    bg-transparent text-primary
    hover:(shadow) active:(shadow-none)
  )
  [&[data-outlined=true]]:(
    bg-transparent text-primary
    border-primary
    hover:(bg-primary/10)
    active:(bg-primary/20)
  )
  `}
`;

const Button = styled.button`
  ${tw`
  h-10 px-4 rounded-lg font-medium transition
  flex items-center justify-center gap-2
  border border-primary border-solid
  cursor-pointer
  [&:not([data-outlined=true])]:(
    bg-primary text-white
    hover:(shadow) active:(shadow-none)
  )
  [&[data-outlined=true]]:(
    bg-transparent text-primary
    border-primary
    hover:(bg-primary/10)
    active:(bg-primary/20)
  )
  `}
`;

const WrapButton = styled.div(() => [tw`w-full flex justify-end mt-4`]);

const InnerWrapButton = styled.div(() => [tw`flex w-[fit-content] gap-x-2`]);

export const AddFileModal = ({ isOpen, onClose, onAddDevice, hotelCode, getData }: any) => {
  const AgentMap = DeviceConfig;
  const [agentOptions, setAgentOptions] = useState<any[]>([]);

  const [files, setFiles] = useState<any>([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles: any) => {
      setFiles(
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const uploadFile = async () => {
    const mediaFormData = new FormData();
    mediaFormData.append("file", files[0]);
    try {
      await toast.promise(postBlob(mediaFormData), {
        loading: "Sending...",
        success: (res: any) => {
          getData();
          return <b>Successful</b>;
        },
        error: (error: any) => {
          if (axios.isAxiosError(error) && error.response?.status === 400) {
            return <b>Failed</b>;
          }
          return <b>Failed</b>;
        },
      });
    } catch (err) {
      responseErrorHandler(err);
    }
  };

  useEffect(() => {
    setFiles([]);
    setAgentOptions(
      Object.keys(AgentMap).map((item: any) => ({
        label: item,
        value: item,
      }))
    );
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles} contentLabel="Edit modal">
        <Title>Add Document</Title>
        {files && files.length !== 0 ? (
          <div>
            {/* <img alt="file" src={getImageUrl(files)} style={{ objectFit: "contain", backgroundColor: "white" }} /> */}
            <p>Name: {files[0].path}</p>
            <p>Size {(files[0].size / 1000).toLocaleString()} KB</p>
          </div>
        ) : (
          <Container {...getRootProps()}>
            <input {...getInputProps()} />
            <p style={{ marginBottom: 0 }}>Click to select files</p>
          </Container>
        )}
        <WrapButton>
          <InnerWrapButton>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <Button onClick={() => uploadFile()}>Add</Button>
          </InnerWrapButton>
        </WrapButton>
      </Modal>
    </>
  );
};
