import { useEffect, useState } from "react";
import { PageContainer } from "components/Common/components";
import styled from "styled-components";
import { SingleDatePicker } from "components/DateRangePicker/DateRangePicker";
import { useBuildingContext } from "layouts/BuildingProvider";
import Button from "components/Common/Button";
import DaikinSettingTypeSection from "./DaikinSettingTypeSection";
import ShellSettingTypeSection from "./ShellSettingTypeSection";

const Iframe = styled.iframe`
  width: 100%;
  height: calc(100vh - 150px);
  border: 0px;
`;

const AnalyticPage = () => {
  const { buildingCode } = useBuildingContext();
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [url, setUrl] = useState<string | undefined>();
  const [selectedType, setSelectedType] = useState(buildingCode === "SHE" ? "iso50006" : "power_temp");

  const handleSelectType = (type: string) => {
    setSelectedType(type);
  };

  const hideSpinner = () => {
    // setLoading(false);
  };

  useEffect(() => {
    let today = new Date();
    const yesterday = new Date(today.setDate(today.getDate() - 1));
    setStartDate(yesterday);
  }, []);

  useEffect(() => {
    let code = localStorage.getItem("hotel_code");
    let hotel_code = "Bgrimm";
    const siteMap: any = {
      DAIC: "DaikinCCC",
      BGM: "Bgrimm",
    };
    if (code) {
      hotel_code = siteMap[code];
    }
    if (startDate) {
      let stringDatetime =
        startDate.getFullYear().toString() +
        (startDate.getMonth() < 10 ? "0" + (startDate.getMonth() + 1) : startDate.getMonth() + 1).toString() +
        (startDate.getDate() < 10 ? "0" + startDate.getDate() : startDate.getDate()).toString();
      setUrl(`https://altoautomate.blob.core.windows.net/automation/${hotel_code}_report_` + stringDatetime + ".html");
    }
  }, [startDate]);

  return (
    <>
      <PageContainer>
        {buildingCode && buildingCode === "BGM" && (
          <>
            <Button active>
              <a href={"https://bgrimmds.altotech.net/"} target="_blank" style={{ color: "white" }}>
                VIEW DASHBOARD
              </a>
            </Button>
            <br />
            <SingleDatePicker startDate={startDate} setStartDate={setStartDate} />
            <Iframe src={url} onLoad={hideSpinner} />
          </>
        )}
        {buildingCode && buildingCode === "DAIC" && (
          <>
            <DaikinSettingTypeSection selectedType={selectedType} handleSelectType={handleSelectType} />
            <br />
            <div>
              <Iframe src={"https://daikinds.altotech.net/apps/" + selectedType} onLoad={hideSpinner} />
            </div>
          </>
        )}
        {buildingCode && buildingCode === "SHE" && (
          <>
            <ShellSettingTypeSection selectedType={selectedType} handleSelectType={handleSelectType} buildingCode={buildingCode} />
            <br />
            <div>
              <Iframe src={"https://shelldata.altotech.net/apps/" + selectedType} onLoad={hideSpinner} />
            </div>
          </>
        )}
      </PageContainer>
    </>
  );
};

export default AnalyticPage;
