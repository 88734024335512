import { useEffect, useState } from "react";
import Modal from "react-modal";
import tw from "twin.macro";
import styled from "styled-components";
import { useForm, FieldError, Controller } from "react-hook-form";
import DeviceConfig from "configs/device_config.json";
import Select from "react-select";
import "./EditDeviceModal.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    border: "0.5px solid #f3f3f3",
    maxHeight: "95vh",
    padding: "34px 42px",
    width: "652px",
  },
  overlay: {
    backgroundColor: "#00000066",
    zIndex: 99999,
  },
};

const selectCustomStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: "8px",
  }),
  menu: (provided: any) => ({
    ...provided,
    height: "150px",
    position: "absolute",
    overflow: "scroll",
  }),
};

const Title = tw.h3`sticky top-0 pb-4 text-2xl font-bold mb-0`;

const SubdeviceTitle = tw.h4`pt-1 pb-0 text-xl font-bold mb-0`;

interface FieldErrorMessageProps {
  error: FieldError | undefined;
}

function FieldErrorMessage({ error }: FieldErrorMessageProps) {
  if (!error) return null;
  return <span tw="text-red-700">{error.message}</span>;
}

const InputContainer = tw.div`
  flex flex-col gap-1
`;

const Label = tw.label`
  text-base text-black1
`;

const WrapButton = tw.div`col-span-2 flex items-center justify-end gap-4`;

const Input = styled.input`
  ${tw`
  h-10 px-4 rounded-lg 
  border border-primary hover:border-primary-dark
  outline-none
  transition
  border-solid
  focus:(ring-primary-light ring)
  placeholder:(text-primary)
  disabled:(bg-[#F3F4FA] border-none text-black2 placeholder:text-black2)
  [&[data-view-mode=true]]:(text-primary-dark bg-transparent)
  `}
`;

const Button = styled.button`
  ${tw`
  h-10 px-4 rounded-lg font-medium transition
  flex items-center justify-center gap-2
  border border-primary border-solid
  cursor-pointer
  [&:not([data-outlined=true])]:(
    bg-primary text-white
    hover:(shadow) active:(shadow-none)
  )
  [&[data-outlined=true]]:(
    bg-transparent text-primary
    border-primary
    hover:(bg-primary/10)
    active:(bg-primary/20)
  )
  `}
`;

const Form = styled.form(() => [tw`flex-1 grid grid-cols-2 gap-x-10 gap-y-4 justify-end`]);

export const EditDeviceModal = ({ isOpen, onClose, onEditDevice, hotelCode }: any) => {
  const AgentMap = DeviceConfig;
  const [agentOptions, setAgentOptions] = useState<any[]>([]);
  const [loading] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const onSubmit = (data: any) => {
    // const payload = {
    //   device_id: data.device_id,
    //   device_name: data.device_name,
    //   room_name: data.room_name,
    //   agent_id: data.agent_id,
    //   gateway_name: "daikincccdev",
    //   public_space_name: "",
    //   subdevices: {
    //     [data.subdevice_number]: {
    //       nickname: data.subdevice_name,
    //       schema: [data.schema],
    //     },
    //   },
    // };
  };

  useEffect(() => {
    setAgentOptions(
      Object.keys(AgentMap).map((item: any) => ({
        label: item,
        value: item,
      }))
    );
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles} contentLabel="Edit modal">
        <Title>Add Device</Title>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputContainer>
            <Label>Device ID</Label>
            <Input
              {...register("device_id", { required: "Device id is required" })}
              placeholder="Enter Device ID"
              disabled={loading}
              // data-view-mode={isView}
            />
            <FieldErrorMessage error={errors.device_id} />
          </InputContainer>
          <InputContainer>
            <Label>Device Name</Label>
            <Input
              {...register("device_name", { required: "Device name is required" })}
              placeholder="Enter Device Name"
              disabled={loading}
              // data-view-mode={isView}
            />
            <FieldErrorMessage error={errors.device_name} />
          </InputContainer>
          <InputContainer>
            <Label>Device ID</Label>
            <Input
              {...register("device_id", { required: "Device id is required" })}
              placeholder="Enter Device Name"
              disabled={loading}
              // data-view-mode={isView}
            />
            <FieldErrorMessage error={errors.device_id} />
          </InputContainer>
          <InputContainer>
            <Label>Room ID</Label>
            <Input
              {...register("room_id", { required: "Room id is required" })}
              placeholder="Enter Room ID"
              disabled={loading}
              // data-view-mode={isView}
            />
            <FieldErrorMessage error={errors.room_id} />
          </InputContainer>
          <SubdeviceTitle>Subdevices</SubdeviceTitle>
          <br />

          <InputContainer>
            <Label>Subdevice Name</Label>
            <Input
              {...register(`subdevice_name`, { required: "Subdevice name is required" })}
              placeholder="Enter Subdevice Name"
              disabled={loading}
              // data-view-mode={isView}
            />
            <FieldErrorMessage error={errors.nickname} />
          </InputContainer>
          <InputContainer>
            <Label>Schema</Label>
            {/* <Select options={agentOptions} {...register(`schema`, { required: "Room id is required" })} /> */}
            <Controller
              name="schema"
              control={control}
              render={({ field }) => <Select styles={selectCustomStyles} {...field} options={agentOptions} />}
            />
            <FieldErrorMessage error={errors.schema} />
          </InputContainer>
          <InputContainer>
            <Label>Subdevice Number</Label>
            <Input
              {...register(`subdevice_number`, { required: "Subdevice number is required" })}
              placeholder="Enter Subdevice Number"
              disabled={loading}
              // data-view-mode={isView}
            />
            <FieldErrorMessage error={errors.device_id} />
          </InputContainer>
          <InputContainer>
            <Label>Room Name</Label>
            <Input
              {...register(`room_name`, { required: "Room Name is required" })}
              placeholder="Enter Name"
              disabled={loading}
              // data-view-mode={isView}
            />
            <FieldErrorMessage error={errors.room_name} />
          </InputContainer>
          {/* --------------- TODO Enable this to add more device --------------- */}
          {/* <WrapAddButton>
            <PlusDevice onClick={()=>addSubdevice()}>
              <FiPlus size={20} color={'white'}/>
            </PlusDevice>
          </WrapAddButton> */}
          {/* ------------------------------------------------------------------- */}
          <WrapButton>
            <Button type="submit">Add</Button>
            <Button onClick={onClose} data-outlined>
              Cancel
            </Button>
          </WrapButton>
        </Form>
      </Modal>{" "}
    </>
  );
};
