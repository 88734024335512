import { useState, useEffect, ReactNode } from "react";
import { Row, Col } from "antd";
import Down from "assets/pages/Dashboard/Down.svg";
import Up from "assets/pages/Dashboard/Up.svg";
import { IconType } from "react-icons/lib";
import { FiArrowUpCircle } from "react-icons/fi";
import { BgrimmBuildingMain } from "./BgrimmBuildingMain";
import { CCC } from "./Models/CCC";
import { ContentBody, FloorModelContainer, ImageContainer, Line, Subtitle, UpdatedAt, Value, ViewGraphButton, UpDownImage } from "./Components";
import { Tooltip } from "components/Common/Tooltip/Tooltip";
import { DeviceModal } from "components/DeviceModal/DeviceModal";
import { MintTower } from "./Models/MintTower";
import { sort } from "utils/sort";
import { ShellRama2 } from "./Models/ShellRama2";
import { LotusGoFresh } from "./Models/LotusGoFresh";

type ArrowMapperType = {
  [locationName: string]: {
    icon: IconType;
    top: string;
    left: string;
    startAngle: number;
    fill: string;
    stroke: string;
  };
};

const ArrowMapper: ArrowMapperType = {
  "Load Fl.1": {
    icon: FiArrowUpCircle,
    top: "69.7%",
    left: "73%",
    startAngle: 52,
    fill: "#20A696",
    stroke: "#F3F3F3'",
  },
  "Load Fl.2": {
    icon: FiArrowUpCircle,
    top: "58.4%",
    left: "73%",
    startAngle: 52,
    fill: "#20A696",
    stroke: "#F3F3F3'",
  },
  "Buy-in Power": {
    icon: FiArrowUpCircle,
    top: "71.5%",
    left: "16%",
    startAngle: 48,
    fill: "#788796",
    stroke: "#F3F3F3'",
  },
  "Output Power": {
    icon: FiArrowUpCircle,
    top: "51%",
    left: "13.5%",
    startAngle: 228,
    fill: "#FF7A00",
    stroke: "#F3F3F3'",
  },
};

const TooltipContent = ({ title, items, updatedAt, value, unit, type, handleViewGraph, modalType }: any) => {
  const [hidden, setHidden] = useState(true);
  return (
    <>
      <ContentBody onClick={() => setHidden(!hidden)}>
        <Row>
          <Col xs={24}>
            <Subtitle>{title}</Subtitle>
            <Value>
              {type === "camera" && value ? Number(value)?.toFixed(0) : Number(value)?.toFixed(2)}
              <span style={{ marginLeft: "3px" }}>{unit}</span>
            </Value>
          </Col>
          <UpDownImage src={hidden ? Down : Up} />
        </Row>
        {!hidden && <Line />}
        {!hidden &&
          items &&
          Object.values(items)
            .sort((a: any, b: any) => sort(a.title, b.title))
            .map((itemsValue: any) => (
              <Row key={itemsValue.title}>
                <Col xs={24}>
                  <Subtitle>{itemsValue.title}</Subtitle>
                  <Value>
                    {itemsValue.value}
                    <span style={{ marginLeft: "3px" }}>{itemsValue.unit}</span>
                  </Value>
                </Col>
              </Row>
            ))}
        {!hidden && (
          <Row style={{ justifyContent: "center" }}>
            <ViewGraphButton onClick={() => handleViewGraph(modalType)}>View Graph</ViewGraphButton>
          </Row>
        )}
        <Row>
          <UpdatedAt>{updatedAt}</UpdatedAt>
        </Row>
      </ContentBody>
    </>
  );
};

const GenerateArrow = ({ top, left, startAngle, reverse, color }: any) => {
  const [angle, setAngle] = useState<any>();

  useEffect(() => {
    if (reverse) {
      setAngle(startAngle - 180);
    } else {
      setAngle(startAngle);
    }
  }, [reverse]);

  return (
    <>
      {angle && (
        <FiArrowUpCircle
          style={{
            width: "25px",
            height: "25px",
            position: "absolute",
            top: `${top}%`,
            left: `${left}%`,
            transform: `rotate(${startAngle}deg)`,
            fill: color,
            stroke: "#F3F3F3",
          }}
        />
      )}
    </>
  );
};

const TooltipItem = ({ handleViewGraph, dashboardData2 }: any) => {
  const [consumption, setConsumption] = useState(0);
  const [generation, setGeneration] = useState(0);

  const getSum = (data: any, fixedNumber?: number) => {
    return Object.entries(data).forEach(([tooltipKey, tooltipValue]: any) => {
      data[tooltipKey]["value"] = Object.values(tooltipValue["items"]).reduce((a: number, b: any) => a + b.value, 0);
    });
  };

  const validateValue = (deviceType: string, currentValue: number, consumptionNumber: number, generationNumber: number) => {
    const validateGrid = (currentValue: number, consumptionNumber: number, generationNumber: number) => {
      if (generationNumber > consumptionNumber) {
        return 0;
      } else {
        return consumptionNumber;
      }
    };

    const validateGeneration = (currentValue: number, consumptionNumber: number, generationNumber: number) => {
      if (generationNumber > consumptionNumber) {
        return consumptionNumber;
      } else {
        return currentValue;
      }
    };

    return deviceType === "grid"
      ? validateGrid(currentValue, consumptionNumber, generationNumber)
      : deviceType === "generation"
      ? validateGeneration(currentValue, consumptionNumber, generationNumber)
      : currentValue;
  };

  const balancePower = (data: any) => {
    if (data) {
      getSum(data);
      Object.values(data).forEach((tooltipData: any) => {
        if (tooltipData.type === "consumption") {
          setConsumption((prev) => prev + tooltipData.value);
        } else if (tooltipData.type === "generation") {
          setGeneration((prev) => prev + tooltipData.value);
        }
      });
    }
  };

  useEffect(() => {
    if (dashboardData2) {
      setConsumption(0);
      balancePower(dashboardData2);
    }
  }, [dashboardData2]);

  return (
    <>
      {dashboardData2 &&
        Object.entries(dashboardData2).map(([tooltipKey, tooltipValue]: any) => (
          <div key={tooltipKey}>
            <Tooltip
              visible
              position={tooltipValue.position}
              left={tooltipValue.location.left}
              top={tooltipValue.location.top}
              styles={tooltipValue.styles}
              children={
                <>
                  <TooltipContent
                    title={tooltipValue.title}
                    items={tooltipValue.items}
                    value={validateValue(tooltipValue.type, tooltipValue.value, consumption, generation)}
                    unit={tooltipValue.unit}
                    type={tooltipValue.type}
                    modalType={tooltipValue.key}
                    updatedAt={tooltipValue.updated_at}
                    handleViewGraph={handleViewGraph}
                  />
                </>
              }
            />
            {tooltipValue.arrow && ArrowMapper[tooltipValue.title] && (
              <GenerateArrow
                top={tooltipValue.arrow["top"]}
                left={tooltipValue.arrow["left"]}
                startAngle={ArrowMapper[tooltipValue.title]["startAngle"]}
                reverse={true}
                color={ArrowMapper[tooltipValue.title]["fill"]}
              />
            )}
          </div>
        ))}
    </>
  );
};

type ModelMapProps = {
  [hotelCode: string]: ReactNode;
};

const ModelMap = ({ handleOpenModal }: any): ModelMapProps => {
  return {
    BGM: <BgrimmBuildingMain handleOpenModal={handleOpenModal} />,
    DAIC: <CCC handleOpenModal={handleOpenModal} />,
    MTW: <MintTower />,
    SHE: <ShellRama2 />,
    LTLS: <LotusGoFresh />,
  };
};

export const FloorModel = ({
  dashboardData2,
  handleOpenModal,
  handleCloseModal,
  modalIsOpen,
  selectedTabName,
  tabNameList,
  handleSelectTabName,
  selectedDevice,
  command,
  handleChangeCommand,
  handleViewGraph,
  hotel_code,
  allIotStatusFb,
}: any) => {
  return (
    <FloorModelContainer>
      <ImageContainer>
        {ModelMap({ handleOpenModal })[hotel_code]}
        <TooltipItem handleViewGraph={handleViewGraph} dashboardData2={dashboardData2} />
      </ImageContainer>
      <DeviceModal
        modalIsOpen={modalIsOpen}
        handleCloseModal={handleCloseModal}
        selectedTabName={selectedTabName}
        tabNameList={tabNameList}
        handleSelectTabName={handleSelectTabName}
        deviceData={selectedDevice}
        command={command}
        handleChangeCommand={handleChangeCommand}
        allIotStatusFb={allIotStatusFb}
      />
    </FloorModelContainer>
  );
};
