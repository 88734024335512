import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import styled from "styled-components";
import CCCLogo from "assets/common/logo/ccc_logo.png";
import AltoLogo from "assets/common/logo/AltoLogo_128x128.jpg";
import AltoxWinner from "assets/common/logo/AltoxWinner.png";
import building_config from "configs/building_config.json";

const NavContainer = styled.div`
  width: 64px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-top: 102px;
  background-color: white;
  position: sticky;
  top: 67px;
`;

const TabContainer = styled.div<any>`
  position: relative;
  width: 100%;
  height: 64px;
  padding-left: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.active ? `linear-gradient(248.63deg, #EFF2F7 43.56%, rgba(192, 232, 255, 0.11) 96.67%)` : `white`)};
  cursor: pointer;
  :hover {
    background: linear-gradient(248.63deg, #eff2f7 43.56%, rgba(227, 245, 255, 0.11) 96.67%);
  }
`;

const Stripe = styled.div`
  position: absolute;
  background-color: #0e7ee4;
  width: 3px;
  height: 100%;
  left: 0px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

const TabName = styled.p<any>`
  margin: 0px;
  width: 100%;
  height: fit-content;
  flex-wrap: wrap;
  font-size: 9px;
  font-weight: 500;
  text-align: center;
  margin-top: 4px;
  color: ${(props) => (props.active ? "#0E7EE4" : "#a0a0a0")};
`;

const IconContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 64px;
  height: 68px;
`;

const IconImage = styled.img`
  width: 100%;
  height: 100%;
  cursor: pointer;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.055); */
`;

type SidebarProps = {
  routes: any[];
  selectedRoute: string;
  handleSelectTab: (tab: string) => void;
};

type TabProps = {
  iconImage: any;
  tabName: string;
  active: boolean;
  handleSelectTab: (tab: string) => void;
};

const logoMap: { [hotel_code: string]: any } = {
  // BGM: CCCLogo,
  // BGM: BgrimmLogo,
  DAIC: CCCLogo,
  SHE: AltoxWinner,
};

const buildingConfig: { [buildingCode: string]: { tabs: any[] } } = building_config;

const Tab = ({ iconImage, tabName, active, handleSelectTab }: TabProps) => {
  return (
    <TabContainer active={active} onClick={() => handleSelectTab(tabName)}>
      {active && <Stripe />}
      <Icon src={iconImage} />
      <TabName active={active}>{tabName}</TabName>
    </TabContainer>
  );
};

const availableTabs: { [hotel_code: string]: string[] } = {
  BGM: ["Overview", "Floor Usage", "Energy", "Analytic", "Report", "Automation", "Document", "Store", "Setting", "Help & Support"],
  DAIC: ["Overview", "Floor Usage", "Energy", "Analytic", "Report", "Automation", "Document", "Store", "Setting"],
  MTW: ["Overview", "Floor Usage"],
  SHE: ["Overview", "Floor Usage", "Analytic", "Maintenance", "Automation", "Document", "Setting", "Help & Support"],
};

const Sidebar = ({ routes, selectedRoute, handleSelectTab }: SidebarProps) => {
  let hotel_code = "BGM";
  let code = localStorage.getItem("hotel_code");
  if (code) {
    hotel_code = code;
  }

  return (
    <>
      <NavContainer>
        <IconContainer>
          <IconImage src={hotel_code && logoMap[hotel_code] ? logoMap[hotel_code] : AltoLogo} onClick={() => (window.location.href = "/")} />
        </IconContainer>
        <SimpleBar style={{ maxHeight: "calc(100vh - 102px)" }}>
          {routes.map((route) => {
            if (buildingConfig?.[hotel_code]?.["tabs"]?.includes(route.name))
              return (
                <Tab
                  key={route.name}
                  iconImage={route.icon}
                  tabName={route.name}
                  active={selectedRoute.toLowerCase() === route.name.replace(" ", "").toLowerCase()}
                  handleSelectTab={() => handleSelectTab(route.path)}
                />
              );
            else return null;
          })}
        </SimpleBar>
      </NavContainer>
    </>
  );
};

export default Sidebar;
