import { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import CloseIcon from "assets/pages/Dashboard/Close.svg";
import OpenIcon from "assets/pages/Dashboard/Open.svg";
import SelfDrivingLogo from "assets/pages/Dashboard/SelfDrivingLogo.png";
import WebManualLogo from "assets/pages/Dashboard/WebManualLogo.png";
import { FirebaseContext } from "../../../firebase";
import { global_rl_output } from "MockData/FirebasePath";
import { postC2D } from "services/api.service";
import { Spin } from "antd";
import { useBuildingContext } from "layouts/BuildingProvider";
import { convertTimestampToDatetime } from "utils/convertDatetime";

const Container = styled.div<any>`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 999;
`;

const SubContainer = styled.div`
  top: 0px;
  left: 0px;
  width: 360px;
  height: 88px;
  background: rgba(6, 91, 169, 0.2);
  box-shadow: 2px 4px 20px rgba(161, 183, 205, 0.2);
  backdrop-filter: blur(40px);
  border-radius: 10px;
  overflow: hidden;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
`;

const ToggleButtonBox = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  bottom: 5px;
  right: 5px;
  cursor: pointer;
  background: rgba(14, 126, 228, 0.3);
  backdrop-filter: blur(3.81078px);
`;

const ToggleImage = styled.img`
  width: 100%;
  height: 100%;
`;

const StatusContainer = styled.div`
  width: 180px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 8px;
`;

const Icon = styled.img<any>`
  height: 64px;
  width: 64px;
  opacity: ${(props) => (props.fade ? 0.5 : 1)};
  cursor: pointer;
`;

const StatusBlock = styled.div`
  width: 86px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
`;

const ModeIsActive = styled.div`
  width: 100%;
  height: 18px;
  display: flex;
  color: #45c84d;
  font-size: 12px;
  align-items: center;
`;

const Dot = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  background-color: #45c84d;
  margin-right: 5px;
`;

const ModeText = styled.p<any>`
  font-size: 18px;
  font-weight: 600;
  color: ${(props) => (props.active ? "#065BA9" : "white")};
  line-height: 1rem;
  cursor: pointer;
`;

const IsLoading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(3.81078px);
`;

const SelfDriving = ({ handleClickSelfDriving, isSelfDriving, type }: any) => {
  return (
    <StatusContainer>
      <Icon
        src={type === "selfDriving" ? SelfDrivingLogo : WebManualLogo}
        fade={!isSelfDriving}
        onClick={() => handleClickSelfDriving(!isSelfDriving)}
      />
      <StatusBlock>
        <ModeIsActive>
          {isSelfDriving && (
            <>
              <Dot /> Active
            </>
          )}
        </ModeIsActive>
        <ModeText active={isSelfDriving} onClick={() => handleClickSelfDriving(!isSelfDriving)}>
          {type === "selfDriving" ? "Self-driving" : "Web-manual"}
        </ModeText>
      </StatusBlock>
    </StatusContainer>
  );
};

export const SelfDrivingTab = ({ page }: any) => {
  const firebase = useContext<any>(FirebaseContext);
  const { buildingCode } = useBuildingContext();
  const [selfDriving, setSelfDriving] = useState<boolean | undefined>(undefined);
  const [sourceUsername, setSourceUsername] = useState<string | undefined>(undefined);
  const [lastControlled, setLastControlled] = useState<number | undefined>(undefined);
  const [isOpened, setIsOpened] = useState(false);
  const AnimatedContainer = animated(SubContainer);
  const AnimatedWrapper = animated(Wrapper);
  const ContainerStyle = useSpring({
    from: { width: "180px" },
    width: isOpened ? "360px" : "180px",
  });

  const WrapperStyle = useSpring({
    from: { transform: "translateX(0px)" },
    width: selfDriving ? "translateX(0px)" : "translateX(-170px)",
  });

  const handleClickSelfDriving = () => {
    if (selfDriving !== undefined) {
      if (isOpened) {
        setSelfDriving(!selfDriving);
        // postC2D({
        //   device_id: "global_rl_output",
        //   command: { global_output_enable: !selfDriving },
        //   schema: "rein_global_output",
        // });
      }
    }
    setIsOpened(!isOpened);
  };

  const fetchGlobalRlOutput = (didMount: boolean) => {
    if (didMount) {
      firebase.db.ref(global_rl_output).off("value");
    } else {
      firebase.db.ref(global_rl_output).on("value", function (snap: { val: () => any }) {
        if (snap) {
          let data = snap.val();
          if (data) {
            setSelfDriving(data.global_output_enable);
            setSourceUsername(data?.source_username);
            setLastControlled(data?.unix_timestamp);
          }
        }
      });
    }
  };

  useEffect(() => {
    fetchGlobalRlOutput(false);
    return () => {
      fetchGlobalRlOutput(true);
    };
    // eslint-disable-next-line
  }, []);
  const isSelfDriving = useMemo(() => selfDriving, [selfDriving]);

  return (
    <Container style={page === "overview" ? { top: "0px", left: "0px" } : {}}>
      <AnimatedContainer style={ContainerStyle}>
        {buildingCode === "DAIC" ? (
          <>
            <AnimatedWrapper style={WrapperStyle}>
              {(isOpened || isSelfDriving) && (
                <SelfDriving handleClickSelfDriving={handleClickSelfDriving} isSelfDriving={isSelfDriving} type={"selfDriving"} />
              )}
              {(isOpened || !isSelfDriving) && (
                <SelfDriving handleClickSelfDriving={handleClickSelfDriving} isSelfDriving={!isSelfDriving} type={"webManual"} />
              )}
            </AnimatedWrapper>
            <ToggleButtonBox onClick={() => setIsOpened(!isOpened)}>
              <ToggleImage src={isOpened ? CloseIcon : OpenIcon} />
            </ToggleButtonBox>
            {(selfDriving === undefined || selfDriving === null) && (
              <IsLoading>
                <Spin />
              </IsLoading>
            )}
          </>
        ) : (
          <>
            <AnimatedWrapper style={WrapperStyle}>
              {(isOpened || isSelfDriving) && (
                <SelfDriving isSelfDriving={isSelfDriving} type={"selfDriving"} handleClickSelfDriving={handleClickSelfDriving} />
              )}
              {(isOpened || !isSelfDriving) && (
                <SelfDriving isSelfDriving={!isSelfDriving} type={"webManual"} handleClickSelfDriving={handleClickSelfDriving} />
              )}
            </AnimatedWrapper>
            <ToggleButtonBox onClick={() => setIsOpened(!isOpened)}>
              <ToggleImage src={isOpened ? CloseIcon : OpenIcon} />
            </ToggleButtonBox>
          </>
        )}
      </AnimatedContainer>
      <p
        style={{
          position: "absolute",
          bottom: "2px",
          left: "82px",
          fontSize: "8px",
          margin: "4px 0px 0px 0px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span>By: {sourceUsername ? sourceUsername : "-"}</span>
        <span>{lastControlled && convertTimestampToDatetime(lastControlled)["ago"]}</span>
      </p>
    </Container>
  );
};
