import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { useFirebase } from "utils/hooks/useFirebase";
import { Spin } from "antd";
import { convertTimestampToDatetime } from "utils/convertDatetime";
import { BsLightningFill } from "react-icons/bs";

const RoomColorMap = {
  VC: "#59D1CE",
  VD: "#788796",
  OC: "#0E7EE4",
  OD: "#C0E8FF",
};

interface RoomsTabProps {
  roomsData: any[];
  handleSelectRoom: (roomNumber: string) => void;
}

interface RoomProps {
  status: "VC" | "VD" | "OC" | "OD";
  number: string;
  handleSelectRoom: (roomNumber: string) => void;
  meterList: any[];
}

interface RoomStatusProps {
  status: "VC" | "VD" | "OC" | "OD";
}

const RoomsWrapper = styled.div`
  ${tw`grid md:grid-cols-6 lg:grid-cols-6 xl:grid-cols-6 gap-x-9 gap-y-5 px-4 overflow-scroll`}
`;

const RoomContainer = styled.div(() => [tw`flex flex-col w-full col-span-2 md:col-span-1`]);

const RoomStatus = styled.div<RoomStatusProps>`
  ${tw`relative w-full h-[76px] flex flex-col text-white mb-1 py-1 px-2`}
  ${({ status }) => `background: ${RoomColorMap[status] ? RoomColorMap[status] : "#BDBEC8"}`}
`;

const RoomNumber = styled.p(() => [tw`w-full text-center text-sm text-altoDark-medium font-medium select-none`]);

const UpdatedAt = styled.p(() => [tw`w-full text-left text-xs text-altoWhite font-medium select-none`]);

const MeterId = styled.p(() => [tw`mb-0 select-none truncate font-bold`]);

const MeterValue = styled.span(() => [tw`truncate flex`]);

const SpinnContainer = styled.div(() => [tw`col-span-8 flex justify-center h-40 items-center`]);

const Room = ({ status, number, handleSelectRoom, meterList }: RoomProps) => {
  const { data } = useFirebase({ deviceList: meterList });

  return (
    // <RoomContainer onClick={() => handleSelectRoom(number)}>
    <RoomContainer>
      <RoomStatus status={status}>
        {meterList.map((meterData: any) => {
          const subdev = data?.[meterData.device_id]?.electric?.subdev_3 ? "subdev_3" : "subdev_0";
          const energy = data?.[meterData.device_id]?.electric?.[subdev]?.energy.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          return (
            <>
              <MeterId key={meterData.device_id}>{meterData.device_name}</MeterId>
              <MeterValue>
                <BsLightningFill size={16} style={{ margin: "3px 4px 0px 0px" }} /> {energy ? energy + " kWh" : "No Data"}
              </MeterValue>
              <UpdatedAt>{convertTimestampToDatetime(data?.[meterData.device_id]?.electric?.[subdev]?.unix_timestamp).ago}</UpdatedAt>
            </>
          );
        })}
      </RoomStatus>
      <RoomNumber>{number}</RoomNumber>
    </RoomContainer>
  );
};

export const RoomsTab = ({ roomsData, handleSelectRoom }: RoomsTabProps) => {
  return (
    <RoomsWrapper>
      {!roomsData && (
        <SpinnContainer>
          <Spin />
        </SpinnContainer>
      )}
      {roomsData && roomsData.length === 0 && <p>No room</p>}
      {roomsData &&
        roomsData.map((roomInfo) => (
          <>
            <Room
              key={roomInfo.id}
              status={"VD"}
              number={roomInfo.room_name}
              handleSelectRoom={() => handleSelectRoom(roomInfo)}
              meterList={roomInfo?.devices.filter((deviceInfo: any) => deviceInfo.agent_id === "modbus")}
            />
          </>
        ))}
    </RoomsWrapper>
  );
};
