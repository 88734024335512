import { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { AccumulateSaving } from "./AccumulateSaving";
import { TotalSaving } from "./TotalSaving";
import { TotalEnergyConsumption } from "./TotalEnergyConsumption";
import { SelfDrivingTimeline } from "./SelfDrivingTimeline";
import { Serie } from "@nivo/line";
import { getDeviceByFloor, getSelfDrivingAction } from "services/api.service";
import { convertDatetimeToStrDate } from "utils/convertDatetime";
import { responseErrorHandler } from "services/prepareAxios";
// import { SelfDrivingGraph } from "./SelfDrivingGraph";

const SelfDrivingMockData = [
  {
    id: "baseline",
    data: [
      { x: "2021-08-22 00:00:00", y: "1" },
      { x: "2021-08-23 00:00:00", y: "5" },
      { x: "2021-08-24 00:00:00", y: "4" },
      { x: "2021-08-25 00:00:00", y: "7" },
    ],
  },
  {
    id: "actual",
    data: [
      { x: "2021-08-22 00:00:00", y: "3" },
      { x: "2021-08-23 00:00:00", y: "2" },
      { x: "2021-08-24 00:00:00", y: "6" },
      { x: "2021-08-25 00:00:00", y: "8" },
    ],
  },
];

const AccumulateSavingMockData = [
  {
    id: "accumulate",
    data: [
      { x: "2022-03-15 00:00:00", y: "2317.0" },
      { x: "2022-04-15 00:00:00", y: "2753.9" },
      { x: "2022-05-15 00:00:00", y: "3127.0" },
      { x: "2022-06-15 00:00:00", y: "3572.1" },
      { x: "2022-07-15 00:00:00", y: "4131.72" },
    ],
  },
];

export const RealtimeSection = () => {
  const [selfDrivingData, setSelfDrivingData] = useState<Serie[]>();
  const [accumulateSavingData, setAccumulateSavingData] = useState<Serie[]>();

  const [startDateTimeline, setStartDateTimeline] = useState<Date>(new Date());
  const [endDateTimeline, setEndDateTimeline] = useState<Date>(new Date());
  const [selectedTimelineTabName, setSelectedTimelineTabName] = useState<string>("15hours");

  const [selectedValue, setSelectedValue] = useState<number>(0);
  const [xPosition, setXPosition] = useState(0);
  const [deviceList, setDeviceList] = useState<any>();
  const [visibleDeviceObject, setVisibleDeviceObject] = useState<any>();
  const [historyDeviceData, setHistoryDeviceData] = useState<any>();

  const handleSelectTimelineTabName = (tabName: string) => {
    setSelectedTimelineTabName(tabName);
  };

  const selectTime = (time: number) => {
    setSelectedValue(time);
  };

  const fetchAllDevices = async (floor: number) => {
    try {
      setDeviceList([]);
      let response: any[] = [];
      let visibleDevice: any = {};
      const results = await getDeviceByFloor(floor);
      response = results.data.floor_info[0]["devices"].map((item: any) => ({
        ...item,
        ui_location: item.ui_location.split(","),
      }));
      setDeviceList((prev: any) => ({ ...prev, [floor]: response }));
      response.forEach((deviceObject: any) => {
        visibleDevice[deviceObject.agent_id] = true;
      });
      setVisibleDeviceObject(visibleDevice);
    } catch (err) {
      responseErrorHandler(err);
    }
  };

  const fetchSelfDrivingAction = async () => {
    setHistoryDeviceData(undefined);
    let starttime = convertDatetimeToStrDate(startDateTimeline);
    let endtime = convertDatetimeToStrDate(startDateTimeline, 1);
    try {
      const response: any = await getSelfDrivingAction(starttime, endtime, "Garden Wing");
      setHistoryDeviceData(response?.data?.rl_actions?.fcu);
    } catch (err) {
      responseErrorHandler(err);
    }
  };

  // const fetchFloorEnergy = async (siteName: string, selectedTab: string) => {
  //   try {
  //     const dailyRequest = getEnergyConsumptionByFloor({
  //       type: "floor_energy",
  //       building_name: siteName,
  //       starttime: convertDatetimeToStrDate(starttime, -6),
  //       endtime: convertDatetimeToStrDate(endtime),
  //       floor_name: FloorMap[selectedTab],
  //       sample: "daily",
  //     });
  //     const monthlyRequest = getEnergyConsumptionByFloor({
  //       type: "floor_energy",
  //       building_name: siteName,
  //       starttime: convertDatetimeToStrMonth(starttime, -2),
  //       endtime: convertDatetimeToStrMonth(endtime),
  //       floor_name: FloorMap[selectedTab],
  //       sample: "monthly",
  //     });
  //     const [dailyResponse, monthlyResponse] = await Promise.all([dailyRequest, monthlyRequest]);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   const hotel_code = localStorage.getItem("hotel_code");
  //   const buildingMap: any = {
  //     DAIC: "Daikin CCC",
  //     BGM: "Garden Wing",
  //     MTW: "Mint Tower",
  //   };
  //   if (hotel_code) {
  //     fetchFloorEnergy(buildingMap[hotel_code], selectedTab);
  //     setSiteName(buildingMap[hotel_code]);
  //   } else {
  //     setSiteName("AltoTech");
  //   }
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    setSelfDrivingData(SelfDrivingMockData);
    setAccumulateSavingData(AccumulateSavingMockData);
    fetchAllDevices(1);
    fetchAllDevices(2);
    fetchSelfDrivingAction();
  }, []);

  useEffect(() => {
    // fetchSelfDrivingAction();
  }, [startDateTimeline]);

  return (
    <>
      <Row gutter={[18, 18]}>
        <Col span={8}>
          <AccumulateSaving accumulateSavingData={accumulateSavingData} />
        </Col>
        <Col span={16}>
          <Row gutter={[18, 18]}>
            <Col xs={24}>
              <TotalSaving />
            </Col>
            <Col xs={24}>
              <TotalEnergyConsumption />
            </Col>
          </Row>
        </Col>
        {/* <Col span={24}>
          <SelfDrivingGraph
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selectedTabName={selectedTabName}
            handleSelectTabName={handleSelectTabName}
            selfDrivingData={selfDrivingData}
          />
        </Col> */}
        <Col span={24}>
          <SelfDrivingTimeline
            startDate={startDateTimeline}
            stringStartDate={convertDatetimeToStrDate(startDateTimeline)}
            setStartDate={setStartDateTimeline}
            endDate={endDateTimeline}
            setEndDate={setEndDateTimeline}
            selectedTabName={selectedTimelineTabName}
            handleSelectTabName={handleSelectTimelineTabName}
            selectedValue={selectedValue}
            selectTime={selectTime}
            xPosition={xPosition}
            deviceList={deviceList}
            historyDeviceData={historyDeviceData}
            visibleDeviceObject={visibleDeviceObject}
          />
        </Col>
      </Row>
    </>
  );
};
