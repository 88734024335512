import { iot_devices_path } from 'MockData/FirebasePath';
import { useState, useContext, useEffect } from 'react'
import { FirebaseContext } from "../../firebase";

export interface useFirebaseInterface {
  data: null | any
}

interface useFirebaseProps {
  deviceList: any[]
}

export function useFirebase({ deviceList }: useFirebaseProps): useFirebaseInterface {
  const firebase = useContext<any>(FirebaseContext);
  const [data, setData] = useState({})

  const fetchData = (didMount: boolean) => {
    if (didMount) {
      deviceList.forEach(async (deviceData) => {
        firebase.db.ref(deviceData.device_id).off("value");
      })
    } else {
      const path = iot_devices_path
      deviceList.forEach(async (deviceData) => {
        firebase.db.ref(path + '/' + deviceData.device_id).on("value", function (snap: { val: () => any }) {
          if (snap === null) return
          setData((prev) => {
            const data = snap.val()
            return { ...prev, [deviceData.device_id]: data }
          })
        });
      })
    };
  }

  useEffect(() => {
    fetchData(false)
    return () => fetchData(true)
    // avoid error when deviceList is empty
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { data }
}
