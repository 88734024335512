import styled from 'styled-components';
import {
  BlockContainer,
  BlockHeader4,
  BlockValue,
  SubBlockHeader,
} from 'components/Common/components';
import EnergySavingIcon from 'assets/pages/Dashboard/AggregatedEnergyIcon.svg';
import { Row, Col } from 'antd';

const Container = styled(BlockContainer)`
  box-shadow: 0px 2px 8px rgba(145, 158, 171, 0.25);
  flex-direction: row;
  height: 100%;
`;

const Image = styled.img`
  height: 40px;
  width: 40px;
  margin-right: 13px;
`;

const Wrapper = styled.div`
  width: calc(100% - 64px);
  height: 100%;
`;

export const AggregatedEnergyConsumption = ({
  energySavingThisMonth,
  energySavingAccumulate,
}: any) => {
  return (
    <>
      <Container>
        <Image src={EnergySavingIcon} />
        <Wrapper>
          <Row>
            <Col sm={24}>
              <Row>
                <SubBlockHeader>Energy Saving (THB)</SubBlockHeader>
              </Row>
              <Row>
                <Col sm={24}>
                  <Row>
                    <BlockHeader4>This month</BlockHeader4>
                  </Row>
                  <Row>
                    <BlockValue>{energySavingThisMonth}</BlockValue>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Wrapper>
      </Container>
    </>
  );
};
