import styled from "styled-components";
import Tab from "components/Tab";
import { MainBlockContainer } from "components/Common/components";

type TabProps = "newTask" | "acknowledgedTask";

interface settingTypeProps {
  selectedType: string;
  handleSelectType: (type: TabProps) => void;
  selectedIoTDevice: string;
  handleSelectIoTDevice: (device: string) => void;
}

const Container = styled(MainBlockContainer)`
  background: transparent;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  margin-left: 0px;
  color: #586b7c;
`;

const SettingTypeList = [
  { value: "new", title: "New Task" },
  { value: "acknowledged", title: "Acknowledged Task" },
  { value: "done", title: "Closed Task" },
  // { value: "locationManagement", title: "Location Management" },
];

const SettingTypeSection = ({ selectedType, handleSelectType, selectedIoTDevice, handleSelectIoTDevice }: settingTypeProps) => {
  return (
    <>
      <Container>
        <Tab dataList={SettingTypeList} selectedValue={selectedType} handleSelect={handleSelectType} />
      </Container>
    </>
  );
};

export default SettingTypeSection;
