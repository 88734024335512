import { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { DeviceInfo1 } from "./DeviceInfo1";
import { DeviceInfo2 } from "./DeviceInfo2";
import { DeviceInfo3 } from "./DeviceInfo3";
import { BuildingInfo } from "./BuildingInfo";
import { AutomaticMeterReading } from "./AutomaticMeterReading";
import CDU from "assets/pages/Energy/CDU.png";
import FCU from "assets/pages/Energy/FCU.png";
import SplitType from "assets/pages/Energy/SplitType.png";
import MDM from "assets/pages/Energy/MDM.png";
import CCTV from "assets/pages/Energy/CCTV.png";
import FreshAir from "assets/pages/Energy/FreshAir.png";

const MockDataBuilding = [
  { title: "Usable area", desc: "1,174.6 m2" },
  { title: "High", desc: "2 floors" },
  { title: "Type of Building", desc: "สำนักงาน" },
  { title: "Window to Wall Ratio (WWR)", desc: "0.10" },
  { title: "Calculation Type", desc: "กิจการขนาดกลาง อัตรา 3.1 อัตราปกติ แบบ Two-Part Tariff" },
  { title: "Number of Building", desc: "1 อาคาร" },
  { title: "Age of Building", desc: "1 ปี 3 เดือน" },
];

const MockDataAc = [
  { title: "Image", desc1: FCU, desc2: CDU, desc3: SplitType },
  { title: "Brand", desc1: "TOSHIBA", desc2: "TOSHIBA", desc3: "Carrier" },
  {
    title: "Model",
    desc1: "MM-AP 0566BHP-T, 220-240V, 50 Hz, 410W",
    desc2: "MMY-MMP1606T8P-T, 380-415V, 3N~50 Hz, 22.3kW, 35.8 A HFC -410A",
    desc3: "38RLU060S301, 380V, 3 phase 60,000BTU R-410A",
  },
  { title: "Type", desc1: "FCU", desc2: "CDU", desc3: "Split Type" },
  { title: "Location", desc1: "Garden Wing", desc2: "Garden Wing", desc3: "Pantry" },
  { title: "Quantity", desc1: "24", desc2: "8", desc3: "1" },
  { title: "Schedule", desc1: "", desc2: "", desc3: "" },
];

const MockDataMdm = [
  { title: "Image", desc: MDM },
  { title: "Size and Detail of Transformer", desc: "TOSHIBA" },
  { title: "Single Line Diagram", desc: "" },
  { title: "Number", desc: "FCU" },
  { title: "Using for which part of Building", desc: "Garden Wing" },
  { title: "Generator type", desc: "24" },
  { title: "Generator runtime", desc: "" },
  { title: "Generator size", desc: "" },
];

const MockDataCctv = [
  { title: "Image", desc: CCTV },
  { title: "Brand", desc: "NBU" },
  { title: "Model", desc: "MM-AP 0566BHP-T, 220-240V, 50 Hz, 410W" },
  { title: "Location", desc: "Garden Wing" },
  { title: "Quantity", desc: "3" },
];

const MockDataFreshAir = [
  { title: "Image", desc: FreshAir },
  { title: "Power Consumption", desc: "13 W" },
  { title: "Brand", desc: "Carrier" },
  { title: "Max. motor rating", desc: "5 - 7.5 HP / 3.7 -5.5 kW" },
  { title: "Size", desc: "377 x 493 x 127 mm." },
  { title: "Quantity", desc: "4" },
  { title: "Schedule", desc: "6.45-17.45" },
];

const EnergyAudit = ({ data }: any) => {
  const [infoData, setInfoData] = useState<any>();
  const [acData, setAcData] = useState<any>();
  const [mdmData, setMdmData] = useState<any>();
  const [cctvData, setCctvData] = useState<any>();
  const [freshAirData, setFreshAirData] = useState<any>();

  useEffect(() => {
    setInfoData(MockDataBuilding);
    setAcData(MockDataAc);
    setMdmData(MockDataMdm);
    setCctvData(MockDataCctv);
    setFreshAirData(MockDataFreshAir);
  }, []);

  return (
    <>
      <Row gutter={[18, 18]}>
        <Col sm={8} xl={8}>
          <BuildingInfo
            header={"ข้อมูลทั่วไป"}
            buildingName={"Garden Wing Building"}
            companyName={"บริษัท บี.กริม จอยน์ เว็นเจอร์ โฮลดิ้ง จำกัด (มหาชน)"}
            locationName={"ถนนกรุงเทพกรีฑา แขวงหัวหมาก เขตบางกะปิ กรุงเทพฯ 10240"}
            infoData={infoData}
          />
        </Col>
        <Col sm={16} xl={16}>
          <DeviceInfo1 deviceName={"Air-Condition"} infoData={acData} />
        </Col>
        <Col sm={16} xl={16}>
          <Row gutter={[18, 18]}>
            <Col sm={24} xl={24}>
              <DeviceInfo2 deviceName={"Main Distribution Board (MDB)"} infoData={mdmData} />
            </Col>
            <Col sm={12} xl={12}>
              <DeviceInfo3 deviceName={"Closed-Circuit Television (CCTV)"} infoData={cctvData} />
            </Col>
            <Col sm={12} xl={12}>
              <DeviceInfo3 deviceName={"Intelligent Thermostat & VSD Starter"} infoData={freshAirData} />
            </Col>
          </Row>
        </Col>
        <Col sm={8} xl={8}>
          <Row>
            <Col sm={24} xl={24}>
              <AutomaticMeterReading />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EnergyAudit;
