import styled from 'styled-components'
import { Row, Col } from 'antd';
import { BlockContainer, BlockTitle } from "components/Common/components";
import { PlainTable } from 'components/Common/Table/PlainTable';

const Container = styled(BlockContainer)`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 30px 40px 0px 40px;
  filter: drop-shadow(0px 2px 10px rgba(171, 183, 196, 0.2));
`

export const BuildingEvaluation = ({ data, columns }: any) => {
  return (
    <>
      <Container>
        <Row>
          <Col sm={24} lg={24}>
            <BlockTitle>
              ผลการประเมินอาคาร
            </BlockTitle>
          </Col>
          <Col sm={24} xl={24}>
            {data &&
              <PlainTable data={data} columns={columns} />
            }
          </Col>
        </Row>
      </Container>
    </>
  )
}