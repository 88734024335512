import styled from 'styled-components';
import { Row, Col } from 'antd';
import {
  BlockContainer,
  BlockHeader,
  BlockDesc,
  ContentContainer,
} from 'components/Common/components';
import { BarChart } from 'components/Common/Graph/BarChart';

type DailySavingProps = {
  dailySavingData: any[];
};

const Container = styled(BlockContainer)`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  filter: drop-shadow(0px 2px 10px rgba(171, 183, 196, 0.2));
  height: 400px;
`;

const AccumulateGraphContainer = styled.div`
  width: calc(100%);
  height: 320px;
  left: -12px;
`;

export const DailySaving = ({ dailySavingData }: DailySavingProps) => {
  return (
    <Container>
      <Row>
        <Col sm={18} lg={18}>
          <BlockHeader>Daily Saving</BlockHeader>
          <BlockDesc>with Self-driving Solution</BlockDesc>
        </Col>
      </Row>
      <Row>
        <ContentContainer>
          <AccumulateGraphContainer>
            {dailySavingData && <BarChart data={dailySavingData} unit={'kWh'} />}
          </AccumulateGraphContainer>
        </ContentContainer>
      </Row>
    </Container>
  );
};
