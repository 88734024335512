import styled from 'styled-components'
import ClockImage from 'assets/common/icon/clock.svg'

type UpdatedAtProps = {
  time: string
  icon?: any
}

const Container = styled.div<any>`
  width: fit-content;
  height: ${props => props.height ? props.height : '29px'};
  padding: 0px 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #E1E6F090;
  box-shadow: 1px 3px 13px rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(20px);
  border-radius: 16px;
`

const UpdatedAtText = styled.p`
  font-size: 12px;
  color: #586A7C;
  text-align: center;
  margin: 0px;
  margin-left:5px;
`

const ClockImageContainer = styled.img`
  width: 12px;
  height: 12px;
`

const UpdatedAt = ({ time, icon }: UpdatedAtProps) => {
  return (
    <>
      <Container>
        <ClockImageContainer src={icon ? icon : ClockImage} />
        <UpdatedAtText>{time}</UpdatedAtText>
      </Container>
    </>
  )
}

export default UpdatedAt