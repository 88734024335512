import styled from "styled-components";

type dataProps = { title: string; value: string };

interface TabProps {
  dataList: dataProps[];
  selectedValue: string;
  handleSelect: (type: any) => void;
  bgColor?: string;
  size?: string;
  selectedTextColor?: string;
}

interface SizeMapProps {
  [size: string]: {
    height: string;
    fontSize: string;
  };
}

const SizeMap: SizeMapProps = {
  small: {
    height: "36px",
    fontSize: "14px",
  },
  medium: {
    height: "42px",
    fontSize: "16px",
  },
};

const Container = styled.div`
  width: fit-content;
  background: transparent;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 11px;
  color: #586b7c;
`;

const TabContainer = styled.div<any>`
  width: fit-content;
  height: ${(props) => SizeMap[props.size]["height"]};
  border-radius: 22px;
  background: ${(props) => (props.bgColor ? props.bgColor : "rgba(225, 230, 240, 0.5)")};
  backdrop-filter: blur(4px);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 3px 20px rgba(216, 229, 254, 0.5);
  border: 1px solid #ededed;
  @media only screen and (max-width: 1300px) {
  }
  @media only screen and (max-width: 850px) {
  }
`;

const TabButton = styled.div<any>`
  width: fit-content;
  padding: 0px 30px;
  border-radius: ${(props) => (props.isActive ? "10px" : "0px")};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${(props) => (props.isActive ? "bold" : "normal")};
  height: 100%;
  cursor: pointer;
  background: ${(props) =>
    props.isActive ? (props.bgColor ? props.bgColor : "linear-gradient(274.48deg, #0E7EE4 0%, #55A6F2 100%)") : "transparent"};
  opacity: ${(props) => (props.isActive ? "1" : "0.8")};
  border: ${(props) => (props.isActive ? "1px solid #ffffff90" : "0px")};
  box-shadow: 1px 2px 10px rgba(161, 183, 205, 0.1);
`;

const TabTitle = styled.p<any>`
  margin: 0px;
  white-space: nowrap;
  font-size: ${(props) => SizeMap[props.size]["fontSize"]};
  font-weight: ${(props) => (props.isActive ? "bold" : "normal")};
  color: ${(props) => (props.isActive ? (props.color ? props.color : "#ffffff") : "#586A7C")};
`;

const Tab = ({ dataList, selectedValue, handleSelect, bgColor, size, selectedTextColor }: TabProps) => {
  return (
    <>
      <Container>
        <TabContainer size={size ? size : "medium"}>
          {dataList.map((tabData: dataProps) => (
            <TabButton key={tabData.title} isActive={tabData.value === selectedValue} onClick={() => handleSelect(tabData.value)} bgColor={bgColor}>
              <TabTitle
                isActive={selectedValue === tabData.value}
                size={size ? size : "medium"}
                color={selectedTextColor ? selectedTextColor : "white"}
              >
                {tabData.title}
              </TabTitle>
            </TabButton>
          ))}
        </TabContainer>
      </Container>
    </>
  );
};

export default Tab;
