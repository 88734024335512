import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { CameraDropdownGardenWing } from "./CameraDropdownGardenWing";
import { CameraDropdownDaikin } from "./CameraDropdownDaikin2";
import { FloorEnergySummaryTab } from "./FloorEnergySummaryTab";
import { RoomsTab } from "./RoomsTab";
import { FloorEnergySummaryProps } from "./FloorEnergySummary";

interface RoomsSectionProps {
  buildingCode: string;
  roomsData: any[];
  handleSelectRoom: (roomInfo: any) => void;
  energySummaryData: FloorEnergySummaryProps["energySummaryData"];
  onOpenViewGraph: () => void;
}

const Container = styled.div(() => [
  tw`h-[650px] relative flex flex-col gap-y-6 w-full py-4 px-4 bg-white shadow-[0px 0px 2px rgba(145, 158, 171, 0.21), 0px 16px 32px -4px rgba(225, 230, 240, 0.24)] rounded-xl`,
]);

export const RoomsSection = ({ buildingCode, roomsData, handleSelectRoom, energySummaryData, onOpenViewGraph }: RoomsSectionProps) => {
  return (
    <>
      <Container>
        <FloorEnergySummaryTab energySummaryData={energySummaryData} onOpenViewGraph={onOpenViewGraph} />
        <RoomsTab roomsData={roomsData} handleSelectRoom={handleSelectRoom} />
        {buildingCode === "BGM" && <CameraDropdownGardenWing />}
        {buildingCode === "DAI" && <CameraDropdownDaikin />}
      </Container>
    </>
  );
};
