import styled from "styled-components";
import { Row, Col, Spin } from "antd";
import { BlockContainer, DeviceBlockHeader } from "../../../../components/Common/components";
import { Title, Desc, DeviceImage } from "./StyledComponents";

type DataProps = {
  title: string;
  desc1?: string;
  desc2?: string;
  desc3?: string;
};

interface InfoDetailProps {
  infoData: DataProps[];
}

const Container = styled(BlockContainer)`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  filter: drop-shadow(0px 2px 10px rgba(171, 183, 196, 0.2));
  height: 100%;
  padding: 23px 42px;
`;

const InfoDetail = ({ infoData }: InfoDetailProps) => {
  return (
    <>
      {infoData ? (
        infoData.map((data: DataProps, index: number) =>
          index === 0 ? (
            <Row style={{ marginBottom: "25px" }}>
              <Col sm={6} xl={6}></Col>
              <Col sm={6} xl={6} style={{ display: "flex", alignItems: "center" }}>
                <DeviceImage src={data.desc1} />
              </Col>
              <Col sm={6} xl={6} style={{ display: "flex", alignItems: "center" }}>
                <DeviceImage src={data.desc2} />
              </Col>
              <Col sm={6} xl={6} style={{ display: "flex", alignItems: "center" }}>
                <DeviceImage src={data.desc3} />
              </Col>
            </Row>
          ) : (
            <Row style={{ marginBottom: "25px" }}>
              <Col sm={6} xl={6}>
                <Title>{data.title}</Title>
              </Col>
              <Col sm={6} xl={6}>
                <Desc>{data.desc1}</Desc>
              </Col>
              <Col sm={6} xl={6}>
                <Desc>{data.desc2}</Desc>
              </Col>
              <Col sm={6} xl={6}>
                <Desc>{data.desc3}</Desc>
              </Col>
            </Row>
          )
        )
      ) : (
        <Spin />
      )}
    </>
  );
};

export const DeviceInfo1 = ({ deviceName, infoData }: any) => {
  return (
    <>
      <Container>
        <Row gutter={[18, 18]}>
          <Col sm={24} lg={24}>
            <DeviceBlockHeader style={{ marginBottom: "15px" }}>{deviceName}</DeviceBlockHeader>
          </Col>
          <Col sm={24} lg={24}>
            <InfoDetail infoData={infoData} />
          </Col>
        </Row>
      </Container>
    </>
  );
};
