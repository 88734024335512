import styled from 'styled-components'
import { MainBlockContainer } from "components/Common/components";

export const Container = styled(MainBlockContainer)`
  background: white;
  height: 100%;
  filter: drop-shadow(0px 2px 10px rgba(171, 183, 196, 0.2));
`

export const BlockHeader = styled.h3`
  margin:0px;
  font-size: 32px;
  margin-top: -10px;
  font-weight: 500;
`

export const BlockDesc = styled.p`
  margin:0px;
  font-size: 14px;
`

export const FloorTabContainer = styled.div`
  position: absolute;
  top: 55px;
  left: 0px;
  width: 42px;
  height: fit-content;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border-radius: 21.5px;
  background: linear-gradient(130.93deg, rgba(255, 255, 255, 0.73) 23.22%, rgba(255, 255, 255, 0.37) 163.9%);
  box-shadow: -2px 2px 7px rgba(225, 234, 251, 0.89);
`

export const FloorTab = styled.div<any>`
  width: 100%;
  padding: 10px;
  display:flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 20px;
  color: ${props => props.active ? 'white' : 'black'};
  background: ${props => props.active ? 'linear-gradient(170.49deg, #0E7EE4 7.2%, #14B8B4 92.86%)' : 'transparent'};
`

export const BlockBody = styled.div`
  margin-top: 40px;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content:center;
  align-items: center;
`

export const FloorModelContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content:center;
  align-items: center;
`

export const ImageContainer = styled.div`
  position: relative;
  height: fit-content;
  width: fit-content;
`

export const ContentBody = styled.div<any>`
  width: fit-content;
  height: fit-content;
  padding: 6px 24px 6px 10px;
  cursor: pointer;
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(169, 169, 169, 0.5);
  margin: 3px 0px;
`

export const Subtitle = styled.h4`
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: #0E7EE4;
  margin: 0px;
  user-select: none;
`

export const Value = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #212529;
  margin: 0px;
  user-select: none;
`

export const UpdatedAt = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: #788796;
  margin: 0px;
`

export const ViewGraphButton = styled.button`
  width: fit-content;
  padding: 0px 10px 1px 10px;
  background: linear-gradient(180deg, #F2C94C 0%, #F8AF37 100%);
  box-shadow: 1px 2px 6px rgba(190, 196, 198, 0.2);
  border-radius: 20px;
  border: 0px;
  color: white;
  margin-top: 8px;
  cursor: pointer;
`

export const FilterContainer = styled.div`
  position: absolute;
  top: 55px;
  left: 55px;
  width: 42px;
  height: 42px;
  border-radius: 10px;
  background: linear-gradient(130.93deg, rgba(255, 255, 255, 0.73) 23.22%, rgba(255, 255, 255, 0.37) 163.9%);
  box-shadow: -2px 2px 7px rgba(225, 234, 251, 0.89);
  cursor: pointer;
`

export const FilterWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Icon = styled.img<any>`
  max-width: ${props => props.width}px;
  max-height: ${props => props.height}px;
  user-select: none;
`

export const Tablist = styled.div`
  position: absolute;
  bottom: -177px;
  left: 50px;
  height: fit-content;
  width: fit-content;
  padding: 8px 16px;
  border-radius: 10px;
  background: white;
  box-shadow: -2px 2px 7px rgb(225 234 251 / 89%);
  z-index: 9999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: default;
`

export const Tab = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7px;
`

export const DeviceName = styled.p`
  margin: 0px;
  width: 160px;
  margin-left: 7px;
  user-select: none;
`

export const DeviceInput = styled.input`
  margin-right: 10px;
  cursor: pointer;
`

export const ScaleImage = styled.img`
  height: 28px;
  width: auto;
  position: absolute;
  bottom: -40px;
  right: 0px;
`

export const UpDownImage = styled.img`
  position: absolute;
  bottom: 10px;
  right: 8px;
  width: 10px;
  height: 10px;
`