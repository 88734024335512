import { Row, Col } from "antd";
import { RealtimePowerGeneration } from "./RealtimePowerGeneration";
import { SummaryBox } from "./SummaryBox";
import { Co2EmissionMd } from "./Co2Emission";
import { AggregatedEnergyConsumption } from "./AggregatedEnergyConsumption";
import { CurrentPowerGeneration } from "./CurrentPowerGeneration";
import { SolarAttributes } from "./SolarAttributes";

const SummarySection = ({ realtimePowerData, realtimePowerConsumption, selectedTab, energySaving, floorMap }: any) => {
  return (
    <>
      {["M", "F1", "F2", "F3", "F4", "F5", "F6", "F7", "F8", "F9"].includes(selectedTab) && (
        <Row gutter={[18, 18]}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ zIndex: 9999 }}>
            <SummaryBox
              title={"Real-time Power Consumption"}
              subtitle={floorMap[selectedTab]["summary_box_label"]}
              graphType={"line"}
              data={
                realtimePowerConsumption && realtimePowerConsumption[selectedTab] && realtimePowerConsumption[selectedTab]["realtimePowerConsumption"]
              }
              unit={"kW"}
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <SummaryBox
              title={"Daily Energy Consumption"}
              subtitle={floorMap[selectedTab]["summary_box_label"]}
              graphType={"bar"}
              keys={["y"]}
              data={
                realtimePowerConsumption && realtimePowerConsumption[selectedTab] && realtimePowerConsumption[selectedTab]["dailyEnergyConsumption"]
              }
              unit={"kWh"}
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <SummaryBox
              title={"Monthly Energy Consumption"}
              subtitle={floorMap[selectedTab]["summary_box_label"]}
              graphType={"bar"}
              keys={["y"]}
              data={
                realtimePowerConsumption && realtimePowerConsumption[selectedTab] && realtimePowerConsumption[selectedTab]["monthlyEnergyConsumption"]
              }
              unit={"kWh"}
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Row gutter={[18, 18]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Co2EmissionMd
                  data={realtimePowerConsumption && realtimePowerConsumption[selectedTab] && realtimePowerConsumption[selectedTab]["co2"]}
                  floor={floorMap[selectedTab]["summary_box_label"]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {["S"].includes(selectedTab) && (
        <Row gutter={[18, 18]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <RealtimePowerGeneration data={realtimePowerData} />
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <AggregatedEnergyConsumption energySavingThisMonth={energySaving.this_month} energySavingAccumulate={energySaving.accumulated} />
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <CurrentPowerGeneration energySavingThisMonth={energySaving.this_month} energySavingAccumulate={energySaving.accumulated} />
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <SolarAttributes energySavingThisMonth={energySaving.this_month} energySavingAccumulate={energySaving.accumulated} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default SummarySection;
