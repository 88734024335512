export function MoreIcon() {
  return (
    <svg
      width="3"
      height="11"
      viewBox="0 0 3 11"
      fill="#0E7EE4"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="1.5" cy="1.5" r="1.5" />
      <circle cx="1.5" cy="5.5" r="1.5" />
      <circle cx="1.5" cy="9.5" r="1.5" />
    </svg>
  )
}
