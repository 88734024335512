import styled from "styled-components";
import { Row, Col } from "antd";
import { BlockContainer, BlockHeader } from "components/Common/components";
import { Serie } from "@nivo/line";
import SelfDriving from "assets/pages/Energy/SelfDriving.svg";
import Website from "assets/pages/Energy/Website.svg";
import HumanIntervention from "assets/pages/Energy/HumanIntervention.svg";

type DeviceControlActionSummaryProps = {
  deviceControlActionSummaryData: Serie[] | undefined;
};

const Container = styled(BlockContainer)`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  filter: drop-shadow(0px 2px 10px rgba(171, 183, 196, 0.2));
`;

const Image = styled.img`
  width: 100px;
`;

const Text = styled.p`
  height: 60px;
  margin: 0px;
  padding: 12px;
`;

const ActionSummaryMockData = [
  [null, <Image src={SelfDriving} />, <Image src={Website} />, <Image src={HumanIntervention} />],
  [<Text>Today (times)</Text>, <Text>30</Text>, <Text>10</Text>, <Text>20</Text>],
  [<Text>Month (times)</Text>, <Text>538</Text>, <Text>139</Text>, <Text>345</Text>],
];

const CustomRow = ({ data }: any) => {
  return (
    <>
      <Row>
        <Col xs={6} style={{ display: "flex", justifyContent: "center" }}>
          {data[0]}
        </Col>
        <Col xs={6} style={{ display: "flex", justifyContent: "center" }}>
          {data[1]}
        </Col>
        <Col xs={6} style={{ display: "flex", justifyContent: "center" }}>
          {data[2]}
        </Col>
        <Col xs={6} style={{ display: "flex", justifyContent: "center" }}>
          {data[3]}
        </Col>
      </Row>
    </>
  );
};

export const DeviceControlActionSummary = ({ deviceControlActionSummaryData }: DeviceControlActionSummaryProps) => {
  return (
    <Container>
      <Row>
        <Col sm={18} lg={18}>
          <BlockHeader>Device Control Action Summary Data</BlockHeader>
        </Col>
      </Row>
      <Row style={{ marginTop: "16px" }}>
        <Col sm={24} xl={24}>
          {ActionSummaryMockData.map((item) => (
            <CustomRow data={item} />
          ))}
        </Col>
      </Row>
    </Container>
  );
};
