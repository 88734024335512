import styled from 'styled-components';
import { Row, Col } from 'antd';
import CCTV from 'assets/pages/Dashboard/DeviceControl/CCTV.png';
import InImage from 'assets/pages/Dashboard/DeviceControl/cctv_activity_in.svg';
import OutImage from 'assets/pages/Dashboard/DeviceControl/cctv_activity_out.svg';

const Container = styled.div`
  width: 750px;
  height: auto;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 190px;
  height: 135px;
`;

const CountBox = styled.div`
  width: 100%;
  height: 131px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1.59763px 4.7929px 12.7811px rgba(169, 174, 177, 0.03);
  backdrop-filter: blur(20.6047px);
  border-radius: 13.3931px;
  margin-bottom: 8px;
`;

const InnerBox = styled.div`
  width: 100%;
  height: 80px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.p`
  margin: 0px;
  font-size: 26px;
  line-height: 37px;
  display: flex;
  align-items: flex-end;
  color: #212529;
  margin-left: 8px;
`;

const Number = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 51px;
  color: #0e7ee4;
  margin: 0px;
`;

export const GatewayControl = ({ command }: any) => {
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <Row>
            <ImageContainer>
              <Image src={CCTV} />
            </ImageContainer>
          </Row>
        </Col>
        <Col xs={12}>
          <Row>
            <Col xs={24}>
              <CountBox>
                <InnerBox>
                  <img src={InImage} />
                  <Text>In</Text>
                </InnerBox>
                <InnerBox>
                  <Row>
                    <Col xs={24}>
                      <InnerBox>
                        <Number>{command && command.count_people_in}</Number>
                        <Text>pax.</Text>
                      </InnerBox>
                    </Col>
                    <Col xs={24} style={{ display: 'flex', justifyContent: 'center' }}></Col>
                  </Row>
                </InnerBox>
              </CountBox>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <CountBox>
                <InnerBox>
                  <img src={OutImage} />
                  <Text>Out</Text>
                </InnerBox>
                <InnerBox>
                  <Row>
                    <Col xs={24}>
                      <InnerBox>
                        <Number>{command && command.count_people_out}</Number>
                        <Text>pax.</Text>
                      </InnerBox>
                    </Col>
                    <Col xs={24} style={{ display: 'flex', justifyContent: 'center' }}></Col>
                  </Row>
                </InnerBox>
              </CountBox>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
