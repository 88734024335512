import styled from "styled-components";
import AcIconOn from "assets/pages/Dashboard/DeviceIcon/AcIconOn.svg";
import AcIconOff from "assets/pages/Dashboard/DeviceIcon/AcIconOff.svg";
import AcIconAlert from "assets/pages/Dashboard/DeviceIcon/AcIconAlert.svg";
import NetatmoOn from "assets/pages/Dashboard/DeviceIcon/NetatmoOn.svg";
import NetatmoOff from "assets/pages/Dashboard/DeviceIcon/NetatmoOff.svg";
import NetatmoAlert from "assets/pages/Dashboard/DeviceIcon/NetatmoAlert.svg";
import Probe from "assets/pages/Dashboard/DeviceIcon/Probe.svg";
import CCTV from "assets/pages/Dashboard/DeviceIcon/CCTV.svg";
import FreshAirOn from "assets/pages/Dashboard/DeviceIcon/FreshAirOn.svg";
import FreshAirOff from "assets/pages/Dashboard/DeviceIcon/FreshAirOff.svg";
import FreshAirAlert from "assets/pages/Dashboard/DeviceIcon/FreshAirAlert.svg";
import AiGateway from "assets/pages/Dashboard/DeviceIcon/AiGateway.svg";
import IoTGateway from "assets/pages/Dashboard/DeviceIcon/IoTGateway.svg";
import BlindOn from "assets/pages/Dashboard/DeviceIcon/BlindOn.svg";
import BlindOff from "assets/pages/Dashboard/DeviceIcon/BlindOff.svg";
import ModbusMeter from "assets/pages/Dashboard/DeviceIcon/ModbusMeter.svg";
import Disconnected from "assets/pages/Dashboard/DeviceStatus/Disconnected.svg";
import Alert from "assets/pages/Dashboard/DeviceStatus/Alert.svg";
import { Spin } from "antd";
import BgrimmTempZoneMap from "configs/bgrimm_custom_temp_zone.json";

interface IconProps {
  key: any;
  deviceId?: string;
  agentId: string;
  onClick?: (action: any) => void;
  x: number;
  y: number;
  showTempZone?: boolean;
  temp?: number;
  visible?: boolean;
  firebaseDeviceData?: any;
  subdevices?: any;
}

const IconContainer = styled.div<any>`
  position: absolute;
  width: fit-content;
  height: fit-content;
  top: ${(props) => props.y}%;
  left: ${(props) => props.x}%;
`;

const Icon = styled.img<any>`
  width: 24px;
  max-height: 24px;
  cursor: pointer;
`;

const TempZone = styled.div<any>`
  position: absolute;
  top: ${(props) => `calc(${props.y}% + 10.8%)`};
  left: ${(props) => `calc(${props.x}% - 1.8%)`};
  width: ${(props) => `${props.width}%`};
  /* width: 6.3%; */
  height: 81%;
  background-color: ${(props) => props.bgColor};
  z-index: -1;
`;

const TempZoneValue = styled.p<any>`
  position: relative;
  display: flex;
  justify-content: space-around;
  font-size: 12px;
  font-weight: 700;
  margin: 0px;
  top: 1px;
  color: ${(props) => (props.color ? props.color : "gray")};
`;

const AcTooltipContainer = styled.div<any>`
  position: absolute;
  top: ${(props) => `calc(${props.y}% - 4%)`};
  left: ${(props) => `calc(${props.x}% - 1.8%)`};
  width: 6.3%;
  height: 12%;
  border-radius: 6px;
  padding: 0px 10px 0px 0px;
  background-color: ${(props) => mapTmpZoneColor(props.temp)};
`;

const AcTooltipWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeviceId = styled.h3`
  margin: 0px;
  font-size: 10px;
  position: absolute;
  top: -15px;
  width: 100%;
  text-align: center;
`;

const AcTemp = styled.p<any>`
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin: 0px;
  color: ${(props) => props.color};
`;

const Co2Box = styled.div`
  position: absolute;
  right: -12px;
  top: 36px;
  width: 45px;
  height: 56px;
  background: linear-gradient(283.41deg, #f9faff 0%, rgba(249, 250, 255, 0.6) 100%);
  box-shadow: -3px -3px 5px #ffffff, 3px 3px 5px rgba(120, 135, 150, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 30px 30px 6px 6px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 4px;
  padding-left: 2px;
  color: #788796;
  font-size: 10px;
  font-weight: 700;
`;

const SensorTemp = styled.div`
  position: absolute;
  right: -12px;
  top: 36px;
  width: 45px;
  height: 22px;
  background: linear-gradient(283.41deg, #f9faff 0%, rgba(249, 250, 255, 0.6) 100%);
  box-shadow: -3px -3px 5px #ffffff, 3px 3px 5px rgba(120, 135, 150, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #788796;
  font-size: 10px;
  font-weight: 700;
`;

const OnlineStatusImage = styled.img`
  position: absolute;
  width: 16px;
  height: 16px;
  z-index: 100;
`;

const getIcon: { [agentId: string]: { [status: string]: string } } = {
  bac0hvac: {
    on: AcIconOn,
    off: AcIconOff,
    alert: AcIconAlert,
  },
  netatmo: {
    on: NetatmoOn,
    off: NetatmoOff,
    alert: NetatmoAlert,
  },
  son_env: {
    on: NetatmoOn,
    off: NetatmoOff,
    alert: NetatmoAlert,
  },
  airveda: {
    on: NetatmoOn,
    off: NetatmoOff,
    alert: NetatmoAlert,
  },
  cctv: {
    on: CCTV,
  },
  tasmota: {
    on: FreshAirOn,
    off: FreshAirOff,
    alert: FreshAirAlert,
  },
  aigateway: {
    on: AiGateway,
  },
  iotgateway: {
    on: IoTGateway,
  },
  tuyacloudcurtain: {
    on: BlindOn,
    off: BlindOff,
  },
  actooltip: {
    on: BlindOn,
    off: BlindOff,
  },
  tuya_temp_humid: {
    on: NetatmoOn,
    off: NetatmoOff,
    alert: NetatmoAlert,
  },
  tuya_air_quality: {
    on: NetatmoOn,
    off: NetatmoOff,
    alert: NetatmoAlert,
  },
  tuya_env_relay: {
    on: Probe,
    off: Probe,
    alert: NetatmoAlert,
  },
  camera: {
    on: CCTV,
  },
  tuyablinds: {
    on: BlindOn,
    off: BlindOff,
  },
  itm_fcu: {
    on: AcIconOn,
    off: AcIconOff,
    alert: AcIconAlert,
  },
  itm_oau: {
    on: FreshAirOn,
    off: FreshAirOff,
    alert: FreshAirAlert,
  },
  modbus: {
    on: ModbusMeter,
    off: ModbusMeter,
  },
};

const AcTooltipOn = ({ deviceId, agentId, x, y, temp, acStatus }: any) => {
  return (
    <AcTooltipContainer x={x} y={y} temp={temp}>
      <AcTooltipWrapper>
        <DeviceId>{deviceId}</DeviceId>
        <AcTemp color={mapTmpTextColor(temp)}>{temp}°C</AcTemp>
      </AcTooltipWrapper>
    </AcTooltipContainer>
  );
};

const DeviceTooltip = ({ status }: any) => {
  return (
    <div className="group absolute w-4 h-4" style={{ right: "-8px", top: "-8px" }}>
      {status === "error" && (
        <>
          <OnlineStatusImage src={Alert} />
          <div
            className="hidden group-hover:block absolute z-1000 px-3 py-2 text-sm whitespace-nowrap shadow rounded-lg font-bold"
            style={{ top: "-32px", left: "16px", backdropFilter: "blur(8px)", background: "#ffffff30", color: "#EF4337" }}
          >
            Error
          </div>
        </>
      )}
      {status === "offline" && (
        <>
          <OnlineStatusImage src={Disconnected} />
          <div
            className="hidden group-hover:block absolute px-3 py-2 text-sm whitespace-nowrap shadow rounded-lg font-bold"
            style={{ top: "-32px", left: "16px", backdropFilter: "blur(8px)", background: "#ffffff30", color: "#EF4337", zIndex: 999 }}
          >
            Disconnected
          </div>
        </>
      )}
    </div>
  );
};

const mapTmpZoneColor = (temp: number | string, acMode?: string) => {
  if (acMode === "off" || temp == "No Control") {
    return "#BDBEC840";
  } else {
    if (temp <= 19) {
      return "#0E7EE480";
    } else if (temp <= 20) {
      return "#0E7EE480";
    } else if (temp <= 21) {
      return "#0E7EE44D";
    } else if (temp <= 22) {
      return "#0E7EE44D";
    } else if (temp <= 23) {
      return "#8BC6FF4D";
    } else if (temp <= 24) {
      return "#8BC6FF4D";
    } else if (temp <= 25) {
      return "#A2E3A633";
    } else if (temp <= 26) {
      return "#F3A48C33";
    } else if (temp <= 27) {
      return "#F3A48C33";
    } else if (temp <= 28) {
      return "#EA663F4D";
    } else if (temp <= 29) {
      return "#EA663F4D";
    } else {
      return "#e444144d";
    }
  }
};

const mapTmpTextColor = (temp: number | string, mode?: string) => {
  if (mode === "off" || temp == "No Control") {
    return "#5f5f5f";
  } else {
    if (temp <= 19) {
      return "#065BA9";
    } else if (temp <= 20) {
      return "#065BA9";
    } else if (temp <= 21) {
      return "#065BA9";
    } else if (temp <= 22) {
      return "#065BA9";
    } else if (temp <= 23) {
      return "#065BA9";
    } else if (temp <= 24) {
      return "#065BA9";
    } else if (temp <= 25) {
      return "#2E8285";
    } else if (temp <= 26) {
      return "#EA663F";
    } else if (temp <= 27) {
      return "#EA663F";
    } else if (temp <= 28) {
      return "#EA663F";
    } else if (temp <= 29) {
      return "#EA663F";
    } else {
      return "#e44414";
    }
  }
};

export const DeviceIcon = ({ deviceId, agentId, onClick, x, y, showTempZone, visible, firebaseDeviceData }: IconProps) => {
  if (!visible) {
    return null;
  }
  const BgrimmTempZoneWidthMap: any = BgrimmTempZoneMap;
  const source = firebaseDeviceData?.ac?.subdev_0?.source;
  const acTemp = source === "rl_action" ? firebaseDeviceData?.ac?.subdev_0?.temperature : firebaseDeviceData?.ac?.subdev_0?.set_temperature;
  const acTempLoading = firebaseDeviceData?.loading;
  const acStatus = firebaseDeviceData?.device?.subdev_0?.status_code;
  const status = firebaseDeviceData?.ac?.subdev_0?.mode === "off" ? "off" : "on";
  const envTempSonoff = firebaseDeviceData?.environment?.subdev_0?.temperature?.toFixed(1);
  const envTempNetatmo = firebaseDeviceData?.environment?.subdev_1?.temperature?.toFixed(1);
  const envTempAirveda = firebaseDeviceData?.environment?.subdev_0?.temperature?.toFixed(1);
  const envCo2Netatmo = firebaseDeviceData?.environment?.subdev_1?.co2?.toFixed(0);
  const envCo2Airveda = firebaseDeviceData?.environment?.subdev_0?.co2?.toFixed(0);
  const isOnline = firebaseDeviceData?.device?.subdev_0?.online_status;
  const blindIsOpen = firebaseDeviceData?.device?.subdev_0?.percent_position === 0 ? "off" : "on";

  return (
    <>
      {(agentId === "bac0hvac" || agentId === "actooltip") && showTempZone && acTemp && (
        <>
          <TempZone
            bgColor={mapTmpZoneColor(acTemp, status)}
            x={x}
            y={y}
            width={deviceId && BgrimmTempZoneWidthMap[deviceId] && BgrimmTempZoneWidthMap[deviceId]["width"]}
          >
            <TempZoneValue color={mapTmpTextColor(acTemp, status)}>
              {acTempLoading && <Spin size="small" />}
              {acTemp} {acTemp !== "No Control" && "°C"}
            </TempZoneValue>
          </TempZone>
        </>
      )}

      {agentId === "actooltip" ? (
        <AcTooltipOn deviceId={deviceId} agentId={agentId} x={x} y={y} temp={acTemp} />
      ) : (
        <IconContainer x={x} y={y}>
          {agentId === "tuyacloudcurtain" || agentId === "tuyablinds" ? (
            <Icon src={getIcon[agentId] && getIcon[agentId][blindIsOpen]} onClick={onClick} />
          ) : (
            <Icon src={getIcon[agentId] && getIcon[agentId][status]} onClick={onClick} />
          )}
          {agentId === "itm_fcu" && acStatus === "error" && <DeviceTooltip status={acStatus} />}
          {(agentId === "son_env" || agentId === "tuya_temp_humid" || agentId === "tuya_env_relay") && (
            <>
              <SensorTemp>{envTempSonoff ? envTempSonoff : "-"} °C</SensorTemp>
              {isOnline === false && <DeviceTooltip status={"offline"} />}
            </>
          )}
          {(agentId === "tuyacloudcurtain" || agentId === "tuyablinds") && <>{isOnline === false && <DeviceTooltip status={"offline"} />}</>}
          {agentId === "netatmo" && (
            <>
              <Co2Box>
                <small>CO2</small>
                <div>
                  {envCo2Netatmo} <small>ppm</small>
                </div>
              </Co2Box>
              <SensorTemp>{envTempNetatmo ? envTempNetatmo : "-"} °C</SensorTemp>
            </>
          )}
          {(agentId === "airveda" || agentId === "tuya_air_quality") && (
            <>
              <Co2Box>
                <small>CO2</small>
                <div>
                  {envCo2Airveda} <small>ppm</small>
                </div>
              </Co2Box>
              <SensorTemp>{envTempAirveda ? envTempAirveda : "-"} °C</SensorTemp>
              {isOnline === false && <DeviceTooltip status={"offline"} />}
            </>
          )}
        </IconContainer>
      )}
    </>
  );
};
