import tw from "twin.macro";

export interface FloorEnergySummaryProps {
  energySummaryData: { [key: string]: any } | undefined;
}

type dataProp = {
  name: string;
  value: number;
  unit: string;
};

const energyData: dataProp[] = [
  { name: "Energy Consumption", value: 0, unit: "kWh" },
  { name: "Forecast Ebill", value: 0, unit: "THB" },
  {
    name: "Peak Demand",
    value: 0,
    unit: "kW",
  },
  {
    name: "CO2 Emissions",
    value: 0,
    unit: "kg",
  },
];

const SingleTab = tw.div`h-20 w-1/4 flex flex-col items-center px-1 py-3 border border-gray-100 border-r-0 bg-altoWhite-white04 first:rounded-[10px 0px 0px 10px] last:rounded-[0px 10px 10px 0px] last:border-r`;

const Name = tw.p`h-5 w-full text-center text-sm`;

const Value = tw.h3`m-1 text-altoDark`;

const Unit = tw.h3`m-1 text-altoDark`;

const Row = tw.div`flex`;

export const FloorEnergySummary = ({ energySummaryData }: FloorEnergySummaryProps) => {
  return (
    <div className="flex w-full">
      {energyData.map((data) => {
        const key = data?.value;
        const label = data?.name;
        const unit = data?.unit;
        const value = energySummaryData?.[key]?.value;
        return (
          <SingleTab key={data.name}>
            <Name>{label}</Name>
            <Row>
              <Value>{value ? value : "-"}</Value>
              <Unit>{unit}</Unit>
            </Row>
          </SingleTab>
        );
      })}
    </div>
  );
};
