export const Floor2CCC = () => {
  return (
    <>
      <svg
        width="923"
        height="492"
        viewBox="0 0 923 492"
        fill="none"
        style={{ width: "100%", height: "fit-content", marginBottom: "-5px" }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M590.55 2.26099V58.3316M590.55 2.26099H758.762V67.8274V90.8887M590.55 2.26099H106.715M758.762 282.162V225.187V197.152M758.762 282.162V323.763M758.762 282.162H648.43M634.412 345.467V369.433M692.743 323.763H758.762M758.762 323.763V386.164M9.04364 387.068H190.821V419.173M9.04364 387.068V489.714H109.88H591.907M9.04364 387.068V90.8887M190.821 489.714V419.173M591.907 489.714H758.762V386.164M591.907 489.714V411.034M591.907 426.86H634.412V386.164M634.412 386.164H758.762M634.412 386.164V369.433M190.821 419.173H216.595M243.274 419.173H269.501M269.501 419.173V489.714H352.702V387.068M269.501 419.173V387.068H352.702M352.702 387.068V58.3316M352.702 2.26099V58.3316M590.55 90.8887H633.96M590.55 90.8887V197.152M590.55 90.8887V85.0104M758.762 90.8887V163.69V197.152M758.762 90.8887H633.96M633.96 90.8887V197.152M633.96 197.152H590.55M633.96 197.152H648.43M590.55 197.152V249.153H648.43M758.762 197.152H648.43M634.412 369.433H591.002V282.162H648.43M648.43 249.153V197.152M648.43 249.153V282.162M590.55 58.3316H352.702M590.55 58.3316V64.6898M106.715 2.26099H9.04364V90.8887M106.715 2.26099V45.2183M9.04364 90.8887H106.715"
          stroke="#788796"
          strokeWidth="2"
        />
        <line x1="330.093" y1="12.136" x2="351.798" y2="12.136" stroke="#788796" strokeWidth="1.05021" />
        <path d="M330.565 12.661C330.565 22.3356 338.88 33.4614 351.798 33.4614" stroke="#A1B7CD" strokeWidth="1.05021" />
        <line y1="-0.525103" x2="21.7048" y2="-0.525103" transform="matrix(-1 0 0 1 292.11 391.59)" stroke="#788796" strokeWidth="1.05021" />
        <path d="M291.638 391.59C291.638 401.265 283.323 412.39 270.405 412.39" stroke="#A1B7CD" strokeWidth="1.05021" />
        <line x1="168.212" y1="392.874" x2="189.917" y2="392.874" stroke="#788796" strokeWidth="1.05021" />
        <path d="M168.684 393.399C168.684 403.073 176.999 414.199 189.917 414.199" stroke="#A1B7CD" strokeWidth="1.05021" />
        <line y1="-0.525103" x2="21.7048" y2="-0.525103" transform="matrix(1 0 0 -1 567.941 55.1663)" stroke="#788796" strokeWidth="1.05021" />
        <path d="M568.413 55.1664C568.413 45.4918 576.728 34.366 589.646 34.366" stroke="#A1B7CD" strokeWidth="1.05021" />
        <line x1="596.356" y1="113.046" x2="596.356" y2="91.3409" stroke="#788796" strokeWidth="1.05021" />
        <path d="M596.881 112.574C606.555 112.574 617.681 104.259 617.681 91.3408" stroke="#A1B7CD" strokeWidth="1.05021" />
        <line x1="596.356" y1="271.31" x2="596.356" y2="249.605" stroke="#788796" strokeWidth="1.05021" />
        <path d="M596.881 270.838C606.555 270.838 617.681 262.523 617.681 249.605" stroke="#A1B7CD" strokeWidth="1.05021" />
        <line x1="596.356" y1="448.565" x2="596.356" y2="426.86" stroke="#788796" strokeWidth="1.05021" />
        <path d="M596.881 448.093C606.555 448.093 617.681 439.778 617.681 426.86" stroke="#A1B7CD" strokeWidth="1.05021" />
        <line y1="-0.525103" x2="21.7048" y2="-0.525103" transform="matrix(0 1 1 0 609.542 346.372)" stroke="#788796" strokeWidth="1.05021" />
        <path d="M609.542 346.844C619.216 346.844 630.342 355.159 630.342 368.077" stroke="#A1B7CD" strokeWidth="1.05021" />
        <line x1="567.941" y1="12.136" x2="589.646" y2="12.136" stroke="#788796" strokeWidth="1.05021" />
        <path d="M568.413 12.661C568.413 22.3356 576.728 33.4614 589.646 33.4614" stroke="#A1B7CD" strokeWidth="1.05021" />
        <line y1="-0.525103" x2="21.7048" y2="-0.525103" transform="matrix(1 0 0 -1 567.941 85.0103)" stroke="#788796" strokeWidth="1.05021" />
        <path d="M568.413 85.0104C568.413 75.3358 576.728 64.21 589.646 64.21" stroke="#A1B7CD" strokeWidth="1.05021" />
        <line y1="-0.525103" x2="21.7048" y2="-0.525103" transform="matrix(1 0 0 -1 736.153 55.1663)" stroke="#788796" strokeWidth="1.05021" />
        <path d="M736.625 55.1664C736.625 45.4918 744.94 34.366 757.858 34.366" stroke="#A1B7CD" strokeWidth="1.05021" />
        <line x1="736.153" y1="12.136" x2="757.858" y2="12.136" stroke="#788796" strokeWidth="1.05021" />
        <path d="M736.625 12.661C736.625 22.3356 744.94 33.4614 757.858 33.4614" stroke="#A1B7CD" strokeWidth="1.05021" />
        <line x1="671.039" y1="277.261" x2="649.334" y2="277.261" stroke="#788796" strokeWidth="1.05021" />
        <path d="M670.567 276.736C670.567 267.061 662.252 255.935 649.334 255.935" stroke="#A1B7CD" strokeWidth="1.05021" />
        <line y1="-0.525103" x2="21.7048" y2="-0.525103" transform="matrix(1 0 0 -1 330.093 55.1663)" stroke="#788796" strokeWidth="1.05021" />
        <path d="M330.565 55.1664C330.565 45.4918 338.88 34.366 351.798 34.366" stroke="#A1B7CD" strokeWidth="1.05021" />
        <rect x="105.811" width="8.13928" height="9.04365" rx="2.14815" fill="url(#paint0_diamond_107_3666)" />
        <rect x="105.811" y="160.073" width="8.13928" height="9.04365" rx="2.14815" fill="url(#paint1_diamond_107_3666)" />
        <rect width="8.74219" height="10.6849" rx="2.14815" fill="url(#paint2_diamond_107_3666)" />
        <rect y="160.073" width="8.74219" height="10.6849" rx="2.14815" fill="url(#paint3_diamond_107_3666)" />
        <rect x="105.811" y="321.954" width="8.13928" height="9.04365" rx="2.14815" fill="url(#paint4_diamond_107_3666)" />
        <rect x="267.692" width="8.13928" height="9.04365" rx="2.14815" fill="url(#paint5_diamond_107_3666)" />
        <rect x="428.669" width="8.13928" height="9.04365" rx="2.14815" fill="url(#paint6_diamond_107_3666)" />
        <rect x="590.55" y="1.80884" width="8.13928" height="9.04365" rx="2.14815" fill="url(#paint7_diamond_107_3666)" />
        <rect x="750.623" y="1.80884" width="8.13928" height="9.04365" rx="2.14815" fill="url(#paint8_diamond_107_3666)" />
        <rect x="590.55" y="160.073" width="8.13928" height="9.04365" rx="2.14815" fill="url(#paint9_diamond_107_3666)" />
        <rect x="750.623" y="160.073" width="8.13928" height="9.04365" rx="2.14815" fill="url(#paint10_diamond_107_3666)" />
        <rect x="590.55" y="481.122" width="8.13928" height="9.04365" rx="2.14815" fill="url(#paint11_diamond_107_3666)" />
        <rect x="429.573" y="481.122" width="8.13928" height="9.04365" rx="2.14815" fill="url(#paint12_diamond_107_3666)" />
        <rect x="269.501" y="481.122" width="8.13928" height="9.04365" rx="2.14815" fill="url(#paint13_diamond_107_3666)" />
        <rect x="105.811" y="481.122" width="8.13928" height="9.04365" rx="2.14815" fill="url(#paint14_diamond_107_3666)" />
        <rect x="0.904358" y="481.122" width="8.74219" height="10.6849" rx="2.14815" fill="url(#paint15_diamond_107_3666)" />
        <rect y="321.954" width="8.74219" height="10.6849" rx="2.14815" fill="url(#paint16_diamond_107_3666)" />
        <rect x="750.623" y="481.122" width="8.13928" height="9.04365" rx="2.14815" fill="url(#paint17_diamond_107_3666)" />
        <rect x="750.623" y="323.763" width="8.13928" height="9.04365" rx="2.14815" fill="url(#paint18_diamond_107_3666)" />
        <path
          d="M826.59 67.8274C800.101 67.8274 785.25 67.8274 758.762 67.8274V2.26099C785.25 2.26099 800.101 2.26099 826.59 2.26099M826.59 67.8274V112.594M826.59 67.8274V52.4532M826.59 2.26099H922V112.594M826.59 2.26099V32.105M826.59 112.594V154.194M826.59 112.594H894.869M922 112.594V154.194M922 112.594H915.217M826.59 154.194V230.161M826.59 154.194H922M922 154.194V230.161"
          stroke="#788796"
          strokeWidth="2"
        />
        <path
          d="M826.5 161.46H922.5M922.5 161.46V154.6H826.5V167.862M922.5 161.46V167.862M826.5 167.862H922.5M826.5 167.862V175.179M922.5 167.862V175.179M826.5 175.179H922.5M826.5 175.179V181.582M922.5 175.179V181.582M826.5 181.582H922.5M826.5 181.582V188.899M922.5 181.582V188.899M826.5 188.899H922.5M826.5 188.899V195.301M922.5 188.899V195.301M826.5 195.301H922.5M826.5 195.301V201.704M922.5 195.301V201.704M826.5 201.704H922.5M826.5 201.704V209.021M922.5 201.704V209.021M826.5 209.021H922.5M826.5 209.021V215.423M922.5 209.021V215.423M826.5 215.423H922.5M826.5 215.423V222.74M922.5 215.423V222.74M826.5 222.74V229.6H922.5V222.74M826.5 222.74H922.5"
          stroke="#788796"
        />
        <path
          d="M874.646 229.354C874.842 229.549 875.158 229.549 875.354 229.354L878.536 226.172C878.731 225.976 878.731 225.66 878.536 225.464C878.34 225.269 878.024 225.269 877.828 225.464L875 228.293L872.172 225.464C871.976 225.269 871.66 225.269 871.464 225.464C871.269 225.66 871.269 225.976 871.464 226.172L874.646 229.354ZM874.5 155V229H875.5V155H874.5Z"
          fill="#788796"
        />
        <defs>
          <radialGradient
            id="paint0_diamond_107_3666"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(109.88 4.52183) rotate(-91.0912) scale(23.7439 21.3671)"
          >
            <stop stopColor="#A1B7CD" />
            <stop offset="1" stopColor="#0E7EE4" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint1_diamond_107_3666"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(109.88 164.594) rotate(-91.0912) scale(23.7439 21.3671)"
          >
            <stop stopColor="#A1B7CD" />
            <stop offset="1" stopColor="#0E7EE4" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint2_diamond_107_3666"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(4.3711 5.34245) rotate(-90.992) scale(28.0521 22.9506)"
          >
            <stop stopColor="#A1B7CD" />
            <stop offset="1" stopColor="#0E7EE4" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint3_diamond_107_3666"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(4.3711 165.415) rotate(-90.992) scale(28.0521 22.9506)"
          >
            <stop stopColor="#A1B7CD" />
            <stop offset="1" stopColor="#0E7EE4" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint4_diamond_107_3666"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(109.88 326.476) rotate(-91.0912) scale(23.7439 21.3671)"
          >
            <stop stopColor="#A1B7CD" />
            <stop offset="1" stopColor="#0E7EE4" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint5_diamond_107_3666"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(271.762 4.52183) rotate(-91.0912) scale(23.7439 21.3671)"
          >
            <stop stopColor="#A1B7CD" />
            <stop offset="1" stopColor="#0E7EE4" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint6_diamond_107_3666"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(432.739 4.52183) rotate(-91.0912) scale(23.7439 21.3671)"
          >
            <stop stopColor="#A1B7CD" />
            <stop offset="1" stopColor="#0E7EE4" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint7_diamond_107_3666"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(594.62 6.33066) rotate(-91.0912) scale(23.7439 21.3671)"
          >
            <stop stopColor="#A1B7CD" />
            <stop offset="1" stopColor="#0E7EE4" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint8_diamond_107_3666"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(754.692 6.33066) rotate(-91.0912) scale(23.7439 21.3671)"
          >
            <stop stopColor="#A1B7CD" />
            <stop offset="1" stopColor="#0E7EE4" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint9_diamond_107_3666"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(594.62 164.594) rotate(-91.0912) scale(23.7439 21.3671)"
          >
            <stop stopColor="#A1B7CD" />
            <stop offset="1" stopColor="#0E7EE4" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint10_diamond_107_3666"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(754.692 164.594) rotate(-91.0912) scale(23.7439 21.3671)"
          >
            <stop stopColor="#A1B7CD" />
            <stop offset="1" stopColor="#0E7EE4" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint11_diamond_107_3666"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(594.62 485.644) rotate(-91.0912) scale(23.7439 21.3671)"
          >
            <stop stopColor="#A1B7CD" />
            <stop offset="1" stopColor="#0E7EE4" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint12_diamond_107_3666"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(433.643 485.644) rotate(-91.0912) scale(23.7439 21.3671)"
          >
            <stop stopColor="#A1B7CD" />
            <stop offset="1" stopColor="#0E7EE4" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint13_diamond_107_3666"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(273.57 485.644) rotate(-91.0912) scale(23.7439 21.3671)"
          >
            <stop stopColor="#A1B7CD" />
            <stop offset="1" stopColor="#0E7EE4" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint14_diamond_107_3666"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(109.88 485.644) rotate(-91.0912) scale(23.7439 21.3671)"
          >
            <stop stopColor="#A1B7CD" />
            <stop offset="1" stopColor="#0E7EE4" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint15_diamond_107_3666"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(5.27545 486.465) rotate(-90.992) scale(28.0521 22.9506)"
          >
            <stop stopColor="#A1B7CD" />
            <stop offset="1" stopColor="#0E7EE4" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint16_diamond_107_3666"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(4.3711 327.296) rotate(-90.992) scale(28.0521 22.9506)"
          >
            <stop stopColor="#A1B7CD" />
            <stop offset="1" stopColor="#0E7EE4" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint17_diamond_107_3666"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(754.692 485.644) rotate(-91.0912) scale(23.7439 21.3671)"
          >
            <stop stopColor="#A1B7CD" />
            <stop offset="1" stopColor="#0E7EE4" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint18_diamond_107_3666"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(754.692 328.285) rotate(-91.0912) scale(23.7439 21.3671)"
          >
            <stop stopColor="#A1B7CD" />
            <stop offset="1" stopColor="#0E7EE4" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </>
  );
};
