import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import CloseIcon from "assets/pages/Dashboard/Close.svg";
import OpenIcon from "assets/pages/Dashboard/Open.svg";
import SelfDrivingLogo from "assets/pages/Dashboard/SelfDrivingLogo.png";
import WebManualLogo from "assets/pages/Dashboard/WebManualLogo.png";

const Container = styled.div`
  position: absolute;
  top: -40px;
  left: 0px;
  width: 340px;
  height: 80px;
  background: rgba(6, 91, 169, 0.2);
  box-shadow: 2px 4px 20px rgba(161, 183, 205, 0.2);
  backdrop-filter: blur(40px);
  border-radius: 10px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
`;

const ToggleButtonBox = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  bottom: 5px;
  right: 5px;
  cursor: pointer;
  background: rgba(14, 126, 228, 0.3);
  backdrop-filter: blur(3.81078px);
`;

const ToggleImage = styled.img`
  width: 100%;
  height: 100%;
`;

const StatusContainer = styled.div`
  width: 170px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 8px;
`;

const Icon = styled.img<any>`
  height: 64px;
  width: 64px;
  opacity: ${(props) => (props.fade ? 0.5 : 1)};
  cursor: pointer;
`;

const StatusBlock = styled.div`
  width: 86px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
`;

const ModeIsActive = styled.div`
  width: 100%;
  height: 18px;
  display: flex;
  color: #45c84d;
  font-size: 12px;
  align-items: center;
`;

const Dot = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  background-color: #45c84d;
  margin-right: 5px;
`;

const ModeText = styled.p<any>`
  font-size: 18px;
  font-weight: 600;
  color: ${(props) => (props.active ? "#065BA9" : "white")};
  line-height: 1rem;
`;

const SelfDriving = ({ setMode, mode }: any) => {
  return (
    <StatusContainer style={{ cursor: "no-drop" }}>
      {/* TODO Uncomment this when Self-driving is ready */}
      {/* <Icon src={SelfDrivingLogo} onClick={() => setMode("self-driving")} fade={mode !== "self-driving"}  style={{ cursor: "no-drop" }}/> */}
      <Icon src={SelfDrivingLogo} fade={mode !== "self-driving"} style={{ cursor: "no-drop" }} />
      <StatusBlock>
        <ModeIsActive>
          {mode === "self-driving" && (
            <>
              <Dot /> Active
            </>
          )}
        </ModeIsActive>
        <ModeText active={mode === "self-driving"}>Self-driving</ModeText>
      </StatusBlock>
    </StatusContainer>
  );
};

const WebManual = ({ setMode, mode }: any) => {
  return (
    <StatusContainer style={{ cursor: "no-drop" }}>
      {/* <Icon src={WebManualLogo} onClick={() => setMode("webmanual")} fade={mode !== "webmanual"} /> */}
      <Icon src={WebManualLogo} fade={mode !== "webmanual"} style={{ cursor: "no-drop" }} />
      <StatusBlock>
        <ModeIsActive>
          {mode === "webmanual" && (
            <>
              <Dot /> Active
            </>
          )}
        </ModeIsActive>
        <ModeText active={mode === "webmanual"}>Web Manual</ModeText>
      </StatusBlock>
    </StatusContainer>
  );
};

export const SelfDrivingTab = ({ isOpened, handleClickSelfDriving, mode, setMode }: any) => {
  const AnimatedContainer = animated(Container);
  const AnimatedWrapper = animated(Wrapper);
  const ContainerStyle = useSpring({
    from: { width: "170px" },
    width: isOpened ? "340px" : "170px",
  });

  const WrapperStyle = useSpring({
    from: { transform: "translateX(0px)" },
    width: mode !== "self-driving" ? "translateX(0px)" : "translateX(-170px)",
  });

  return (
    <AnimatedContainer style={ContainerStyle}>
      <AnimatedWrapper style={WrapperStyle}>
        {(isOpened || mode === "self-driving") && <SelfDriving setMode={setMode} mode={mode}></SelfDriving>}
        {(isOpened || mode === "webmanual") && <WebManual setMode={setMode} mode={mode}></WebManual>}
      </AnimatedWrapper>
      <ToggleButtonBox onClick={() => handleClickSelfDriving()}>
        <ToggleImage src={isOpened ? CloseIcon : OpenIcon} />
      </ToggleButtonBox>
    </AnimatedContainer>
  );
};
