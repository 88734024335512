import React, { useState } from "react";
import { Col, Row } from "antd";
import RULIcon from "assets/pages/Dashboard/Maintenance/RULIcon.svg";
import NextMaintenanceIcon from "assets/pages/Dashboard/Maintenance/NextMaintenanceIcon.svg";
import IconCheck from "assets/pages/Dashboard/Maintenance/IconCheck.svg";
import tw from "twin.macro";
import styled from "styled-components";

import PmTable from "./PmTable";
import { pmData } from "./MakeData";

type RulValueBoxProps = {
  value: number;
  hours: number;
};

type SummaryBoxProps = {
  icon: string;
  title: string;
  children: React.ReactNode;
};

const Container = styled.div(() => [tw`relative grid-cols-12 p-4 pl-16 h-32 shadow rounded-lg`]);

const SummaryDetail = styled.div(() => [tw`grid col-span-12 grid-cols-12`]);

const SummaryBoxTitle = styled.p(() => [tw`text-[#065BA9] my-2`]);

const ColSpan = styled.div<any>`
  grid-column: ${({ span }) => `span ${span} / span ${span}`};
`;

const Icon = styled.img(() => [tw`absolute left-4 top-4 w-10 h-10`]);

const RulBox = styled.div(() => [tw`w-full h-4 bg-gray-200 rounded-full p-[1px] mt-2.5`]);

const RulValue = styled.p(() => [tw`text-2xl text-altoDark`]);

const RulText = styled.p(() => [tw`text-sm text-[#788796]`]);

const RulBar = styled.div<any>`
  height: 100%;
  width: ${({ value }) => `${value}%`};
  border-radius: 9999px;
  background: linear-gradient(247.24deg, #065ba9 0%, #55a6f2 179.94%);
`;

const NextMaintenanceValue = styled.p(() => [tw`text-2xl text-altoDark font-bold`]);

const TitleContainer = styled.div(() => [tw`flex items-center px-2 py-1.5 gap-x-4`]);

const Title = styled.p(() => [tw`text-lg text-primary font-bold`]);

// 07_RQ_CPR : แก้ไข //
const TableTitle = () => {
  return (
    <TitleContainer>
      <img src={IconCheck} alt="next maintenance icon" />
      <Title>Completed PMs</Title>
    </TitleContainer>
  );
};

const RulValueBox = ({ value, hours }: RulValueBoxProps) => {
  return (
    <>
      <SummaryDetail>
        <ColSpan span={9} style={{ paddingRight: "16px", display: "flex", flexDirection: "column" }}>
          <RulBox>
            <RulBar value={value} />
          </RulBox>
          <RulText>in {hours} hours</RulText>
        </ColSpan>
        <ColSpan span={3}>
          <RulValue>{value.toFixed(1)} %</RulValue>
        </ColSpan>
        <ColSpan span={12}></ColSpan>
      </SummaryDetail>
    </>
  );
};

const SummaryBox = ({ icon, title, children }: SummaryBoxProps) => {
  return (
    <Container>
      <Icon src={icon} alt="icon" />
      <ColSpan span={12}>
        <SummaryBoxTitle>{title}</SummaryBoxTitle>
      </ColSpan>
      {children}
    </Container>
  );
};

export const DevicePms = ({ deviceInfo }: any) => {
  // const lastMaintenanceDate = deviceInfo.lastMaintenanceDate;
  // const lastMaintenanceDate = "2022-07-31";
  const nextMaintenanceDate = "2022-11-31";
  const nextMaintenanceDateTimestamp = convertDateStringToTimestamp(nextMaintenanceDate);
  const todayTimestamp = new Date().getTime();
  const diff = nextMaintenanceDateTimestamp - todayTimestamp;
  const diffInHours = Math.floor(diff / (1000 * 60 * 60));

  return (
    <>
      <Row>
        <Col xs={24}>
          <div className="grid grid-cols-12 gap-x-4 mt-4">
            <div className="col-span-6">
              <SummaryBox icon={RULIcon} title={"Remaining Useful Life"}>
                <RulValueBox value={diffInHours / 28.80} hours={diffInHours} />
              </SummaryBox>
            </div>
            <div className="col-span-6">
              <SummaryBox icon={NextMaintenanceIcon} title={"Next Maintenance"}>
                <NextMaintenanceValue>in {diffInHours} hours</NextMaintenanceValue>
              </SummaryBox>
            </div>
            <div className="col-span-12 mt-4">
              <TableTitle />
              <PmTable data={pmData?.[deviceInfo?.device_id]} searchable={false} />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

const convertDateStringToTimestamp = (dateString: string) => {
  // Date format: YYYY-MM-DD
  const date = dateString.split("-");
  return new Date(+date[0], +date[1] - 1, +date[2]).getTime();
};
