import React, { useMemo } from "react";
import { Col, Row } from "antd";
import { ConditionData, ConditionValueProps, TriggerProps, ConditionDataMap, TriggerValueProps } from "./MakeData";
import tw from "twin.macro";
import styled from "styled-components";
import Button from "components/Common/Button";
import { BiPlus } from "react-icons/bi";
import IconEdit from "assets/pages/Dashboard/Maintenance/IconEdit.svg";
import IconRemove from "assets/pages/Dashboard/Maintenance/IconRemove.svg";

type DeviceConditionProps = {
  deviceInfo: any;
};

const BodyWrapper = styled.div(() => [tw`grid grid-cols-12 gap-x-6 mt-4 w-[900px]`]);

const ButtonContainer = styled.div(() => [tw`col-span-12 flex justify-end`]);

const CardContainer = styled.div(() => [tw`grid grid-cols-12 py-2 pb-4 px-4 rounded-lg shadow-widget col-span-4 gap-y-2`]);

const ImageContainer = styled.div(() => [tw`flex w-8 h-8 rounded-full bg-[rgba(219, 228, 255, 0.5)] justify-center items-center`]);

const TriggerRow = styled.div(() => [tw`grid col-span-9 gap-y-2`]);

export const DeviceCondition = ({ deviceInfo }: DeviceConditionProps) => {
  const data: ConditionValueProps[] = useMemo(() => ConditionData[deviceInfo.device_id], [ConditionData]);
  return (
    <>
      <Row>
        <Col xs={24}>
          <ButtonContainer>
            <Button active title={"Add"} size={"medium"} bgColor={"#0E7EE4"} width={160} icon={<BiPlus size={15} color={"white"} />} />
          </ButtonContainer>
        </Col>
        <Col xs={24}>
          <BodyWrapper>{data && data.map((item: ConditionValueProps) => <ConditionCard key={deviceInfo.device_id} data={item} />)}</BodyWrapper>
        </Col>
      </Row>
    </>
  );
};

const ConditionCard = ({ title, value, data }: any) => {
  return (
    <CardContainer>
      <div className="col-span-12 flex justify-between">
        <p className="font-bold text-primary text-base">{data.name}</p>
        <div className="flex gap-x-2 py-1">
          <img src={IconEdit} alt="edit" className="w-4 h-4 cursor-pointer" />
          <img src={IconRemove} alt="remove" className="w-4 h-4 cursor-pointer" />
        </div>
      </div>
      {data.trigger.map((item: TriggerProps, index: number) => (
        <>
          <div className="col-span-3">
            <p className="text-sm text-primary mt-1">IF</p>
          </div>
          <TriggerCard item={item} />
        </>
      ))}
      {data.action.map((item: TriggerProps, index: number) => (
        <>
          <div className="col-span-3">
            <p className="text-sm text-primary mt-1">THEN</p>
          </div>
          <TriggerCard item={item} />
        </>
      ))}
    </CardContainer>
  );
};

const TriggerCard = ({ item }: any) => {
  return (
    <TriggerRow>
      {item.datapoints.map((datapoint: TriggerValueProps, index: number) => {
        const mapData = ConditionDataMap[datapoint.parameter];
        return (
          <div className="flex gap-x-4 items-center">
            <ImageContainer>
              <img src={mapData.icon} alt="icon" className="w-4 h-4" />
            </ImageContainer>
            <p>
              {mapData.text} {datapoint.operator} {datapoint.value} {mapData.unit}
            </p>
          </div>
        );
      })}
    </TriggerRow>
  );
};
