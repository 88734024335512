import { useState, useEffect } from "react";
import { Col, Row } from "antd";
import { PageContainer } from "components/Common/components";
import SettingTypeSection from "./SettingTypeSection";
import { DeviceExtensionCamera, DeviceExtensionEdge } from "components/Common/Table/DeviceActions";
import { deleteAutomation, getAutomationsByType, postAutomation } from "services/api.service";
import { useDisclosure } from "utils/hooks";
import { responseErrorHandler } from "services/prepareAxios";
import { ScheduleSection } from "./ScheduleSection";
import { useDevicesContext } from "layouts/DevicesProvider";
import DeviceConfig from "configs/device_config.json";
import { ParameterSection } from "./ParameterSection";
import toast from "react-hot-toast";
import axios from "axios";
import { useBuildingContext } from "layouts/BuildingProvider";

export type Device = {
  id: string;
  name: string;
  description: string;
  installed_at: string;
  updated_at: string;
  status: boolean;
  site_id: number;
  floor_id: number;
} & (DeviceExtensionCamera | DeviceExtensionEdge);

interface DataProps {
  schedule: undefined | null | any[];
  parameter: undefined | null | any[];
}

type TabProps = "schedule" | "parameter";

const SettingPage = () => {
  const { buildingCode } = useBuildingContext();
  const { devices } = useDevicesContext();
  const TempDeviceConfig: any = DeviceConfig;
  const addDialog = useDisclosure();
  const deleteDialog = useDisclosure();
  const addParameterDialog = useDisclosure();
  const [data, setData] = useState<DataProps>({
    schedule: undefined,
    parameter: undefined,
  });
  const [loading, setLoading] = useState(false);

  const [selectedType, setSelectedType] = useState<TabProps>("schedule");
  const [selectedIoTDevice, setSelectedIoTDevice] = useState<any>("all");
  const [controllableAgents, setControllableAgents] = useState<string[] | undefined>();
  const [allAgents, setAllAgents] = useState<string[] | undefined>();
  const [selectedAutomation, setSelectedAutomation] = useState<any>();

  const handleSelectType = (type: TabProps) => {
    setSelectedType(type);
  };

  const handleSelectIoTDevice = (device: string) => {
    setSelectedIoTDevice(device);
  };

  const getData = async (tabName: TabProps) => {
    setData((prev) => {
      let tempPrev = { ...prev };
      tempPrev[tabName] = undefined;
      return tempPrev;
    });
    try {
      const result = await getAutomationsByType(tabName);
      setData((prev) => ({ ...prev, [tabName]: result?.data?.automations }));
    } catch (err) {
      responseErrorHandler(err);
      setData((prev) => {
        let tempPrev = { ...prev };
        tempPrev[tabName] = null;
        return tempPrev;
      });
    }
  };

  const openDeleteAutomation = (value: any) => {
    setSelectedAutomation(value.automation_id);
    deleteDialog.onOpen();
  };

  const onDeleteAutomation = async () => {
    try {
      setLoading(true);
      // await toast.promise(deleteAutomation(selectedAutomation), {
      //   loading: "Sending...",
      //   success: (res: any) => {
      //     setLoading(false);
      //     getData(selectedType);
      //     deleteDialog.onClose();
      //     return <b>Successful</b>;
      //   },
      //   error: (error: any) => {
      //     deleteDialog.onClose();
      //     setLoading(false);
      //     if (axios.isAxiosError(error) && error.response?.status === 400) {
      //       return <b>Failed</b>;
      //     }
      //     return <b>Failed</b>;
      //   },
      // });
    } catch (e: unknown) {
      deleteDialog.onClose();
      setLoading(false);
      if (e instanceof Error) {
        toast.error(<b>Failed to add device: {e.message}</b>);
      }
      throw e;
    }
  };

  const onAddAutomation = async (payload: any) => {
    try {
      setLoading(true);
      // await toast.promise(postAutomation(payload), {
      //   loading: "Sending...",
      //   success: (res: any) => {
      //     setLoading(false);
      //     getData(selectedType);
      //     addDialog.onClose();
      //     return <b>Successful</b>;
      //   },
      //   error: (error: any) => {
      //     addDialog.onClose();
      //     setLoading(false);
      //     if (axios.isAxiosError(error) && error.response?.status === 400) {
      //       return <b>Failed</b>;
      //     }
      //     return <b>Failed</b>;
      //   },
      // });
    } catch (err) {
      addDialog.onClose();
      setLoading(false);
      responseErrorHandler(err);
    }
  };

  useEffect(() => {
    getData(selectedType);
  }, [selectedType]);

  useEffect(() => {
    let listOfControllableAgents: string[] = [];
    let listOfAllAgents: string[] = [];
    Object.keys(TempDeviceConfig).map((agentId: string) => {
      if (TempDeviceConfig[agentId]["command"]) {
        listOfControllableAgents.push(agentId);
      }
      listOfAllAgents.push(agentId);
    });
    setControllableAgents(listOfControllableAgents);
    setAllAgents(listOfAllAgents);
  }, []);

  return (
    <PageContainer>
      <Row gutter={[18, 18]}>
        <Col xs={24}>
          <SettingTypeSection
            selectedType={selectedType}
            handleSelectType={handleSelectType}
            selectedIoTDevice={selectedIoTDevice}
            handleSelectIoTDevice={handleSelectIoTDevice}
          />
        </Col>
        <Col xs={24} xl={24}>
          {selectedType === "schedule" && (
            <ScheduleSection
              data={data["schedule"]}
              addDialog={addDialog}
              loading={loading}
              deleteDialog={deleteDialog}
              deviceData={devices?.filter((item: any) => controllableAgents?.includes(item.agent_id) && item.room)}
              openDeleteAutomation={openDeleteAutomation}
              onCloseDeleteModal={() => deleteDialog.onClose()}
              onDeleteAutomation={onDeleteAutomation}
              onAddAutomation={onAddAutomation}
              selectedAutomation={selectedAutomation}
              buildingCode={buildingCode}
            />
          )}
          {selectedType === "parameter" && (
            <ParameterSection
              data={data["parameter"]}
              loading={loading}
              addParameterDialog={addParameterDialog}
              deviceData={devices?.filter((item: any) => allAgents?.includes(item.agent_id))}
              controllableDeviceData={devices?.filter((item: any) => controllableAgents?.includes(item.agent_id) && item.room)}
              onAddAutomation={onAddAutomation}
            />
          )}
        </Col>
      </Row>
    </PageContainer>
  );
};

export default SettingPage;
