export interface AppProps {
  name: string
  url: string
  status: string
}

export interface StoreListProps {
  hightlight: AppProps[],
  available: AppProps[]
}

export const StoreList: StoreListProps =
{
  hightlight: [{
    name: 'Alto Bot',
    url:
      'https://firebasestorage.googleapis.com/v0/b/altohotel-b6ae5.appspot.com/o/AltoStore%2FSmart_Hotel_Management%2F14.png?alt=media&token=958cca9e-3352-42d8-a83e-c97f8b1dc124',
    status: 'INSTALLED',
  },
  {
    name: 'Asset Management',
    url:
      'https://firebasestorage.googleapis.com/v0/b/altohotel-b6ae5.appspot.com/o/AltoStore%2FSmart_Hotel_Management%2F13.png?alt=media&token=81e9159c-0732-4169-83d4-3e220fabfbe6',
    status: 'INSTALLED',
  }],
  available: [
    {
      name: 'Cloud Monitor And Control',
      url:
        'https://firebasestorage.googleapis.com/v0/b/altohotel-b6ae5.appspot.com/o/AltoStore%2FSmart_Hotel_Management%2F16.png?alt=media&token=59ca3631-1182-42c9-bb1f-09faf72e8b80',
      status: 'INSTALLED',
    },
    {
      name: 'PMS Integration',
      url:
        'https://firebasestorage.googleapis.com/v0/b/altohotel-b6ae5.appspot.com/o/AltoStore%2FThird_Party_Integration%2F19.png?alt=media&token=9cfc414b-496f-4de5-a1dd-14a3eddfe13b',
      status: 'INSTALLED',
    },
    {
      name: 'Load Forecast',
      url:
        'https://firebasestorage.googleapis.com/v0/b/altohotel-b6ae5.appspot.com/o/AltoStore%2FEnergy_Management%2F7.png?alt=media&token=af8ee7a1-aac4-442a-96e0-acc92af9de0b',
      status: 'INSTALLED',
    },
    {
      name: 'Peak Tracking',
      url:
        'https://firebasestorage.googleapis.com/v0/b/altohotel-b6ae5.appspot.com/o/AltoStore%2FEnergy_Management%2F6.png?alt=media&token=2d0e7570-e210-4108-a4ba-842aed37f547',
      status: 'INSTALLED',
    },
    {
      name: 'Counting people in public space',
      url:
        'https://firebasestorage.googleapis.com/v0/b/altohotel-b6ae5.appspot.com/o/AltoStore%2FEnergy_Management%2F1.png?alt=media&token=909c6f35-d7a0-4d56-865f-e9df8253d6d1',
      status: 'INSTALLED',
    },
    {
      name: 'Common Space Energy Management System',
      url:
        'https://firebasestorage.googleapis.com/v0/b/altohotel-b6ae5.appspot.com/o/AltoStore%2FEnergy_Management%2F3.png?alt=media&token=7dc8854c-f897-46fd-887e-79c24c41c634',
      status: 'INSTALLED',
    },
    {
      name: 'Maid Connect',
      url:
        'https://firebasestorage.googleapis.com/v0/b/altohotel-b6ae5.appspot.com/o/AltoStore%2FEnergy_Management%2F5.png?alt=media&token=d88657af-34f4-478c-b1b5-1384ba33a53a',
      status: 'INSTALLED',
    },

    {
      name: 'Device Operation Scheduling',
      url:
        'https://firebasestorage.googleapis.com/v0/b/altohotel-b6ae5.appspot.com/o/AltoStore%2FEnergy_Management%2F9.png?alt=media&token=49db5e10-a3cb-4593-b43b-2f8b0ed572ea',
      status: 'INSTALLED',
    },
    {
      name: 'Room Energy Management System',
      url:
        'https://firebasestorage.googleapis.com/v0/b/altohotel-b6ae5.appspot.com/o/AltoStore%2FEnergy_Management%2F4.png?alt=media&token=036d91dd-9fce-42b7-a887-b47b693dd21f',
      status: 'INSTALLED',
    },
    {
      name: 'LINE Notification For Hotel Owner',
      url:
        'https://firebasestorage.googleapis.com/v0/b/altohotel-b6ae5.appspot.com/o/AltoStore%2FThird_Party_Integration%2F20.png?alt=media&token=d9964031-0586-41ff-8222-7d69dd539b2e',
      status: 'INSTALLED',
    },
    {
      name: 'Room Allocation',
      url:
        'https://firebasestorage.googleapis.com/v0/b/altohotel-b6ae5.appspot.com/o/AltoStore%2FEnergy_Management%2F10.png?alt=media&token=24e0d523-27ef-45d5-8361-562db3736aeb',
      status: 'GET',
    },
    {
      name: 'Solar Forecast',
      url:
        'https://firebasestorage.googleapis.com/v0/b/altohotel-b6ae5.appspot.com/o/AltoStore%2FEnergy_Management%2F8.png?alt=media&token=a518be62-4dd3-4501-9849-1e663c775d3a',
      status: 'GET',
    },
    {
      name: 'Guest occupancy detection',
      url:
        'https://firebasestorage.googleapis.com/v0/b/altohotel-b6ae5.appspot.com/o/AltoStore%2FEnergy_Management%2F2.png?alt=media&token=1a89f403-f6ce-4d24-b846-76a87b1b5bc6',
      status: 'INSTALLED',
    },
    {
      name: 'Chiller Settings Recommentdation',
      url:
        'https://firebasestorage.googleapis.com/v0/b/altohotel-b6ae5.appspot.com/o/AltoStore%2FEnergy_Management%2F11.png?alt=media&token=988a1d2a-3325-4e8b-95b5-71d0748afc72',
      status: 'GET',
    },
    {
      name: 'Voice Command In Room',
      url:
        'https://firebasestorage.googleapis.com/v0/b/altohotel-b6ae5.appspot.com/o/AltoStore%2FSmart_Hotel_Management%2F12.png?alt=media&token=5fff7870-88db-4186-a5a0-c07429060cdd',
      status: 'GET',
    },

    {
      name: 'Calculate CO2 Emission',
      url:
        'https://firebasestorage.googleapis.com/v0/b/altohotel-b6ae5.appspot.com/o/AltoStore%2FSmart_Hotel_Management%2F15.png?alt=media&token=9727ba50-5cb0-49f2-92d5-cb8d0c48371e',
      status: 'GET',
    },

    {
      name: 'Local Monitor And Control',
      url:
        'https://firebasestorage.googleapis.com/v0/b/altohotel-b6ae5.appspot.com/o/AltoStore%2FSmart_Hotel_Management%2F17.png?alt=media&token=c33fd1a9-9143-47c6-bcc2-b54b11eb2ef4',
      status: 'GET',
    },
    {
      name: 'Google Calendar',
      url:
        'https://firebasestorage.googleapis.com/v0/b/altohotel-b6ae5.appspot.com/o/AltoStore%2FThird_Party_Integration%2F18.png?alt=media&token=ea71670b-624e-40dc-b41c-ac3d7db54fbc',
      status: 'GET',
    }
  ]
}
