import { useState, useEffect } from 'react'
import { Row, Col } from 'antd';

import { TabNameProps } from 'pages/EnergyPage';
import { RealtimeSection } from './RealtimeSection';
import { SummarySection } from './SummarySection';
import LineTab from 'components/LineTab';

const TabNameList: TabNameProps = [
  { value: 'realtime', title: "Realtime" },
  { value: 'summary', title: "Summary" },
]

const SelfDriving = ({ data }: any) => {
  const [selectedSecTabName, setSelectedSecTabName] = useState<string>('realtime')

  const handleSelectSecTabName = (tabName: string) => {
    setSelectedSecTabName(tabName)
  }

  useEffect(() => {
  }, [])

  return (
    <>
      <Row gutter={[18,18]}>
        <Col sm={24} xl={24} style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <LineTab
            dataList={TabNameList}
            selectedValue={selectedSecTabName}
            handleSelect={handleSelectSecTabName}
            bgColor={'transparent'}
            size={'small'}
            selectedTextColor={'#397CDD'}
          />
        </Col>
      </Row>
      {selectedSecTabName === "realtime" &&
        <RealtimeSection />
      }
      {selectedSecTabName === "summary" &&
        <SummarySection />
      }
    </>
  )
}

export default SelfDriving