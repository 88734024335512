import { useContext, useEffect, useState } from "react";
import { PageContainer } from "components/Common/components";
import FloorSection from "./FloorSection";
import SummarySection from "./SummarySection";
import { FirebaseContext } from "../../firebase";
import {
  co2_path,
  energy_saving_path,
  indoor_path,
  iot_devices,
  outdoor_path,
  EmptyDeviceData,
  total_energy_consumption_path,
  iot_devices_path,
  tooltips_path,
  daikin_camera,
  benchmark_rl_path,
} from "MockData/FirebasePath";
import { PieDataProps } from "components/Common/Graph/PieChart";
import { getEnergyConsumption, getEnergyConsumptionByFloor } from "services/api.service";
import { GraphModal } from "components/DeviceModal/GraphModal";
import { Col, Row } from "antd";
import { convertDatetimeToStrDate, convertDatetimeToStrMonth, getTomorrow } from "utils/convertDatetime";
import { responseErrorHandler } from "services/prepareAxios";
import { prepBarGraph, prepLineGraph, prepLineGraphModal, prepPieGraph } from "utils/prepGraphData";
import { useBuildingContext } from "layouts/BuildingProvider";
import modal_graph_map_by_building from "configs/modal_graph_map_by_building.json";
import real_time_power_map_by_building from "configs/real_time_power_map_by_building.json";
import real_time_power_map_by_floor from "configs/real_time_power_map_by_floor.json";
import building_config from "configs/building_config.json";
import energy_consumption_key from "configs/energy_consumption_key.json";
import { mapArrayToKey } from "utils/mapArrayValueToKey";

const realtimePowerMapByBuilding: any = real_time_power_map_by_building;
const realtimePowerMapByFloor: { [buildingCode: string]: { [floorName: string]: { key: string; x: string; y: string[] } } } =
  real_time_power_map_by_floor;
const modalGraphMapByBuilding: any = modal_graph_map_by_building;
const floorByBuilding: { [buildingCode: string]: { floors: { label: string; value: string }[] } } = building_config;
const energyConsumptionMap: any = energy_consumption_key;

const DashboardPage = () => {
  const { buildingCode, buildingName } = useBuildingContext();
  const firebase = useContext<any>(FirebaseContext);
  const [outdoorData, setOutdoorData] = useState();
  const [indoorData, setIndoorData] = useState();
  const [co2Data, setCo2Data] = useState({ this_month: "", accumulated: "" });
  const [energySavingData, setEnergySavingData] = useState({ this_month: "", accumulated: "" });
  const [benchmarkRl, setBenchmarkRl] = useState({ avg_energy: 0, percent: 0, updated_at: "" });
  const [iotDevices, setIotDevices] = useState(EmptyDeviceData);
  const [realtimePowerData, setRealtimePowerData] = useState<any>();
  const [realtimePowerConsumption, setRealtimePowerConsumption] = useState<any>();
  const [energyConsumptionData, setEnergyConsumptionData] = useState<PieDataProps[] | null>();
  const [energyConsumptionVsBaselineData, setEnergyConsumptionVsBaselineData] = useState<any>();
  const [dashboardData2, setDashboardData2] = useState<any>();
  const [totalEnergyConsumption, setTotalEnergyConsumption] = useState<any>();
  const [selectedTab, setSelectedTab] = useState("B");
  const [selectedGraphOpen, setSelectedGraphOpen] = useState(false);
  const [modalType, setModalType] = useState("floor_1");
  const [graphData, setGraphData] = useState<any>();
  const [graphDataModal, setGraphDataModal] = useState<any>();
  const [floorMap, setFloorMap] = useState<{ [key: string]: any }>(mapArrayToKey(floorByBuilding[buildingCode]["floors"], "label"));
  // const [summaryBoxSubtitle] = useState<{ [key: string]: any }>(mapArrayToKey(floorByBuilding[buildingCode]["floors"], "label", "summary_box_label"));
  const [summaryData, setSummaryData] = useState({
    building_power_kW: [],
    floor_energy_kWh: [],
    human_count: [],
  });
  const [starttime] = useState(new Date());
  const [endtime] = useState(new Date());
  const [modalStarttime, setModalStarttime] = useState<{ [modalType: string]: Date }>({
    floor_1: new Date(),
    floor_2: new Date(),
    grid: new Date(),
    solar: new Date(),
    people: new Date(),
  });
  const [modalEndtime, setModalEndtime] = useState<{ [modalType: string]: Date }>({
    floor_1: getTomorrow(),
    floor_2: getTomorrow(),
    grid: getTomorrow(),
    solar: getTomorrow(),
    people: getTomorrow(),
  });
  const [tooltipObjectState, setTooltipObjectState] = useState<any>();

  //---------------------------1) MAIN---------------------------//
  const handleViewGraph = async (deviceData: any) => {
    setModalType(deviceData);
    setSelectedGraphOpen(true);
  };

  const search = async () => {
    let tempStarttime = convertDatetimeToStrDate(modalStarttime[modalType]);
    let tempEndtime = convertDatetimeToStrDate(modalEndtime[modalType]);
    try {
      setGraphDataModal((prev: any) => {
        const tempPrev = { ...prev };
        tempPrev[modalType] = undefined;
        return tempPrev;
      });
      const response = await getEnergyConsumption({ type: "dashboard", building_name: buildingName, starttime: tempStarttime, endtime: tempEndtime });
      const result = response.data.results;
      const prepData: any = prepLineGraphModal(result, modalGraphMapByBuilding[buildingCode]);
      setGraphDataModal((prev: any) => {
        const tempPrev = { ...prev };
        tempPrev[modalType] = prepData[modalType];
        return tempPrev;
      });
    } catch (err) {
      setGraphDataModal((prev: any) => {
        const tempPrev = { ...prev };
        tempPrev[modalType] = null;
        return tempPrev;
      });
    }
  };

  const subscribeDevice = (
    didMount: boolean,
    tooltipKey: string,
    device_id: string,
    type?: string,
    schema?: string,
    subdevice?: string,
    subdeviceKey?: string,
    hotel_code?: any
  ) => {
    if (didMount) {
      firebase.db.ref(iot_devices_path + "/" + device_id).off("value");
    } else {
      firebase.db.ref(iot_devices_path + "/" + device_id).on("value", function (snap: { val: () => any }) {
        if (snap) {
          const value = snap.val();
          if (type === "camera") {
            if (hotel_code === "BGM") {
              const numberOfPeople = Number(value?.count_people_in - value?.count_people_out);
              setDashboardData2((prev: any) => {
                let tempPrev = { ...prev };
                if (tempPrev[tooltipKey]["items"][device_id]) {
                  tempPrev[tooltipKey]["items"][device_id]["value"] = numberOfPeople;
                  return tempPrev;
                }
                return tempPrev;
              });
            } else {
              const numberOfPeople = Number(value?.camera_inout?.subdev_0?.count_people_in - value?.camera_inout?.subdev_0?.count_people_out);
              setDashboardData2((prev: any) => {
                let tempPrev = { ...prev };
                if (tempPrev[tooltipKey]["items"][device_id]) {
                  tempPrev[tooltipKey]["items"][device_id]["value"] = numberOfPeople;
                  return tempPrev;
                }
                return tempPrev;
              });
            }
          } else {
            if (value && schema && subdevice && subdeviceKey) {
              let power = Number(value[schema][subdevice][subdeviceKey].toFixed(2));
              const ts = new Date(value[schema][subdevice]["timestamp"]);
              const tsString = ts.getHours() + ":" + ts.getMinutes();
              setDashboardData2((prev: any) => {
                let tempPrev = { ...prev };
                tempPrev[tooltipKey]["items"][device_id]["value"] = power;
                tempPrev[tooltipKey]["items"][device_id]["timestamp"] = tsString;
                return tempPrev;
              });
            }
          }
        }
      });
    }
  };

  function fetchFbData(didMount: boolean, buildingCode?: string) {
    if (didMount) {
      firebase.db.ref(total_energy_consumption_path).off("value");
      firebase.db.ref(outdoor_path).off("value");
      firebase.db.ref(indoor_path).off("value");
      firebase.db.ref(iot_devices).off("value");
      firebase.db.ref(co2_path).off("value");
      firebase.db.ref(energy_saving_path).off("value");
      firebase.db.ref(daikin_camera["camera1"]).off("value");
      firebase.db.ref(daikin_camera["camera2"]).off("value");
      firebase.db.ref(daikin_camera["camera3"]).off("value");
      firebase.db.ref(daikin_camera["camera4"]).off("value");
      if (tooltipObjectState) {
        Object.entries(tooltipObjectState).forEach(([tooltipKey, tooltipValue]: any) => {
          Object.entries(tooltipValue.items).forEach(([deviceId, value]: any) => {
            if (value.device_id) {
              subscribeDevice(true, tooltipKey, value.device_id);
            }
          });
        });
      }
    } else {
      firebase.db.ref(total_energy_consumption_path).on("value", function (snap: { val: () => any }) {
        if (snap) {
          setTotalEnergyConsumption(snap.val());
        }
      });
      firebase.db.ref(outdoor_path).on("value", function (snap: { val: () => any }) {
        if (snap) {
          setOutdoorData(snap.val());
        }
      });
      firebase.db.ref(indoor_path).on("value", function (snap: { val: () => any }) {
        if (snap) {
          setIndoorData(snap.val());
        }
      });
      firebase.db.ref(iot_devices).on("value", function (snap: { val: () => any }) {
        if (snap) {
          setIotDevices(snap.val());
        }
      });
      firebase.db.ref(co2_path).on("value", function (snap: { val: () => any }) {
        if (snap) {
          setCo2Data(snap.val());
        }
      });
      firebase.db.ref(energy_saving_path).on("value", function (snap: { val: () => any }) {
        if (snap) {
          setEnergySavingData(snap.val());
        }
      });
      firebase.db.ref(benchmark_rl_path).on("value", function (snap: { val: () => any }) {
        if (snap) {
          setBenchmarkRl(snap.val());
        }
      });
      if (buildingCode === "DAIC") {
        ["camera1", "camera2", "camera3", "camera4"].forEach((cameraName: string) => {
          firebase.db.ref(daikin_camera["cameraName"]).on("value", function (snap: { val: () => any }) {
            if (snap) {
              const value = snap.val();
              const numberOfPeople = Number(value?.count_people_in - value?.count_people_out);
              setDashboardData2((prev: any) => {
                let tempPrev = { ...prev };
                if (tempPrev?.people?.items?.camera1) {
                  tempPrev["people"]["items"]["cameraName"]["value"] = numberOfPeople;
                  return tempPrev;
                }
                return tempPrev;
              });
            }
          });
        });
      }
      firebase.db.ref(tooltips_path).once("value", function (snap: { val: () => any }) {
        if (snap) {
          const tooltipObject = snap.val();
          setDashboardData2(tooltipObject);
          if (tooltipObject) {
            setTooltipObjectState(tooltipObject);
            Object.entries(tooltipObject).forEach(([tooltipKey, tooltipValue]: any) => {
              Object.entries(tooltipValue.items).forEach(([deviceId, value]: any) => {
                if (value.device_id) {
                  subscribeDevice(
                    false,
                    tooltipKey,
                    value.device_id,
                    tooltipValue.type,
                    value.schema,
                    value.subdevice,
                    value.subdevice_key,
                    buildingCode
                  );
                }
              });
            });
          }
        }
      });
    }
  }

  const fetchEnergyConsumptionBuilding = async (buildingCode: string) => {
    let today = new Date();
    let tempStarttime = convertDatetimeToStrDate(today);
    let tempEndtime = convertDatetimeToStrDate(today, "tomorrow");
    try {
      const response = await getEnergyConsumption({ type: "dashboard", building_name: buildingName, starttime: tempStarttime, endtime: tempEndtime });
      let result = response.data.results;
      // 1) Set summary graph
      setSummaryData(result);
      setRealtimePowerData(prepLineGraph(result.building_power_kW, realtimePowerMapByBuilding[buildingCode], "timestamp"));
      setEnergyConsumptionVsBaselineData(prepLineGraph(result.building_power_kW, ["power_load_est"], "timestamp"));
      setEnergyConsumptionData(prepPieGraph(result, buildingCode));
      // 2) Set modal graph
      setGraphData(prepLineGraphModal(result, modalGraphMapByBuilding[buildingCode]));
      setGraphDataModal(prepLineGraphModal(result, modalGraphMapByBuilding[buildingCode]));
      let powerConsByFloor: { [floorName: string]: any } = {};
      Object.entries(realtimePowerMapByFloor[buildingCode]).forEach(([floorName, val]: any) => {
        powerConsByFloor[floorName] = {
          realtimePowerConsumption: prepLineGraph(result[val.key], val.y, val.x),
        };
      });
      setRealtimePowerConsumption(powerConsByFloor);
    } catch (err) {
      setGraphData(null);
      setRealtimePowerData(null);
      setEnergyConsumptionVsBaselineData(null);
      setEnergyConsumptionData(null);
      setRealtimePowerConsumption({
        F1: null,
        F2: null,
      });
      responseErrorHandler(err);
    }
  };

  useEffect(() => {
    setFloorMap(mapArrayToKey(floorByBuilding[buildingCode]["floors"], "label"));
    fetchEnergyConsumptionBuilding(buildingCode);
    fetchFbData(false, buildingCode);
    return () => {
      fetchFbData(true);
    };
    // eslint-disable-next-line
  }, []);
  //-------------------------END MAIN--------------------------//

  //---------------------------2) FLOOR---------------------------//
  const fetchEnergyConsByFloor = async (selectedTab: string) => {
    try {
      const dailyRequest = getEnergyConsumptionByFloor({
        type: "floor_energy",
        building_name: buildingName,
        starttime: convertDatetimeToStrDate(starttime, -6),
        endtime: convertDatetimeToStrDate(endtime),
        floor_name: floorMap[selectedTab]["value"],
        sample: "daily",
      });
      const monthlyRequest = getEnergyConsumptionByFloor({
        type: "floor_energy",
        building_name: buildingName,
        starttime: convertDatetimeToStrMonth(starttime, -2),
        endtime: convertDatetimeToStrMonth(endtime),
        floor_name: floorMap[selectedTab]["value"],
        sample: "monthly",
      });
      const [dailyResponse, monthlyResponse] = await Promise.all([dailyRequest, monthlyRequest]);
      if (realtimePowerConsumption[selectedTab]) {
        setRealtimePowerConsumption((prev: any) => {
          const tempPrev: any = { ...prev };
          tempPrev[selectedTab]["dailyEnergyConsumption"] = prepBarGraph(
            dailyResponse?.data?.floor_data,
            energyConsumptionMap[selectedTab]["kWh"],
            "date"
          );
          tempPrev[selectedTab]["monthlyEnergyConsumption"] = prepBarGraph(
            monthlyResponse?.data?.floor_data,
            energyConsumptionMap[selectedTab]["kWh"],
            "date"
          );
          tempPrev[selectedTab]["co2"] = {
            daily: dailyResponse?.data?.floor_data[dailyResponse?.data?.floor_data?.length - 1][energyConsumptionMap[selectedTab]["co2"]],
            monthly: monthlyResponse?.data?.floor_data[monthlyResponse?.data?.floor_data?.length - 1][energyConsumptionMap[selectedTab]["co2"]],
          };
          return tempPrev;
        });
      }
    } catch (err) {
      responseErrorHandler(err);
      setRealtimePowerConsumption({
        [selectedTab]: {
          realtimePowerConsumption: null,
          dailyEnergyConsumption: null,
          monthlyEnergyConsumption: null,
          co2: null,
        },
      });
    }
  };

  useEffect(() => {
    if (selectedTab !== "B") {
      fetchEnergyConsByFloor(selectedTab);
    }
  }, [selectedTab]);
  //------------------------END FLOOR------------------------//

  //--------------------------3) MODAL--------------------------//
  const onChangeModalStarttime = (time: Date) => {
    setModalStarttime((prev: any) => ({ ...prev, [modalType]: time }));
  };

  const onChangeModalEndtime = (time: Date) => {
    setModalEndtime((prev: any) => {
      let tempPrev = { ...prev };
      tempPrev[modalType] = time;
      return tempPrev;
    });
  };

  useEffect(() => {
    if (selectedGraphOpen) {
      search();
    }
  }, [modalStarttime, modalEndtime]);
  //------------------------END MODAL------------------------//

  return (
    <>
      <PageContainer>
        <Row gutter={[18, 18]}>
          <Col xs={24} style={{ zIndex: 9999 }}>
            <FloorSection
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              iotDevices={iotDevices}
              outdoorData={outdoorData}
              indoorData={indoorData}
              dashboardData2={dashboardData2}
              totalEnergyConsumption={totalEnergyConsumption}
              handleViewGraph={handleViewGraph}
              siteName={buildingName}
              buildingCode={buildingCode}
              floorMap={floorMap}
            />
          </Col>
          <Col xs={24}>
            <SummarySection
              realtimePowerData={realtimePowerData}
              realtimePowerConsumption={realtimePowerConsumption}
              selectedTab={selectedTab}
              co2Emission={co2Data}
              energySaving={energySavingData}
              energyConsumptionData={energyConsumptionData}
              energyConsumptionVsBaselineData={energyConsumptionVsBaselineData}
              graphData={graphData}
              summaryData={summaryData}
              buildingCode={buildingCode}
              benchmarkRl={benchmarkRl}
              floorMap={floorMap}
            />
          </Col>
        </Row>
        <GraphModal
          buildingCode={buildingCode}
          selectedGraphOpen={selectedGraphOpen}
          floorName={selectedTab}
          closeModal={() => setSelectedGraphOpen(false)}
          starttime={modalStarttime[modalType]}
          setStarttime={onChangeModalStarttime}
          endtime={modalEndtime[modalType]}
          setEndtime={onChangeModalEndtime}
          modalType={modalType}
          data={graphDataModal}
          search={search}
        />
      </PageContainer>
    </>
  );
};

export default DashboardPage;
