import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepickerStyle.css";
import { CalendarIconBox, CalendarIcon, DateRangePickerContainer } from "./styledComponents";
import CalendarIconImage from "assets/common/icon/calendarIcon.svg";

export const DateRangePicker = ({ startDate, setStartDate, endDate, setEndDate, marginBottom }: any) => {
  return (
    <DateRangePickerContainer marginBottom={marginBottom}>
      <DatePicker
        className="input-class-first"
        selected={startDate}
        onChange={(date: any) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        placeholderText="Start date"
      />
      <DatePicker
        className="input-class-last"
        selected={endDate}
        onChange={(date: any) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        placeholderText="Stop date"
      />
      <CalendarIconBox>
        <CalendarIcon src={CalendarIconImage} />
      </CalendarIconBox>
    </DateRangePickerContainer>
  );
};

export const SingleDatePicker = ({ startDate, setStartDate, marginBottom, disabled }: any) => {
  return (
    <DateRangePickerContainer marginBottom={marginBottom}>
      <DatePicker
        className="input-class-first-2"
        selected={startDate}
        onChange={(date: any) => setStartDate(date)}
        selectsStart
        placeholderText="Date"
        disabled={disabled ? disabled : false}
      />
      <CalendarIconBox>
        <CalendarIcon src={CalendarIconImage} />
      </CalendarIconBox>
    </DateRangePickerContainer>
  );
};
