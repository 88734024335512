import tw from "twin.macro";
import styled from "styled-components";
import { FieldError } from "react-hook-form";

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    border: "0.5px solid #f3f3f3",
    maxHeight: "95vh",
    padding: "34px 42px",
    width: "1096px",
  },
  overlay: {
    backgroundColor: "#00000066",
    zIndex: 99999,
  },
};

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: unset;
`;

export const Title = tw.h3`sticky top-0 pb-4 text-2xl font-bold mb-0 z-10 bg-white`;

export const SubdeviceTitle = tw.h4`col-span-2 pt-1 pb-0 text-xl font-bold mb-0 text-primary`;

export interface FieldErrorMessageProps {
  error: FieldError | undefined;
}

export const WrapInput = tw.div`relative flex flex-col w-[calc(100% - 176px)] h-10 justify-center gap-y-2`;

export const WrapCheckbox = tw.div`flex min-h-[40px] gap-x-4 items-center`;

export const CheckBox = tw.input`w-5 h-5`;

export const CheckBoxText = tw.span`text-base`;

export const Span = tw.span`text-xs absolute text-red-500 bottom-[-16px]`;

export function FieldErrorMessage({ error }: FieldErrorMessageProps) {
  if (!error) return null;
  return <Span>{error.message}</Span>;
}

export const InputContainer = tw.div`
  flex gap-1 items-start
`;

export const Label = tw.label`
  text-base text-black1 flex items-center w-[176px] mt-2
`;

export const WrapTable = tw.div`col-span-2 flex items-center justify-start gap-4`;

export const WrapButton = tw.div`col-span-2 flex items-center justify-end gap-4`;

export const Input = styled.input`
  ${tw`
  w-full h-10 px-4 rounded-lg 
  border border-[rgba(14, 126, 228, 0.6)]
  outline-none
  transition-all
  border-solid
  focus:(ring-primary-light ring)
  placeholder:(text-primary)
  disabled:(bg-[#F3F4FA] border-none text-black2 placeholder:text-black2)
  focus:shadow-[0 0 0 2px rgb(24 144 255 / 20%)]
  [&[data-view-mode=true]]:(text-primary-dark bg-transparent)
  `}
`;

export const Button = styled.button<any>`
  ${tw`
  h-10 px-4 rounded-lg font-medium transition
  flex items-center justify-center gap-2
  border border-primary border-solid
  cursor-pointer disabled:cursor-not-allowed
  [&:not([data-outlined=true])]:(
    bg-primary text-white
    hover:(shadow) active:(shadow-none)
  )
  [&[data-outlined=true]]:(
    bg-transparent text-primary
    border-primary
    hover:(bg-primary/10)
    active:(bg-primary/20)
  )
  `}
`;

export const AddButton = styled.div`
  ${tw`
  absolute h-10 px-10 top-2 right-0 rounded-lg font-medium transition
  flex items-center justify-center gap-2
  border border-primary border-solid
  cursor-pointer
  [&:not([data-outlined=true])]:(
    bg-primary text-white
    hover:(shadow) active:(shadow-none)
  )
  [&[data-outlined=true]]:(
    bg-transparent text-primary
    border-primary
    hover:(bg-primary/10)
    active:(bg-primary/20)
  )
  `}
`;

export const DeviceActionForm = styled.div(() => [tw`flex-1 grid grid-cols-2 gap-x-10 gap-y-6 justify-end z-0`]);

export const Form = styled.form(() => [tw`flex-1 grid grid-cols-2 gap-x-10 gap-y-6 justify-end z-0`]);

export const ActionWrap = styled.div(() => [tw`col-span-2 flex justify-center`]);

export const Line = styled.div(() => [tw`h-[1px] w-full bg-altoWhite-white03 col-span-2`]);
