import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col, Switch } from "antd";
import { FirebaseContext } from "../../firebase";
import { postC2D } from "services/api.service";
import { iot_devices_path } from "MockData/FirebasePath";

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  padding: 9px 18px 12px 18px;
  background-color: #f9f9f9;
  border-radius: 16px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
`;

const Title = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #0e7ee4;
  margin: 0px;
`;

const Desc = styled.p`
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  margin-bottom: 0px;
  color: #0e7ee4;
`;

export const SelfDrivingMode = ({ deviceData, selfDrivingStatus, setSelfDrivingStatus }: any) => {
  const [loading, setLoading] = useState(true);
  const [isInitial] = useState(true);
  const firebase = useContext<any>(FirebaseContext);
  function fetchData(didMount: boolean) {
    const url = iot_devices_path + `/${deviceData?.device_id}` + ":;0";
    if (didMount) {
      firebase.db.ref(url).off("value");
    } else {
      firebase.db.ref(url).on("value", function (snap: { val: () => any }) {
        if (snap?.val()) {
          const value = snap.val()?.rl_output_state?.subdev_0;
          if (isInitial) {
            setSelfDrivingStatus(value);
            setLoading(() => false);
          } else {
            if (loading && selfDrivingStatus) {
              if (value.output_enable === selfDrivingStatus.output_enable) {
                setLoading(() => false);
                setSelfDrivingStatus(value);
              }
            } else {
              setLoading(() => false);
              setSelfDrivingStatus(value);
            }
          }
        }
      });
    }
  }

  const onChange = (data: any) => {
    setLoading(() => true);
    postC2D({
      device_id: `${deviceData?.device_id}` + ":;0",
      command: { output_enable: data },
      schema: "rein_output",
    });
  };

  useEffect(() => {
    fetchData(false);
    return () => {
      fetchData(true);
    };
  }, []);

  if (!deviceData) {
    return null;
  }

  return (
    <>
      <Container>
        <Row style={{ width: "100%" }}>
          <Col xs={20}>
            <Row>
              <Col xs={24}>
                <Title>Self-Driving Mode</Title>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Desc>Automatically control the building by AI</Desc>
              </Col>
            </Row>
          </Col>
          <Col xs={4}>
            <div
              style={{
                display: "flex",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Switch
                defaultChecked
                onChange={onChange}
                disabled={selfDrivingStatus === undefined}
                checked={selfDrivingStatus?.output_enable}
                loading={loading}
                style={{ background: "rgba(0, 0, 0, 0.25)" }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
