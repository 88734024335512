const convertString = (rawNumber: number) => rawNumber < 10 ? '0' + rawNumber : (rawNumber).toString();

const dateTypeMap: { [type: string]: number } = {
  "tomorrow": 1,
  "yesterday": -1
}

const changeDatetime = (datetime: Date, number: number): Date => {
  let newDate = new Date()
  newDate.setDate(datetime.getDate() + number)
  return newDate
}

const changeMonth = (datetime: Date, number: number): Date => {
  let newDate = new Date()
  newDate.setMonth(datetime.getMonth() + number)
  return newDate
}

export const convertDatetimeToStr = ({ datetime, time, type }: { datetime: Date, time?: any, type?: string }) => {
  if (type && type === 'yesterday') {
    const year = datetime.getFullYear()
    const month = convertString(datetime.getMonth() + 1)
    const date = convertString(datetime.getDate() - 1)
    return year + '-' + month + '-' + date
  }
  if (type && type === 'tomorrow') {
    const year = datetime.getFullYear()
    const month = convertString(datetime.getMonth() + 1)
    const date = convertString(datetime.getDate())
    return year + '-' + month + '-' + date
  }
  let month = convertString(datetime.getMonth() + 1)
  let date = convertString(datetime.getDate())
  let selectedTime
  if (!time) {
    const hour = convertString(datetime.getHours())
    const min = convertString(datetime.getMinutes())
    selectedTime = hour + ':' + min
  } else {
    selectedTime = time
  }
  let strDatetime = datetime.getFullYear() + '-' + month + '-' + date + ' ' + selectedTime;
  return strDatetime;
};

export const convertDatetimeToStrDatetime = (datetime: Date, dateType?: string) => {
  if (dateType === "yesterday") {
    const hour = convertString(datetime.getHours())
    const min = convertString(datetime.getMinutes())
    const selectedTime = hour + ':' + min
    return datetime.getFullYear() + "-" + convertString(datetime.getMonth() + 1) + "-" + convertString(datetime.getDate() - 1) + ' ' + selectedTime
  } else if (dateType === "tomorrow") {
    let tomorrow = new Date()
    tomorrow.setDate(datetime.getDate() + 1)
    const hour = convertString(tomorrow.getHours())
    const min = convertString(tomorrow.getMinutes())
    const selectedTime = hour + ':' + min
    return tomorrow.getFullYear() + "-" + convertString(tomorrow.getMonth() + 1) + "-" + convertString(tomorrow.getDate()) + ' ' + selectedTime
  } else {
    const hour = datetime.getHours() < 10 ? '0' + datetime.getHours() : datetime.getHours()
    const min = datetime.getMinutes() < 10 ? '0' + datetime.getMinutes() : datetime.getMinutes()
    const selectedTime = hour + ':' + min
    return (datetime.getFullYear() + "-" + convertString(datetime.getMonth() + 1) + "-" + convertString(datetime.getDate()) + ' ' + selectedTime)

  }
}

export const convertDatetimeToStrDate = (datetime: Date, dateType?: string | number) => {
  let compensation = 0
  if (typeof dateType === "string") {
    compensation = dateTypeMap[dateType]
  } else if (typeof dateType === 'number') {
    compensation = dateType
  }
  let newDate = changeDatetime(datetime, compensation)
  return newDate.getFullYear() + "-" + convertString(newDate.getMonth() + 1) + "-" + convertString(newDate.getDate())
}

export const convertDatetimeToStrDateNew = (datetime: Date, dateType?: string | number) => {
  return datetime.getFullYear() + "-" + convertString(datetime.getMonth() + 1) + "-" + convertString(datetime.getDate())
}

export const convertDatetimeToStrDatetimeNew = (datetime: Date, dateType?: string | number) => {
  let compensation = 0
  const hour = convertString(datetime.getHours())
  const min = convertString(datetime.getMinutes())
  if (typeof dateType === "string") {
    compensation = dateTypeMap[dateType]
  } else if (typeof dateType === 'number') {
    compensation = dateType
  }
  let newDate = changeDatetime(datetime, compensation)
  return newDate.getFullYear() + "-" + convertString(newDate.getMonth() + 1) + "-" + convertString(newDate.getDate()) + ' ' + hour + ':' + min
}

export const convertDatetimeToStrMonth = (datetime: Date, dateType?: number) => {
  let compensation = 0
  if (dateType) {
    compensation = dateType
  }
  let newDate = changeMonth(datetime, compensation)
  return newDate.getFullYear() + "-" + convertString(newDate.getMonth() + 1)
}

export const getTomorrow = (): Date => {
  const today = new Date();
  let tomorrow: Date = new Date();
  return new Date(tomorrow.setDate(today.getDate() + 1));
};

export const convertTimestampToDatetime = (timestamp: number): { datetime: string, ago: string } => {
  if (!timestamp) return { datetime: '', ago: '' }
  const getTimeAgo = (timestamp: number): string => {
    const now = new Date().getTime();
    const diff = now - timestamp;
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor(diff / (1000 * 60));
    const seconds = Math.floor(diff / 1000);
    if (days > 0) {
      return days + ' days';
    } else if (hours > 0) {
      return hours + ' hours';
    } else if (minutes > 0) {
      return minutes + ' minutes';
    } else if (seconds > 0) {
      return seconds + ' seconds';
    } else {
      return 'now';
    }
  }

  return (
    {
      datetime: new Date(timestamp * 1000).toLocaleString(),
      ago: getTimeAgo(timestamp * 1000) + ' ago'
    }
  )
}
