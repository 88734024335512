import { useState, useMemo, useContext } from "react";
import { Table } from "components/Common/Table/Table";
import { getDeviceHistory } from "services/api.service";
import { DateRangePicker } from "components/DateRangePicker/DateRangePicker";
import { convertDatetimeToStr, convertDatetimeToStrDatetime } from "utils/convertDatetime";
import device_config from "configs/device_config.json";
import { LoadingPage } from "components/Common/LoadingPage/LoadingPage";
import { responseErrorHandler } from "services/prepareAxios";
import { add, min, startOfDay, endOfDay } from "date-fns";
import { CSVLink } from "react-csv";
import tw from "twin.macro";
import { FiDownload } from "react-icons/fi";
import { FirebaseContext } from "../../../firebase";
import { api_monitor } from "MockData/FirebasePath";
import { API_CONSTANTS } from "services/api.constants";

export const Button = tw.button`
  h-10 px-4 rounded-lg font-medium transition
  flex items-center justify-center gap-2
  border border-transparent
  [&:not([data-outlined=true])]:(
    bg-primary text-white
    hover:(shadow) active:(shadow-none)
  )
  [&[data-outlined=true]]:(
    bg-transparent text-primary
    border-primary
    hover:(bg-primary/10)
    active:(bg-primary/20)
  )
`;

const DateContainer = tw.div`
    flex justify-between w-full
`;

export const DeviceHistory = ({ deviceData }: any) => {
  const firebase = useContext<any>(FirebaseContext);
  let today = new Date();
  const DeviceMap: any = device_config;
  const [startDate, setStartDate] = useState<Date>(today);
  const [endDate, setEndDate] = useState<Date>(new Date(convertDatetimeToStrDatetime(today, "tomorrow")));
  const [data, setData] = useState<any[] | null>();
  const [downloadData, setDownloadData] = useState<any[] | null>();
  const columns = useMemo(() => DeviceMap[deviceData.agent_id] && DeviceMap[deviceData.agent_id]["columns"], []);

  const floorData = (historyData: any[]) => {
    const toFixedColumns = ["set_temperature_x", "temperature", "set_temperature", "humidity_x"];
    const historyColumns = Object.keys(historyData[0]);
    const intersectedColumns = historyColumns.filter((column: string) => toFixedColumns.includes(column));
    return historyData.map((item: Object) => {
      const tempItem: { [key: string]: any } = { ...item };
      intersectedColumns.forEach((toFixedColumn: string) => {
        let value;
        try {
          value = tempItem[toFixedColumn].toFixed(1);
        } catch (err) {
          value = tempItem[toFixedColumn];
        }
        tempItem[toFixedColumn] = value;
      });
      return tempItem;
    });
  };

  function getUnixTime() {
    return (Date.now() / 1000) | 0;
  }

  const getDeviceHistoryUrl = (
    starttime: any,
    endtime: any,
    device_id: any,
    subdevice_idx: any,
    device_activity: any,
    sample_min: any,
    type: string
  ) => {
    let url: string = `?RequestId=123&starttime=${starttime}&endtime=${endtime}&device_id=${device_id}&subdevice_idx=${subdevice_idx}&device_activity=${device_activity}&sample_min=${sample_min}&type=${type}`;
    return API_CONSTANTS.HISTORY_DEVICE + url;
  };

  const dropUnusedKey = (columns: any, data: any) => {
    let emptyData: any = [];
    const allKeys = Object.keys(data[0]);
    const selectedKeys = columns.map((cols: any) => cols.accessor);
    data.forEach((item: any) => {
      let selectedData: any = {};
      selectedKeys.forEach((col: string) => {
        if (allKeys.includes(col)) {
          selectedData[col] = item[col];
        }
      });
      emptyData.push(selectedData);
    });
    setDownloadData(emptyData);
  };

  const getHistory = async (startDate: Date, endDate: Date, device_id: string, subdevice_idx: number, type: string) => {
    setData(undefined);
    let starttime = convertDatetimeToStr({ datetime: startDate }).substring(0, 10);
    let endtime = convertDatetimeToStr({ datetime: endDate }).substring(0, 10);
    try {
      if (deviceData.device_id === "9c:a5:25:ea:64:f1:1") subdevice_idx = 3;
      let response: any = await getDeviceHistory(starttime, endtime, device_id, subdevice_idx, false, 15, type);
      response = response.data.results;
      if (response[0].hasOwnProperty("humidity")) {
        response = response.map((item: any) => ({ ...item, humidity_x: item["humidity"] }));
      }
      if (response[0].hasOwnProperty("energy_y")) {
        response = response.map((item: any) => ({ ...item, energy: item["energy_y"] }));
      }
      const formattedResponse = floorData(response);
      setData(formattedResponse);
      dropUnusedKey(columns, formattedResponse);
      let payload = {
        [getUnixTime()]: {
          url: getDeviceHistoryUrl(starttime, endtime, device_id, subdevice_idx, false, 15, type),
          startDate,
          endDate,
          device_id,
          subdevice_idx,
          type,
        },
      };
      firebase.db.ref(api_monitor).update(payload);
    } catch (err) {
      setData(null);
      responseErrorHandler(err);
    }
  };

  const onRangeChange = (startInput: any, stopInput: any, maxDays: number) => {
    let start = startInput;
    let stop = stopInput;
    if (maxDays && stop) {
      //  Determine the max date
      const maxDate = add(start, { days: maxDays });
      //  Then, choose between max value and actual value
      stop = min([maxDate, stop]);
    }

    // Ensure that we include info from the start of the day
    start = startOfDay(start);

    if (stop) {
      // Ensure that we include info until the end of the day
      stop = endOfDay(stop);
    }
    setStartDate(start);
    setEndDate(stop);
  };

  useMemo(() => {
    if (deviceData && startDate && endDate) {
      let subdevice = Number(Object.keys(deviceData.subdevices)[0]);
      if (DeviceMap[deviceData.agent_id]) {
        getHistory(startDate, endDate, deviceData.device_id, subdevice, DeviceMap[deviceData.agent_id]["type"]);
      } else {
        setData(null);
      }
    }
  }, [startDate, endDate]);

  return (
    <>
      <DateContainer>
        <DateRangePicker
          startDate={startDate}
          setStartDate={(date: any) => onRangeChange(date, endDate, 31)}
          endDate={endDate}
          setEndDate={(date: any) => onRangeChange(startDate, date, 31)}
        />
        <Button title={""} type="button">
          <FiDownload size={15} color={"white"} />
          <CSVLink data={downloadData ? downloadData : []} style={{ color: "white" }}>
            Download
          </CSVLink>
        </Button>
      </DateContainer>

      {data === undefined && <LoadingPage height={"90px"} />}
      {data === null && (
        <p style={{ width: "100%", textAlign: "center", marginTop: "35px", marginBottom: "40px", color: "#0E7EE4" }}>No matching data found</p>
      )}
      {data && (
        <Table
          columns={columns}
          data={data}
          pagination
          size={"small"}
          sortBy={[
            {
              id: "timestamp",
              desc: true,
            },
          ]}
        />
      )}
    </>
  );
};
