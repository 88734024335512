import { useState, useMemo } from "react";
import { Row, Col } from "antd";
import { LoadingPage } from "components/Common/LoadingPage/LoadingPage";
import { DeviceActions } from "./DeviceActions";
import { Table } from "components/Common/Table/Table";

interface TableSectionProps {
  data: any[];
  setSelectedDevices?: any;
  setSelectedAgents?: any;
  openAddDevice?: any;
  openEditDevice?: any;
  openDeleteDevice?: any;
}

const TableSection = ({ data }: TableSectionProps) => {
  const columns = useMemo(
    () => [
      {
        Header: "Schedule Name",
        accessor: "automation_name",
      },
      // {
      //   Header: "Trigger",
      //   accessor: "trigger",
      // },
      // {
      //   Header: "Action",
      //   accessor: "action",
      // },
      {
        Header: "Allow Notification",
        accessor: "allow_notification",
      },
      {
        Header: "Notification Message",
        accessor: "notification_message",
      },
    ],
    []
  );

  return (
    <>
      <Row gutter={[18, 18]}>
        <Col xs={24} xl={24}>
          {data === undefined && <LoadingPage height={"114px"} />}
          {data === null && <p style={{ width: "100%", textAlign: "center", marginTop: "50px", color: "#0E7EE4" }}>Coming soon</p>}
          {data && (
            <Table
              columns={columns}
              data={data}
              pagination
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default TableSection;
