import { Col, Row } from "antd";
import { DeviceProps } from "MockData/DeviceData";
import { DeviceIcon } from "pages/DashboardPage/FloorSection/FloorModel/DeviceIcon";
import { Floor1 } from "pages/DashboardPage/FloorSection/FloorModel/Floor1";
import { Floor2 } from "pages/DashboardPage/FloorSection/FloorModel/Floor2";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: fit-content;
  padding: 30px 65px;
`;

const SubTitle = styled.h3`
  font-size: 18px;
  margin: 0px;
  margin-bottom: 40px;
  font-weight: 600;
`;

export const FloorContainer = styled.div`
  position: relative;
  height: fit-content;
  width: fit-content;
`;

export const DeviceHistory = ({ deviceList, visibleDeviceObject, historyDeviceData }: any) => {
  return (
    <>
      <Container>
        <Row>
          <Col xs={24} xl={24}>
            <SubTitle>Floor 01</SubTitle>
          </Col>
          <Col xs={24} xl={24}>
            <Floor1 />
            {deviceList &&
              deviceList["1"] &&
              deviceList["1"].map((item: DeviceProps) => (
                <>
                  <DeviceIcon
                    key={item.device_id}
                    deviceId={item.device_id}
                    agentId={item.agent_id}
                    x={item.ui_location[0]}
                    y={item.ui_location[1]}
                    showTempZone={true}
                    visible={visibleDeviceObject && visibleDeviceObject[item.agent_id]}
                    firebaseDeviceData={historyDeviceData && historyDeviceData[item.device_id + ":;0"]}
                  />
                </>
              ))}
          </Col>
        </Row>
        <Row>
          <Col xs={24} xl={24}>
            <SubTitle>Floor 02</SubTitle>
          </Col>
          <Col xs={24} xl={24}>
            {/* <FloorContainer> */}
            <Floor2 />
            {deviceList &&
              deviceList["2"] &&
              deviceList["2"].map((item: DeviceProps) => (
                <>
                  <DeviceIcon
                    key={item.device_id}
                    agentId={item.agent_id}
                    deviceId={item.device_id}
                    x={item.ui_location[0]}
                    y={item.ui_location[1]}
                    showTempZone={true}
                    visible={visibleDeviceObject && visibleDeviceObject[item.agent_id]}
                    firebaseDeviceData={historyDeviceData && historyDeviceData[item.device_id + ":;0"]}
                  />
                </>
              ))}
            {/* </FloorContainer> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};
