import styled from 'styled-components'

export const DateRangeContainer = styled.div`
  position: absolute;
  top: -50px;
  right: 0;
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 11px;
`

export  const DateRangeTitle = styled.h3`
  margin: 0px;
  font-weight: 600;
  font-size: 14px;
  margin-right: 11px;
`