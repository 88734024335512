export function ViewIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.34303 7.99951C3.42409 10.2318 5.57697 11.7 8.00012 11.7C10.4229 11.7 12.5755 10.2323 13.6567 8.00052C12.5757 5.7682 10.4228 4.3 7.99963 4.3C5.57684 4.3 3.42426 5.76775 2.34303 7.99951ZM1.07874 7.63423C0.973775 7.8663 0.973754 8.1325 1.07868 8.36458C2.31864 11.1073 4.95196 13 8.00012 13C11.0478 13 13.6808 11.1079 14.921 8.3658C15.026 8.13372 15.026 7.86753 14.9211 7.63545C13.6811 4.89273 11.0478 3 7.99963 3C4.95192 3 2.31893 4.89217 1.07874 7.63423Z"
        fill="#212529"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00472 9.70082C8.95932 9.70082 9.72116 8.933 9.72116 8.00065C9.72116 7.0683 8.95932 6.30049 8.00472 6.30049C7.05012 6.30049 6.28828 7.0683 6.28828 8.00065C6.28828 8.933 7.05012 9.70082 8.00472 9.70082ZM8.00472 11.0008C9.67065 11.0008 11.0212 9.6576 11.0212 8.00065C11.0212 6.34371 9.67065 5.00049 8.00472 5.00049C6.33879 5.00049 4.98828 6.34371 4.98828 8.00065C4.98828 9.6576 6.33879 11.0008 8.00472 11.0008Z"
        fill="#212529"
      />
    </svg>
  );
}
