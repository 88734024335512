import { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";

export interface UserContextValue {
  role: string;
}

const UserContext = createContext<UserContextValue>(null as unknown as UserContextValue);

export function useUserContext() {
  return useContext(UserContext);
}

export function UserProvider({ children }: PropsWithChildren<{}>) {
  const [role, setRole] = useState<string>("");

  useEffect(() => {
    const userRole = localStorage.getItem("role");
    if (userRole) {
      setRole(userRole);
    } else {
      setRole("");
    }
  }, []);

  return <UserContext.Provider value={{ role: role }}>{children}</UserContext.Provider>;
}
