import EbillSaving from 'assets/pages/Energy/EbillSaving.svg';
import Co2Emission from 'assets/pages/Energy/Co2Emission.svg';
import EnergySaving from 'assets/pages/Energy/EnergySaving.svg';
import PaybackPeriod from 'assets/pages/Energy/PaybackPeriod.svg';
import EBaseline from 'assets/pages/Energy/EBaseline.svg';

export const AccumulateSavingMockData = [
  {
    id: 'accumulate',
    data: [
      { x: '2022-03-15 00:00:00', y: '2317.0' },
      { x: '2022-04-15 00:00:00', y: '2753.9' },
      { x: '2022-05-15 00:00:00', y: '3127.0' },
      { x: '2022-06-15 00:00:00', y: '3572.1' },
      { x: '2022-07-15 00:00:00', y: '4131.72' },
    ],
  },
];

export const DailySavingMockData = [
  { label: '2022-07-11 00:00:00', kWh: '59.2' },
  { label: '2022-07-12 00:00:00', kWh: '11.44' },
  { label: '2022-07-13 00:00:00', kWh: '12.36' },
  { label: '2022-07-14 00:00:00', kWh: '68.23' },
  { label: '2022-07-15 00:00:00', kWh: '95.96' },
];

export const SummaryOfEnergyData: any[] = [
  {
    no: 1,
    desc: 'Total Energy Saving in THB',
    year1: '33,748',
    year2: '32,060',
    year3: '30,457',
    year4: '28,934',
    year5: '27,488',
    year6: '26,113',
    year7: '24,808',
    year8: '23,567',
    year9: '22,389',
    year10: '21,269',
    sum: '270,834',
  },
  {
    no: 2,
    desc: 'Operation saving in THB',
    year1: '240,000',
    year2: '240,000',
    year3: '240,000',
    year4: '240,000',
    year5: '240,000',
    year6: '240,000',
    year7: '240,000',
    year8: '240,000',
    year9: '240,000',
    year10: '240,000',
    sum: '2,400,000',
  },
  {
    no: 3,
    desc: 'Total Saving THB',
    year1: '273,748',
    year2: '272,060',
    year3: '270,457',
    year4: '268,934',
    year5: '267,488',
    year6: '266,113',
    year7: '264,808',
    year8: '263,567',
    year9: '262,389',
    year10: '261,269',
    sum: '2,670,834',
  },
  {
    no: 4,
    desc: 'Equipment cost in THB',
    year1: '259,910',
    year2: '0',
    year3: '0',
    year4: '0',
    year5: '0',
    year6: '0',
    year7: '0',
    year8: '0',
    year9: '0',
    year10: '0',
    sum: '259,160',
  },
  {
    no: 5,
    desc: 'Software + MA expense in THB',
    year1: '0',
    year2: '0',
    year3: '0',
    year4: '0',
    year5: '0',
    year6: '0',
    year7: '0',
    year8: '0',
    year9: '0',
    year10: '0',
    sum: '0',
  },
  {
    no: 6,
    desc: 'Interest in THB',
    year1: '0',
    year2: '0',
    year3: '0',
    year4: '0',
    year5: '0',
    year6: '0',
    year7: '0',
    year8: '0',
    year9: '0',
    year10: '0',
    sum: '0',
  },
  {
    no: 7,
    desc: 'Total Implementation in THB',
    year1: '259,160',
    year2: '0',
    year3: '0',
    year4: '0',
    year5: '0',
    year6: '0',
    year7: '0',
    year8: '0',
    year9: '0',
    year10: '0',
    sum: '259,160',
  },
  {
    no: 8,
    desc: 'Energy Savings Share in THB',
    year1: '13,838',
    year2: '272,060',
    year3: '270,457',
    year4: '268,934',
    year5: '267,488',
    year6: '266,113',
    year7: '264,808',
    year8: '263,567',
    year9: '262,389',
    year10: '261,269',
    sum: '2,410,924',
  },
  {
    no: 9,
    desc: 'AltoTech (30%)',
    year1: '4,151',
    year2: '81,618',
    year3: '81,137',
    year4: '80,680',
    year5: '80,246',
    year6: '79,834',
    year7: '79,442',
    year8: '79,070',
    year9: '78,717',
    year10: '78,381',
    sum: '723,277',
  },
  {
    no: 10,
    desc: 'Estimated B.Grimm (70%)',
    year1: '9,686',
    year2: '190,442',
    year3: '189,320',
    year4: '188,254',
    year5: '187,241',
    year6: '186,279',
    year7: '185,365',
    year8: '184,497',
    year9: '183,672',
    year10: '182,889',
    sum: '1,687,647',
  },
  {
    no: 11,
    desc: '(Real) Accumulated Saving in THB',
    year1: '223',
    year2: '0',
    year3: '0',
    year4: '0',
    year5: '0',
    year6: '0',
    year7: '0',
    year8: '0',
    year9: '0',
    year10: '0',
    sum: '223',
  },
  {
    no: 10,
    desc: '(Real) Accumulated Saving in THB',
    year1: '1,026.44',
    year2: '0',
    year3: '0',
    year4: '0',
    year5: '0',
    year6: '0',
    year7: '0',
    year8: '0',
    year9: '0',
    year10: '0',
    sum: '1,026.44',
  },
];

export const SummaryMockData = [
  {
    title: 'E-Bill Saving',
    value: 18179.56,
    unit: 'THB/year',
    targetValue: 1506400,
    bgColor: '#0E7EE4',
    iconUrl: EbillSaving,
  },
  {
    title: 'Energy Saving',
    value: 9.34,
    unit: '%',
    targetValue: 30,
    bgColor: '#14B0BC',
    iconUrl: EnergySaving,
  },
  {
    title: 'CO2 Emission',
    value: 60558,
    unit: 'ton',
    targetValue: 155834,
    bgColor: '#788796',
    iconUrl: Co2Emission,
  },
  {
    title: 'Payback Period',
    value: 3.2,
    unit: 'years',
    targetValue: 4.6,
    bgColor: '#AB63E8',
    iconUrl: PaybackPeriod,
  },
  {
    title: 'E-baseline',
    value: 52.9,
    unit: 'EUI',
    targetValue: 90,
    bgColor: '#FF7A00',
    iconUrl: EBaseline,
  },
];

export const EmptySummaryData = [
  {
    title: '',
    value: '',
    unit: '',
    targetValue: '',
    bgColor: '#0E7EE4',
    iconUrl: EbillSaving,
  },
  {
    title: '',
    value: '',
    unit: '',
    targetValue: '40',
    bgColor: '#14B0BC',
    iconUrl: EnergySaving,
  },
  {
    title: '',
    value: '',
    unit: '',
    targetValue: '',
    bgColor: '#788796',
    iconUrl: Co2Emission,
  },
  {
    title: '',
    value: '',
    unit: '',
    targetValue: '',
    bgColor: '#AB63E8',
    iconUrl: PaybackPeriod,
  },
  {
    title: '',
    value: '',
    unit: '',
    targetValue: '',
    bgColor: '#FF7A00',
    iconUrl: EBaseline,
  },
];

export const MockDataBuilding = [
  { title: 'Usable area', desc: '1,174.6 m2' },
  { title: 'High', desc: '7 m' },
  { title: 'Type of Building', desc: 'Office building' },
  { title: 'Window to Wall Ratio (WWR)', desc: '0.37' },
  {
    title: 'Calculation Type',
    desc: '3.1.2 Medium General Service (normal tariff) กิจการขนาดกลาง อัตราปกติ (แรงดัน 12-24 kVA)',
  },
];

export const MockDataBuildingEvaluation = [
  {
    title: 'OTTV (W/m2)',
    bec: '50',
    heps: '40',
    econ: '30',
    bgrimm: '61',
    zeb: '20',
  },
  {
    title: 'RTTV (W/m2)',
    bec: '15',
    heps: '15',
    econ: '12',
    bgrimm: '14',
    zeb: '12',
  },
  {
    title: 'LPD (W/m2)',
    bec: '14',
    heps: '10',
    econ: '6',
    bgrimm: '5.46',
    zeb: '2',
  },
  {
    title: 'WHOLE (kWh/year)',
    bec: '171',
    heps: '141',
    econ: '82',
    bgrimm: '79',
    zeb: '57',
  },
  {
    title: 'การใช้พลังงานโดยรวมของอาคารอ้างอิง (kWh/year)',
    bec: '',
    heps: '',
    econ: '',
    bgrimm: '101,195.74',
    zeb: '',
  },
];