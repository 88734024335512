import Modal from "react-modal";
import "./AddParameterModal.css";
import DeviceTableSection from "./DeviceTableSection";
import { UnderlineTab } from "components/Common/UnderlineTab/UnderlineTab";
import { ActionWrap, Button, Container, customStyles, DeviceActionForm, SubdeviceTitle, Title, WrapButton, WrapTable } from "./AddParameterModalComponents";
import { AcControl } from "components/Common/DeviceModal/AcControl";
import { SwitchControl } from "components/Common/DeviceModal/SwitchControl";
import { OauControl } from "components/Common/DeviceModal/OauControl";
import { BlindControl } from "components/Common/DeviceModal/BlindControl";
import "antd/dist/antd.css";

export const AddDeviceActionModal = ({
  value,
  onChange,
  isOpen,
  onClose,
  onSubmit,
  deviceData,
  currentAgent,
  setCurrentAgent,
  agentsList,
  onSelectRow,
}: any) => {
  const onChangeAgentTab = (tabName: string) => {
    setCurrentAgent(tabName);
  };

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles} contentLabel="Edit modal">
        <Container>
          <Title>Device Action</Title>
          <DeviceActionForm>
            <SubdeviceTitle>Device</SubdeviceTitle>
            <WrapTable>
              <DeviceTableSection data={deviceData} onSelectRow={onSelectRow} />
            </WrapTable>

            <SubdeviceTitle>Action</SubdeviceTitle>
            {currentAgent && <UnderlineTab listOfTabs={agentsList} selectedTab={currentAgent} onChange={onChangeAgentTab} />}
            <ActionWrap>
              {deviceData && (currentAgent === "bac0hvac" || currentAgent === "itm_fcu") && (
                <AcControl command={value[currentAgent]["command"]} handleChangeCommand={onChange} deviceData={deviceData} agentId={currentAgent} />
              )}
              {deviceData && currentAgent === "tasmota" && (
                <SwitchControl
                  command={value[currentAgent]["command"]}
                  deviceInfo={deviceData}
                  handleChangeCommand={onChange}
                  agentId={currentAgent}
                />
              )}
              {deviceData && currentAgent === "itm_oau" && (
                <OauControl command={value[currentAgent]["command"]} deviceInfo={deviceData} handleChangeCommand={onChange} agentId={currentAgent} />
              )}
              {deviceData && (currentAgent === "tuyacloudcurtain" || currentAgent === "tuyablinds") && (
                <BlindControl
                  command={value[currentAgent]["command"]}
                  handleChangeCommand={onChange}
                  deviceData={deviceData}
                  agentId={currentAgent}
                />
              )}
            </ActionWrap>
            <WrapButton>
              <Button onClick={onSubmit}>Add</Button>
              <Button onClick={onClose} data-outlined>
                Cancel
              </Button>
            </WrapButton>
          </DeviceActionForm>
        </Container>
      </Modal>
    </>
  );
};
