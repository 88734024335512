import { useMemo } from "react";
import { Row, Col } from "antd";
import { LoadingPage } from "components/Common/LoadingPage/LoadingPage";
import { UserActions } from "./UserActions";
import { Table } from "components/Common/Table/Table";

interface TableSectionProps {
  data: any[];
  openAddDevice: any;
  openViewUser: any;
  openDeleteUser: any;
}

const TableSection = ({ data, openAddDevice, openViewUser, openDeleteUser }: TableSectionProps) => {
  const columns = useMemo(
    () => [
      {
        Header: "Username",
        accessor: "username",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
      },
      {
        Header: "Role",
        accessor: "role",
      },
      {
        id: "action",
        Header: <div tw="flex-1 flex justify-center">Action</div>,
        accessor: "id",
        disableSortBy: true,
        Cell: ({ row }: any) => <UserActions userInfo={row.original} openViewUser={openViewUser} openDeleteUser={openDeleteUser} />,
      },
    ],
    []
  );

  return (
    <>
      <Row gutter={[18, 18]}>
        <Col xs={24} xl={24}>
          {data === undefined && <LoadingPage height={"114px"} />}
          {data === null && <p style={{ width: "100%", textAlign: "center", marginTop: "50px", color: "#0E7EE4" }}>Coming soon</p>}
          {data && <Table columns={columns} data={data} pagination openAddDevice={openAddDevice} />}
        </Col>
      </Row>
    </>
  );
};

export default TableSection;
