import styled from 'styled-components';

export const GraphContainer = styled.div`
  width: 100%;
  height: 180px;
  padding-left: 20px;
  margin-bottom: 20px;
`;

export const GraphName = styled.h2`
  margin: 0px;
  width: 100%;
  text-align: start;
  font-size: 16px;
  padding-left: 17px;
`;

export const RoomHeading = styled.div`
  height: fit-content;
  width: fit-content;
  background-color: #086bba;
  display: flex;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 5px;
`;

export const H2 = styled.h2`
  color: white;
  font-size: 18px;
  margin: 0px;
`;

export const Grid = styled.div<any>`
  display: flex;
  width: ${(props) => props.width}%;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  justify-content: ${(props) => (props.column ? 'flex-start' : 'flex-start')};
  align-items: ${(props) => (props.column ? 'flex-start' : 'flex-start')};
`;

export const OuterBlock = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;

export const InnerBlock = styled.div`
  width: 100%;
  height: fit-content;
  border-radius: 10px;
  background-color: #ecf7fd;
  padding: 10px 15px;
`;

export const BodyBlock = styled.div`
  width: 100%;
  height: fit-content;
  margin-top: 20px;
`;

export const BlockHeading = styled.h3`
  font-size: 18px;
  margin: 0px;
  width: 100%;
  text-align: center;
  color: #086bba;
  font-weight: 600;
`;

export const Heading = styled.p`
  font-size: 18px;
  margin: 0px;
`;

export const ParameterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const ParameterName = styled.p`
  font-size: 16px;
  text-align: start;
  margin: 0px;
  color: black;
  font-weight: 400;
  margin-top: 5px;
`;

export const ParameterValueRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

export const ParameterValue = styled.p`
  font-size: 16px;
  margin: 0px 5px 0px 0px;
  color: #086bba;
`;

export const ParameterUnit = styled.p`
  font-size: 15px;
  margin: 0px;
  color: #868686;
  font-weight: 400;
`;

export const CloseButton = styled.img`
  width: 20px;
  height: 20px;
  margin: 5px;
  cursor: pointer;
`;

export const CalendarIconBox = styled.div`
  width: 40px;
  height: 40px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 8px 8px 0px;
  border: 1px solid rgba(14, 126, 228, 0.6);
  border-left: 0px;
`;

export const CalendarIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const DateRangePickerContainer = styled.div<any>`
  display: flex;
  flex-direction: row;
  width: fit-content;
  justify-content: flex-end;
  padding-right: 20px;
  margin-bottom: ${(props) => props.marginBottom ? `${props.marginBottom}px` : '18px'};
  margin-left: 0px;
`;
