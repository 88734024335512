import Modal from "react-modal";
import styled from "styled-components";
import { ResponsiveLine } from "@nivo/line";
import { Grid } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepickerStyle.css";
import CloseButtonIcon from "assets/common/icon/close.svg";
import CalendarIconImage from "assets/common/icon/calendarIcon.svg";
import { SearchButton } from "components/Common/SearchButton/SearchButton";
import { LoadingPage } from "components/Common/LoadingPage/LoadingPage";

const ModalTypeMap: any = {
  DAIC: {
    floor_1: {
      title: "Power Consumption of Total Load",
      yAxis: "kW",
    },
    floor_2: {
      title: "Power Consumption of AC Load",
      yAxis: "kW",
    },
    grid: {
      title: "Buy-in Power",
      yAxis: "kW",
    },
    people: {
      title: "People In & Out",
      yAxis: "people",
    },
  },
  BGM: {
    floor_1: {
      title: "Power Consumption of the 1st Floor",
      yAxis: "kW",
    },
    floor_2: {
      title: "Power Consumption of the 2nd Floor",
      yAxis: "kW",
    },
    grid: {
      title: "Buy-in Power",
      yAxis: "kW",
    },
    solar: {
      title: "Power Generation from Solar",
      yAxis: "kW",
    },
    people: {
      title: "People In & Out",
      yAxis: "people",
    },
  },
  MTW: {
    floor_1: {
      title: "Power Consumption of the 1st Floor",
      yAxis: "kW",
    },
    floor_2: {
      title: "Power Consumption of the 2nd Floor",
      yAxis: "kW",
    },
    grid: {
      title: "Buy-in Power",
      yAxis: "kW",
    },
    solar: {
      title: "Power Generation from Solar",
      yAxis: "kW",
    },
    people: {
      title: "People In & Out",
      yAxis: "people",
    },
  },
};

export const defs = [
  {
    id: "dots",
    type: "patternDots",
    background: "inherit",
    color: "#38bcb2",
    size: 4,
    padding: 1,
    stagger: true,
  },
  {
    id: "lines",
    type: "patternLines",
    background: "inherit",
    color: "#eed312",
    rotation: -45,
    lineWidth: 6,
    spacing: 10,
  },
];

export const fill = [
  {
    match: {
      id: "fries",
    },
    id: "dots",
  },
  {
    match: {
      id: "sandwich",
    },
    id: "lines",
  },
];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    border: "0.5px solid #f3f3f3",
    overflow: "unset",
  },
  overlay: {
    backgroundColor: "#00000066",
    zIndex: 99999,
  },
};

const HeaderTab = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const HeaderText = styled.h4`
  color: black;
`;

const CloseButton = styled.img`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 20px;
  height: 20px;
  margin: 5px;
  cursor: pointer;
`;

const CalendarIconBox = styled.div`
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: #eff2f7;
  border-radius: 0px 5px 5px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(189, 189, 189);
`;

const CalendarIcon = styled.img`
  width: 17px;
  height: 17px;
`;

export const GraphModal = ({
  selectedGraphOpen,
  closeModal,
  starttime,
  setStarttime,
  endtime,
  setEndtime,
  modalType,
  data,
  search,
  buildingCode,
}: any) => {
  return (
    <>
      <Modal isOpen={selectedGraphOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <HeaderTab>
          <Grid container spacing={2}>
            <Grid item xs={5} style={{ display: "flex", justifyContent: "flex-start" }}>
              {/* <HeaderText>{ModalTypeMap[buildingCode][modalType]["title"]}</HeaderText> */}
            </Grid>
            {starttime && endtime && (
              <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <DatePicker
                  className="input-class-first"
                  selected={starttime}
                  onChange={(time: any) => setStarttime(time)}
                  selectsStart
                  startDate={starttime}
                  endDate={endtime}
                  placeholderText="Start date"
                />
                <DatePicker
                  className="input-class-last"
                  selected={endtime}
                  onChange={(time: any) => setEndtime(time)}
                  selectsEnd
                  startDate={starttime}
                  endDate={endtime}
                  minDate={starttime}
                  placeholderText="Stop date"
                />
                <CalendarIconBox>
                  <CalendarIcon src={CalendarIconImage} />
                </CalendarIconBox>
                <SearchButton onClick={() => search()} width={"40px"} height={"40px"} />
              </Grid>
            )}
          </Grid>
          <CloseButton src={CloseButtonIcon} onClick={closeModal} />
        </HeaderTab>
        <div style={{ width: "1000px", height: "400px" }}>
          {data === undefined && (
            <div className="w-full h-full flex justify-center items-center">
              <LoadingPage />
            </div>
          )}
          {data && data[modalType] === undefined && (
            <div className="w-full h-full flex justify-center items-center">
              <LoadingPage />
            </div>
          )}
          {data === null && <p className="h-full w-full flex justify-center items-center">Something went wron</p>}
          {data && data[modalType] === null && <p className="h-full w-full flex justify-center items-center">Something went wron</p>}
          {data && modalType && data[modalType] && (
            <ResponsiveLine
              data={data[modalType]}
              margin={{ top: 50, right: 10, bottom: 45, left: 45 }}
              // xScale={{ type: 'point' }}
              xScale={{ format: "%Y-%m-%d %H:%M", type: "time", useUTC: false }}
              xFormat="time:%Y-%m-%d %H:%M"
              yScale={{ type: "linear", min: "auto", max: "auto", stacked: false, reverse: false }}
              yFormat=" >-.2f"
              // curve="cardinal"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: "bottom",
                format: "%H:%M",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -41,
                legend: "Time",
                legendOffset: 36,
                legendPosition: "middle",
              }}
              axisLeft={{
                // orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: ModalTypeMap[buildingCode][modalType]["yAxis"],
                legendOffset: -40,
                legendPosition: "middle",
                // tickValues: [0, 15, 30, 45, 60, 75],
              }}
              lineWidth={1}
              enableGridX={false}
              enableGridY={false}
              colors={{ scheme: "paired" }}
              enablePoints={false}
              pointSize={10}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              enableArea={false}
              areaOpacity={0.15}
              useMesh={true}
              legends={[
                {
                  anchor: "top",
                  direction: "row",
                  justify: false,
                  translateX: 0,
                  translateY: -15,
                  itemsSpacing: 0,
                  itemDirection: "left-to-right",
                  itemWidth: 100,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          )}
        </div>
      </Modal>
    </>
  );
};
