import { DeviceInput, DeviceName, FilterContainer, FilterWrapper, Icon, Tab, Tablist } from "./Components";
import FilterIcon from "assets/pages/Dashboard/Filter.svg";
import { useState } from "react";
import { DeviceListProps } from "MockData/DeviceData";
import { useDevicesContext } from "layouts/DevicesProvider";

interface DeviceFilterProps {
  selectedDeviceList?: DeviceListProps;
  handleSelectDevice?: (agentId: string) => void;
}

export const DeviceFilter = ({ selectedDeviceList, handleSelectDevice }: DeviceFilterProps) => {
  const [isOpened, setIsOpened] = useState(false);
  const { agents } = useDevicesContext();

  return (
    <FilterContainer>
      <FilterWrapper onClick={() => setIsOpened(!isOpened)}>
        <Icon width={18} height={18} src={FilterIcon} />
      </FilterWrapper>
      {isOpened && (
        <Tablist>
          {selectedDeviceList &&
            selectedDeviceList
              .filter((item: any) => agents.has(item.agentId))
              .map((item) => (
                <Tab>
                  <DeviceInput type="checkbox" checked={item.isOpened} onChange={() => handleSelectDevice && handleSelectDevice(item.agentId)} />
                  <Icon width={22} height={22} src={item.icon} />
                  <DeviceName>{item.name}</DeviceName>
                </Tab>
              ))}
        </Tablist>
      )}
    </FilterContainer>
  );
};
