import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

const scheduleDayOptions = ["S", "M", "T", "W", "TH", "F", "S"];

interface ScheduleDayProps {
  value: number[];
  onChange: (value: number[]) => void;
}

export const Container = styled.div(() => [tw`flex gap-x-2`]);

export const Date = styled.div<any>`
  ${tw`w-6 h-6 rounded-full flex justify-center items-center text-altoDark-medium font-bold text-[12px] cursor-pointer user-select[none]`}
  ${({ active }) => (active ? `background:linear-gradient(274.48deg, #0E7EE4 0%, #55A6F2 100%); color: white` : `background: #EDEFF9;`)}
`;

export const ScheduleDay = ({ value, onChange }: ScheduleDayProps) => {
  const handleOnChange = (day: number, onDelete: boolean) => {
    // if (onDelete) {
    //   const tempValue: any = value.filter((currentDay) => {
    //     return currentDay !== day;
    //   });
    //   onChange(tempValue);
    // } else {
    //   if (value) {
    //     let tempValue: any = [...value, day];
    //     onChange(tempValue);
    //   } else {
    //     let tempValue: any = [day];
    //     onChange(tempValue);
    //   }
    // }
    return onChange([day]);
  };

  return (
    <Container>
      {scheduleDayOptions &&
        scheduleDayOptions.map((dayOption: string, index: number) => (
          <Date active={value ? value.includes(index) : false} onClick={() => handleOnChange(index, value ? value.includes(index) : false)}>
            {dayOption}
          </Date>
        ))}
    </Container>
  );
};
