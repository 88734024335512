import styled, { keyframes } from "styled-components";
import { Row, Col } from "antd";
import FanOn from "assets/pages/Dashboard/DeviceControl/fan_mode_on.png";
import FanOff from "assets/pages/Dashboard/DeviceControl/fan_mode_off.png";
import { SelfDrivingMode } from "./SelfDrivingMode";

const deviceAnimation = keyframes`
  0% {  transform: rotate(0deg) }
  100% { transform: rotate(359deg) }
`;

const Container = styled.div`
  width: 750px;
  height: auto;
`;

const Status = styled.p`
  font-weight: 600;
  font-size: 22px;
  color: #212529;
  margin-top: 10px;
`;

const DeviceStatusBox = styled.div`
  width: 50%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const DeviceIcon = styled.img<any>`
  width: 120px;
  height: 120px;
  cursor: pointer;
  animation: rotation 1.5s infinite linear;
  animation-name: ${(props) => (props.active ? deviceAnimation : "none")};
  margin-top: 16px;
`;

const subdevices = [
  {
    subdev: 0,
    subvice_name: "subdev_0",
    location: "1st Floor Left",
  },
  {
    subdev: 1,
    subvice_name: "subdev_1",
    location: "1st Floor Right",
  },
  {
    subdev: 2,
    subvice_name: "subdev_2",
    location: "2nd Floor Left",
  },
  {
    subdev: 3,
    subvice_name: "subdev_3",
    location: "2nd Floor Right",
  },
];

export const SwitchControl = ({ command, deviceInfo, handleChangeCommand }: any) => {
  return (
    <Container>
      <Row>
        <Col xs={24}>
          <Row>
            {command &&
              subdevices.map((subdeviceItem: any) => {
                let id = "subdev_" + subdeviceItem.subdev;
                return (
                  <DeviceStatusBox>
                    {command[id] && (
                      <>
                        <DeviceIcon
                          src={command[id]["state"] === "on" ? FanOn : FanOff}
                          active={command[id]["state"] === "on"}
                          onClick={() => {
                            handleChangeCommand(
                              "state",
                              command[id]["state"] === "on" ? "on" : "off",
                              deviceInfo.agent_id,
                              subdeviceItem.subvice_name
                            );
                          }}
                        />
                        <Status>
                          {"ID : " + subdeviceItem.subdev} {command[id]["state"] === "on" ? ", STATUS : ON" : ", STATUS : OFF"}
                        </Status>
                      </>
                    )}
                  </DeviceStatusBox>
                );
              })}
          </Row>
        </Col>
        <Col xs={12}>
          <Row>
            <Col xs={24}>
              <SelfDrivingMode status={"on"} onChange={() => console.log("self")} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
