import styled from "styled-components";
import ProgressBar from "react-customizable-progressbar";

type DeviceSummaryCardProp = {
  title: string;
  on: number;
  off: number;
  icon: string;
  color: string;
};

const Container = styled.div`
  background: white;
  box-shadow: 1.4px 4.2px 18.2px rgba(196, 196, 196, 0.15);
  border-radius: 16px;
  min-height: 346px;
  overflow: scroll;
  padding: 18px 24px;
`;

const InstallDeviceContainer = styled.div`
  background: rgba(189, 190, 200, 0.1);
  opacity: 0.7;
  border: 1px dashed #bdbec8;
  box-shadow: 1.4px 4.2px 18.2px rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(28px);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 346px;
  transition: all;
  cursor: pointer;
  &:hover {
    background: rgba(189, 190, 200, 0.2);
  }
`;

const DeviceTitle = styled.h4`
  font-size: 20px;
  font-weight: bold;
`;

const WrapProgress = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WrapDeviceIcon = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  left: 0;
  top: 0;
  padding-top: 54px;
`;

const Icon = styled.img`
  width: 40px;
`;

const OnNumber = styled.p`
  font-size: 32px;
  font-weight: bold;
  margin: -4px 0px;
`;

const Unit = styled.p`
  font-size: 12px;
  font-weight: 300;
  color: #788796;
`;

const WrapStatus = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

const WrapStatusTitle = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
`;

const WrapStatusValue = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Status = styled.p`
  font-size: 16px;
  margin: 0px;
`;

const Dot = styled.div<any>`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#0E7EE4")};
  margin-right: 12px;
`;

const getProgress = (on: number, off: number): number => {
  if (on && off) {
    return (on / (on + off)) * 100;
  } else {
    return 0;
  }
};

export const DeviceSummaryCard = ({ title, on, off, icon, color }: DeviceSummaryCardProp) => {
  return (
    <>
      <Container>
        <DeviceTitle>{title}</DeviceTitle>
        <WrapProgress>
          <ProgressBar
            radius={80}
            progress={getProgress(on, off)}
            strokeWidth={12}
            strokeColor={color}
            strokeLinecap="butt"
            trackStrokeWidth={14}
            trackStrokeLinecap="butt"
            cut={0}
            rotate={270}
          />
          <WrapDeviceIcon>
            <Icon src={icon} />
            <OnNumber>{on + off}</OnNumber>
            <Unit>Total</Unit>
          </WrapDeviceIcon>
        </WrapProgress>
        <WrapStatus>
          <WrapStatusTitle>
            <Dot bgColor={color} />
            <Status>On</Status>
          </WrapStatusTitle>
          <WrapStatusValue>
            <Status>{on} times</Status>
          </WrapStatusValue>
        </WrapStatus>
        <WrapStatus>
          <WrapStatusTitle>
            <Dot bgColor={color} />
            <Status>Off</Status>
          </WrapStatusTitle>
          <WrapStatusValue>
            <Status>{off} times</Status>
          </WrapStatusValue>
        </WrapStatus>
      </Container>
    </>
  );
};

export const InstallMoreDeviceCard = () => {
  return (
    <>
      <InstallDeviceContainer>
        <span style={{ fontSize: "40px", fontWeight: 300 }}>+</span>
        Please install more
        <br />
        device to see data
      </InstallDeviceContainer>
    </>
  );
};
