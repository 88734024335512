import styled from "styled-components";
import AcIconOn from "assets/pages/Dashboard/DeviceIcon/AcIconOn.svg";
import AcIconAlert from "assets/pages/Dashboard/DeviceIcon/AcIconAlert.svg";
import AcCoolMode from "assets/pages/Dashboard/DeviceIcon/AcCoolMode.svg";
import AcDryMode from "assets/pages/Dashboard/DeviceIcon/AcDryMode.svg";
import AcFanMode from "assets/pages/Dashboard/DeviceIcon/AcFanMode.svg";
import AcOffMode from "assets/pages/Dashboard/DeviceIcon/AcOffMode.svg";
import NetatmoOn from "assets/pages/Dashboard/DeviceIcon/NetatmoOn.svg";
import NetatmoOff from "assets/pages/Dashboard/DeviceIcon/NetatmoOff.svg";
import ProbeOn from "assets/pages/Dashboard/DeviceIcon/Probe.svg";
import NetatmoAlert from "assets/pages/Dashboard/DeviceIcon/NetatmoAlert.svg";
import CCTV from "assets/pages/Dashboard/DeviceIcon/CCTV.svg";
import FreshAirOn from "assets/pages/Dashboard/DeviceIcon/FreshAirOn.svg";
import FreshAirOff from "assets/pages/Dashboard/DeviceIcon/FreshAirOff.svg";
import FreshAirAlert from "assets/pages/Dashboard/DeviceIcon/FreshAirAlert.svg";
import AiGateway from "assets/pages/Dashboard/DeviceIcon/AiGateway.svg";
import IoTGateway from "assets/pages/Dashboard/DeviceIcon/IoTGateway.svg";
import BlindOn from "assets/pages/Dashboard/DeviceIcon/BlindOn.svg";
import BlindOff from "assets/pages/Dashboard/DeviceIcon/BlindOff.svg";
import ModbusMeter from "assets/pages/Dashboard/DeviceIcon/ModbusMeter.svg";
import SmartPlug from "assets/pages/Dashboard/DeviceIcon/SmartPlug.svg";
import Disconnected from "assets/pages/Dashboard/DeviceStatus/Disconnected.svg";
import Alert from "assets/pages/Dashboard/DeviceStatus/Alert.svg";
import Repair from "assets/pages/Dashboard/DeviceStatus/NotiPreventive.svg";
import { Spin } from "antd";
import BgrimmTempZoneMap from "configs/bgrimm_custom_temp_zone.json";

interface IconProps {
  key: any;
  deviceId?: string;
  agentId: string;
  onClick?: (action: any) => void;
  x: number;
  y: number;
  showTempZone?: boolean;
  temp?: number;
  visible?: boolean;
  firebaseDeviceData?: any;
  subdevices?: any;
}

const IconContainer = styled.div<any>`
  position: absolute;
  width: fit-content;
  height: fit-content;
  top: ${(props) => props.y}%;
  left: ${(props) => props.x}%;
`;

const Icon = styled.img<any>`
  width: 24px;
  max-height: 24px;
  cursor: pointer;
`;

const TempZone = styled.div<any>`
  position: absolute;
  top: ${(props) => `calc(${props.y}% + 10.8%)`};
  left: ${(props) => `calc(${props.x}% - 1.8%)`};
  width: ${(props) => `${props.width}%`};
  /* width: 6.3%; */
  height: 81%;
  background-color: ${(props) => props.bgColor};
  z-index: -1;
`;

const TempZoneValue = styled.p<any>`
  position: relative;
  display: flex;
  justify-content: space-around;
  font-size: 12px;
  font-weight: 700;
  margin: 0px;
  top: 1px;
  color: ${(props) => (props.color ? props.color : "gray")};
`;

const Co2Box = styled.div`
  position: absolute;
  /* width: 45px; */
  /* right: -12px; */
  top: 36px;
  right: -28px;
  width: 80px;
  height: 56px;
  background: linear-gradient(283.41deg, #f9faff 0%, rgba(249, 250, 255, 0.6) 100%);
  box-shadow: -3px -3px 5px #ffffff, 3px 3px 5px rgba(120, 135, 150, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 30px 30px 6px 6px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 4px;
  padding-left: 2px;
  color: #788796;
  font-size: 10px;
  font-weight: 700;
`;

const SensorTemp = styled.div`
  position: absolute;
  right: -28px;
  top: 36px;
  width: 80px;
  height: 22px;
  background: linear-gradient(283.41deg, #f9faff 0%, rgba(249, 250, 255, 0.6) 100%);
  box-shadow: -3px -3px 5px #ffffff, 3px 3px 5px rgba(120, 135, 150, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #788796;
  font-size: 10px;
  font-weight: 700;
`;

const ProbeTemp = styled.div`
  position: absolute;
  right: -10px;
  top: 36px;
  width: 50px;
  height: 22px;
  background: linear-gradient(283.41deg, #f9faff 0%, rgba(249, 250, 255, 0.6) 100%);
  box-shadow: -3px -3px 5px #ffffff, 3px 3px 5px rgba(120, 135, 150, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #788796;
  font-size: 10px;
  font-weight: 700;
`;

const PeopleCount = styled.div`
  position: absolute;
  right: -24px;
  top: 36px;
  width: 80px;
  height: 22px;
  background: linear-gradient(283.41deg, #f9faff 0%, rgba(249, 250, 255, 0.6) 100%);
  box-shadow: -3px -3px 5px #ffffff, 3px 3px 5px rgba(120, 135, 150, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #788796;
  font-size: 10px;
  font-weight: 700;
`;

const OnlineStatusImage = styled.img`
  position: absolute;
  width: 16px;
  height: 16px;
  z-index: 100;
`;

const getIcon: { [agentId: string]: { [status: string]: string } } = {
  bac0hvac: {
    on: AcIconOn,
    off: AcOffMode,
    cool: AcCoolMode,
    fan: AcFanMode,
    dry: AcDryMode,
    alert: AcIconAlert,
  },
  itm_fcu: {
    on: AcIconOn,
    off: AcOffMode,
    cool: AcCoolMode,
    fan: AcFanMode,
    dry: AcDryMode,
    alert: AcIconAlert,
  },
  ac_wifi_adapter: {
    on: AcIconOn,
    off: AcOffMode,
    cool: AcCoolMode,
    fan: AcFanMode,
    dry: AcDryMode,
    alert: AcIconAlert,
  },
  ac_wifi_adaptor: {
    on: AcIconOn,
    off: AcOffMode,
    cool: AcCoolMode,
    fan: AcFanMode,
    dry: AcDryMode,
    alert: AcIconAlert,
  },
  netatmo: {
    on: NetatmoOn,
    off: NetatmoOff,
    alert: NetatmoAlert,
  },
  son_env: {
    on: NetatmoOn,
    off: NetatmoOff,
    alert: NetatmoAlert,
  },
  airveda: {
    on: NetatmoOn,
    off: NetatmoOff,
    alert: NetatmoAlert,
  },
  cctv: {
    on: CCTV,
  },
  tasmota: {
    on: FreshAirOn,
    off: FreshAirOff,
    alert: FreshAirAlert,
  },
  aigateway: {
    on: AiGateway,
  },
  iotgateway: {
    on: IoTGateway,
  },
  tuyacloudcurtain: {
    on: BlindOn,
    off: BlindOff,
  },
  actooltip: {
    on: BlindOn,
    off: BlindOff,
  },
  tuya_temp_humid: {
    on: NetatmoOn,
    off: NetatmoOff,
    alert: NetatmoAlert,
  },
  tuya_air_quality: {
    on: NetatmoOn,
    off: NetatmoOff,
    alert: NetatmoAlert,
  },
  tuya_env_relay: {
    on: ProbeOn,
    off: ProbeOn,
    alert: NetatmoAlert,
  },
  camera: {
    on: CCTV,
  },
  tuyablinds: {
    on: BlindOn,
    off: BlindOff,
  },
  itm_oau: {
    on: FreshAirOn,
    off: FreshAirOff,
    alert: FreshAirAlert,
  },
  modbus: {
    on: ModbusMeter,
    off: ModbusMeter,
  },
  tuya_plug: {
    on: SmartPlug,
  },
  lorawan_env: {
    on: NetatmoOn,
    off: NetatmoOff,
    alert: NetatmoAlert,
  },
};

// const AcTooltipOn = ({ deviceId, agentId, x, y, temp, acStatus }: any) => {
//   return (
//     <AcTooltipContainer x={x} y={y} temp={temp}>
//       <AcTooltipWrapper>
//         <DeviceId>{deviceId}</DeviceId>
//         <AcTemp color={mapTmpTextColor(temp)}>{temp}°C</AcTemp>
//       </AcTooltipWrapper>
//     </AcTooltipContainer>
//   );
// };

const DeviceOnlineTooltip = ({ status }: any) => {
  return (
    <div className="group absolute w-4 h-4" style={{ right: "-8px", top: "-8px" }}>
      {status === "error" && (
        <>
          <OnlineStatusImage src={Alert} />
          <div
            className="hidden group-hover:block absolute z-1000 px-3 py-2 text-sm whitespace-nowrap shadow rounded-lg font-bold"
            style={{ top: "-32px", left: "16px", backdropFilter: "blur(8px)", background: "#ffffff30", color: "#EF4337" }}
          >
            Error
          </div>
        </>
      )}
      {status === "offline" && (
        <>
          <OnlineStatusImage src={Disconnected} />
          <div
            className="hidden group-hover:block absolute px-3 py-2 text-sm whitespace-nowrap shadow rounded-lg font-bold"
            style={{ top: "-32px", left: "16px", backdropFilter: "blur(8px)", background: "#ffffff30", color: "#EF4337", zIndex: 999 }}
          >
            Disconnected
          </div>
        </>
      )}
    </div>
  );
};

const DeviceWarningTooltip = ({ message }: any) => {
  return (
    <div className="group absolute w-4 h-4" style={{ left: "-8px", top: "-8px" }}>
      <OnlineStatusImage src={Alert} />
      <div
        className="hidden group-hover:block absolute z-1000 px-3 py-2 text-sm shadow rounded-lg font-bold"
        style={{ top: "-3px", left: "16px", backdropFilter: "blur(8px)", background: "#ffffff30", color: "#EF4337", width: "200px" }}
      >
        {message}
      </div>
    </div>
  );
};

const DeviceMaintenanceTooltip = ({ message }: any) => {
  return (
    <div className="group absolute w-4 h-4" style={{ left: "-8px", bottom: "-8px" }}>
      <OnlineStatusImage src={Repair} />
      <div
        className="hidden group-hover:block absolute z-1000 px-3 py-2 text-sm shadow rounded-lg font-bold"
        style={{ top: "-3px", left: "16px", backdropFilter: "blur(8px)", background: "#ffffff30", color: "#EF4337", width: "200px" }}
      >
        {message}
      </div>
    </div>
  );
};

const mapTmpZoneColor = (temp: number | string, acMode?: string) => {
  if (acMode === "off" || temp === "No Control") {
    return "#BDBEC840";
  } else {
    if (temp <= 19) {
      return "#0E7EE480";
    } else if (temp <= 20) {
      return "#0E7EE480";
    } else if (temp <= 21) {
      return "#0E7EE44D";
    } else if (temp <= 22) {
      return "#0E7EE44D";
    } else if (temp <= 23) {
      return "#8BC6FF4D";
    } else if (temp <= 24) {
      return "#8BC6FF4D";
    } else if (temp <= 25) {
      return "#A2E3A633";
    } else if (temp <= 26) {
      return "#F3A48C33";
    } else if (temp <= 27) {
      return "#F3A48C33";
    } else if (temp <= 28) {
      return "#EA663F4D";
    } else if (temp <= 29) {
      return "#EA663F4D";
    } else {
      return "#e444144d";
    }
  }
};

const mapTmpTextColor = (temp: number | string, mode?: string) => {
  if (mode === "off" || temp === "No Control") {
    return "#5f5f5f";
  } else {
    if (temp <= 19) {
      return "#065BA9";
    } else if (temp <= 20) {
      return "#065BA9";
    } else if (temp <= 21) {
      return "#065BA9";
    } else if (temp <= 22) {
      return "#065BA9";
    } else if (temp <= 23) {
      return "#065BA9";
    } else if (temp <= 24) {
      return "#065BA9";
    } else if (temp <= 25) {
      return "#2E8285";
    } else if (temp <= 26) {
      return "#EA663F";
    } else if (temp <= 27) {
      return "#EA663F";
    } else if (temp <= 28) {
      return "#EA663F";
    } else if (temp <= 29) {
      return "#EA663F";
    } else {
      return "#e44414";
    }
  }
};

const HumiditySpan = ({ humidity }: any) => {
  return (
    <>
      {!humidity && <span style={{ color: "#788796", marginLeft: "2px" }}> - %</span>}
      {humidity && humidity >= 61 ? (
        <span style={{ color: "red", marginLeft: "2px", fontWeight: 700 }}> {humidity} %</span>
      ) : (
        <span style={{ color: "#788796", marginLeft: "2px" }}> {humidity} %</span>
      )}
    </>
  );
};

const Ac = ({ agentId, firebaseDeviceData, deviceId, x, y, onClick }: any) => {
  const BgrimmTempZoneWidthMap: any = BgrimmTempZoneMap;
  const setTemperature = firebaseDeviceData?.ac?.subdev_0?.set_temperature;
  const acTempLoading = firebaseDeviceData?.loading;
  const mode = firebaseDeviceData?.ac?.subdev_0?.mode;
  return (
    <>
      <IconContainer x={x} y={y}>
        <Icon src={getIcon[agentId] && getIcon[agentId][mode]} onClick={onClick} style={{ width: "44px", maxHeight: "40px" }} />
        {setTemperature && mode === "cool" && (
          <span
            style={{
              position: "absolute",
              top: "0px",
              left: "3px",
              fontSize: "8px",
              fontWeight: "bold",
              color: "#ffffff",
              width: "fit-content",
              pointerEvents: "none",
            }}
          >
            {setTemperature}°C
          </span>
        )}
      </IconContainer>
      <TempZone
        bgColor={mapTmpZoneColor(setTemperature, mode === "off" ? "off" : "on")}
        x={x}
        y={y}
        width={deviceId && BgrimmTempZoneWidthMap[deviceId] && BgrimmTempZoneWidthMap[deviceId]["width"]}
      >
        <TempZoneValue color={mapTmpTextColor(setTemperature, mode === "off" ? "off" : "on")}>
          {acTempLoading && <Spin size="small" />}
          {setTemperature} {setTemperature !== "No Control" && "°C"}
        </TempZoneValue>
      </TempZone>
    </>
  );
};

const AcItm = ({ agentId, firebaseDeviceData, deviceId, x, y, onClick }: any) => {
  const acStatus = firebaseDeviceData?.ac?.subdev_0?.status;
  const mode = firebaseDeviceData?.ac?.subdev_0?.mode;
  const setTemperature = firebaseDeviceData?.ac?.subdev_0?.set_temperature;
  return (
    <>
      <IconContainer x={x} y={y}>
        <Icon src={getIcon[agentId] && getIcon[agentId][mode]} onClick={onClick} style={{ width: "44px", maxHeight: "40px" }} />
        {setTemperature && mode === "cool" && (
          <span
            style={{
              position: "absolute",
              top: "0px",
              left: "3px",
              fontSize: "8px",
              fontWeight: "bold",
              color: "#ffffff",
              width: "fit-content",
              pointerEvents: "none",
            }}
          >
            {setTemperature}°C
          </span>
        )}
        {agentId === "itm_fcu" && acStatus === "error" && <DeviceOnlineTooltip status={"offline"} />}
      </IconContainer>
    </>
  );
};

const AcWifiAdaptor = ({ agentId, firebaseDeviceData, x, y, onClick }: any) => {
  const mode = firebaseDeviceData?.ac?.subdev_0?.mode;
  const onlineStatus = firebaseDeviceData?.device?.subdev_0?.device_status;
  const setTemperature = firebaseDeviceData?.ac?.subdev_0?.set_temperature;
  const alertStatus = firebaseDeviceData?.maintenance?.alert_status;
  const alertMessage = firebaseDeviceData?.maintenance?.alert_message;
  const pmStatus = firebaseDeviceData?.maintenance?.preventive_maintenance_status;
  const pmMessage = firebaseDeviceData?.maintenance?.preventive_maintenance_message;
  return (
    <>
      <IconContainer x={x} y={y}>
        <Icon src={getIcon[agentId] && getIcon[agentId][mode]} onClick={onClick} style={{ width: "44px", maxHeight: "40px" }} />
        {setTemperature && mode === "cool" && (
          <span
            style={{
              position: "absolute",
              top: "0px",
              left: "3px",
              fontSize: "8px",
              fontWeight: "bold",
              color: "#ffffff",
              width: "fit-content",
              pointerEvents: "none",
            }}
          >
            {setTemperature}°C
          </span>
        )}
        {agentId === "ac_wifi_adapter" && onlineStatus === "offline" && <DeviceOnlineTooltip status={"offline"} />}
        {agentId === "ac_wifi_adapter" && alertStatus === "warning" && <DeviceWarningTooltip message={alertMessage} />}
        {agentId === "ac_wifi_adapter" && pmStatus === "warning" && <DeviceMaintenanceTooltip message={pmMessage} />}
      </IconContainer>
    </>
  );
};

const TempHumidSensor = ({ agentId, firebaseDeviceData, x, y, onClick }: any) => {
  const envTempSonoff = firebaseDeviceData?.environment?.subdev_0?.temperature?.toFixed(1);
  const envHumidSonoff = firebaseDeviceData?.environment?.subdev_0?.humidity?.toFixed(0);
  // const batteryState = firebaseDeviceData?.device?.subdev_0?.battery_state;
  const isOnline = firebaseDeviceData?.device?.subdev_0?.online_status;
  return (
    <>
      <IconContainer x={x} y={y}>
        <Icon src={getIcon[agentId] && getIcon[agentId]["on"]} onClick={onClick} />
        <SensorTemp>
          {envTempSonoff ? envTempSonoff : "-"} °C | <HumiditySpan humidity={envHumidSonoff} />
        </SensorTemp>
        {isOnline === false && <DeviceOnlineTooltip status={"offline"} />}
      </IconContainer>
    </>
  );
};

const Probe = ({ agentId, firebaseDeviceData, x, y, onClick }: any) => {
  const envTempSonoff = firebaseDeviceData?.environment?.subdev_0?.temperature?.toFixed(1);
  const isOnline = firebaseDeviceData?.device?.subdev_0?.online_status;
  const alertStatus = firebaseDeviceData?.device?.subdev_0?.alert_status;
  const alertMessage = firebaseDeviceData?.device?.subdev_0?.alert_message;
  const pmStatus = firebaseDeviceData?.maintenance?.preventive_maintenance_status;
  const pmMessage = firebaseDeviceData?.maintenance?.preventive_maintenance_message;
  return (
    <>
      <IconContainer x={x} y={y}>
        <Icon src={getIcon[agentId] && getIcon[agentId]["on"]} onClick={onClick} />
        <ProbeTemp>{envTempSonoff ? envTempSonoff : "-"} °C</ProbeTemp>
        {isOnline === false && <DeviceOnlineTooltip status={"offline"} />}
      </IconContainer>
      {alertStatus === "warning" && <DeviceWarningTooltip message={alertMessage} />}
      {pmStatus === "warning" && <DeviceMaintenanceTooltip message={pmMessage} />}
    </>
  );
};

const CctvIcon = ({ agentId, firebaseDeviceData, x, y, onClick }: any) => {
  const status = "on";
  return (
    <>
      <IconContainer x={x} y={y}>
        <Icon src={getIcon[agentId] && getIcon[agentId][status]} onClick={onClick} />
        {firebaseDeviceData?.camera_crowdcount && (
          <PeopleCount>
            {Object.values(firebaseDeviceData?.camera_crowdcount?.subdev_0).reduce((total: any, number: any) => total + number.count_people, 0)}{" "}
            persons
          </PeopleCount>
        )}
      </IconContainer>
    </>
  );
};

const NetatmoSensor = ({ agentId, firebaseDeviceData, x, y, onClick }: any) => {
  const envCo2 = firebaseDeviceData?.environment?.subdev_1?.co2?.toFixed(0);
  const envTemp = firebaseDeviceData?.environment?.subdev_1?.temperature?.toFixed(1);
  const envHumid = firebaseDeviceData?.environment?.subdev_1?.humidity?.toFixed(1);
  const status = "on";
  return (
    <IconContainer x={x} y={y}>
      <Icon src={getIcon[agentId] && getIcon[agentId][status]} onClick={onClick} />
      <Co2Box>
        <small>CO2</small>
        <div>
          {envCo2} <small>ppm</small>
        </div>
      </Co2Box>
      <SensorTemp>
        {envTemp ? envTemp : "-"} °C | <HumiditySpan humidity={envHumid} />
      </SensorTemp>
    </IconContainer>
  );
};

const AqiSensor = ({ agentId, firebaseDeviceData, x, y, onClick }: any) => {
  const envCo2 = firebaseDeviceData?.environment?.subdev_0?.co2?.toFixed(0);
  const envTemp = firebaseDeviceData?.environment?.subdev_0?.temperature?.toFixed(1);
  const envHumid = firebaseDeviceData?.environment?.subdev_0?.humidity?.toFixed(1);
  const isOnline = firebaseDeviceData?.device?.subdev_0?.online_status;
  const status = "on";
  return (
    <IconContainer x={x} y={y}>
      <Icon src={getIcon[agentId] && getIcon[agentId][status]} onClick={onClick} />
      <Co2Box>
        <small>CO2</small>
        <div>
          {envCo2} <small>ppm</small>
        </div>
      </Co2Box>
      <SensorTemp>
        {envTemp ? envTemp : "-"} °C | <HumiditySpan humidity={envHumid} />
      </SensorTemp>
      {isOnline === false && <DeviceOnlineTooltip status={"offline"} />}
    </IconContainer>
  );
};

const BlindSensor = ({ agentId, firebaseDeviceData, x, y, onClick }: any) => {
  const isOnline = firebaseDeviceData?.device?.subdev_0?.online_status;
  const blindIsOpen = firebaseDeviceData?.device?.subdev_0?.percent_position === 0 ? "off" : "on";
  return (
    <IconContainer x={x} y={y}>
      <Icon src={getIcon[agentId] && getIcon[agentId][blindIsOpen]} onClick={onClick} />
      {isOnline === false && <DeviceOnlineTooltip status={"offline"} />}
    </IconContainer>
  );
};

const ItmOauSensor = ({ agentId, firebaseDeviceData, x, y, onClick }: any) => {
  const status = firebaseDeviceData?.ac?.subdev_0?.mode === "off" ? "off" : "on";
  const isOnline = firebaseDeviceData?.device?.subdev_0?.online_status;
  return (
    <IconContainer x={x} y={y}>
      <Icon src={getIcon[agentId] && getIcon[agentId][status]} onClick={onClick} />
      {isOnline === false && <DeviceOnlineTooltip status={"offline"} />}
    </IconContainer>
  );
};

const TuyaPlug = ({ agentId, firebaseDeviceData, x, y, onClick }: any) => {
  const status = firebaseDeviceData?.ac?.subdev_0?.mode === "off" ? "off" : "on";
  const isOnline = firebaseDeviceData?.device?.subdev_0?.online_status;
  return (
    <IconContainer x={x} y={y}>
      <Icon src={getIcon[agentId] && getIcon[agentId][status]} onClick={onClick} />
      {isOnline === false && <DeviceOnlineTooltip status={"offline"} />}
    </IconContainer>
  );
};

const Tasmota = ({ agentId, firebaseDeviceData, x, y, onClick }: any) => {
  const status = firebaseDeviceData?.ac?.subdev_0?.mode === "off" ? "off" : "on";
  const isOnline = firebaseDeviceData?.device?.subdev_0?.online_status;
  return (
    <IconContainer x={x} y={y}>
      <Icon src={getIcon[agentId] && getIcon[agentId][status]} onClick={onClick} />
      {isOnline === false && <DeviceOnlineTooltip status={"offline"} />}
    </IconContainer>
  );
};

const TuyaMeter = ({ agentId, firebaseDeviceData, x, y, onClick }: any) => {
  const status = "on";
  const isOnline = firebaseDeviceData?.device?.subdev_0?.online_status;
  return (
    <IconContainer x={x} y={y}>
      <Icon src={getIcon[agentId] && getIcon[agentId][status]} onClick={onClick} />
      {isOnline === false && <DeviceOnlineTooltip status={"offline"} />}
    </IconContainer>
  );
};

const DeviceIconMap = ({ agentId, firebaseDeviceData, deviceId, x, y, onClick }: any): any => ({
  bac0hvac: <Ac agentId={agentId} firebaseDeviceData={firebaseDeviceData} deviceId={deviceId} x={x} y={y} onClick={onClick} />,
  itm_fcu: <AcItm agentId={agentId} firebaseDeviceData={firebaseDeviceData} x={x} y={y} deviceId={deviceId} onClick={onClick} />,
  ac_wifi_adapter: <AcWifiAdaptor agentId={agentId} firebaseDeviceData={firebaseDeviceData} x={x} y={y} onClick={onClick} />,
  son_env: <TempHumidSensor agentId={agentId} firebaseDeviceData={firebaseDeviceData} x={x} y={y} onClick={onClick} />,
  tuya_temp_humid: <TempHumidSensor agentId={agentId} firebaseDeviceData={firebaseDeviceData} x={x} y={y} onClick={onClick} />,
  cctv: <CctvIcon agentId={agentId} firebaseDeviceData={firebaseDeviceData} x={x} y={y} onClick={onClick} />,
  camera: <CctvIcon agentId={agentId} firebaseDeviceData={firebaseDeviceData} x={x} y={y} onClick={onClick} />,
  netatmo: <NetatmoSensor agentId={agentId} firebaseDeviceData={firebaseDeviceData} x={x} y={y} onClick={onClick} />,
  airveda: <AqiSensor agentId={agentId} firebaseDeviceData={firebaseDeviceData} x={x} y={y} onClick={onClick} />,
  tuya_air_quality: <AqiSensor agentId={agentId} firebaseDeviceData={firebaseDeviceData} x={x} y={y} onClick={onClick} />,
  tuya_env_relay: <Probe agentId={agentId} firebaseDeviceData={firebaseDeviceData} x={x} y={y} onClick={onClick} />,
  tuyacloudcurtain: <BlindSensor agentId={agentId} firebaseDeviceData={firebaseDeviceData} x={x} y={y} onClick={onClick} />,
  tuyablinds: <BlindSensor agentId={agentId} firebaseDeviceData={firebaseDeviceData} x={x} y={y} onClick={onClick} />,
  itm_oau: <ItmOauSensor agentId={agentId} firebaseDeviceData={firebaseDeviceData} x={x} y={y} onClick={onClick} />,
  tuya_plug: <TuyaPlug agentId={agentId} firebaseDeviceData={firebaseDeviceData} x={x} y={y} onClick={onClick} />,
  tasmota: <Tasmota agentId={agentId} firebaseDeviceData={firebaseDeviceData} x={x} y={y} onClick={onClick} />,
  modbus: <TuyaMeter agentId={agentId} firebaseDeviceData={firebaseDeviceData} x={x} y={y} onClick={onClick} />,
  lorawan_env: <AqiSensor agentId={agentId} firebaseDeviceData={firebaseDeviceData} x={x} y={y} onClick={onClick} />,
  ac_wifi_adaptor: <AcWifiAdaptor agentId={agentId} firebaseDeviceData={firebaseDeviceData} x={x} y={y} onClick={onClick} />,
});

export const DeviceIcon = ({ deviceId, agentId, onClick, x, y, visible, firebaseDeviceData }: IconProps) => {
  if (!visible) {
    return null;
  }
  return <>{DeviceIconMap({ agentId, firebaseDeviceData, deviceId, x, y, onClick })[agentId]}</>;
};
