import { createContext, PropsWithChildren, useContext, useState } from "react";
import BuildingConfig from "configs/building_config.json";

export interface BuildingContextValue {
  buildingCode: string;
  buildingName: string;
}

const BuildingContext = createContext<BuildingContextValue>(null as unknown as BuildingContextValue);

export function useBuildingContext() {
  return useContext(BuildingContext);
}

export function BuildingProvider({ children }: PropsWithChildren<{}>) {
  const buildingConfig: { [siteCode: string]: any } = BuildingConfig;
  const siteMap: { [code: string]: string } = Object.keys(buildingConfig).reduce(
    (key, value) => ({ ...key, [value]: buildingConfig[value].building_name }),
    {}
  );
  const code = localStorage.getItem("hotel_code");
  const [buildingCode] = useState<string>(code ? code : "d");
  const [buildingName] = useState<string>(code ? siteMap[code] : "");

  return <BuildingContext.Provider value={{ buildingCode: buildingCode, buildingName: buildingName }}>{children}</BuildingContext.Provider>;
}
