import styled from 'styled-components';
import {
  BlockContainer,
  SubBlockHeader,
  BlockHeader4,
  BlockValue,
} from 'components/Common/components';
import Co2EmissionIcon from 'assets/pages/Dashboard/Co2EmissionIcon.svg';
import { Row, Col } from 'antd';

type BgColor = {
  bgColor?: string;
};

interface EnergySavingCompareProps {
  value?: string | number;
  header: string;
  imgUrl: string;
  bgColor?: string;
  color?: string;
  updatedAt?: string;
  imageBgColor?: string;
  unit?: string;
}

const Container = styled(BlockContainer)<BgColor>`
  background: ${(props) =>
    props.bgColor
      ? props.bgColor
      : 'linear-gradient(180deg, rgba(243, 249, 255, 0) 0%, rgba(230, 242, 255, 0.76) 100%)'};
  box-shadow: 0px 2px 8px rgba(145, 158, 171, 0.25);
  flex-direction: row;
`;

const ImageContainer = styled.div<any>`
  width: 80px;
  height: 80px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px;
  background: ${(props) => (props.bgColor ? props.bgColor : 'transparent')};
  margin-right: 12px;
`;

const Image = styled.img`
  width: 35px;
`;

const Wrapper = styled.div`
  width: calc(100% - 64px);
  height: 100%;
`;

const Header = styled.h3`
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  color: #ff7a00;
  margin: 0px;
`;

const Value = styled.p`
  font-weight: bold;
  font-size: 32px;
  line-height: 47px;
  color: #212529;
  margin: 0px;
`;

const Unit = styled.small`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

export const AnalyticSummary = ({
  value,
  header,
  imgUrl,
  bgColor,
  color,
  updatedAt,
  imageBgColor,
  unit,
}: EnergySavingCompareProps) => {
  return (
    <>
      <Container bgColor={bgColor}>
        <ImageContainer bgColor={imageBgColor}>
          <Image src={imgUrl} />
        </ImageContainer>
        <Wrapper>
          <Row>
            <Col sm={24}>
              <Row>
                <Header style={{ color: color }}>{header}</Header>
              </Row>
              <Row>
                <Value>
                  {value} <Unit>{unit}</Unit>
                </Value>
              </Row>
            </Col>
          </Row>
        </Wrapper>
      </Container>
    </>
  );
};
