export const pmData: { [device_id: string]: any[] } = {
  shell_rama2_ac_1: [
    {
      id: "AC1-0001",
      name: "preventive maintenance",
      due: "2022-07-31",
      completed_at: "2022-07-31",
      assigned_to: "tecnician team",
    },
  ],
  shell_rama2_ac_2: [
    {
      id: "73029",
      name: "ติดตามงานอาการเดิม แอร์ตัวที่ 2 ของร้านซีเล็ค มีน้ำหยด_Ref.68288",
      due: "2022-09-05",
      completed_at: "2022-09-09",
      assigned_to: "tecnician team",
    },
    {
      id: "68288",
      name: "แอร์ตัวที่ 2 ของร้านซีเล็ค น้ำหยดตอนเช้าตอนเปิดร้านทุกวัน",
      due: "2022-08-22",
      completed_at: "2022-08-24",
      assigned_to: "tecnician team",
    },
    {
      id: "AC2-0001",
      name: "preventive maintenance",
      due: "2022-07-31",
      completed_at: "2022-07-31",
      assigned_to: "tecnician team",
    },
    {
      id: "52919",
      name: "แก้ไข แอร์ในร้าน Select มีน้ำไหลออกจากเครื่อง จำนวน 1 เครื่อง",
      due: "2022-05-27",
      completed_at: "2022-05-29",
      assigned_to: "tecnician team",
    },
  ],
  shell_rama2_ac_3: [
    {
      id: "AC3-0001",
      name: "preventive maintenance",
      due: "2022-07-31",
      completed_at: "2022-07-31",
      assigned_to: "tecnician team",
    },
  ],
  shell_rama2_ac_4: [
    {
      id: "AC4-0001",
      name: "preventive maintenance",
      due: "2022-07-31",
      completed_at: "2022-07-31",
      assigned_to: "tecnician team",
    },
  ],
  ebc2dd2a42e4e29f46a94q: [
    {
      id: "Freezer1-0001",
      name: "preventive maintenance",
      due: "2022-07-31",
      completed_at: "2022-07-31",
      assigned_to: "tecnician team",

    },
  ],
  ebbd6a1f74fd8e71b5zrs0: [
    {
      id: "Freezer1-0001",
      name: "preventive maintenance",
      due: "2022-07-31",
      completed_at: "2022-07-31",
      assigned_to: "tecnician team",

    }],
  eb6c6e422496f076f0jtwe: [
    {
      id: "Freezer1-0001",
      name: "preventive maintenance",
      due: "2022-07-31",
      completed_at: "2022-07-31",
      assigned_to: "tecnician team",

    }
  ]
};