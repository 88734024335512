import { useState, useCallback, useEffect } from "react";
import { PageContainer } from "components/Common/components";
import SettingTypeSection from "./SettingTypeSection";
import { getMaintenanceTaksByStatus } from "services/api.service";
import { TasksManagementSection } from "./TaskManagementSection";
import { Col, Row } from "antd";
import { useBuildingContext } from "layouts/BuildingProvider";
import { updateMaintenanceTask } from "services/api.service";
import axios from "axios";
import toast from "react-hot-toast";
import { useDisclosure } from "utils/hooks";
import { OpenedTaskManagementSection } from "./OpenedTaskManagementSection";
import { FiDownload } from "react-icons/fi";
import Button from "components/Common/Button";
import { CSVLink } from "react-csv";
import { ButtonWrapper } from "components/Common/Button/Button";

interface DataProps {
  new: any[];
  acknowledged: any[];
  done: any[];
}

type TabProps = "new" | "acknowledged" | "done";

const MaintenancePage = () => {
  const acknowledgeDialog = useDisclosure();
  const doneDialog = useDisclosure();
  const closeDialog = useDisclosure();
  const { buildingCode } = useBuildingContext();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DataProps>({
    new: [],
    acknowledged: [],
    done: [],
  });
  const [selectedType, setSelectedType] = useState<TabProps>("new");
  const [selectedIoTDevice, setSelectedIoTDevice] = useState("all");

  const handleSelectIoTDevice = (device: string) => {
    setSelectedIoTDevice(device);
  };

  const getData = async (tabName: TabProps) => {
    try {
      const newTask = await getMaintenanceTaksByStatus(tabName);
      // sort response by unix_time stamp
      const sortedNewTask = newTask.data.sort((a: any, b: any) => {
        return a.unix_timestamp - b.unix_timestamp;
      });
      setData((prev) => ({ ...prev, [tabName]: sortedNewTask }));
    } catch (err) {
      setData((prev) => ({
        ...prev,
        [tabName]: [],
      }));
    }
  };

  const onSubmitAcknowledge = useCallback(async (id: string) => {
    try {
      setLoading(true);
      await toast.promise(updateMaintenanceTask({ task_id: id, alert_status: "acknowledged" }), {
        loading: "Sending...",
        success: (res: any) => {
          setLoading(false);
          acknowledgeDialog.onClose();
          closeDialog.onClose();
          getData(selectedType);
          return <b>Successful</b>;
        },
        error: (error: any) => {
          setLoading(false);
          if (axios.isAxiosError(error) && error.response?.status === 400) {
            return <b>Failed</b>;
          }
          return <b>Failed</b>;
        },
      });
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(<b>Failed to acknowledge task: {e.message}</b>);
      }
      throw e;
    }
  }, []);

  const onSubmitDone = useCallback(async (id: string) => {
    try {
      setLoading(true);
      await toast.promise(updateMaintenanceTask({ task_id: id, alert_status: "done" }), {
        loading: "Sending...",
        success: (res: any) => {
          setLoading(false);
          doneDialog.onClose();
          closeDialog.onClose();
          getData(selectedType);
          return <b>Successful</b>;
        },
        error: (error: any) => {
          setLoading(false);
          if (axios.isAxiosError(error) && error.response?.status === 400) {
            return <b>Failed</b>;
          }
          return <b>Failed</b>;
        },
      });
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(<b>Failed to acknowledge task: {e.message}</b>);
      }
      throw e;
    }
  }, []);

  const onSubmitClose = useCallback(async (id: string) => {
    try {
      setLoading(true);
      await toast.promise(updateMaintenanceTask({ task_id: id, alert_status: "normal" }), {
        loading: "Sending...",
        success: (res: any) => {
          setLoading(false);
          acknowledgeDialog.onClose();
          closeDialog.onClose();
          getData(selectedType);
          return <b>Successful</b>;
        },
        error: (error: any) => {
          setLoading(false);
          if (axios.isAxiosError(error) && error.response?.status === 400) {
            return <b>Failed</b>;
          }
          return <b>Failed</b>;
        },
      });
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(<b>Failed to close task: {e.message}</b>);
      }
      throw e;
    }
  }, []);

  const handleSelectType = (type: any) => {
    setSelectedType(type);
  };

  useEffect(() => {
    getData(selectedType);
  }, [selectedType]);

  return (
    <PageContainer>
      <Row gutter={[18, 18]}>
        <Col xs={24}>
          <SettingTypeSection
            selectedType={selectedType}
            handleSelectType={handleSelectType}
            selectedIoTDevice={selectedIoTDevice}
            handleSelectIoTDevice={handleSelectIoTDevice}
          />
        </Col>
        <Col xs={24} xl={24}>
          <h1 className="font-bold">All tasks</h1>
          {data?.[selectedType] && data?.[selectedType]?.length !== 0 && (
            <ButtonWrapper>
              <Button active title={""} size={"medium"} bgColor={"#0E7EE4"} width={132} icon={<FiDownload size={15} color={"white"} />}>
                <CSVLink data={data[selectedType] ? data[selectedType] : []} style={{ color: "white" }}>
                  Download
                </CSVLink>
              </Button>
            </ButtonWrapper>
          )}
        </Col>
        <Col xs={24} xl={24}>
          {selectedType === "new" && (
            <TasksManagementSection
              data={data[selectedType]}
              hotelCode={buildingCode}
              selectedIoTDevice={selectedIoTDevice}
              handleSelectIoTDevice={handleSelectIoTDevice}
              onSubmitAcknowledge={onSubmitAcknowledge}
              onSubmitClose={onSubmitClose}
              acknowledgeDialog={acknowledgeDialog}
              closeDialog={closeDialog}
            />
          )}
          {selectedType === "acknowledged" && (
            <OpenedTaskManagementSection
              data={data[selectedType]}
              hotelCode={buildingCode}
              selectedIoTDevice={selectedIoTDevice}
              handleSelectIoTDevice={handleSelectIoTDevice}
              onSubmitDone={onSubmitDone}
              onSubmitClose={onSubmitClose}
              doneDialog={doneDialog}
              closeDialog={closeDialog}
            />
          )}
          {selectedType === "done" && (
            <OpenedTaskManagementSection
              data={data[selectedType]}
              hotelCode={buildingCode}
              selectedIoTDevice={selectedIoTDevice}
              handleSelectIoTDevice={handleSelectIoTDevice}
              onSubmitDone={onSubmitDone}
              onSubmitClose={onSubmitClose}
              doneDialog={doneDialog}
              closeDialog={closeDialog}
            />
          )}
        </Col>
      </Row>
    </PageContainer>
  );
};

export default MaintenancePage;
