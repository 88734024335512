import FloorConfig from "configs/building_config.json";
import { FloorTab, FloorTabContainer } from "./Components";

interface FloorSelectionProps {
  selectedTab: string;
  setSelectedTab: (tabName: string) => void;
  buildingCode: any;
}

export const FloorSelection = ({ selectedTab, setSelectedTab, buildingCode }: FloorSelectionProps) => {
  const FloorConfigData: any = FloorConfig;
  if (!FloorConfig) {
    return null;
  }

  return (
    <FloorTabContainer>
      {FloorConfigData &&
        FloorConfigData[buildingCode] &&
        FloorConfigData[buildingCode]["floors"]
          .filter((item: { value: string; label: string }) => item.label !== "B")
          .map((item: { value: string; label: string }) => (
            <FloorTab key={item.value} active={selectedTab === item.label} onClick={() => setSelectedTab(item.label)}>
              {item.label}
            </FloorTab>
          ))}
    </FloorTabContainer>
  );
};
