import { ResponsiveBar } from "@nivo/bar";

export type DatumValue = string | number | Date;

export interface Datum {
  x?: DatumValue | null;
  y?: DatumValue | null;
  [key: string]: any;
}

export interface Serie {
  id: string | number;
  data: Datum[];
  [key: string]: any;
}

type BarChartProps = {
  data: Serie[];
  unit?: string;
  xUnit?: string;
  yUnit?: string;
  horizontal?: boolean;
  legend?: string;
  keys?: string[];
};

export const BarChart = (prop: BarChartProps) => (
  <>
    <ResponsiveBar
      data={prop.data}
      keys={prop.keys ? prop.keys : ["kWh"]}
      indexBy="label"
      margin={{ top: 30, right: 20, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      groupMode="grouped"
      // indexScale={{ type: 'band', round: true }}
      colors={["#0E7EE4", "#E0F0FF"]}
      borderColor={{ from: "color", modifiers: [["brighter", 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: prop.legend ? prop.legend : "Date",
        legendPosition: "middle",
        legendOffset: 32,
        format: (ticks) => {
          let returnTick;
          try {
            returnTick = ticks.toString().split(" ")[0].substring(5, 11);
          } catch (err) {
            console.log(err);
            returnTick = ticks;
          }
          return returnTick.toString();
        },
      }}
      axisLeft={{
        tickValues: 3,
        tickSize: 2,
        tickPadding: 5,
        tickRotation: 0,
        legend: prop.unit ? prop.unit : "kWh",
        legendPosition: "middle",
        legendOffset: -40,
      }}
      layout={prop.horizontal ? "horizontal" : "vertical"}
      enableGridY={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["brighter", 1.6]] }}
      // legends={[
      //   {
      //     dataFrom: 'keys',
      //     anchor: 'bottom-right',
      //     direction: 'column',
      //     justify: false,
      //     translateX: 120,
      //     translateY: 0,
      //     itemsSpacing: 2,
      //     itemWidth: 100,
      //     itemHeight: 20,
      //     itemDirection: 'left-to-right',
      //     itemOpacity: 0.85,
      //     symbolSize: 20,
      //     effects: [
      //       {
      //         on: 'hover',
      //         style: {
      //           itemOpacity: 1
      //         }
      //       }
      //     ]
      //   }
      // ]}
      animate={true}
    />
  </>
);

export const SummaryBarChart = (prop: BarChartProps) => (
  <>
    <ResponsiveBar
      data={prop.data}
      keys={prop.keys ? prop.keys : ["kWh"]}
      indexBy="label"
      margin={{ top: 0, right: 20, bottom: 20, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      groupMode="grouped"
      // indexScale={{ type: 'band', round: true }}
      colors={["#0E7EE4", "#E0F0FF"]}
      borderColor={{ from: "color", modifiers: [["brighter", 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: prop.legend ? prop.legend : "Date",
        legendPosition: "middle",
        legendOffset: 10,
        format: (ticks) => {
          let returnTick;
          try {
            returnTick = ticks.toString().split(" ")[0].substring(5, 11);
          } catch (err) {
            console.log(err);
            returnTick = ticks;
          }
          return returnTick.toString();
        },
      }}
      axisLeft={{
        tickValues: 3,
        tickSize: 2,
        tickPadding: 5,
        tickRotation: 0,
        legend: prop.unit ? prop.unit : "kWh",
        legendPosition: "middle",
        legendOffset: -40,
      }}
      layout={prop.horizontal ? "horizontal" : "vertical"}
      enableGridY={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["brighter", 1.6]] }}
      // legends={[
      //   {
      //     dataFrom: 'keys',
      //     anchor: 'bottom-right',
      //     direction: 'column',
      //     justify: false,
      //     translateX: 120,
      //     translateY: 0,
      //     itemsSpacing: 2,
      //     itemWidth: 100,
      //     itemHeight: 20,
      //     itemDirection: 'left-to-right',
      //     itemOpacity: 0.85,
      //     symbolSize: 20,
      //     effects: [
      //       {
      //         on: 'hover',
      //         style: {
      //           itemOpacity: 1
      //         }
      //       }
      //     ]
      //   }
      // ]}
      animate={true}
    />
  </>
);
