import Sunny from "assets/pages/Dashboard/weather/sunny.svg";
import GradientOutdoorTop from "assets/pages/Dashboard/weather/GradientOutdoorTop.svg";
import GradientOutdoorBottom from "assets/pages/Dashboard/weather/GradientOutdoorBottom.svg";
import Raining from "assets/pages/Dashboard/weather/Raining.svg";
import Humidity from "assets/pages/Dashboard/weather/Humidity.svg";
import AirQuality from "assets/pages/Dashboard/weather/Strom.svg";
import Wind from "assets/pages/Dashboard/weather/Wind.svg";
import styled from "styled-components";
import { BlockContainer } from "components/Common/components";

interface OutdoorType {
  data: any;
}

const Container = styled(BlockContainer)`
  background-color: tomato;
  border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0px 10px;
  background: radial-gradient(322.12% 375.43% at 294.55% -121.45%, #0e7ee4 10%, #d3ebff 100%);
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.21), 0px 16px 32px -4px rgba(225, 230, 240, 0.24);
`;

const LabelCard = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 3px;
  padding-bottom: 2px;
  font-weight: lighter;
  font-size: 10px;
  margin-top: 3px;
  color: #ffffff;
  height: 17px;
  background: ${(props) => (props.value < 50 ? "#64BD4C" : props.value < 100 ? "#F5A623" : "#eb2300")};
  border-radius: 4px;
`;

const WeatherInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const ImageDetailContainer = styled.div`
  position: relative;
  top: 5px;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 13px;
`;

const Img = styled.img``;

const RadialImageTop = styled.img`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
  width: 70%;
`;

const RadialImageBottom = styled.img`
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 0;
  width: 40%;
`;

const WeatherDetail = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 30px 10px 30px;
  z-index: 9;
`;
const Detial = styled.div`
  margin-left: 10px;
  display: inline-grid;
`;

const WeatherHeaderContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 30px 0px 0px;
`;

const ImageContainer = styled.div`
  position: relative;
  top: 0px;
  width: 46px;
  height: 46px;
  display: flex;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  background: #ffffff97;
  border-radius: 22.5px;
`;

const OutdoorImage = styled.img`
  width: 28px;
  height: 28px;
`;

const OutdoorText = styled.p`
  font-size: 10px;
  margin: 0px;
`;

export const OutdoorWeather = ({ data }: OutdoorType) => {
  // const [plotData, setPlotData]: any = useState();
  // const [unit, setUnit] = useState('°C');
  // const [currentTab, setCurrentTab] = useState('temperature');

  return (
    <>
      <Container>
        <RadialImageTop src={GradientOutdoorTop} alt="gradient" width="100%" />
        <RadialImageBottom src={GradientOutdoorBottom} alt="gradient" width="100%" />
        <WeatherInfo>
          <WeatherHeaderContainer style={{ width: "60%", paddingRight: 0 }}>
            <ImageContainer>
              <OutdoorImage src={Sunny} alt="sunny" />
            </ImageContainer>
            <OutdoorText>
              Outdoor,
              <br />
              {data?.location}
            </OutdoorText>
          </WeatherHeaderContainer>
          <WeatherHeaderContainer style={{ justifyContent: "flex-end", width: "fit-content", padding: 0 }}>
            <h4 style={{ display: "flex", fontSize: "32px", padding: "0px", margin: "0px" }}>
              {data ? Number(data?.temperature?.value).toFixed(1) : 0} <span style={{ fontSize: "12px", paddingTop: "24px" }}>&#8451;</span>
            </h4>
          </WeatherHeaderContainer>
        </WeatherInfo>

        <WeatherDetail>
          <div style={{ width: "100%", display: "flex" }}>
            <ImageDetailContainer>
              <Img src={Raining} alt="raining" />
            </ImageDetailContainer>
            <Detial>
              <small style={{ fontSize: "10px", color: "#788796" }}>Dew point</small>
              <strong style={{ fontSize: "14px" }}>
                {data ? data?.dew_point?.value : 0}
                &#8451;
              </strong>
            </Detial>
          </div>

          <div style={{ width: "100%" }}>
            <div style={{ width: "100%", display: "flex" }}>
              <ImageDetailContainer>
                <Img src={AirQuality} alt="air" />
              </ImageDetailContainer>
              <Detial>
                <small style={{ fontSize: "10px", color: "#788796" }}>Air Quality Index</small>
                <strong style={{ fontSize: "14px", display: "flex" }}>
                  {data ? data?.air_quality_index?.value : 0} &nbsp;{" "}
                  <LabelCard value={data ? data?.air_quality_index?.value : 0}>
                    {data && data?.air_quality_index?.value < 50 && "Goode"}
                    {data && data?.air_quality_index?.value < 100 && "Moderate"}
                    {data && data?.air_quality_index?.value >= 100 && "Unhealthy"}
                  </LabelCard>
                </strong>
              </Detial>
            </div>
          </div>
        </WeatherDetail>

        <WeatherDetail>
          <div style={{ width: "100%", display: "flex" }}>
            <ImageDetailContainer>
              <Img src={Humidity} alt="humi" />
            </ImageDetailContainer>
            <Detial>
              <small style={{ fontSize: "10px", color: "#788796" }}>Humidity</small>
              <strong style={{ fontSize: "14px" }}>{data ? data?.relative_humidity?.value : 0}%</strong>
            </Detial>
          </div>

          <div style={{ width: "100%" }}>
            <div style={{ width: "100%", display: "flex" }}>
              <ImageDetailContainer>
                <Img src={Wind} alt="wind" />
              </ImageDetailContainer>
              <Detial>
                <small style={{ fontSize: "10px", color: "#788796" }}>Wind</small>
                <strong style={{ fontSize: "14px" }}>{data ? data?.wind_speed?.value : 0} km/h</strong>
              </Detial>
            </div>
          </div>
        </WeatherDetail>
      </Container>
    </>
  );
};
