import styled from "styled-components";
import { Row, Col } from "antd";
import InImage from "assets/pages/Dashboard/DeviceControl/cctv_activity_in.svg";
import OutImage from "assets/pages/Dashboard/DeviceControl/cctv_activity_out.svg";
import { useEffect, useRef, useState } from "react";

const Container = styled.div`
  width: 750px;
  height: auto;
`;

const CountBox = styled.div`
  width: 100%;
  height: 131px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 13.3931px;
  margin-bottom: 8px;
`;

const InnerBox = styled.div`
  width: 100%;
  height: 80px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.p`
  margin: 0px;
  font-size: 26px;
  line-height: 37px;
  display: flex;
  align-items: flex-end;
  color: #212529;
  margin-left: 8px;
`;

const Number = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 51px;
  color: #0e7ee4;
  margin: 0px;
`;

const UpdatedAt = styled.div`
  width: 123px;
  height: 25px;
  background: linear-gradient(93.31deg, rgba(225, 234, 251, 0.385) 3.03%, rgba(216, 229, 254, 0.33) 105.45%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
`;

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding: 6px;
  box-shadow: 2.54475px 5.0895px 25.4475px rgba(161, 183, 205, 0.2);
  backdrop-filter: blur(50.895px);
  border-radius: 10px;
  margin-bottom: 12px;
`;

const VideoBox = styled.img`
  width: 100%;
  height: 250px !important;
`;

const DeviceIdMap: any = {
  "68:39:43:85:60:05": 1,
  "44:EF:BF:F1:84:24": 2,
  "68:39:43:85:35:E4": 3,
  "44:EF:BF:A0:14:4C": 6,
  "44:EF:BF:9F:DD:71": 7,
  "44:EF:BF:A0:09:C2": 8,
  "44:EF:BF:A0:0B:AF": 0,
};

export const CctvControl = ({ command, deviceInfo }: any) => {
  const WS_URL = `wss://stream.altotech.net/socket/${DeviceIdMap[deviceInfo?.device_id]}`;
  let ImageRef = useRef<any>();
  const [sockets, setSockets] = useState<any[]>([]);

  const getNewDatetimeFormat = (datetime: any) => {
    const months: any = {
      0: "Jan",
      1: "Feb",
      2: "Mar",
      3: "Apr",
      4: "May",
      5: "Jun",
      6: "Jul",
      7: "Aug",
      8: "Sep",
      9: "Oct",
      10: "Nov",
      11: "Dec",
    };
    const d = new Date(datetime);
    const date = d.getDate();
    const month = months[d.getMonth()];
    const year = d.getFullYear().toString().substring(2);
    const hours = d.getHours();
    const minutes = d.getMinutes();
    return month + " " + date + ", " + year + "  " + hours + ":" + minutes;
  };

  useEffect(() => {
    setSockets([new WebSocket(WS_URL)]);

    setSockets((prev: any) => {
      prev[0].onopen = function () {
        prev[0].onmessage = function (event: any) {
          const reader = new FileReader();
          reader.onload = () => {
            if (ImageRef.current) {
              ImageRef.current.src = reader.result;
            }
          };
          reader.readAsDataURL(event.data);
        };
      };
      return prev;
    });

    return () => {
      setSockets((prev: any) => {
        prev[0].close();
        return prev;
      });
    };
  }, []);

  return (
    <Container>
      <Row>
        {command?.cameraType === "camera_crowdcount" && (
          <>
            {Object.keys(command)
              .filter((item: any) => item !== "cameraType")
              .map((key: any) => {
                return (
                  <>
                    <Col
                      xs={8}
                      key={key}
                      style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginBottom: "32px" }}
                    >
                      <Text style={{ marginRight: "8px" }}>
                        <strong>ZONE</strong>: {key}
                      </Text>
                      <CountBox style={{ height: "88px" }}>
                        <InnerBox>
                          <Number style={{ paddingBottom: "8px" }}>{command[key]["count_people"]}</Number>
                          <Text>persons</Text>
                        </InnerBox>
                      </CountBox>
                      <UpdatedAt>{command?.[key]?.["timestamp"] && getNewDatetimeFormat(command?.[key]?.["timestamp"])}</UpdatedAt>
                    </Col>
                  </>
                );
              })}
          </>
        )}
        {command?.cameraType === "camera_inout" && (
          <>
            <Col xs={12}>
              <Row>
                {/* <ImageContainer>
              <Image src={CCTV} />
            </ImageContainer> */}
                <VideoContainer>
                  {/* <ZoomBox>
                <ZoomIcon src={Zoom} />
              </ZoomBox> */}
                  <VideoBox ref={ImageRef} />
                </VideoContainer>
              </Row>
            </Col>
            <Col xs={12}>
              <Row>
                <Col xs={24}>
                  <CountBox>
                    <InnerBox>
                      <img src={InImage} />
                      <Text>In</Text>
                    </InnerBox>
                    <InnerBox>
                      <Row>
                        <Col xs={24}>
                          <InnerBox>
                            <Number>{command && command.count_people_in}</Number>
                            <Text>pax.</Text>
                          </InnerBox>
                        </Col>
                        <Col xs={24} style={{ display: "flex", justifyContent: "center" }}>
                          <UpdatedAt>{command && getNewDatetimeFormat(command.timestamp)}</UpdatedAt>
                        </Col>
                      </Row>
                    </InnerBox>
                  </CountBox>
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <CountBox>
                    <InnerBox>
                      <img src={OutImage} />
                      <Text>Out</Text>
                    </InnerBox>
                    <InnerBox>
                      <Row>
                        <Col xs={24}>
                          <InnerBox>
                            <Number>{command && command.count_people_out}</Number>
                            <Text>pax.</Text>
                          </InnerBox>
                        </Col>
                        <Col xs={24} style={{ display: "flex", justifyContent: "center" }}>
                          <UpdatedAt>{command && getNewDatetimeFormat(command.timestamp)}</UpdatedAt>
                        </Col>
                      </Row>
                    </InnerBox>
                  </CountBox>
                </Col>
              </Row>
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
};
