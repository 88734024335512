import { useEffect, useState } from "react";
import styled from "styled-components";
import AltoSupportQR from "assets/common/images/AltoSupportQR.png";
import { Toaster } from "react-hot-toast";
import { GrClose } from "react-icons/gr";
import tw from "twin.macro";

const Container = styled.div<any>`
  position: fixed;
  bottom: 94px;
  right: 24px;
  z-index: 99999;
  display: ${(props) => (props.isShown ? "block" : "none")};
`;

const Button = styled.button<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  padding-left: 10px;
  padding-right: 10px;
  height: ${(props) => (props.height ? `${props.height}px` : "32px")};
  font-size: 14px;
  color: white;
  border: 0px;
  font-weight: medium;
  border-radius: ${(props) => (props.borderRadius ? `${props.borderRadius}px` : "8px")};
  background: ${(props) => props.bgColor};
  font-weight: bold;
  box-shadow: rgb(0 0 0 / 15%) 0px 4px 12px 0px;
  cursor: pointer;
  /* :hover {
    opacity: 0.95;
  } */
`;

const TooltipContainer = styled.div`
  position: absolute;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 300px;
  height: 320px;
  padding: 16px;
  border-radius: 8px;
  top: -332px;
  right: 0px;
  background: white;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
`;

const TooltipWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`;

const LineQR = styled.img`
  height: 140px;
`;

const Title = styled.h3`
  font-size: 24px;
  margin-bottom: 10px;
`;

const Text = styled.p`
  margin: 0px;
`;

const CloseContainer = tw.div`hidden group-hover:flex absolute top-0 right-0 bg-white w-5 h-5 rounded-full justify-center items-center hover:bg-[#f3f3f3]`;

export const ContactSupport = (prop: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isShown, setIsShown] = useState(true);

  useEffect(() => {
    return () => {};
  }, []);

  if (window.location.pathname === "/" || window.location.pathname.includes("login")) {
    return null;
  }

  return (
    <>
      <Container isShown={isShown}>
        <div className="w-full h-full group">
          <Button onClick={() => setIsOpen(!isOpen)} bgColor={"#00B900"} height={60} borderRadius={30}>
            LINE
            <CloseContainer onClick={() => setIsShown(false)}>
              <GrClose size={10} />
            </CloseContainer>
          </Button>
          <TooltipContainer style={{ display: isOpen ? "flex" : "none" }}>
            <TooltipWrap>
              <Title>Contact Us</Title>
              <Text>Add our line</Text>
              <LineQR src={AltoSupportQR} />
              <p>or</p>
              <Button bgColor={"#00B900"}>
                <a href="line://ti/p/@170gcigy" style={{ padding: "0px", color: "white", fontWeight: "bold" }} target="_blank">
                  CHAT WITH US
                </a>
              </Button>
            </TooltipWrap>
          </TooltipContainer>
        </div>
      </Container>
      <Toaster />
    </>
  );
};
